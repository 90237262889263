<template>
  <label class="custom-switch">
    <input
      type="checkbox"
      :name="name"
      class="custom-switch-input"
      :value="value"
      :checked="isChecked"
      @change="handleChange"
    />
    <span class="custom-switch-indicator"></span>
    <span class="custom-switch-description" v-if="description">{{
      description
    }}</span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    value: {
      type: [Boolean, String],
      default: true
    },
    uncheckedValue: {
      type: [Boolean, String],
      default: false
    },
    checked: {
      type: [Boolean, String],
      default: false
    },
    name: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    }
  },
  computed: {
    isChecked() {
      if (this.checked === '0' || this.checked === 0) {
        return false === Boolean(this.value)
      }

      if (this.checked === '1' || this.checked === 1) {
        return true === Boolean(this.value)
      }

      return Boolean(this.checked) === Boolean(this.value)
    }
  },
  methods: {
    handleChange({ target: { checked } }) {
      this.$emit(
        'change',
        checked ? Boolean(this.value) : Boolean(this.uncheckedValue)
      )
    }
  }
}
</script>
