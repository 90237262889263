import axios from 'axios'

export default {
  props: ['id'],
  data() {
    return {
      validation: {},
      pending: false,
      continueEditing: false,
      continueEditingModal: false
    }
  },
  computed: {
    isNew() {
      return this.id === undefined
    }
  },
  methods: {
    initSave() {
      this.continueEditing = false
    },
    initContinue() {
      this.continueEditing = true
    },
    initContinueModal() {
      this.continueEditingModal = true
    },
    async fetchData() {
      if (!this.isNew) {
        let { data } = await axios.get(
          this.$app.route(`${this.resourceRoute}.show`, {
            [this.modelName]: this.id
          })
        )

        Object.keys(data).forEach(key => {
          if (key === 'data') {
            this.data = data[key]
          }

          if (key === 'meta') {
            this.meta = data[key]
          }

          /*
          if (key == 'meta' && data['meta']['title']) {
            this.title = data['meta']['title']
          }
*/

          if (
            key === 'data' &&
            data['data']['attribute_data'] &&
            data['data']['attribute_list']
          ) {
            Object.keys(data['data']['attribute_list']).forEach(listkey => {
              let handle = data['data']['attribute_list'][listkey].handle
              if (_.has(data['data']['attribute_data'], handle)) {
                /*                this.model.attributes[handle] = {
                                  web: {
                                    lt: data['data']['attribute_data'][handle]['web']['lt']
                                  }
                                }*/
                this.model.attributes[handle] =
                  data['data']['attribute_data'][handle]['web']['lt']
              } else {
                this.model.attributes[handle] = ''
              }
            })
          }

          if (key === 'data' && data['data']['elements']) {
            Object.keys(data['data']['elements']).forEach(elementKey => {
              this.model.elements.push(
                data['data']['elements'][elementKey]['id']
              )
            })
          }

          if (key === 'data' && data['data']['collections']) {
            Object.keys(data['data']['collections']).forEach(collectionKey => {
              this.model.collections.push(
                data['data']['collections'][collectionKey]['id']
              )
            })
          }

          if (key === 'data' && data['data']['categories']) {
            Object.keys(data['data']['categories']).forEach(categoryKey => {
              this.model.categories.push(
                data['data']['categories'][categoryKey]['id']
              )
            })
          }

          if (key === 'data' && data['data']['certificates']) {
            Object.keys(data['data']['certificates']).forEach(
              certificateKey => {
                this.model.certificates.push(
                  data['data']['certificates'][certificateKey]['id']
                )
              }
            )
          }

          if (key === 'data' && data['data']['units']) {
            this.model.units = []
            Object.keys(data['data']['units']).forEach(unitKey => {
              this.model.units.push(data['data']['units'][unitKey]['id'])
            })
          }

          if (key === 'data') {
            Object.keys(data['data']).forEach(dataKey => {
              if (dataKey in this.model) {
                if (
                  dataKey !== 'permissions' &&
                  dataKey !== 'elements' &&
                  dataKey !== 'collections' &&
                  dataKey !== 'units' &&
                  dataKey !== 'categories' &&
                  dataKey !== 'certificates' &&
                  dataKey !== 'colors'
                ) {
                  this.model[dataKey] = data.data[dataKey]
                }
              }
            })
          } else {
            Object.keys(data).forEach(dataKey => {
              if (dataKey in this.model) {
                if (
                  dataKey !== 'elements' &&
                  dataKey !== 'collections' &&
                  dataKey !== 'units' &&
                  dataKey !== 'categories' &&
                  dataKey !== 'colors'
                ) {
                  this.model[dataKey] = data[dataKey]
                }
              }
            })
          }
        })

        this.onModelChanged()
      }
    },
    onModelChanged() {},
    feedback(name) {
      if (this.stateFeedback(name)) {
        return this.validation.errors[name][0]
      }
    },
    state(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? false
        : null
    },
    stateFeedback(name) {
      return this.validation.errors !== undefined &&
        this.validation.errors.hasOwnProperty(name)
        ? true
        : null
    },
    async onSubmit() {
      this.pending = true
      let router = this.$router
      let action = this.isNew
        ? this.$app.route(`${this.resourceRoute}.store`)
        : this.$app.route(`${this.resourceRoute}.update`, {
            [this.modelName]: this.id
          })

      let formData = this.$app.objectToFormData(this.model)

      if (!this.isNew) {
        formData.append('_method', 'PATCH')
      }
      try {
        let { data } = await axios.post(action, formData)

        this.pending = false

        this.$app.noty[data.status](data.message)

        if (this.isModal) {
          this.$emit('context-changed')
        }

        if (!this.continueEditing && !this.continueEditingModal) {
          if (this.listPath) {
            router.push(this.listPath)
          }
        }

        if (this.continueEditing) {
          this.validation.errors = []
          this.fetchData()
        }

        if (this.continueEditingModal) {
          if (data.id !== 0) {
            router.push(this.listPath + '/' + data.id + '/edit')
          }
        }

        if (this.isModal) {
          this.refreshData()
          this.$emit('close')
        }
      } catch (e) {
        this.pending = false

        // Validation errors
        if (e.response.status === 422) {
          //console.log(e.response.data)
          this.validation = e.response.data
          return
        }

        this.$app.error(e)
      }
    },
    modalClose() {
      this.$emit('close')
    }
  },
  created() {
    this.$eventHub.$on('modal-close', this.modalClose)
    this.$eventHub.$on('continue-save', this.initSave)
    this.$eventHub.$on('continue-editing', this.initContinue)
    this.$eventHub.$on('continue-editing-modal', this.initContinueModal)
    this.fetchData()
  }
}
