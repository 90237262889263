<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('geo.titles.city-edit') }}
            </h3>

            <!-- Country name -->
            <div
              role="group"
              class="form-row form-group form-row-info mb-0 mt-0"
            >
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_name') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <template v-if="data && data.name">
                  {{ data.country_name }}
                </template>
              </div>
            </div>

            <!-- Code -->
            <div role="group" class="form-row form-group form-row-info">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_code') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                {{ data.country_code }}
              </div>
            </div>

            <!-- City Name -->
            <b-form-group
              :label="trans('geo.labels.city-name') + ' *'"
              label-for="name"
              label-cols-sm="3"
              :feedback="feedback('name')"
            >
              <b-form-input
                id="name"
                name="name"
                v-model="model.name"
                :state="state('name')"
              ></b-form-input>

              <b-form-feedback>
                {{ feedback('name') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Population -->
            <b-form-group
              :label="trans('geo.labels.adjust-by-population') + ' *'"
              label-for="name"
              label-cols-sm="3"
              :feedback="feedback('population')"
            >
              <b-form-input
                id="population"
                name="population"
                type="number"
                v-model="model.population"
                :state="state('population')"
                class="form-control-status"
                min="0"
              ></b-form-input>

              <b-form-feedback>
                {{ feedback('population') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Latitude -->
            <b-form-group
              :label="trans('geo.labels.latitude') + ' *'"
              label-for="latitude"
              label-cols-sm="3"
              :feedback="feedback('latitude')"
            >
              <b-form-input
                id="latitude"
                name="latitude"
                type="number"
                v-model="model.latitude"
                :state="state('latitude')"
                class="form-control-status"
              ></b-form-input>

              <b-form-feedback>
                {{ feedback('population') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Latitude -->
            <b-form-group
              :label="trans('geo.labels.longitude') + ' *'"
              label-for="longitude"
              label-cols-sm="3"
              :feedback="feedback('longitude')"
            >
              <b-form-input
                id="longitude"
                name="longitude"
                type="number"
                v-model="model.longitude"
                :state="state('longitude')"
                class="form-control-status"
              ></b-form-input>

              <b-form-feedback>
                {{ feedback('longitude') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Enabled -->
            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="enabled"
                    v-model="model.enabled"
                    :description="$t('core.label.enabled')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <!-- Manual enabled -->
            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="status_manual"
                    v-model="model.status_manual"
                    :description="$t('geo.labels.status-manual')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <!-- Modal footer -->

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 50%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 50%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'CityRecord',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'city',
      resourceRoute: 'cities',
      listPath: '/cities',
      model: {
        name: '',
        population: 0,
        latitude: '',
        longitude: '',
        enabled: false,
        status_manual: false
      },
      data: {},
      meta: {}
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('geo.title.city-edit')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    save() {
      // Setup data.
      let data = {}
      data.name = this.model.name
      data.population = this.model.population
      data.latitude = this.model.latitude
      data.longitude = this.model.longitude
      data.enabled = this.model.enabled
      data.status_manual = this.model.status_manual

      axios
        .patch(
          this.$app.route('cities.update', {
            city: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.modalClose()
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.pending = false
          if (error.response.status === 422) {
            this.validation = error.response.data
            return
          }
          this.$app.error(error)
        })
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  width: 40px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: -3px;
  display: block;
}
</style>
