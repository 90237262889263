<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form>
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('translations.title.edit-form') }}
            </h3>
            <div>
              Info content
            </div>
            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 10%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.edit_record') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 38%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'Info',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'translation',
      resourceRoute: 'translations',
      listPath: '/translations',
      model: {
        value: '',
        origin_value: ''
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('translations.title.edit-form')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    save() {
      // Setup data.
      let data = {}
      data.id = this.id
      data.tkey = this.tkey
      data.origin = this.origin

      axios
        .patch(
          this.$app.route('translations.update.origin', {
            translation: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.$modal.hide('translation-form-edit')
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.$app.error(error)
        })
    }
  }
}
</script>
