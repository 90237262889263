// import store from '../store'
// import router from '../router/index'

export default {
  // to get config
  getConfig(config) {
    return ''
  }

  // to check for last activity time and lock/unlock screen
}
