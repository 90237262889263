<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('validation.attributes.name')"
                      :label-for="`name.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('name.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`name.${index}`"
                          :name="`name.${index}`"
                          v-model="model.name[`${index}`]"
                          :state="state('name.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>

                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('attributes.label.tooltip')"
                      :label-for="`tooltip.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('tooltip.' + index)"
                      :description="$t('attributes.notes.tooltip')"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`tooltip.${index}`"
                          :name="`tooltip.${index}`"
                          v-model="model.tooltip[`${index}`]"
                          :state="state('tooltip.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('tooltip.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group
                  :label="$t('attributes.labels.identifier')"
                  label-for="identifier"
                  label-cols-sm="3"
                >
                  <div class="data-identifier-block">
                    <span>{{ data.identifier }}</span>
                  </div>
                </b-form-group>

                <b-form-group
                  :label="$t('attributes.labels.type')"
                  label-for="identifier"
                  label-cols-sm="3"
                >
                  <div class="data-info-block">
                    <span>{{ data.type }}</span>
                  </div>
                </b-form-group>

                <b-form-group
                  :label="$t('attributes.labels.default_min_max')"
                  label-for="default_min_max"
                  label-cols-sm="3"
                  v-if="data.type == 'number_range'"
                >
                  <div class="data-min-max">
                    <div class="row">
                      <div class="col-4">
                        <b-form-input
                          id="default_min"
                          name="default_min"
                          v-model="model.default_min"
                          :state="state('default_min')"
                          type="number"
                        ></b-form-input>
                      </div>
                      <div class="col-1">
                        -
                      </div>
                      <div class="col-4">
                        <b-form-input
                          id="default_max"
                          name="default_max"
                          v-model="model.default_max"
                          :state="state('default_max')"
                          type="number"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('attributes.labels.notes')"
                      :label-for="`notes.${index}`"
                      label-cols-sm="3"
                      :description="$t('attributes.notes.notes')"
                      :feedback="feedback('notes.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`notes.${index}`"
                          :name="`notes.${index}`"
                          v-model="model.notes[`${index}`]"
                          :state="state('notes.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('notes.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>

                <div
                  class="multi-lang multi-lang-open"
                  v-if="
                    data.type == 'text' ||
                      data.type == 'textarea' ||
                      data.type == 'number'
                  "
                >
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('attributes.labels.default')"
                      :label-for="`default.${index}`"
                      label-cols-sm="3"
                      :description="$t('attributes.notes.default')"
                      :feedback="feedback('default.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`default.${index}`"
                          :name="`default.${index}`"
                          v-model="model.default[`${index}`]"
                          :state="state('default.' + index)"
                          :type="data.type == 'number' ? 'number' : 'text'"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('default.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>

                <b-form-group
                  :label="$t('attributes.labels.comment')"
                  label-for="comment"
                  :label-cols="3"
                  :description="$t('attributes.notes.comment')"
                  :feedback="feedback('comment')"
                >
                  <b-row>
                    <b-col md="12">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-comment-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="comment"
                          name="comment"
                          v-model="model.comment"
                          :state="state('comment')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab
          :title="$t('attributes.tabs.additional')"
          id="additional"
          v-if="
            data.type == 'text' ||
              data.type == 'textarea' ||
              data.type == 'number' ||
              data.type == 'wysiwyg'
          "
        >
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('attributes.labels.additional') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="
                      data.type == 'text' ||
                        data.type == 'textarea' ||
                        data.type == 'number'
                    "
                  >
                    <b-form-group
                      :label="$t('attributes.labels.placeholders')"
                      :label-for="`placeholders.${index}`"
                      label-cols-sm="3"
                      :description="$t('attributes.notes.placeholders')"
                      :feedback="feedback('placeholders.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`placeholders.${index}`"
                          :name="`placeholders.${index}`"
                          v-model="model.placeholders[`${index}`]"
                          :state="state('placeholders.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('placeholders.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <b-form-group
                    :label="$t('attributes.labels.classes')"
                    label-for="btn_class"
                    :label-cols="3"
                    :feedback="feedback('classes')"
                  >
                    <b-row>
                      <b-col md="6">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-css3"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="classes"
                            name="classes"
                            v-model="model.classes"
                            :state="state('classes')"
                          ></b-form-input>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </b-card>
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Column sizes
                </h3>
                <b-row
                  class="left-content-center row-sizes form-row form-group"
                >
                  <b-col xl="3" lg="3"></b-col>
                  <b-col xl="1" lg="1">
                    Desktop
                  </b-col>
                  <b-col xl="1" lg="1">
                    Tablet
                  </b-col>
                  <b-col xl="1" lg="1">
                    Mobile
                  </b-col>
                </b-row>
                <b-row
                  class="left-content-center row-sizes form-row form-group"
                >
                  <b-col xl="3" lg="3">
                    <label class="col-form-label" style="width: 100%;">
                      Form
                    </label>
                  </b-col>
                  <b-col xl="1" lg="1">
                    <select
                      v-model="model.layout_col_lg"
                      class="form-control-select"
                    >
                      <option
                        v-for="cSize in columnSizes"
                        :value="cSize.value"
                        :key="cSize.value"
                      >
                        {{ cSize.text }}
                      </option>
                    </select>
                  </b-col>
                  <b-col xl="1" lg="1">
                    <select
                      v-model="model.layout_col_md"
                      class="form-control-select"
                    >
                      <option
                        v-for="cSize in columnSizes"
                        :value="cSize.value"
                        :key="cSize.value"
                      >
                        {{ cSize.text }}
                      </option>
                    </select>
                  </b-col>
                  <b-col xl="1" lg="1">
                    <select
                      v-model="model.layout_col_sm"
                      class="form-control-select"
                    >
                      <option
                        v-for="cSize in columnSizes"
                        :value="cSize.value"
                        :key="cSize.value"
                      >
                        {{ cSize.text }}
                      </option>
                    </select>
                  </b-col>
                </b-row>
                <b-row
                  class="left-content-center  row-sizes form-row form-group"
                >
                  <b-col xl="3" lg="3">
                    <label class="col-form-label" style="width: 100%;">
                      View / Details page
                    </label>
                  </b-col>
                  <b-col xl="1" lg="1">
                    <select
                      v-model="model.layout_col_lg_view"
                      class="form-control-select"
                    >
                      <option
                        v-for="cSize in columnSizes"
                        :value="cSize.value"
                        :key="cSize.value"
                      >
                        {{ cSize.text }}
                      </option>
                    </select>
                  </b-col>
                  <b-col xl="1" lg="1">
                    <select
                      v-model="model.layout_col_md_view"
                      class="form-control-select"
                    >
                      <option
                        v-for="cSize in columnSizes"
                        :value="cSize.value"
                        :key="cSize.value"
                      >
                        {{ cSize.text }}
                      </option>
                    </select>
                  </b-col>
                  <b-col xl="1" lg="1">
                    <select
                      v-model="model.layout_col_sm_view"
                      class="form-control-select"
                    >
                      <option
                        v-for="cSize in columnSizes"
                        :value="cSize.value"
                        :key="cSize.value"
                      >
                        {{ cSize.text }}
                      </option>
                    </select>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab
          :title="$t('attributes.tabs.options')"
          id="options"
          v-if="data.type == 'select' || data.type == 'select_multiple'"
        >
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('attributes.titles.options') }}
                  <span
                    class="card-options text-right group-new-btn-wrap option-new-btn-wrap"
                  >
                    <b-button
                      @click="$modal.show('new-option')"
                      variant="success"
                      size="sm"
                    >
                      <i class="fe fe-plus-circle"></i>
                      {{ $t('attributes.buttons.create-option') }}
                    </b-button>
                  </span>
                </h3>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center attribute-groups attribute-options">
            <b-col xl="9" lg="8">
              <b-card>
                <draggable
                  :options="{
                    group: {
                      name: 'groups',
                      pull: false,
                      put: false
                    }
                  }"
                  v-model="model.opts"
                  @sort="saveOrderOptions(model.opts)"
                  @update="saveOrderOptions(model.opts)"
                >
                  <div
                    v-for="AttributeOption in model.opts"
                    :key="AttributeOption.id"
                    class="item draggable group"
                  >
                    <span class="title group-title">
                      <i class="fas fa-arrows-alt"></i>
                      {{ AttributeOption.value[meta.lang] }}
                    </span>
                    <span class="group-action-wrap">
                      <span
                        class="group-action"
                        @click="editOption(AttributeOption.id)"
                      >
                        <i class="fas fa-edit"></i>
                      </span>
                      <span
                        class="group-action"
                        v-confirm="{
                          html: true,
                          okText: 'Delete option',
                          cancelText: 'Cancel',
                          message: `Are you sure want delete <strong>${
                            AttributeOption.value[meta.lang]
                          }</strong> option?`,
                          animation: 'zoom',
                          ok: dialog => deleteConfirmed(dialog, AttributeOption)
                        }"
                      >
                        <i class="fas fa-trash-alt"></i>
                      </span>
                    </span>
                  </div>
                </draggable>
              </b-card>
              <!--<rawDisplayer :value="model.opts" title="Options"></rawDisplayer>-->
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('attributes.tabs.validation')" id="validation">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('attributes.labels.validation') }}
                </h3>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="required"
                        v-model="model.required"
                        :description="$t('attributes.labels.required')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
                <div
                  class="multi-lang multi-lang-open"
                  v-if="
                    data.type == 'text' ||
                      data.type == 'textarea' ||
                      data.type == 'wysiwyg' ||
                      data.type == 'number' ||
                      data.type == 'date' ||
                      data.type == 'time' ||
                      data.type == 'select' ||
                      data.type == 'select_multiple'
                  "
                >
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('attributes.labels.required-text')"
                      :label-for="`validation.required.${index}`"
                      label-cols-sm="3"
                      :description="$t('attributes.notes.required')"
                      :feedback="feedback('validation.required.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`validation.required.${index}`"
                          :name="`validation.required.${index}`"
                          v-model="model.validation.required[`${index}`]"
                          :state="state('validation.required.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('validation.required.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <!--        <v-tab :title="$t('attributes.tabs.layout')" id="layout">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('attributes.labels.layout') }}
                </h3>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3"> </b-col>
                  </b-row>
                </div>
              </b-card>
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('attributes.labels.layout') }}
                </h3>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3"> </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
    <modal
      name="new-option"
      transition="pop-out"
      :width="modalWidth"
      :heigt="modalHeight"
      :focus-trap="true"
    >
      <div class="box">
        <div class="box-part" id="bp-left">
          <div class="partition" id="partition-new">
            <div class="partition-title">CREATE OPTION</div>
            <div class="partition-form">
              <form autocomplete="false">
                <div class="autocomplete-fix">
                  <input disabled type="text" />
                </div>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('attributes.labels.option')"
                      :label-for="`opt.${index}`"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`opt.${index}`"
                          :name="`opt.${index}`"
                          v-model="opt.value[`${index}`]"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </form>

              <div style="margin-top: 42px"></div>

              <div class="button-set">
                <button id="btn-cancel" @click="cancelCreation">
                  Cancel
                </button>
                <button id="btn-confirm" @click="saveOption">
                  Create option
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="edit-option"
      transition="pop-out"
      :width="modalWidth"
      :heigt="modalHeight"
      :focus-trap="true"
    >
      <div class="box">
        <div class="box-part" id="bp-left-edit">
          <div class="partition" id="partition-edit">
            <div class="partition-title">EDIT OPTION</div>
            <div class="partition-form">
              <form autocomplete="false">
                <div class="autocomplete-fix">
                  <input disabled type="text" />
                </div>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                  >
                    <b-form-group
                      :label="$t('attributes.labels.option')"
                      :label-for="`opt.${index}`"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`opt.${index}`"
                          :name="`opt.${index}`"
                          v-model="opt.value[`${index}`]"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </form>

              <div style="margin-top: 42px"></div>

              <div class="button-set">
                <button id="btn-cancel-edit" @click="cancelEdit">
                  Cancel
                </button>
                <button id="btn-confirm-edit" @click="updateOption">
                  Update option
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import _ from 'lodash'
import draggable from 'vuedraggable'

const MODAL_WIDTH = 600
const MODAL_HEIGHT = 'auto'

export default {
  name: `AttributeEditForm`,
  components: {
    VueTabs,
    VTab,
    draggable
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'attribute',
      resourceRoute: 'attributes',
      listPath: '/attributes',
      columnSizes: [
        {
          value: 12,
          text: '100%'
        },
        {
          value: 11,
          text: '90%'
        },
        {
          value: 10,
          text: '80%'
        },
        {
          value: 6,
          text: '50%'
        },
        {
          value: 4,
          text: '33.3%'
        },
        {
          value: 3,
          text: '25%'
        },
        {
          value: 2,
          text: '20%'
        },
        {
          value: 1,
          text: '10%'
        }
      ],
      model: {
        // Data
        name: {},
        comment: '',
        required: false,
        notes: {},
        default: {},
        placeholders: {},
        tooltip: {},
        classes: '',
        validation: {},
        // Options
        opts: [],
        // Min - Max
        default_min: 0,
        default_max: 1,
        step: 1,
        unit: '',
        // Layout
        layout_row: false,
        layout_row_view: false,
        layout_col_main: 12,
        layout_col_main_view: 12,
        layout_col_lg: 12,
        layout_col_md: 12,
        layout_col_sm: 12,
        layout_col_lg_view: 12,
        layout_col_md_view: 12,
        layout_col_sm_view: 12
      },
      data: {},
      meta: {},
      attributeOptions: {},
      modalWidth: MODAL_WIDTH,
      modalHeight: MODAL_HEIGHT,
      opt: {
        id: '',
        value: {}
      },
      controlOnStart: true
    }
  },
  created() {
    this.modalWidth =
      window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH
  },
  mounted() {
    this.$title = this.$t('attributes.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('attributes.titles.unit')
  },
  methods: {
    cancelCreation() {
      this.$modal.hide('new-option')
    },
    cancelEdit() {
      this.opt.id = ''
      this.opt.value = {}
      this.$modal.hide('edit-option')
    },
    saveOrderOptions(opts) {
      for (let i = 0; i < opts.length; i++) {
        opts[i].order = i * 10
      }
    },
    sort() {
      this.model.opts = this.model.opts.sort((a, b) => a.order - b.order)
    },
    editOption(optionId) {
      let optionData = this.model.opts.find(item => item.id === optionId)
      let obj = this.copyObject(optionData)

      if (optionData) {
        this.opt.id = optionId
        this.opt.value = obj.value
        this.$modal.show('edit-option')
      }
    },
    saveOption() {
      let lastOption = _.last(this.model.opts)
      let lastOrder = 0
      if (lastOption) {
        lastOrder = lastOption.order + 10
      }

      this.model.opts.push({
        id: this.rndStr(8),
        order: lastOrder + 10,
        identifier: '',
        value: this.opt.value,
        status: 0,
        default: 0
      })
      this.opt.value = {}
      this.sort()
      this.$modal.hide('new-option')
    },
    updateOption() {
      let optionData = this.model.opts.find(item => item.id === this.opt.id)
      optionData.value = this.opt.value

      this.opt.value = {}
      this.opt.id = ''

      this.$modal.hide('edit-option')
    },
    deleteConfirmed(dialog, option) {
      let i = this.model.opts.map(item => item.id).indexOf(option.id)
      this.model.opts.splice(i, 1)
    },
    copyObject(obj) {
      let nobj = {}
      for (let key in obj) {
        if (typeof obj[key] === 'object') {
          nobj[key] = this.copyObject(obj[key])
        } else {
          nobj[key] = obj[key]
        }
      }
      return nobj
    },
    rndStr(len) {
      let text = ' '
      let chars = 'abcdefghijklmnopqrstuvwxyz123456789'

      for (let i = 0; i < len; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length))
      }

      return text
    },
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
