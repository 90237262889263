<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div
                  role="group"
                  class="form-row form-group form-row-info form-row-no-bm"
                >
                  <label class="col-3 col-form-label">
                    Auction code:
                  </label>
                  <div class="col-form-label col-form-label-value">
                    {{ model.reference }}
                  </div>
                </div>

                <div
                  role="group"
                  class="form-row form-group form-row-info form-row-no-bm"
                >
                  <label class="col-3 col-form-label">
                    Auction created at:
                  </label>
                  <div class="col-form-label col-form-label-value">
                    {{ model.placed_at }}
                  </div>
                </div>
                <div role="group" class="form-row form-group form-row-info">
                  <label class="col-3 col-form-label">
                    Auction categories count:
                  </label>
                  <div class="col-form-label col-form-label-value">
                    {{ model.auction_slots_count }}
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Auction status
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div role="group" class="form-row form-group form-row-info">
                    <label class="col-3 col-form-label">
                      Current status:
                    </label>
                    <div class="col-form-label col-form-label-value">
                      <span v-html="statusRender(model.status)"></span>
                    </div>
                  </div>
                  <b-form-group
                    label="Change status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'reviewed'"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_reviewed"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <!--                  <b-form-group
                    label="Change status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'revise' && !model.code"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses_revise"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>-->
                  <b-form-group
                    label="Change status"
                    label-for="term_max"
                    :label-cols="3"
                    :feedback="feedback('status_to')"
                    v-if="model.status == 'revise' && model.code"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-select
                            id="status_to"
                            name="status_to"
                            v-model="model.status_to"
                            :options="statuses"
                            class="form-control form-control-status"
                          >
                          </b-form-select>
                          <b-form-feedback>
                            {{ feedback('status_to') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    label="Reason for rejection"
                    label-for="canceled_content"
                    :label-cols="3"
                    :feedback="feedback('canceled_content')"
                    v-if="model.status_to == 'canceled'"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="canceled_content"
                        name="canceled_content"
                        v-model="model.canceled_content"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('canceled_content') }}
                    </b-form-feedback>
                  </b-form-group>
                  <b-form-group
                    label="Reason for adjustment"
                    label-for="revise_content"
                    :label-cols="3"
                    :feedback="feedback('revise_content')"
                    v-if="model.status_to == 'revise'"
                  >
                    <b-input-group>
                      <p-richtexteditor
                        id="revise_content"
                        name="revise_content"
                        v-model="model.revise_content"
                        :rows="10"
                      ></p-richtexteditor>
                    </b-input-group>
                    <b-form-feedback>
                      {{ feedback('revise_content') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <!--  eslint-disable -->
            <b-col md="12" xl="12" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Auction data
                </h3>
                <div
                  :key="slotIndex"
                  v-for="(slot, slotIndex) in model.auction_slots"
                  class="multi-lang multi-lang-open service-items-wrap"
                >
                  <div class="row row-service-item">
                    <div class="col-3 col-service-item-ref cat">
                      <div class="slot-title">Product category</div>
                    </div>
                    <div class="col-9 col-service-item-category cat">
                      <div class="slot-value">{{ slot.set_name }}</div>
                    </div>
                  </div>
                  <div class="row row-service-item">
                    <div class="col-3 col-service-item-ref">
                      <div class="slot-title">Reference</div>
                    </div>
                    <div class="col-9 col-service-item-category">
                      <div class="slot-value">{{ slot.slot_reference }}</div>
                    </div>
                  </div>
                  <div class="row row-service-item">
                    <div class="col-3 col-service-item-ref">
                      <div class="slot-title">Qty</div>
                    </div>
                    <div class="col-9 col-service-item-category">
                      <div class="slot-value">{{ slot.qty }} {{ slot.unit_symbol }}</div>
                    </div>
                  </div>

                  <div class="row row-service-item">
                    <div class="col-3 col-service-item-ref">
                      <div class="slot-title">Starting unit price</div>
                    </div>
                    <div class="col-9 col-service-item-category">
                      <div class="slot-value">{{ slot.price }} €</div>
                    </div>
                  </div>

                  <div class="service-items-data">

                    <div
                      :key="index"
                      v-for="(attribute, index) in slot.form_structure"
                    >
                      <div class="row row-service-item">
                        <div
                          class="col-3 col-service-item-name"
                          v-if="
                          (attribute.type == 'text' ||
                            attribute.type == 'email' ||
                            attribute.type == 'checkbox' ||
                            attribute.type == 'textarea' ||
                            attribute.type == 'wysiwyg' ||
                            attribute.type == 'number' ||
                            attribute.type == 'toggle' ||
                            attribute.type == 'upload' ||
                            attribute.type == 'radio' ||
                            attribute.type == 'chekbox' ||
                            attribute.type == 'select') &&
                            attribute.kind != 'form_data'
                        "
                        >
                          {{ attribute.name['en'] }}
                        </div>
                        <div
                          class="col-9 col-service-item-value"
                          v-if="attribute.type == 'service_form_fullname'"
                        >
                          {{ model.user_name }} {{ model.user_surname }}
                        </div>
                        <div
                          class="col-9 col-service-item-value"
                          v-if="attribute.type == 'service_form_email'"
                        >
                          {{ model.user_email }}
                        </div>
                        <div
                          class="col-9 col-service-item-value"
                          v-if="
                          (attribute.type == 'text' ||
                            attribute.type == 'email' ||
                            attribute.type == 'checkbox' ||
                            attribute.type == 'textarea' ||
                            attribute.type == 'wysiwyg' ||
                            attribute.type == 'number' ||
                            attribute.type == 'upload' ||
                            attribute.type == 'toggle' ||
                            attribute.type == 'radio' ||
                            attribute.type == 'chekbox' ||
                            attribute.type == 'select') &&
                            attribute.kind != 'form_data'
                        "
                        >
                          <template v-if="attribute.type == 'select'">
                            {{
                              optionNameById(
                                attribute.options,
                                slot.form_data[`${attribute.identifier}`]
                              )
                            }}
                          </template>
                          <template v-else-if="attribute.type == 'radio'">
                            {{
                              optionNameById(
                                attribute.options,
                                slot.form_data[`${attribute.identifier}`]
                              )
                            }}
                          </template>
                          <!--                        <template v-else-if="attribute.type == 'checkbox'">
                            <div
                              v-for="(checkValue, cK) in selectedCheckBoxOptions(
                                attribute.options,
                                model.form_data[`${attribute.identifier}`]
                              )"
                              :key="cK"
                            >
                              {{ checkValue }}
                            </div>
                          </template>-->
                          <template v-else-if="attribute.type == 'toggle'">
                            {{
                              toggleLabelByValue(
                                slot.form_data[`${attribute.identifier}`]
                              )
                            }}
                          </template>
                          <template v-else-if="attribute.type == 'wysiwyg'">
                            <div
                              v-html="slot.form_data[`${attribute.identifier}`]"
                            ></div>
                          </template>
                          <template v-else-if="attribute.type == 'upload'">
                            <div class="upload-wrap">
                              <file-preview
                                :folder="
                                slot.form_data[`${attribute.identifier}`]
                              "
                              ></file-preview>
                            </div>
                          </template>
                          <template v-else>
                            {{ slot.form_data[`${attribute.identifier}`] }}
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </b-card>
            </b-col>
          </b-row>
          <!--  eslint-enable -->
        </v-tab>

        <v-tab title="Auction dates" id="dates">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Registration
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.start_registration')"
                  label-for="start_registration"
                  horizontal
                  :label-cols="3"
                  :state="state('start_registration')"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="start_registration"
                          name="start_registration"
                          :config="config"
                          v-model="model.start_registration"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('start_registration') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('auctions.dates.end_registration')"
                  label-for="end_registration"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_registration"
                          name="end_registration"
                          :config="config"
                          v-model="model.end_registration"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_registration') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Delivery price calculation
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.start_calculation')"
                  label-for="start_calculation"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="start_calculation"
                          name="start_calculation"
                          :config="config"
                          v-model="model.start_calculation"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('start_calculation') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('auctions.dates.end_calculation')"
                  label-for="end_calculation"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_calculation"
                          name="end_calculation"
                          :config="config"
                          v-model="model.end_calculation"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_calculation') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Bids
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.start_bids')"
                  label-for="start_bids"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="start_bids"
                          name="start_bids"
                          :config="config"
                          v-model="model.start_bids"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('start_bids') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('auctions.dates.end_bids')"
                  label-for="end_bids"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_bids"
                          name="end_bids"
                          :config="config"
                          v-model="model.end_bids"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_bids') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Winner
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.start_winner')"
                  label-for="start_winner"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="start_winner"
                          name="start_winner"
                          :config="config"
                          v-model="model.start_winner"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('start_winner') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('auctions.dates.end_winner')"
                  label-for="end_winner"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_winner"
                          name="end_winner"
                          :config="config"
                          v-model="model.end_winner"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_winner') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Contract
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.start_contract')"
                  label-for="start_contract"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="start_contract"
                          name="start_contract"
                          :config="config"
                          v-model="model.start_contract"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('start_contract') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('auctions.dates.end_contract')"
                  label-for="end_contract"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_contract"
                          name="end_contract"
                          :config="config"
                          v-model="model.end_contract"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_contract') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Delivery
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.start_delivery')"
                  label-for="start_delivery"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="start_delivery"
                          name="start_delivery"
                          :config="config"
                          v-model="model.start_delivery"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('start_delivery') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('auctions.dates.end_delivery')"
                  label-for="end_delivery"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_delivery"
                          name="end_delivery"
                          :config="config"
                          v-model="model.end_delivery"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_delivery') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Closing deal
                </h3>
                <b-form-group
                  :label="$t('auctions.dates.end_close')"
                  label-for="end_close"
                  horizontal
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="5">
                      <b-input-group>
                        <p-datetimepicker
                          id="end_close"
                          name="end_close"
                          :config="config"
                          v-model="model.end_close"
                        ></p-datetimepicker>
                        <b-input-group-append>
                          <b-input-group-text data-toggle>
                            <i class="fe fe-calendar"></i>
                          </b-input-group-text>
                          <b-input-group-text data-clear>
                            <i class="fe fe-x-circle"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('end_close') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab title="History" id="history">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  History
                </h3>
                <div class="multi-lang multi-lang-open service-items-wrap">
                  <div
                    :key="index"
                    v-for="(historyRecord, index) in model.history"
                  >
                    <div class="row row-service-item row-service-item-history">
                      <div class="col-3 col-service-item-name">
                        {{ historyRecord.history_at }}
                      </div>
                      <!-- Draft -->
                      <div class="col-9 col-service-item-value">
                        <template v-if="historyRecord.status == 'verification'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <template v-if="historyRecord.status == 'draft'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Reviewed -->
                        <template v-if="historyRecord.status == 'reviewed'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Completed -->
                        <template v-if="historyRecord.status == 'completed'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                        </template>
                        <!-- Canceled -->
                        <template v-if="historyRecord.status == 'canceled'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                          <div class="row row-history-comment">
                            <div class="col-5 col-comment-label">
                              Reason for rejection:
                            </div>
                            <div class="col-7 col-comment-value">
                              <span v-html="historyRecord.content"></span>
                            </div>
                          </div>
                        </template>
                        <!-- Revise -->
                        <template v-if="historyRecord.status == 'revise'">
                          <div class="row row-history-user">
                            <div class="col-12 col-history-name">
                              {{ historyRecord.user_name }}
                              {{ historyRecord.user_surname }}
                            </div>
                          </div>
                          <div class="row row-history-status">
                            <div class="col-5 col-history-label">
                              Status:
                            </div>
                            <div class="col-7 col-history-value">
                              <span
                                v-html="statusRender(historyRecord.status_old)"
                              ></span>
                              <span class="status-divider">
                                <i class="fas fa-chevron-right"></i>
                              </span>
                              <span
                                v-html="statusRender(historyRecord.status)"
                              ></span>
                            </div>
                          </div>
                          <div class="row row-history-comment">
                            <div class="col-5 col-comment-label">
                              Reason for adjustment:
                            </div>
                            <div class="col-7 col-comment-value">
                              <span v-html="historyRecord.content"></span>
                            </div>
                          </div>
                        </template>
                        <div
                          class="row row-history-send-user"
                          v-if="historyRecord.send_to_user"
                        >
                          <div class="col-5 col-send-label">
                            Sent email to user:
                          </div>
                          <div class="col-7 col-send-value">
                            <span class="badge email-send-status">Yes</span>
                            <span class="vline-divider">
                              |
                            </span>
                            <span class="user-email-list">
                              {{ historyRecord.user_email }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="row row-history-send-user"
                          v-if="historyRecord.send_to_managers"
                        >
                          <div class="col-5 col-send-label">
                            Sent email to administrators:
                          </div>
                          <div class="col-7 col-send-value">
                            <span class="badge email-send-status">Yes</span>
                            <span class="vline-divider">
                              |
                            </span>
                            <span class="user-email-list">
                              {{ historyRecord.managers_emails }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <!--        <v-tab :title="$t('auctions.tabs.categories')" id="categories">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Categories
                </h3>
                <b-form-group
                  :label="$t('auctions.fields.categories')"
                  label-for="categories"
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="8">
                      <b-form-checkbox-group
                        stacked
                        v-model="model.categories"
                        name="categories[]"
                      >
                        <b-form-checkbox
                          v-for="category in categories"
                          :key="category.id"
                          :value="category.id"
                        >
                          {{ category.name }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <!-- conR <v-tab :title="$t('labels.url')" id="url">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-url"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.url') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.url')"
                  :label-for="`routes.${index}.slug`"
                  label-cols-sm="3"
                  :feedback="feedback('routes.' + index + '.slug')"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`routes.${index}.slug`"
                      :name="`routes.${index}.slug`"
                      v-model="model.routes[`${index}`]['slug']"
                      :state="state('routes.' + index + '.slug')"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-link"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('routes.' + index + '.slug') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.url-target')"
                  label-for="target"
                  :description="$t('validation.attributes.url-target-info')"
                  label-cols-sm="3"
                >
                  <div class="url-link">
                    <a
                      :href="
                        $app.appUrlClient +
                          '/' +
                          meta.routes[`${index}`]['slug']
                      "
                      target="_blank"
                    >
                      {{ $app.appUrlClient }}/{{
                        meta.routes[`${index}`]['slug']
                      }}
                    </a>
                    <template
                      v-if="
                        meta.routes[`${index}`]['slug'] !==
                          model.routes[`${index}`]['slug']
                      "
                    >
                      <span class="url-link-new">
                        <i class="fas fa-long-arrow-alt-right"></i>
                        {{ $app.appUrlClient }}/{{
                          model.routes[`${index}`]['slug']
                        }}
                      </span>
                    </template>
                  </div>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab> -->

        <!-- conM <v-tab :title="$t('labels.seo')" id="seo">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.seo') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('labels.meta.title')"
                  :label-for="`metadata.title.${index}`"
                  label-cols-sm="3"
                  :description="$t('labels.meta.title-note')"
                  :feedback="feedback('metadata.title.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`metadata.title.${index}`"
                      :name="`metadata.title.${index}`"
                      v-model="model.metadata.title[`${index}`]"
                      :state="state('metadata.title.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-window-maximize"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('metadata.title.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.description')"
                  :label-for="`metadata.description.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.description.${index}`"
                    :name="`metadata.description.${index}`"
                    :rows="3"
                    v-model="model.metadata.description[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.keywords')"
                  :label-for="`metadata.keywords.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.keywords.${index}`"
                    :name="`metadata.keywords.${index}`"
                    :rows="3"
                    v-model="model.metadata.keywords[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="false"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `AuctionEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'auction',
      resourceRoute: 'auctions',
      listPath: '/auctions',
      statuses_reviewed: {
        active: 'Active',
        revise: 'Revise',
        canceled: 'Canceled'
      },
      statuses_active: {
        canceled: 'Canceled',
        suspended: 'Suspended'
      },
      statuses_suspended: {
        active: 'Active'
      },
      statuses: {
        completed: 'Įvykdyta',
        canceled: 'Canceled',
        revise: 'Revise'
      },
      config: {
        wrap: true,
        time_24hr: true,
        enableTime: true
      },
      model: {
        // Data
        name: '',
        reference: '',
        status: '',
        set_name: '',
        status_to: '',
        content: '',
        placed_at: '',
        canceled_content: '',
        revise_content: '',
        categories: [],
        position: 0,
        history: [],
        enabled: false,
        auction_slots_count: 0,
        // Routes
        routes: {},
        auction_slots: [],
        form_structure: [],
        form_data: [],
        start_registration: '',
        end_registration: '',
        start_calculation: '',
        end_calculation: '',
        start_bids: '',
        end_bids: '',
        start_winner: '',
        end_winner: '',
        start_contract: '',
        end_contract: '',
        start_delivery: '',
        end_delivery: '',
        end_close: '',
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      categories: [],
      form_data: [],
      form_structure: [],
      icons: {
        opened: '<i class="fas fa-chevron-up"></i>',
        closed: '<i class="fas fa-chevron-down"></i>'
      }
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('auctions.titles.edit')
    this.initCategories()
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('auctions.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    async initCategories() {
      let { data } = await axios.get(this.$app.route('categories.list'))
      this.categories = data
    },
    toggleLabelByValue: function(value) {
      if (value) return 'Yes'

      return 'No'
    },
    optionNameById: function(options, identifier) {
      if (options) {
        let option = _.find(options, { id: identifier })
        if (option) {
          return option.text
        }
      }
      return ''
    },
    selectedCheckBoxOptions: function(options, identifiers) {
      let records = []
      if (options && identifiers) {
        identifiers.forEach(function(identifier) {
          let option = _.find(options, { id: identifier })
          if (option) {
            //return option.text
            records.push(option.text)
          }
        })
      }
      return records
    },
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('auctions.status.' + status) +
        '<span>'
      )
    }
  }
}
</script>
