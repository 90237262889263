<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('auctions.titles.lots') }}
        </h3>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="auctions_lots.search"
        delete-route="auctions_lots.destroy"
        action-route="auctions_lots.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(auction_type)="row">
            <template v-if="row.item.auction_data.auction_type === 1">
              <div class="auction-type-sell">
                <i class="fas fa-chevron-circle-up"></i> Sell
              </div>
            </template>
            <template v-if="row.item.auction_data.auction_type === 2">
              <div class="auction-type-buy">
                <i class="fas fa-chevron-circle-down"></i> Buy
              </div>
            </template>
          </template>
          <template v-slot:cell(name)="row">
            -
          </template>
          <template v-slot:cell(country_code)="row">
            <template v-if="row.item.auction_data.auction_address_country_code">
              {{ row.item.auction_data.auction_address_country_code }}
            </template>
          </template>
          <template v-slot:cell(country_name)="row">
            <template v-if="row.item.auction_data.auction_address_country_name">
              {{ row.item.auction_data.auction_address_country_name }}
            </template>
          </template>
          <template v-slot:cell(flag)="row">
            <img
              class="country-flag"
              :src="getFlag(row.item.auction_data.auction_address_country_code)"
              :alt="row.item.auction_data.auction_address_country_name"
            />
          </template>
          <template v-slot:cell(participants)="row">
            <div>
              <b-button
                v-if="row.item.can_edit"
                :to="`/lots/${row.item.id}/participants`"
                class="ipsAction ipsAction-150"
              >
                Participants
                <span class="btn-icon btn-icon-right">
                  <i class="fas fa-chevron-right"></i>
                </span>
              </b-button>
            </div>
            <div class="st-count-wrap">
              <div class="st-count st-count-total">
                <span
                  v-b-tooltip.hover
                  :title="$t('auctions.tooltips.participants_count')"
                >
                  {{ row.item.statistic.participants_count }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(logistic)="row">
            <div>
              <b-button
                v-if="row.item.can_edit"
                :to="`/lots/${row.item.id}/logistic`"
                class="ipsAction ipsAction-150"
              >
                Transportation
                <span class="btn-icon btn-icon-right">
                  <i class="fas fa-chevron-right"></i>
                </span>
              </b-button>
            </div>
            <div class="st-count-wrap">
              <div class="st-count st-count-total">
                <span
                  v-b-tooltip.hover
                  :title="$t('auctions.tooltips.participants_count')"
                >
                  {{ row.item.statistic.participants_count }}
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            -
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  title: '',
  name: 'AuctionLots',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'slot_reference',
          label: 'Lot reference',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'set_name',
          label: 'Product category',
          sortable: true,
          class: 'data-name data-name-strong'
        },
        {
          key: 'qty',
          label: 'Qty',
          sortable: true,
          class: 'data-name text-right data-name-strong'
        },
        {
          key: 'unit_symbol',
          label: '',
          sortable: false,
          class: 'data-name text-left'
        },
        {
          key: 'auction_type',
          label: 'Auction type',
          sortable: false,
          class: 'data-name'
        },
        {
          key: 'flag',
          label: '',
          sortable: false,
          class: 'data-flag'
        },
        /*        {
          key: 'country_code',
          label: this.$t('geo.labels.code'),
          sortable: true,
          class: 'data-code'
        },*/
        {
          key: 'country_name',
          label: 'Country',
          sortable: true,
          class: 'data-country'
        },
        {
          key: 'participants',
          label: 'Participants',
          sortable: true,
          class: 'data-participants'
        },
        {
          key: 'logistic',
          label: 'Transportation prices',
          sortable: true,
          class: 'data-transportation'
        },
        {
          key: 'stage',
          label: 'Stage',
          sortable: false,
          class: 'data-name'
        }
        /*        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }*/
      ],
      actions: {
        destroy: this.$t('geo.actions.destroy'),
        enable: this.$t('geo.actions.enable'),
        disable: this.$t('geo.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('auctions.titles.lots')
  },
  methods: {
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ lot: id })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: 0;
}
</style>
