<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('geo.titles.edit') }}
            </h3>

            <!-- Country Name -->
            <b-form-group
              :label="trans('geo.labels.country_name') + ' *'"
              label-for="name"
              label-cols-sm="3"
              :feedback="feedback('name')"
            >
              <b-form-input
                id="name"
                name="name"
                v-model="model.name"
                :state="state('name')"
              ></b-form-input>

              <b-form-feedback>
                {{ feedback('name') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Country native name -->
            <b-form-group
              :label="trans('geo.labels.name_original') + ' *'"
              label-for="native"
              label-cols-sm="3"
              :feedback="feedback('native')"
            >
              <b-form-input
                id="native"
                name="native"
                v-model="model.native"
                :state="state('native')"
              ></b-form-input>
              <b-form-feedback>
                {{ feedback('native') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Country Flag -->
            <div role="group" class="form-row form-group form-row-info mb-0">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_flag') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <img
                  class="country-flag"
                  :src="getFlag(data.code)"
                  :alt="data.name"
                />
              </div>
            </div>

            <!-- Code -->
            <div role="group" class="form-row form-group form-row-info">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_code') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                {{ data.code }}
              </div>
            </div>

            <!-- Capital -->
            <b-form-group
              :label="trans('geo.labels.capital')"
              label-for="capital"
              label-cols-sm="3"
              :feedback="feedback('capital')"
            >
              <b-form-input
                id="capital"
                name="capital"
                v-model="model.capital"
                :state="state('capital')"
              ></b-form-input>
              <b-form-feedback>
                {{ feedback('capital') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Continent -->
            <div role="group" class="form-row form-group form-row-info">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.continent') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <template v-if="data && data.continent && data.continent.name">
                  {{ data.continent.name }}
                </template>
              </div>
            </div>

            <!-- Enabled -->
            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="enabled"
                    v-model="model.enabled"
                    :description="$t('core.label.enabled')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <!-- Modal footer -->

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 50%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 50%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'CountryRecord',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'country',
      resourceRoute: 'countries',
      listPath: '/countries',
      model: {
        name: '',
        native: '',
        capital: '',
        enabled: false
      },
      data: {},
      meta: {}
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('geo.title.edit')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    save() {
      // Setup data.
      let data = {}
      data.name = this.model.name
      data.native = this.model.native
      data.capital = this.model.capital
      data.enabled = this.model.enabled

      axios
        .patch(
          this.$app.route('countries.update', {
            country: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.modalClose()
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.pending = false
          if (error.response.status === 422) {
            this.validation = error.response.data
            return
          }
          this.$app.error(error)
        })
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  width: 40px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: -3px;
  display: block;
}
</style>
