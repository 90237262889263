<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="false"
      :back-url="listPath"
      :no-side="true"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="horizontal"
        @tab-change="tabAction"
        class="vue-tabs-no-side"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <!-- *** Set *** -->
          <b-row class="left-content-center">
            <b-col xl="6" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <!-- Name -->
                <TextLang
                  name="name"
                  :label="$t('validation.attributes.name')"
                  v-model="model.name"
                  :errors="validation.errors"
                >
                </TextLang>
                <b-form-group
                  :label="$t('units.titles.units')"
                  label-for="units"
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="8">
                      <b-form-checkbox-group
                        stacked
                        v-model="model.units"
                        name="units[]"
                      >
                        <b-form-checkbox
                          v-for="unit in units"
                          :key="unit.id"
                          :value="unit.id"
                        >
                          {{ unit.name }} - {{ unit.symbol }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <!-- Min load min_load_bulk -->
                <b-form-group
                  :label="trans('attributes.label.min-load-bulk') + ' *'"
                  label-for="min_load_bulk"
                  label-cols-sm="3"
                  :feedback="feedback('min_load_bulk')"
                >
                  <b-form-input
                    id="min_load_bulk"
                    name="min_load_bulk"
                    type="number"
                    v-model="model.min_load_bulk"
                    :state="state('min_load_bulk')"
                    class="form-control-status"
                    min="0"
                  ></b-form-input>

                  <b-form-feedback>
                    {{ feedback('min_load_bulk') }}
                  </b-form-feedback>
                </b-form-group>
                <!-- Min load  min_load_packed -->
                <b-form-group
                  :label="trans('attributes.label.min-load-packed') + ' *'"
                  label-for="min_load_packed"
                  label-cols-sm="3"
                  :feedback="feedback('min_load_packed')"
                >
                  <b-form-input
                    id="min_load_packed"
                    name="min_load_packed"
                    type="number"
                    v-model="model.min_load_packed"
                    :state="state('min_load_packed')"
                    class="form-control-status"
                    min="0"
                  ></b-form-input>

                  <b-form-feedback>
                    {{ feedback('min_load_packed') }}
                  </b-form-feedback>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <!-- *** Groups *** -->
          <b-row class="left-content-center">
            <!-- *** Unassigned attributes *** -->
            <b-col sm="12" xl="12" lg="12">
              <b-card>
                <h3
                  class="header-card-title card-title"
                  slot="header"
                  style="width: 100%"
                >
                  {{ $t('attributes.labels.unassigned-attributes') }}
                  <span
                    class="card-options text-right group-new-btn-wrap"
                    v-if="this.$app.user.can('create attributes')"
                  >
                    <b-button
                      @click="modalCreate('form')"
                      variant="warning"
                      size="sm"
                    >
                      <i class="fas fa-plus"></i>
                      {{ $t('attributes.buttons.create-attribute') }}
                    </b-button>
                  </span>
                </h3>
              </b-card>
            </b-col>
          </b-row>
          <b-row
            class="left-content-center attribute-groups attribute-options attribute-list"
          >
            <b-col xl="12" lg="12">
              <b-card>
                <div class="row" v-if="attributes.data_form.length > 0">
                  <div
                    class="item group col-12 attribute-item attribute-item-header"
                  >
                    <list-item-header></list-item-header>
                  </div>
                </div>
                <draggable
                  class="row"
                  :options="{
                    group: {
                      name: 'groups',
                      pull: false,
                      put: false
                    }
                  }"
                  v-model="attributes.data_form"
                  @sort="saveOrderOptions(attributes.data_form)"
                  @update="saveOrderOptions(attributes.data_form)"
                >
                  <div
                    v-for="(AttributeOption, index) in attributes.data_form"
                    :key="index"
                    class="item draggable group col-12 attribute-item"
                    v-if="attributes.data_form.length > 0"
                  >
                    <list-item
                      :data="AttributeOption"
                      :lang="meta.lang"
                      type="form"
                    ></list-item>
                  </div>
                  <div
                    v-if="attributes.data_form.length == 0"
                    class="item group col-12 attribute-item attribute-item-empty"
                  >
                    {{ $t('attributes.alert.empty') }}
                  </div>
                </draggable>
              </b-card>
              <!--              <rawDisplayer :value="attributes" title="Options"></rawDisplayer>
              <rawDisplayer :value="model" title="Options"></rawDisplayer>-->
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import TextLang from '@core/js/components/Content/TextLang'
import Attribute from '@attributes/js/modals/Attribute'
import ListItem from '@attributes/js/Elements/ListItem.vue'
import ListItemHeader from '@attributes/js/Elements/ListItemHeader.vue'
import draggable from 'vuedraggable'
import axios from 'axios'

export default {
  name: `AttributeSetEditForm`,
  components: {
    VueTabs,
    VTab,
    TextLang,
    draggable,
    'list-item': ListItem,
    'list-item-header': ListItemHeader
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'attribute_set',
      resourceRoute: 'attributes_sets',
      listPath: '/attributes/sets',
      model: {
        // Data
        name: {},
        data_list: [],
        data_form: [],
        units: [],
        min_load_bulk: 0,
        min_load_packed: 0,
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      folder_id: '',
      units: []
    }
  },
  computed: {
    attributes() {
      return this.$store.getters['attributes/data']
    }
  },
  watch: {
    attributes: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler(data) {
        this.model.data_list = data.data_list
        this.model.data_form = data.data_form
      }
    }
  },
  mounted() {
    this.$title = this.$t('attributes.titles.edit-set')
    this.initUnits()
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('attributes.titles.unit-set')
    let arrList = []
    let arrForm = []

    this.model.data_list.forEach((value, index) => {
      /*      let newObj = {
        identifier: value.identifier,
        type: value.type,
        name: value.name,
        value: value.value
      }*/

      //let newObj = value

      arrList.push(value)
    })

    this.model.data_form.forEach((value, index) => {
      /*      let newObj = {
        identifier: value.identifier,
        type: value.type,
        name: value.name,
        value: value.value
      }*/

      arrForm.push(value)
    })

    this.$store.commit('attributes/setDataList', arrList)
    this.$store.commit('attributes/setDataForm', arrForm)
  },
  beforeMount() {
    //console.log(this.id)
    //this.model.categories = []
    this.model.units = []
    /*    if (this.isNew) {
      this.$store.commit('auction/emptyFields')
    } else {
      this.$store.commit(
        'auction/setCategoriesAuction',
        JSON.parse(JSON.stringify(this.categoriesAuction).replace(/"/g, ''))
      )*/
    // console.log(this.data)
    //this.$store.commit('attributes/setData', this.data)
    //}
  },
  methods: {
    tabAction(tabIndex, newTab, oldTab) {},
    modalCreate(type) {
      if (type == 'form') {
        this.$modal.show(
          Attribute,
          {
            modal: true,
            type: type,
            mode: 'create',
            uploadFieldForm: true,
            fileField: false,
            imageField: false,
            selectFieldForm: true,
            textFieldForm: true,
            textFieldFormData: true,
            textField: true,
            textareaField: false,
            textareaFieldForm: true,
            textareaFieldFormData: true,
            wysiwygField: false,
            wysiwygFieldForm: true,
            wysiwygFieldFormData: true,
            numberField: false,
            numberFieldForm: true,
            toggleFieldForm: true,
            urlField: true,
            urlFieldForm: true,
            radioFieldForm: true,
            checkboxFieldForm: true,
            emailField: true,
            emailFieldForm: true
          },
          {
            clickToClose: false,
            resizable: false,
            adaptive: true,
            draggable: false,
            scrollable: true,
            height: 'auto',
            width: 1100,
            transition: 'nice-modal-fade'
          }
        )
      } else {
        this.$modal.show(
          Attribute,
          {
            modal: true,
            type: type,
            mode: 'create',
            fileField: true,
            imageField: true,
            textField: true,
            textareaField: true,
            wysiwygField: true,
            numberField: true,
            urlField: true,
            emailField: true
          },
          {
            clickToClose: false,
            resizable: false,
            adaptive: true,
            draggable: false,
            scrollable: true,
            height: 'auto',
            width: 1100,
            transition: 'nice-modal-fade'
          }
        )
      }
      //this.$store.dispatch('attribute/setCurrentTab', 'Laukelio tipas')
    },
    modalEdit(type, data) {
      this.$modal.show(
        Attribute,
        {
          modal: true,
          type: type,
          mode: 'edit',
          attributeData: data,
          textField: true,
          textareaField: true,
          wysiwygField: true,
          numberField: true,
          emailField: true
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: 1100,
          transition: 'nice-modal-fade'
        }
      )
      //this.$store.dispatch('attribute/setCurrentTab', 'Laukelio tipas')
    },
    saveOrderOptions(opts) {
      for (let i = 0; i < opts.length; i++) {
        opts[i].order = i * 10
      }
    },
    deleteConfirmedList(dialog, option) {
      /*      console.log(option.identifier)
      let i = this.attributes
        .data_list(item => item.identifier)
        .indexOf(option.identifier)
      console.log(i)
      this.attributes.splice(i, 1)*/

      return this.$store.dispatch('attributes/removeListRecord', option)
    },
    deleteConfirmedForm(dialog, option) {
      return this.$store.dispatch('attributes/removeFormRecord', option)
    },
    async initUnits() {
      let { data } = await axios.get(this.$app.route('units.list'))
      this.units = data
    }
  }
}
</script>
<style lang="scss">
.scale-enter-active,
.scale-leave-active {
  transition: all 0.2s;
}
.scale-enter,
.scale-leave-active {
  opacity: 0;
  transform: scale(0.9) translateY(0);
}
</style>
