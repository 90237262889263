export default {
  path: 'email',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: 'list/:id/preview',
      name: 'email_lists_preview',
      component: require('@email-services/js/components/SendLists/SendListPreviewForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    },
    /* list END */
    /* messages */
    {
      path: 'messages/',
      name: 'email_messages',
      component: require('@email-services/js/components/Messages/MessageList')
        .default,
      meta: {
        label: ''
      }
    },
    /* messages END */
    /* templates */
    {
      path: 'templates/',
      name: 'email_templates',
      component: require('@email-services/js/components/Templates/TemplateList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'templates/create',
      name: 'email_templates_create',
      component: require('@email-services/js/components/Templates/TemplateCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'templates/:id/edit',
      name: 'email_templates_edit',
      component: require('@email-services/js/components/Templates/TemplateEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    },
    /* templates END */
    /* services */
    {
      path: 'services/',
      name: 'email_services',
      component: require('@email-services/js/components/Services/EmailServiceList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'services/create',
      name: 'email_services_create',
      component: require('@email-services/js/components/Services/EmailServiceCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'services/:id/edit',
      name: 'email_services_edit',
      component: require('@email-services/js/components/Services/EmailServiceEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
    /* services END */
  ]
}
