import axios from 'axios'

export function createActions(route) {
  return {
    LOAD_COUNTERS: ({ commit }) => {
      return new Promise(resolve => {
        axios
          .all([
            axios.get(route('auctions.pending.counter')),
            axios.get(route('auctions.revise.counter')),
            axios.get(route('auctions.draft.counter')),
            axios.get(route('auctions.active.counter')),
            axios.get(route('auctions.suspended.counter')),
            axios.get(route('auctions.canceled.counter')),
            axios.get(route('auctions.ongoing.counter')),
            axios.get(route('auctions.until.counter')),
            axios.get(route('auctions.ended.counter')),
            axios.get(route('auctions.all.counter')),
            axios.get(route('auctions.bids.counter')),
            axios.get(route('users.all.counter')),
            axios.get(route('users.active.counter')),
            axios.get(route('users.pending.counter'))
          ])
          .then(
            axios.spread(
              (
                pendingAuctionsCount,
                reviseAuctionsCount,
                draftAuctionsCount,
                activeAuctionsCount,
                suspendedAuctionsCount,
                canceledAuctionsCount,
                ongoingAuctionsCount,
                untilAuctionsCount,
                endedAuctionsCount,
                allAuctionsCount,
                allBidsCount,
                allUsersCount,
                activeUsersCount,
                pendingUsersCount
              ) => {
                commit('SET_COUNTER', {
                  type: 'pendingAuctionsCount',
                  counter: pendingAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'reviseAuctionsCount',
                  counter: reviseAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'draftAuctionsCount',
                  counter: draftAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'activeAuctionsCount',
                  counter: activeAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'suspendedAuctionsCount',
                  counter: suspendedAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'canceledAuctionsCount',
                  counter: canceledAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'ongoingAuctionsCount',
                  counter: ongoingAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'untilAuctionsCount',
                  counter: untilAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'endedAuctionsCount',
                  counter: endedAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allAuctionsCount',
                  counter: allAuctionsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allBidsCount',
                  counter: allBidsCount.data
                })
                commit('SET_COUNTER', {
                  type: 'allUsersCount',
                  counter: allUsersCount.data
                })
                commit('SET_COUNTER', {
                  type: 'activeUsersCount',
                  counter: activeUsersCount.data
                })
                commit('SET_COUNTER', {
                  type: 'pendingUsersCount',
                  counter: pendingUsersCount.data
                })
                resolve()
              }
            )
          )
      })
    }
  }
}
