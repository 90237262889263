<template>
  <div class="sidebar sidebar-minimized sidebar-show">
    <slot>Sidebar</slot>
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  props: {
    fixed: {
      type: Boolean,
      default: false
    },
    minimized: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    this.isFixed()
    this.isMinimized()
  },
  methods: {
    isFixed () {
      this.fixed ? document.body.classList.add('sidebar-fixed') : document.body.classList.remove('sidebar-fixed')
      return this.fixed
    },
    isMinimized () {
      this.isMinimized ? document.body.classList.add('brand-minimized') : document.body.classList.remove('brand-minimized')
      this.isMinimized ? document.body.classList.add('sidebar-minimized') : document.body.classList.remove('sidebar-minimized')
      //document.querySelector('.sidebar-nav section').classList.toggle('ps')
      return this.isMinimized
    }
  }
}
</script>
