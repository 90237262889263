<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="trans('core.tab.main')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ trans('core.section.main') }}
                </h3>
                <b-form-group
                  :label="trans('languages.label.locale')"
                  label-for="locale"
                  label-cols-sm="3"
                >
                  <div class="data-identifier-block">
                    <span>{{ data.locale }}</span>
                  </div>
                </b-form-group>
                <b-form-group
                  :label="trans('languages.label.regional')"
                  label-for="regional"
                  label-cols-sm="3"
                >
                  <div class="data-identifier-block">
                    <span>{{ data.regional }}</span>
                  </div>
                </b-form-group>

                <b-form-group
                  :label="trans('languages.label.name')"
                  label-for="name"
                  :label-cols="3"
                  :description="trans('languages.note.name')"
                  :feedback="feedback('name')"
                >
                  <b-row>
                    <b-col md="12">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="model.name"
                          :state="state('name')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-form-feedback>
                    {{ feedback('name') }}
                  </b-form-feedback>
                </b-form-group>

                <b-form-group
                  :label="trans('languages.label.native')"
                  label-for="native"
                  :label-cols="3"
                  :description="trans('languages.note.native')"
                  :feedback="feedback('native')"
                >
                  <b-row>
                    <b-col md="12">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="native"
                          name="native"
                          v-model="model.native"
                          :state="state('native')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="trans('languages.tab.site')" id="lang-site">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang">
                <h3 class="header-card-title card-title" slot="header">
                  {{ trans('languages.section.site') }}
                </h3>
                <b-form-group label-cols-sm="3">
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="enabled_site"
                        v-model="model.enabled_site"
                        :description="trans('languages.label.enabled_site')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label-cols-sm="3"
                  :description="trans('languages.note.show_site')"
                  v-if="model.enabled_site"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="enabled_site"
                        v-model="model.show_site"
                        :description="trans('languages.label.show_site')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="trans('languages.label.position_site')"
                  label-for="position_site"
                  label-cols-sm="3"
                  :description="trans('languages.note.site_sort_order')"
                  v-if="model.enabled_site"
                >
                  <b-row>
                    <b-col md="4">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-position"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-arrows-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="position_site"
                          name="position_site"
                          type="number"
                          v-model="model.position_site"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="trans('languages.tab.center')" id="lang-backend-center">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang">
                <h3 class="header-card-title card-title" slot="header">
                  {{ trans('languages.section.center') }}
                </h3>
                <b-form-group label-cols-sm="3">
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="enabled_center"
                        v-model="model.enabled_center"
                        :description="trans('languages.label.enabled_center')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label-cols-sm="3"
                  :description="trans('languages.note.required_center')"
                  v-if="model.enabled_center"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="required_center"
                        v-model="model.required_center"
                        :description="trans('languages.label.required_center')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab
          :title="trans('languages.tab.center_interface')"
          id="lang-backend-interface"
        >
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang">
                <h3 class="header-card-title card-title" slot="header">
                  {{ trans('languages.section.center_interface') }}
                </h3>
                <b-form-group
                  label-cols-sm="3"
                  :description="trans('languages.note.interface_center')"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="interface_center"
                        v-model="model.interface_center"
                        :description="trans('languages.label.interface_center')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  label-cols-sm="3"
                  :description="trans('languages.note.show_center')"
                  v-if="model.interface_center"
                >
                  <b-row>
                    <b-col lg="9">
                      <c-switch
                        name="show_center"
                        v-model="model.show_center"
                        :description="trans('languages.label.show_center')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="trans('languages.label.position_center')"
                  label-for="position_center"
                  label-cols-sm="3"
                  :description="trans('languages.note.center_sort_order')"
                  v-if="model.interface_center"
                >
                  <b-row>
                    <b-col md="4">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-position"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-arrows-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="position_center"
                          name="position_center"
                          type="number"
                          v-model="model.position_center"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `LanguageEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'language',
      resourceRoute: 'languages',
      listPath: '/languages',
      model: {
        // Data
        native: '',
        default_center: false,
        default_site: false,
        name: '',
        locale: '',
        locale_selected: '',
        enabled_center: false,
        enabled_site: false,
        show_site: false,
        show_center: false,
        interface_center: false,
        required_center: false,
        position_site: '',
        position_center: '',
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.trans('languages.title.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.trans('languages.title.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
