import axios from 'axios'
import Vue from 'vue'

const defaultState = {
  processingPopup: undefined,
  fileQueue: [],
  filesInQueueTotal: 0,
  filesInQueueUploaded: 0,

  isProcessingFile: false,
  isProcessingFolder: false,
  uploadingProgress: 0
}

const actions = {
  async proceedFolder({ commit, getters, rootGetters, dispatch }, data) {
    let apiUrl = rootGetters.api

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getters.token
    }

    await axios({
      method: 'post',
      url: 'create-folder',
      baseURL: apiUrl,
      headers: configHeaders,
      data: data
    })
      .then(response => {
        commit('ADD_NEW_FOLDER', response.data)

        //this.$store.commit('LOADING_STATE', { loading: true, data: [] })

        let currentFolder = {
          name: 'Folder',
          location: 'public',
          unique_id: response.data.unique_id,
          folder_id: response.data.id
        }

        commit('STORE_CURRENT_FOLDER', currentFolder)

        commit('attribute/setElementFolder', {
          folder_id: response.data.id,
          unique_folder_id: response.data.unique_id
        })

        //window.location.reload()

        commit('PROCESSING_FOLDER', false)
        //console.log(getters.isProcessingFolder)
      })
      .catch(error => {
        // console.log(error)

        commit('PROCESSING_FOLDER', false)
      })

    /*    //let apiUrl = 'http://ep-client.go'
    axios
      .post(apiUrl + 'create-folder')
      .then(resp => {
        console.log(resp.data)
      })
      .catch(err => {
        console.log(err)
        console.log(err.message)
        // Handle Error Here
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log('Server Error:', err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log('Network Error:', err)
        } else {
          console.log('Client Error:', err)
        }
      })*/

    /*    axios
      .post(route, {
        module: data.module,
        record: data.record,
        element: data.element
      })
      .then(response => {
        console.log(response.data)
        //commit('ADD_NEW_FOLDER', response.data)

        //events.$emit('scrollTop')

        if (getters.currentFolder.location !== 'public') dispatch('getAppData')
        if (getters.currentFolder.location === 'public')
          dispatch('getFolderTree')
      })
      .catch(() => Vue.prototype.$isSomethingWrong())*/

    // Get route

    // Proceed folders End
  },
  renameItem: ({ commit, getters, dispatch, rootGetters }, data) => {
    let apiUrl = rootGetters.api

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getters.token
    }

    axios({
      method: 'patch',
      url: 'rename-item/' + data.unique_id,
      baseURL: apiUrl,
      headers: configHeaders,
      data: {
        name: data.name,
        type: data.type,
        folder_icon: data.folder_icon
      }
    })
      .then(response => {
        commit('CHANGE_ITEM_NAME', response.data)

        if (
          data.type === 'folder' &&
          getters.currentFolder.location !== 'public'
        )
          dispatch('getAppData')
        if (
          data.type === 'folder' &&
          getters.currentFolder.location === 'public'
        )
          dispatch('getFolderTree')
      })
      .catch(() => Vue.prototype.$isSomethingWrong())
  },
  createFolder: ({ commit, getters, dispatch }, folderName) => {
    // Get route
    let route =
      getters.sharedDetail && !getters.sharedDetail.protected
        ? '/api/create-folder/public/' + router.currentRoute.params.token
        : '/api/create-folder'

    axios
      .post(route, {
        parent_id: getters.currentFolder.unique_id,
        name: folderName
      })
      .then(response => {
        commit('ADD_NEW_FOLDER', response.data)

        // events.$emit('scrollTop')

        //Set focus on new folder name
        setTimeout(() => {
          events.$emit('newFolder:focus', response.data.unique_id)
        }, 10)

        if (getters.currentFolder.location !== 'public') dispatch('getAppData')
        if (getters.currentFolder.location === 'public')
          dispatch('getFolderTree')
      })
      .catch(() => Vue.prototype.$isSomethingWrong())
  },
  uploadFiles: (
    { commit, getters, rootGetters },
    { form, fileSize, totalUploadedSize }
  ) => {
    return new Promise((resolve, reject) => {
      // Get route
      /*      let route =
        getters.sharedDetail && !getters.sharedDetail.protected
          ? '/api/upload/public/' + router.currentRoute.params.token
          : '/api/upload'*/

      let apiUrl = rootGetters.api

      const configHeaders = {
        'content-type': 'application/json',
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + getters.token
      }

      let route = apiUrl + '/upload'

      // Create cancel token for axios cancellation
      const CancelToken = axios.CancelToken,
        source = CancelToken.source()

      axios
        .post(route, form, {
          cancelToken: source.token,
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: 'Bearer ' + getters.token
          },
          onUploadProgress: event => {
            let percentCompleted = Math.floor(
              ((totalUploadedSize + event.loaded) / fileSize) * 100
            )

            commit(
              'UPLOADING_FILE_PROGRESS',
              percentCompleted >= 100 ? 100 : percentCompleted
            )

            // Set processing file
            if (percentCompleted >= 100) commit('PROCESSING_FILE', true)
          }
        })
        .then(response => {
          resolve(response)

          commit('PROCESSING_FILE', false)

          // Remove first file from file queue
          commit('SHIFT_FROM_FILE_QUEUE')

          // Check if user is in uploading folder, if yes, than show new file
          if (response.data.folder_id == getters.currentFolder.unique_id) {
            // Add uploaded item into view
            commit('ADD_NEW_ITEMS', response.data)

            commit('attribute/setFiles', {
              files: getters.data
            })

            // Reset file progress
            commit('UPLOADING_FILE_PROGRESS', 0)

            // Increase count in files in queue uploaded for 1
            commit('INCREASE_FILES_IN_QUEUE_UPLOADED')
          }

          // Start uploading next file if file queue is not empty
          if (getters.fileQueue.length) {
            Vue.prototype.$handleUploading(getters.fileQueue[0])
          }

          // Reset upload process
          if (!getters.fileQueue.length) commit('CLEAR_UPLOAD_PROGRESS')
        })
        .catch(error => {
          reject(error)

          let messages = {
            '423': {
              title: Vue.prototype.trans('popup_exceed_limit.title'),
              message: Vue.prototype.trans('popup_exceed_limit.message')
            },
            '415': {
              title: Vue.prototype.trans('popup_mimetypes_blacklist.title'),
              message: Vue.prototype.trans('popup_mimetypes_blacklist.message')
            },
            '413': {
              title: Vue.prototype.trans('popup_paylod_error.title'),
              message: Vue.prototype.trans('popup_paylod_error.message')
            }
          }

          events.$emit('alert:open', {
            emoji: '😬😬😬',
            title: messages[error.response.status]['title'],
            message: messages[error.response.status]['message']
          })

          commit('PROCESSING_FILE', false)
          commit('CLEAR_UPLOAD_PROGRESS')
        })

      // Cancel the upload request
      events.$on('cancel-upload', () => {
        source.cancel()

        // Hide upload progress bar
        commit('PROCESSING_FILE', false)
        commit('CLEAR_UPLOAD_PROGRESS')
      })
    })
  },
  deleteItem: ({ commit, getters, dispatch }, noSelectedItem) => {
    let itemsToDelete = []
    let items = [noSelectedItem]

    // If coming no selected item dont get items to move from fileInfoDetail
    if (!noSelectedItem) items = getters.fileInfoDetail

    items.forEach(data => {
      itemsToDelete.push({
        force_delete: data.deleted_at ? true : false,
        type: data.type,
        unique_id: data.unique_id
      })

      // Remove file
      commit('REMOVE_ITEM', data.unique_id)

      // Remove item from sidebar
      if (getters.permission === 'master') {
        if (data.type === 'folder') commit('REMOVE_ITEM_FROM_FAVOURITES', data)
      }

      // Remove file
      commit('REMOVE_ITEM', data.unique_id)

      // Remove item from sidebar
      if (getters.permission === 'master') {
        if (data.type === 'folder') commit('REMOVE_ITEM_FROM_FAVOURITES', data)
      }
    })

    // Remove file preview
    if (!noSelectedItem) {
      commit('CLEAR_FILEINFO_DETAIL')
    }

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getters.token
    }

    let apiUrl = getters.api

    // Get route
    let route =
      getters.sharedDetail && !getters.sharedDetail.protected
        ? '/remove-item/public/' + router.currentRoute.params.token
        : '/remove-item'

    axios({
      method: 'post',
      url: route,
      baseURL: apiUrl,
      headers: configHeaders,
      data: { data: itemsToDelete }
    })
      .then(() => {
        itemsToDelete.forEach(data => {
          // If is folder, update app data
          if (data.type === 'folder') {
            if (data.unique_id === getters.currentFolder.unique_id) {
              if (getters.currentFolder.location === 'public') {
                dispatch('browseShared', [
                  {
                    folder: last(getters.browseHistory),
                    back: true,
                    init: false
                  }
                ])
              } else {
                dispatch('getFolder', [
                  {
                    folder: last(getters.browseHistory),
                    back: true,
                    init: false
                  }
                ])
              }
            }
          }
        })

        if (getters.currentFolder.location !== 'public') dispatch('getAppData')

        if (getters.currentFolder.location === 'public')
          dispatch('getFolderTree')

        commit('attribute/setFiles', {
          files: getters.data
        })
      })
      .catch(() => Vue.prototype.$isSomethingWrong())
  }
}

const mutations = {
  PROCESSING_POPUP(state, status) {
    state.processingPopup = status
  },
  ADD_FILES_TO_QUEUE(state, file) {
    state.fileQueue.push(file)
  },
  SHIFT_FROM_FILE_QUEUE(state) {
    state.fileQueue.shift()
  },
  PROCESSING_FILE(state, status) {
    state.isProcessingFile = status
  },
  PROCESSING_FOLDER(state, status) {
    state.isProcessingFolder = status
  },
  UPLOADING_FILE_PROGRESS(state, percentage) {
    state.uploadingProgress = percentage
  },
  INCREASE_FILES_IN_QUEUES_TOTAL(state, count) {
    state.filesInQueueTotal += count
  },
  INCREASE_FILES_IN_QUEUE_UPLOADED(state) {
    state.filesInQueueUploaded++
  },
  CLEAR_UPLOAD_PROGRESS(state) {
    state.filesInQueueUploaded = 0
    state.filesInQueueTotal = 0
    state.fileQueue = []
  }
}

const getters = {
  filesInQueueUploaded: state => state.filesInQueueUploaded,
  filesInQueueTotal: state => state.filesInQueueTotal,
  uploadingProgress: state => state.uploadingProgress,
  isProcessingFile: state => state.isProcessingFile,
  isProcessingFolder: state => state.isProcessingFolder,
  processingPopup: state => state.processingPopup,
  fileQueue: state => state.fileQueue
}

export default {
  state: defaultState,
  mutations,
  actions,
  getters
}
