import axios from 'axios'

const defaultState = {
  authorized: undefined,
  permission: 'master', // master | editor | visitor
  user: undefined,
  auth: {
    // to store auth user data
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    roles: []
  },
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null,
  is_auth: false, // to store auth status
  two_factor_code: null, // to store two factor code if required
  config: {}, // to store all configuration variables
  permissions: [], // to store all permissions of authenticated user
  last_activity: null // to store last activity time of user
}

const actions = {
  setAuthStatus({ commit }) {
    commit('setAuthStatus')
  },
  setAuthUserDetail({ commit }, auth) {
    commit('setAuthUserDetail', auth)
  },
  resetAuthUserDetail({ commit }) {
    commit('resetAuthUserDetail')
  },
  setLastActivity({ commit }) {
    commit('setLastActivity')
  },
  ping() {
    let apiUrl = 'http://ep-client.go/endpoint/'

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*'
    }

    axios({
      method: 'post',
      url: 'ping',
      baseURL: apiUrl,
      headers: configHeaders
    })
      .then(response => {
        //console.log(response.data)
        //window.location.reload()
      })
      .catch(error => {
        //console.log(error)
      })

    /*    axios
      .post(
        '/endpoint/ping',
        {},
        {
          changeOrigin: true,
          dataType: 'json'
        }
      )
      .then(resp => {
        console.log(resp.data)
      })
      .catch(err => {
        console.log(err)
        console.log(err.message)
        // Handle Error Here
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log('Server Error:', err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log('Network Error:', err)
        } else {
          console.log('Client Error:', err)
        }
      })*/
  },
  logIn: ({ dispatch, commit, getters, rootGetters }, data) => {
    let apiUrl = rootGetters.api
    return new Promise((resolve, reject) => {
      axios
        .post(
          apiUrl + '/user/login',
          {
            email: data.email,
            password: data.password
          },
          {
            changeOrigin: true,
            dataType: 'json'
          }
        )
        .then(response => {
          resolve(response)
          dispatch('setAuthStatus')
          dispatch('setLastActivity')
          dispatch('attempt', response.data.access_token)

          //let redirect_path = '/dashboard'
          //this.$router.go(redirect_path)

          //console.log(response)
          //commit('RETRIEVE_USER', response.data)
        })
        .catch(error => {
          // console.log(error)
          reject(error)

          // Redirect if unauthenticated
          /*          if ([401, 403].includes(error.response.status)) {
            commit('SET_AUTHORIZED', false)
            //router.push({name: 'SignIn'})
          }*/
        })
    })
  },
  async attempt({ commit, state }, token) {
    if (token) {
      commit('SET_TOKEN', token)
    }
    if (!state.token) {
      return
    }
  },
  getAppData: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(getters.api + '/user' + getters.sorting.URI)
        .then(response => {
          resolve(response)

          // Redirect user if is logged
          if (router.currentRoute.name === 'SignIn')
            router.push({ name: 'Files' })

          commit('RETRIEVE_USER', response.data)
        })
        .catch(error => {
          reject(error)

          // Redirect if unauthenticated
          if ([401, 403].includes(error.response.status)) {
            commit('SET_AUTHORIZED', false)
            //router.push({name: 'SignIn'})
          }
        })
    })
  }
}

const getters = {
  permission: state => state.permission,
  isGuest: state => !state.authorized,
  isLogged: state => state.authorized,
  user: state => state.user,
  token: state => state.token,
  getAuthUser: state => name => {
    return state.auth[name]
  },
  getAuthStatus: state => {
    return state.is_auth
  },
  getLastActivity: state => {
    return state.last_activity
  }
}

const mutations = {
  SET_TOKEN(state, token) {
    localStorage.setItem('token', token)
    state.token = token
  },
  SET_USER(state, data) {
    state.user = data
  },
  setAuthStatus(state) {
    state.is_auth = true
  },
  setAuthUserDetail(state, auth) {
    for (let key of Object.keys(auth)) {
      state.auth[key] = auth[key] !== null ? auth[key] : ''
    }
    if ('avatar' in auth)
      state.auth.avatar = auth.avatar !== null ? auth.avatar : ''
    state.is_auth = true
    state.auth.roles = auth.roles
  },
  resetAuthUserDetail(state) {
    for (let key of Object.keys(state.auth)) {
      state.auth[key] = ''
    }
    state.is_auth = false
    state.auth.roles = []
    state.last_activity = null
  },
  setLastActivity(state) {
    state.last_activity = moment().format()
  }
}

export default {
  state: defaultState,
  getters,
  actions,
  mutations
}
