<template>
  <div class="empty-page" v-if="isLoading || isEmpty">
    <div class="empty-state">
      <div
        class="text-content"
        v-if="$isThisLocation(['base', 'public', 'latest'])"
      >
        <h1 class="title title-empty">
          {{ $t('assets.message.empty_title') }}
        </h1>
        <ButtonUpload
          v-if="$checkPermission(['master', 'editor'])"
          button-style="theme"
        >
          {{ trans('assets.button.call_to_action') }}
        </ButtonUpload>
      </div>

      <!--Spinner-->
      <div class="text-content" v-if="isLoading">
        <Spinner></Spinner>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonUpload from './ButtonUpload'
import Spinner from '@assets/js/components/Spinner'
import { mapGetters } from 'vuex'

export default {
  name: 'EmptyPage',
  components: {
    ButtonUpload,
    Spinner
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['data', 'isLoading', 'currentFolder']),
    isEmpty() {
      return this.data && this.data.length == 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.empty-page {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin-top: 85px;
  display: flex;
  align-items: center;

  .empty-state {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.text-content {
  text-align: center;
  margin: 30px 0;

  .title {
    @include font-size(16);
    color: $text;
    font-weight: 700;
    margin: 0 0 20px 0;
  }

  .description {
    @include font-size(13);
    color: $text-muted;
    margin-bottom: 20px;
    display: block;
  }
}
</style>
