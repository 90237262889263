export default {
  path: 'auctions',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'auctions',
      component: require('@auctions/js/components/AuctionList').default,
      meta: {
        label: ''
      }
    },

    {
      path: 'unverified',
      name: 'agencies',
      component: require('@auctions/js/components/AuctionUnverifiedList')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'auctions_create',
      component: require('@auctions/js/components/AuctionCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'auctions_edit',
      component: require('@auctions/js/components/AuctionEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'unverified/:id/edit',
      name: 'auctions_unverified_edit',
      component: require('@auctions/js/components/AuctionEditUnverifiedForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
