<template>
  <div class="row list-page">
    <data-sidebar class="col-3 col-md-3 col-lg-2 col-xl-2">
      <!--      <template v-slot:stats>
        <div class="side-block">
          <div class="data-sidebar-title">
            {{ trans('translations.title.status') }}
          </div>
          <div class="data-sidebar-data"></div>
        </div>
      </template>-->
      <template v-slot:filters>
        <div class="side-block">
          <!--          <div class="data-sidebar-title">
            {{ trans('translations.title.filters') }}
          </div>-->
          <div class="data-sidebar-data">
            <div class="sidebar-title">
              {{ trans('translations.filter.status') }}
            </div>
            <div class="sidebar-choices">
              <div
                :key="index"
                v-for="(statusFilter, index) in statuses"
                class="value"
              >
                <button
                  @click="setStatus(statusFilter.id)"
                  :class="{ active: statusFilter.id === status }"
                >
                  {{ statusFilter.name }}
                </button>
              </div>
            </div>
            <div class="sidebar-title">
              {{ trans('translations.filter.area') }}
            </div>
            <div class="sidebar-choices">
              <div
                :key="index"
                v-for="(areaFilter, index) in areas"
                class="value"
              >
                <button
                  @click="setArea(areaFilter.id)"
                  :class="{ active: areaFilter.id === area }"
                >
                  {{ areaFilter.name }}
                </button>
              </div>
            </div>
            <div class="sidebar-title">
              {{ trans('translations.filter.languages') }}
            </div>
            <div class="sidebar-choices">
              <div
                :key="index"
                v-for="(langFilter, index) in langs"
                class="value"
              >
                <button
                  @click="setLang(langFilter.id)"
                  :class="{ active: langFilter.id === lang }"
                >
                  {{ langFilter.name }}
                </button>
              </div>
            </div>
            <div class="sidebar-title">
              {{ trans('translations.filter.modules') }}
            </div>
            <div class="sidebar-choices">
              <div
                :key="index"
                v-for="(moduleFilter, index) in modules"
                class="value"
              >
                <button
                  @click="setModule(moduleFilter.id)"
                  :class="{ active: moduleFilter.id === module }"
                >
                  {{ moduleFilter.name }}
                </button>
              </div>
            </div>
            <div class="sidebar-title">
              {{ trans('translations.filter.groups') }}
            </div>
            <div class="sidebar-choices">
              <div
                :key="index"
                v-for="(groupFilter, index) in groups"
                class="value"
              >
                <button
                  @click="setGroup(groupFilter.id)"
                  :class="{ active: groupFilter.id === group }"
                >
                  {{ groupFilter.name }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="sidebar-nav-container">
          <div class="sub-container"></div>
        </div>-->
      </template>
    </data-sidebar>
    <b-card class="col-9 col-md-9 col-lg-10 col-xl-10">
      <template slot="header">
        <h3 class="card-title">
          {{ trans('translations.title.list') }}
        </h3>
        <div
          class="card-options"
          v-if="this.$app.user.can('create translations')"
        >
          <!--          <b-button
            @click="modalCreate"
            variant="success"
            size="sm"
            class="adm-btn"
          >
            <i class="fe fe-plus-circle"></i>
            {{ $t('translations.buttons.create') }}
          </b-button>-->
          <b-dropdown
            size="sm"
            id="ddown_dark"
            text="Actions"
            variant="dark"
            class="adm-btn-dropdown"
          >
            <b-dropdown-item @click="syncTranslations('site')">
              <i class="fas fa-sync"></i>
              {{ trans('translations.button.sync-site') }}
            </b-dropdown-item>
            <b-dropdown-item @click="syncTranslations('center')">
              <i class="fas fa-sync"></i>
              {{ trans('translations.button.sync-center') }}
            </b-dropdown-item>
            <b-dropdown-item @click="importTranslations">
              <i class="fa fa-cloud-download-alt"></i>
              {{ trans('translations.button.import') }}
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            @click="publishTranslations"
            variant="warning"
            size="sm"
            class="adm-btn"
          >
            <i class="fa fa-cloud-upload-alt"></i>
            {{ trans('translations.button.publish') }}
          </b-button>
        </div>
      </template>
      <!--:actions="actions"-->
      <div class="list-container-wrap">
        <div class="data-container">
          <b-datatable
            ref="datasource"
            @context-changed="onContextChanged"
            search-route="translations.search"
            delete-route="translations.destroy"
            action-route="translations.batch_action"
            :length-change="true"
            :paging="true"
            :infos="true"
            :search="true"
            :export-data="false"
            :status="status"
            :area="area"
            :lang="lang"
            :module="module"
            :group="group"
          >
            <b-table
              ref="datatable"
              borderless
              hover
              show-empty
              stacked="sm"
              no-local-sorting
              :empty-text="trans('core.datatable.no_results')"
              :empty-filtered-text="trans('core.datatable.no_matched_results')"
              :fields="fields"
              :items="dataLoadProvider"
              :busy.sync="isBusy"
              sort-by="created_at"
              :sort-desc="true"
            >
              <template v-slot:cell(status)="row">
                <span
                  v-if="row.value == 1"
                  class="t-status t-status-ok"
                  v-b-tooltip.hover
                  :title="trans('translations.tooltip.status_ok')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'check-circle']"
                  ></font-awesome-icon>
                  <!--<i class="fas fa-check-circle"></i>-->
                </span>
                <span
                  v-else-if="row.value == 2 || row.value == 3"
                  class="t-status t-status-changed"
                  v-b-tooltip.hover
                  :title="trans('translations.tooltip.status_waiting')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'info-circle']"
                  ></font-awesome-icon>
                  <!--<i class="fas fa-info-circle"></i>-->
                </span>
                <span
                  v-else-if="row.value == 4"
                  class="t-status t-status-missing"
                  v-b-tooltip.hover
                  :title="trans('translations.tooltip.status_missing')"
                >
                  <font-awesome-icon
                    :icon="['fas', 'stop']"
                  ></font-awesome-icon>
                  <!--<i class="fas fa-stop"></i>-->
                </span>
                <span v-else> - </span>
              </template>
              <template v-slot:cell(locale)="row">
                <span> {{ row.value | uppercase }}</span>
              </template>
              <template v-slot:cell(area_id)="row">
                <span v-if="row.value == 1">{{
                  trans('translations.label.backend')
                }}</span>
                <span v-if="row.value == 2">{{
                  trans('translations.label.frontend')
                }}</span>
              </template>
              <template v-slot:cell(module)="row">
                <span v-text="row.value"></span>
              </template>
              <template v-slot:cell(value)="row">
                <span v-text="row.value"></span>
              </template>
              <template v-slot:cell(trans_key)="row">
                <span v-text="row.value"></span>
              </template>
              <template v-slot:cell(saved_value)="row">
                <span
                  v-if="row.value"
                  class="translation-value"
                  v-text="row.value"
                  @click="
                    translationEdit(
                      row.item.id,
                      row.item.area_id,
                      row.item.locale,
                      row.item.module,
                      row.item.key,
                      row.value,
                      row.item.saved_value,
                      row.item.origin_value
                    )
                  "
                ></span>
                <span
                  v-else
                  class="translation-value translation-value-missing"
                  @click="
                    translationEdit(
                      row.item.id,
                      row.item.area_id,
                      row.item.locale,
                      row.item.module,
                      row.item.key,
                      row.item.value,
                      row.saved_value,
                      row.item.origin_value
                    )
                  "
                >
                  Missing translation
                </span>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button
                  v-if="row.item.can_edit && row.item.opt_default"
                  size="sm"
                  variant="primary"
                  v-b-tooltip.hover
                  :title="trans('core.button.edit')"
                  class="mr-1"
                  @click="
                    TranslationFormEdit(
                      row.item.id,
                      row.item.area_id,
                      row.item.locale,
                      row.item.module,
                      row.item.key,
                      row.value,
                      row.item.saved_value,
                      row.item.origin_value,
                      row.item.group
                    )
                  "
                >
                  <i class="fe fe-edit"></i>
                </b-button>
                <b-button
                  v-if="row.item.can_delete && row.item.opt_default"
                  size="sm"
                  variant="danger"
                  v-b-tooltip.hover
                  :title="trans('core.button.delete')"
                  @click.stop="onDelete(row.item.id)"
                >
                  <i class="fe fe-trash"></i>
                </b-button>
              </template>
            </b-table>
          </b-datatable>
        </div>
      </div>
    </b-card>
    <modals-container></modals-container>
  </div>
</template>

<script>
import axios from 'axios'
import TranslationCreateForm from './TranslationCreateForm.vue'
import TranslationImportProgress from './modal/TranslationImportProgress.vue'
import TranslationSyncProgress from './modal/TranslationSyncProgress.vue'
import TranslationPublishProgress from './modal/TranslationPublishProgress.vue'
import TranslationForm from './modal/TranslationForm.vue'
import TranslationFormEdit from './modal/TranslationFormEdit.vue'
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'

export default {
  title: '',
  name: 'TranslationList',
  directives: {
    sticky: VueStickyDirective
  },
  filters: {
    uppercase: function(v) {
      return v.toUpperCase()
    }
  },
  data() {
    return {
      status: '',
      area: '',
      lang: '',
      module: '',
      group: '',
      statuses: [],
      areas: [],
      langs: [],
      modules: [],
      groups: [],
      filters: [],
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'status',
          label: this.trans('translations.label.status'),
          sortable: true,
          class: 't-data-status'
        },
        {
          key: 'locale',
          label: this.trans('translations.label.locale'),
          sortable: true,
          class: 'data-module'
        },
        {
          key: 'origin_value',
          label: this.trans('translations.label.original'),
          sortable: true,
          class: 'data-value'
        },
        {
          key: 'saved_value',
          label: this.trans('translations.label.value'),
          sortable: true,
          class: 'data-value'
        },
        {
          key: 'area_id',
          label: this.trans('translations.label.area'),
          sortable: true,
          class: 'data-module'
        },
        {
          key: 'module',
          label: this.trans('translations.label.module'),
          sortable: true,
          class: 'data-module'
        },
        {
          key: 'group',
          label: this.trans('translations.label.group'),
          sortable: true,
          class: 'data-module'
        },
        {
          key: 'trans_key',
          label: this.trans('translations.label.key'),
          sortable: true,
          class: 'data-key'
        },
        {
          key: 'actions',
          label: this.trans('core.label.actions'),
          class: 'nowrap text-right actions'
        }
      ]
      /*      actions: {
        destroy: this.$t('translations.actions.destroy'),
        enable: this.$t('translations.actions.enable'),
        disable: this.$t('translations.actions.disable')
      }*/
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.trans('translations.title.list')
    this.getFilters()
  },
  methods: {
    translationEdit(
      id,
      area,
      locale,
      module,
      key,
      value,
      saved_value,
      origin_value
    ) {
      this.$modal.show(
        TranslationForm,
        {
          modal: this.isModalCreate,
          id: id,
          area: area,
          locale: locale,
          module: module,
          tkey: key,
          value: value,
          saved: saved_value,
          origin: origin_value
        },
        {
          name: 'translation-form',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    TranslationFormEdit(
      id,
      area,
      locale,
      module,
      key,
      value,
      saved_value,
      origin_value,
      group
    ) {
      this.$modal.show(
        TranslationFormEdit,
        {
          modal: this.isModalCreate,
          id: id,
          area: area,
          locale: locale,
          module: module,
          tkey: key,
          value: value,
          saved: saved_value,
          origin: origin_value,
          group: group
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    modalCreate() {
      this.$modal.show(
        TranslationCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          name: 'translation-create-form',
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ translation: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('translations.enabled', { translation: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    syncTranslations(area) {
      this.$modal.show(
        TranslationSyncProgress,
        {
          modal: this.isModalCreate,
          area: area
        },
        {
          name: 'translations-sync-progress',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
      axios
        .post(this.$app.route('translations.sync'), {
          area: area
        })
        .then(response => {
          this.refreshData()
          this.$modal.hide('translations-sync-progress')
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    importTranslations() {
      this.$modal.show(
        TranslationImportProgress,
        {
          modal: this.isModalCreate
        },
        {
          name: 'translations-import-progress',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
      axios
        .post(this.$app.route('translations.import'))
        .then(response => {
          this.refreshData()
          this.$modal.hide('translations-import-progress')
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    publishTranslations() {
      this.$modal.show(
        TranslationPublishProgress,
        {
          modal: this.isModalCreate
        },
        {
          name: 'translations-publish-progress',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
      axios
        .post(this.$app.route('translations.publish'))
        .then(response => {
          this.$modal.hide('translations-publish-progress')
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    setStatus(status) {
      if (this.status == status) {
        this.status = ''
      } else {
        this.status = status
      }
      this.getFilters()
      this.onContextChanged()
    },
    setArea(area) {
      if (this.area == area) {
        this.area = ''
      } else {
        this.area = area
      }
      this.getFilters()
      this.onContextChanged()
    },
    setLang(lang) {
      if (this.lang == lang) {
        this.lang = ''
      } else {
        this.lang = lang
      }
      this.getFilters()
      this.onContextChanged()
    },
    setModule(module) {
      if (this.module == module) {
        this.module = ''
      } else {
        this.module = module
      }
      this.getFilters()
      this.onContextChanged()
    },
    setGroup(group) {
      if (this.group == group) {
        this.group = ''
      } else {
        this.group = group
      }
      this.getFilters()
      this.onContextChanged()
    },
    async getFilters() {
      axios
        .post(
          this.$app.route('translations.filters', {
            area: this.area,
            lang: this.lang,
            module: this.module,
            group: this.group,
            status: this.status
          })
        )
        .then(response => {
          let data = response.data
          this.statuses = data.status
          this.areas = data.areas
          this.langs = data.langs
          this.modules = data.modules
          this.groups = data.groups
        })
        .catch(error => {
          this.$app.error(error)
        })
    }
  }
}
</script>
