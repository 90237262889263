<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form>
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('settings.titles.testing') }}
            </h3>

            <div>{{ sKey }}</div>
            <b-form-group
              :label="trans('settings.label.test-email-address')"
              label-for="email"
              label-cols-sm="3"
            >
              <b-form-input
                id="email"
                name="email"
                type="email"
                v-model="email"
              ></b-form-input>
            </b-form-group>
            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 24%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 38%"
                  :disabled="pending"
                  @click="send"
                >
                  {{ trans('settings.buttons.send') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'EmailTesting',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    sKey: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      email: ''
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('settings.titles.testing')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    send() {
      // Setup data.
      let data = {}
      data.key = this.sKey
      data.email = this.email

      axios
        .post(this.$app.route('email_services.test_sending'), data)
        .then(response => {
          this.refreshData()
          this.$modal.hide('email-testing')
          this.$app.noty[response.data.status](response.data.message)
          //this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.$app.error(error)
        })
    }
  }
}
</script>
