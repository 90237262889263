<template>
  <div>
    <b-row class="attribute-data-block">
      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.attribute-type')"
            label-for="attribute-type"
            :label-cols="3"
          >
            <b-input-group>
              <div class="attribute-type-block">- {{ getAttributeType() }}</div>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>

      <b-col md="12">
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.settings-layout-desktop') }}
              </h4>
            </b-col>
            <b-col lg="9"> </b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.layout_col')"
            label-for="d_col"
            label-cols-sm="3"
          >
            <div class="data-min-max">
              <div class="row">
                <div class="col-9">
                  <b-form-select
                    id="d_col"
                    name="d_col"
                    v-model="model.layout.d_col"
                    :options="layouts"
                    class="form-control select-box-with-arrow custom-select w-9"
                  >
                  </b-form-select>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="validation_required"
                  v-model="model.layout.d_n"
                  :description="$t('attributes.label.layout_col_show_name')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>

      <b-col md="12">
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.settings-layout-tablet') }}
              </h4>
            </b-col>
            <b-col lg="9"></b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.layout_col')"
            label-for="d_col"
            label-cols-sm="3"
          >
            <div class="data-min-max">
              <div class="row">
                <div class="col-9">
                  <b-form-select
                    id="d_col"
                    name="d_col"
                    v-model="model.layout.t_col"
                    :options="layouts"
                    class="form-control select-box-with-arrow custom-select w-9"
                  >
                  </b-form-select>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="validation_required"
                  v-model="model.layout.t_n"
                  :description="$t('attributes.label.layout_col_show_name')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="12">
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.settings-layout-mobile') }}
              </h4>
            </b-col>
            <b-col lg="9"></b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.layout_col')"
            label-for="d_col"
            label-cols-sm="3"
          >
            <div class="data-min-max">
              <div class="row">
                <div class="col-9">
                  <b-form-select
                    id="d_col"
                    name="d_col"
                    v-model="model.layout.m_col"
                    :options="layouts"
                    class="form-control select-box-with-arrow custom-select w-9"
                  >
                  </b-form-select>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="validation_required"
                  v-model="model.layout.m_n"
                  :description="$t('attributes.label.layout_col_show_name')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AttributeLayout',
  components: {},
  props: {},
  data() {
    return {
      layouts: {
        12: '12',
        11: '11',
        10: '10',
        9: '9',
        8: '8',
        7: '7',
        6: '6',
        5: '5',
        4: '4',
        3: '3',
        2: '2',
        1: '1'
      }
    }
  },
  computed: {
    mode() {
      return this.$store.getters['attribute/mode']
    },
    model() {
      return this.$store.getters['attribute/data']
    },
    attribute() {
      return this.$store.getters['attribute/attribute']
    },
    errors() {
      return this.$store.getters['attribute/errors']
    }
  },
  methods: {
    getAttributeType() {
      let recordType = this.model.type

      if (recordType == 'text') {
        return this.$t('attributes.types.text')
      }

      if (recordType == 'textarea') {
        return this.$t('attributes.types.textarea')
      }

      if (recordType == 'wysiwyg') {
        return this.$t('attributes.types.wysiwyg')
      }

      if (recordType == 'number') {
        return this.$t('attributes.types.number')
      }

      if (recordType == 'email') {
        return this.$t('attributes.types.email')
      }

      if (recordType == 'upload') {
        return this.$t('attributes.types.upload')
      }

      if (recordType == 'image') {
        return this.$t('attributes.types.image')
      }

      if (recordType == 'file') {
        return this.$t('attributes.types.file')
      }

      if (recordType == 'select') {
        return this.$t('attributes.types.select')
      }

      if (recordType == 'toggle') {
        return this.$t('attributes.types.toggle')
      }

      if (recordType == 'checkbox') {
        return this.$t('attributes.types.checkbox')
      }

      if (recordType == 'radio') {
        return this.$t('attributes.types.radio')
      }

      if (recordType == 'service_updated_at') {
        return this.$t('services.types.service_updated_at')
      }

      if (recordType == 'service_online') {
        return this.$t('services.types.service_online')
      }

      return recordType
    }
  }
}
</script>
