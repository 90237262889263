<template>
  <div class="data-sidebar">
    <slot name="stats"></slot>
    <slot name="filters"></slot>
  </div>
</template>
<script>
export default {
  name: 'DataSidebar',
  props: {},
  mounted: function() {},
  methods: {}
}
</script>
