<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onAttributeSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('attributes.titles.create') }}
            </h3>
            <div class="multi-lang multi-lang-open">
              <div
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
              >
                <b-form-group
                  :label="$t('validation.attributes.name') + ' *'"
                  :label-for="`name.${index}`"
                  :label-cols="3"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`name.${index}`"
                      :name="`name.${index}`"
                      v-model="model.name[`${index}`]"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fab fa-autoprefixer"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <!--                    <b-form-feedback>
                      {{ feedback('name.' + index) }}
                    </b-form-feedback>-->
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('attributes.labels.type')"
                  label-for="type"
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="6" v-if="fieldText">
                      <card-select
                        :name="$t('attributes.types.text')"
                        @click.native="setType('text')"
                        :css-class="model.type === 'text' ? 'active' : ''"
                      ></card-select>
                    </b-col>
                    <b-col md="6" v-if="fieldTextarea">
                      <card-select
                        :name="$t('attributes.types.textarea')"
                        @click.native="setType('textarea')"
                        :css-class="model.type === 'textarea' ? 'active' : ''"
                      ></card-select>
                    </b-col>
                    <b-col md="6" v-if="fieldWysiwyg">
                      <card-select
                        :name="$t('attributes.types.wysiwyg')"
                        @click.native="setType('wysiwyg')"
                        :css-class="model.type === 'wysiwyg' ? 'active' : ''"
                      ></card-select>
                    </b-col>
                    <b-col md="6" v-if="fieldNumber">
                      <card-select
                        :name="$t('attributes.types.number')"
                        @click.native="setType('number')"
                        :css-class="model.type === 'number' ? 'active' : ''"
                      ></card-select>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('attributes.labels.value')"
                  label-for="value"
                  :label-cols="3"
                  v-if="model.type && type !== 'form'"
                >
                  <b-row>
                    <!--Text-->
                    <b-col md="12" v-if="fieldText && model.type === 'text'">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`value.${index}`"
                          :name="`value.${index}`"
                          v-model="model.value[`${index}`]"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <!--                    <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>-->
                      </b-input-group>
                    </b-col>
                    <!--Text END-->
                    <!--Text Area-->
                    <b-col
                      md="12"
                      v-if="fieldText && model.type === 'textarea'"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                        </b-input-group-prepend>
                        <b-form-textarea
                          :id="`value.${index}`"
                          :name="`value.${index}`"
                          :rows="5"
                          v-model="model.value[`${index}`]"
                        ></b-form-textarea>
                        <!--                    <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>-->
                      </b-input-group>
                    </b-col>
                    <!--Text Area END-->
                    <!--Text Editor-->
                    <b-col md="12" v-if="fieldText && model.type === 'wysiwyg'">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                        </b-input-group-prepend>
                        <p-richtexteditor
                          :id="`value.${index}`"
                          :name="`value.${index}`"
                          v-model="model.value[`${index}`]"
                        ></p-richtexteditor>
                        <!--                    <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>-->
                      </b-input-group>
                    </b-col>
                    <!--Text Editor END-->
                    <!--Number-->
                    <b-col md="12" v-if="fieldText && model.type === 'number'">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`value.${index}`"
                          :name="`value.${index}`"
                          type="number"
                          v-model="model.valueNumeric"
                        ></b-form-input>
                        <!--                    <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>-->
                      </b-input-group>
                    </b-col>
                    <!--Number END-->
                  </b-row>
                </b-form-group>
              </div>
            </div>
            <template v-if="modal">
              <action-footer-attribute-modal
                :page-title="$t('attributes.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="false"
                :save-permission="resourceRoute"
              >
              </action-footer-attribute-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import attribute from '@attributes/js/mixins/attribute'
export default {
  title: '',
  name: 'AttributeCreate',
  mixins: [attribute],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    fieldText: {
      type: Boolean,
      default: false
    },
    fieldTextarea: {
      type: Boolean,
      default: false
    },
    fieldWysiwyg: {
      type: Boolean,
      default: false
    },
    fieldNumber: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelName: 'attribute',
      resourceRoute: 'attributes',
      isModal: this.modal,
      model: {
        // General field
        identifier: '',
        type: '',
        name: {},
        order: 0,
        orderLang: {},
        status: true,
        deletable: true,
        comment: {},

        // Fields by attribute type
        value: {}, // text | textarea | wysiwyg
        valueNumeric: '', // number
        note: {}, // text | textarea | wysiwyg | number
        placeholder: {} // text | textarea | wysiwyg | number
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('attributes.titles.create')
  },
  methods: {
    setType(type) {
      this.model.type = type
      this.model.value = {}
      this.model.valueNumeric = ''
    },
    modalClose() {
      this.$emit('close')
    }
  }
}
</script>
