<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('auctions.titles.list') }}
        </h3>
        <!--        <div class="card-options" v-if="this.$app.user.can('create auctions')">
          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('auctions.buttons.create') }}
          </b-button>
        </div>-->
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="auctions.search"
        delete-route="auctions.destroy"
        action-route="auctions.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(reference)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/form/records/${row.item.id}/edit`"
              v-text="row.value"
            ></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template v-slot:cell(set_name)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/auctions/${row.item.id}/edit`"
              v-text="row.value"
              class="data-name-strong"
            ></router-link>
            <span v-else v-text="row.value" class="data-name-strong"></span>
          </template>
          <template v-slot:cell(product_categories)="row">
            <div class="row row-cat-index row-cat-index-header">
              <div class="col-5">Name</div>
              <div class="col-2">Quantity</div>
              <div class="col-5 text-right">Action / Information</div>
            </div>
            <div
              class="row row-cat-index"
              :key="slotIndex"
              v-for="(slot, slotIndex) in row.item.auction_slots"
            >
              <div class="col-5">{{ slot.set_name }}</div>
              <div class="col-2">{{ slot.qty }} {{ slot.unit_symbol }}</div>
              <div class="col-5">
                <div class="slot-buttons">
                  <template v-if="slot.stage == 'ended' && slot.winner_id">
                    <b-button
                      v-b-tooltip.hover
                      class="slot-btn slot-contract"
                      @click="SignWinnerModal(slot.id)"
                      v-if="!slot.signed_winner"
                    >
                      <i class="fas fa-info"></i> Winner contract
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      class="slot-btn slot-contract"
                      @click="SignOwnerModal(slot.id)"
                      v-if="!slot.signed_owner"
                    >
                      <i class="fas fa-info"></i> Owner contract
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      class="slot-btn slot-delivered"
                      @click="SignDeliveredModal(slot.id)"
                      v-if="
                        slot.signed_winner &&
                          slot.signed_owner &&
                          !slot.delivered &&
                          !slot.closed
                      "
                    >
                      <i class="fas fa-info"></i> Delivered
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      class="slot-btn slot-close"
                      @click="SignClosedModal(slot.id)"
                      v-if="
                        slot.signed_winner &&
                          slot.signed_owner &&
                          slot.delivered &&
                          !slot.closed
                      "
                    >
                      <i class="fas fa-info"></i> Close deal
                    </b-button>
                  </template>
                  <template v-if="slot.closed">
                    <div>
                      <span class="badge badge-active">
                        Deal closed
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:cell(status)="row">
            <span v-html="statusRender(row.value)"></span>
          </template>
          <template v-slot:cell(auction_stage)="row">
            <span v-html="stageRender(row.value)"></span>
          </template>
          <template v-slot:cell(enabled)="row">
            <c-switch
              v-if="row.item.can_edit"
              :checked="row.value"
              @change="onActiveToggle(row.item.id)"
            ></c-switch>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/auctions/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-dropdown
              size="sm"
              text=""
              class="adm-btn-dropdown action-btn-dropdown"
            >
              <b-dropdown-item @click.stop="onDelete(row.item.id)">
                <span class="icon-holder icon-danger">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="label-holder label-delete">{{
                  trans('core.button.delete')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import AuctionCreateForm from './AuctionCreateForm.vue'
import SignWinner from '@auctions/js/components/Modals/SignWinner'
import SignOwner from '@auctions/js/components/Modals/SignOwner'
import SignDelivered from '@auctions/js/components/Modals/Delivered'
import SignClosed from '@auctions/js/components/Modals/Closed'
export default {
  title: '',
  name: 'AuctionList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'reference',
          label: this.$t('auctions.label.code'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'product_categories',
          label: this.$t('auctions.label.auction_set'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'status',
          label: this.$t('auctions.label.status'),
          sortable: true,
          class: 'data-status text-center'
        },
        {
          key: 'auction_stage',
          label: 'Auction stage',
          sortable: true,
          class: 'data-status text-center'
        },
        {
          key: 'placed_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        /*        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },*/
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('auctions.actions.destroy'),
        enable: this.$t('auctions.actions.enable'),
        disable: this.$t('auctions.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('auctions.titles.list')
  },
  methods: {
    SignWinnerModal(id) {
      this.$modal.show(
        SignWinner,
        {
          modal: true,
          id: id
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    SignOwnerModal(id) {
      this.$modal.show(
        SignOwner,
        {
          modal: true,
          id: id
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    SignDeliveredModal(id) {
      this.$modal.show(
        SignDelivered,
        {
          modal: true,
          id: id
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    SignClosedModal(id) {
      this.$modal.show(
        SignClosed,
        {
          modal: true,
          id: id
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    modalCreate() {
      this.$modal.show(
        AuctionCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ auction: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('auctions.enabled', { auction: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('auctions.status.' + status) +
        '<span>'
      )
    },
    stageRender(stage) {
      return (
        '<span class="badge badge-stage-' +
        stage +
        '">' +
        this.trans('auctions.stage.' + stage) +
        '<span>'
      )
    }
  }
}
</script>
