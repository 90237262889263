var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"attribute-data-block"},[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.label.attribute-type'),"label-for":"attribute-type","label-cols":3}},[_c('b-input-group',[_c('div',{staticClass:"attribute-type-block"},[_vm._v("- "+_vm._s(_vm.getAttributeType()))])])],1)],1)]),_vm._v(" "),(_vm.checkModelTypeGeneral())?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('validation.attributes.name') + ' *',"label-for":("name." + index),"label-cols":3}},[_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"input-group-prepend-title prepend-lang",class:[locale.required ? 'prepend-lang-required' : '']},[_c('b-input-group-text',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"data-toggle":"","title":_vm.$t(locale.name)}},[_c('span',[_vm._v(_vm._s(index))])])],1),_vm._v(" "),_c('b-form-input',{attrs:{"id":("name." + index),"name":("name." + index)},model:{value:(_vm.model.name[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.name, ("" + index), $$v)},expression:"model.name[`${index}`]"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"input-group-append-title"},[_c('b-input-group-text',{attrs:{"data-toggle":""}},[_c('i',{staticClass:"fab fa-autoprefixer"})])],1),_vm._v(" "),(_vm.errors.error_name_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.name-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(
        _vm.model.type && _vm.model.type === 'text' && _vm.model.kind !== 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.field.value'),"label-for":("value." + index),"label-cols":3}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":("value." + index),"name":("value." + index)},model:{value:(_vm.model.valueLang[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.valueLang, ("" + index), $$v)},expression:"model.valueLang[`${index}`]"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"input-group-append-title"},[_c('b-input-group-text',{attrs:{"data-toggle":""}},[_c('i',{staticClass:"fab fa-autoprefixer"})])],1),_vm._v(" "),(_vm.errors.error_value_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.name-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(
        _vm.model.type &&
          _vm.model.type === 'textarea' &&
          _vm.model.kind !== 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.field.value'),"label-for":("value." + index),"label-cols":3}},[_c('b-input-group',[_c('b-form-textarea',{attrs:{"id":("value." + index),"name":("value." + index),"rows":10},model:{value:(_vm.model.valueLang[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.valueLang, ("" + index), $$v)},expression:"model.valueLang[`${index}`]"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"input-group-append-title"},[_c('b-input-group-text',{attrs:{"data-toggle":""}},[_c('i',{staticClass:"fab fa-autoprefixer"})])],1),_vm._v(" "),(_vm.errors.error_value_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.name-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(
        _vm.model.type &&
          _vm.model.type === 'wysiwyg' &&
          _vm.model.kind !== 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.field.value'),"label-for":("value." + index),"label-cols":3}},[_c('b-input-group',[_c('p-richtexteditor',{attrs:{"id":("value." + index),"name":("value." + index)},model:{value:(_vm.model.valueLang[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.valueLang, ("" + index), $$v)},expression:"model.valueLang[`${index}`]"}}),_vm._v(" "),(_vm.errors.error_value_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.name-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(
        _vm.model.type && _vm.model.type === 'number' && _vm.model.kind !== 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.field.value'),"label-for":"valueNumeric","label-cols":3}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"valueNumeric","name":"valueNumeric","type":"number"},model:{value:(_vm.model.valueNumeric),callback:function ($$v) {_vm.$set(_vm.model, "valueNumeric", $$v)},expression:"model.valueNumeric"}}),_vm._v(" "),(_vm.errors.error_value_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.name-required'))+"\n              ")]):_vm._e()],1)],1)],1)])]):_vm._e(),_vm._v(" "),(
        _vm.model.type && _vm.model.type === 'file' && _vm.model.kind !== 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},[_c('FileView',{attrs:{"allow-files":true,"allow-images":false,"single":false}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.model.type && _vm.model.type === 'image' && _vm.model.kind !== 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},[_c('FileView',{attrs:{"allow-files":false,"allow-images":true,"single":false}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.model.type && _vm.checkModelTypeForNote() && _vm.model.kind == 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.labels.note'),"label-for":("note." + index),"label-cols":3,"description":_vm.$t('attributes.notes.notes')}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":("note." + index),"name":("note." + index)},model:{value:(_vm.model.note[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.note, ("" + index), $$v)},expression:"model.note[`${index}`]"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"input-group-append-title"},[_c('b-input-group-text',{attrs:{"data-toggle":""}},[_c('i',{staticClass:"fab fa-autoprefixer"})])],1),_vm._v(" "),(_vm.errors.error_note_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.note-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(
        _vm.model.type &&
          _vm.checkModelTypeForPlaceholder() &&
          _vm.model.kind == 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.labels.placeholder'),"label-for":("placeholder." + index),"label-cols":3}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":("placeholder." + index),"name":("placeholder." + index)},model:{value:(_vm.model.placeholder[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.placeholder, ("" + index), $$v)},expression:"model.placeholder[`${index}`]"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"input-group-append-title"},[_c('b-input-group-text',{attrs:{"data-toggle":""}},[_c('i',{staticClass:"fab fa-autoprefixer"})])],1),_vm._v(" "),(_vm.errors.error_placeholder_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.placeholder-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e(),_vm._v(" "),(
        _vm.model.type &&
          _vm.checkModelTypeForTooltip() &&
          _vm.model.kind == 'form_element'
      )?_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"multi-lang multi-lang-open"},_vm._l((this.$app.locales),function(locale,index){return (locale.backend)?_c('div',{key:index},[_c('b-form-group',{attrs:{"label":_vm.$t('attributes.labels.tooltip'),"label-for":("tooltip." + index),"label-cols":3}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":("tooltip." + index),"name":("tooltip." + index)},model:{value:(_vm.model.tooltip[("" + index)]),callback:function ($$v) {_vm.$set(_vm.model.tooltip, ("" + index), $$v)},expression:"model.tooltip[`${index}`]"}}),_vm._v(" "),_c('b-input-group-append',{staticClass:"input-group-append-title"},[_c('b-input-group-text',{attrs:{"data-toggle":""}},[_c('i',{staticClass:"fab fa-autoprefixer"})])],1),_vm._v(" "),(_vm.errors.error_tooltip_no)?_c('div',{staticClass:"invalid-feedback"},[_vm._v("\n                "+_vm._s(_vm.$t('attributes.alert.tooltip-required'))+"\n              ")]):_vm._e()],1)],1)],1):_vm._e()}),0)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }