<template>
  <button class="button" :title="action" type="button" :class="`btn-${source}`">
    <edit-2-icon v-if="source === 'rename'" size="19"></edit-2-icon>
    <i class="fas fa-print" v-if="source === 'print'"></i>
    <i class="fas fa-trash-alt" v-if="source === 'trash'"></i>
    <i class="fas fa-times" v-if="source === 'close'"></i>
    <sorting-and-preview-icon
      v-if="source === 'preview-sorting'"
      size="19"
      class="preview-sorting"
    ></sorting-and-preview-icon>
    <i class="fas fa-cloud-download-alt" v-if="source === 'download'"></i>
  </button>
</template>

<script>
import { Edit2Icon } from 'vue-feather-icons'
import SortingAndPreviewIcon from '@assets/js/components/Icons/SortingAndPreviewIcon'

export default {
  name: 'ToolbarButton',
  components: {
    SortingAndPreviewIcon,
    Edit2Icon
  },
  props: {
    source: {
      type: String,
      default: ''
    },
    action: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.preview-sorting {
  svg {
    width: 19px;
    height: 19px;
  }
}

.button {
  height: 42px;
  width: 42px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  border: none;
  @include transition(150ms);
  background: transparent;

  .svg-inline--fa {
    font-size: 24px;
  }

  .fa-trash-alt {
    color: red;
  }

  svg {
    &.fa-print,
    &.fa-cloud-download-alt,
    &.fa-times {
      color: $color-light;
      font-size: 18px;

      &:hover {
        color: $color-yellow;
        @include transition(150ms);
        background: transparent !important;
      }
    }
  }

  &:hover {
    .preview-sorting {
      path,
      line,
      polyline,
      rect,
      circle {
        stroke: $theme !important;
      }
    }
  }

  &:hover {
    background: $light_background;

    path,
    line,
    polyline,
    rect,
    circle {
      @include transition(150ms);
      stroke: $theme;
    }
  }
  &.btn-close {
    @include transition(150ms);
    &:hover {
      background: transparent;

      svg {
        &.fa-times {
          color: $color-red;
        }
      }
    }
  }
  &.btn-download,
  &.btn-print {
    @include transition(150ms);
    &:hover {
      background: transparent;

      svg {
        &.fa-times {
          color: $color-red;
        }
      }
    }
  }
}
</style>
