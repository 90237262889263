<template>
  <ul class="menu-option-group">
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'OptionGroup'
}
</script>

<style lang="scss" scoped>
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.menu-option-group {
  padding: 5px 0;
  border-bottom: 1px solid $light_mode_border;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }
}
</style>
