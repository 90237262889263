<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <b-form-group
                  :label="$t('companies.fields.name') + ' *'"
                  label-for="name"
                  :label-cols="3"
                  :feedback="feedback('name')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="name"
                          name="name"
                          v-model="model.name"
                          :state="state('name')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('companies.fields.email') + ' *'"
                  label-for="email"
                  :label-cols="3"
                  :feedback="feedback('email')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-at"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="email"
                          name="email"
                          type="email"
                          v-model="model.email"
                          :state="state('email')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('email') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.phone') + ' *'"
                  label-for="phone"
                  :label-cols="3"
                  :feedback="feedback('phone')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-phone"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="phone"
                          name="phone"
                          v-model="model.phone"
                          :state="state('phone')"
                        ></b-form-input>
                        <b-form-feedback>
                          {{ feedback('phone') }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.country')"
                  label-for="country"
                  :label-cols="3"
                  :feedback="feedback('country')"
                >
                  <b-row>
                    <b-col md="8">
                      <multiselect
                        class="input-m-select input-h-select"
                        v-model="model.country"
                        track-by="code"
                        label="asciiname"
                        placeholder=""
                        :options="countries"
                        :searchable="false"
                        :allow-empty="false"
                        :multiple="false"
                        autocomplete="off"
                        @input="setCountry"
                        @select="setCountry"
                        @remove="removeCountry"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          <strong>
                            {{ option.asciiname }} ({{ option.name }})
                          </strong>
                          <img
                            class="option__img option__img-selected-right country-flag"
                            :src="getFlag(option.code)"
                            :alt="option.name"
                          />
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              {{ props.option.asciiname }}
                            </span>
                            <span class="option__small">
                              ({{ props.option.name }})
                            </span>
                          </div>
                          <img
                            class="option__img option__img-right country-flag"
                            :src="getFlag(props.option.icode)"
                            :alt="props.option.name"
                          />
                        </template>
                        <template slot="caret" slot-scope="{ toggle }">
                          <div
                            @mousedown.prevent.stop="toggle"
                            class="toggle-hold"
                          >
                            <font-awesome-icon
                              :icon="['fa', 'chevron-down']"
                            ></font-awesome-icon>
                          </div>
                        </template>
                      </multiselect>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.city') + ' *'"
                  label-for="adr_city"
                  :label-cols="3"
                  :feedback="feedback('adr_city')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="adr_city"
                          name="adr_city"
                          v-model="model.adr_city"
                          :state="state('adr_city')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.street') + ' *'"
                  label-for="adr_street"
                  :label-cols="3"
                  :feedback="feedback('adr_street')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="adr_street"
                          name="adr_street"
                          v-model="model.adr_street"
                          :state="state('adr_street')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.street_no') + ' *'"
                  label-for="adr_no"
                  :label-cols="3"
                  :feedback="feedback('adr_no')"
                >
                  <b-row>
                    <b-col md="4">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="adr_no"
                          name="adr_no"
                          v-model="model.adr_no"
                          :state="state('adr_no')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.zip') + ' *'"
                  label-for="adr_zip"
                  :label-cols="3"
                  :feedback="feedback('adr_zip')"
                >
                  <b-row>
                    <b-col md="4">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="adr_zip"
                          name="adr_zip"
                          v-model="model.adr_zip"
                          :state="state('adr_zip')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.company_code') + ' *'"
                  label-for="company_code"
                  :label-cols="3"
                  :feedback="feedback('company_code')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="company_code"
                          name="company_code"
                          v-model="model.company_code"
                          :state="state('company_code')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.vat_no') + ' *'"
                  label-for="vat_no"
                  :label-cols="3"
                  :feedback="feedback('vat_no')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="vat_no"
                          name="vat_no"
                          v-model="model.vat_no"
                          :state="state('vat_no')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('companies.fields.website')"
                  label-for="website"
                  :label-cols="3"
                  :feedback="feedback('website')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="website"
                          name="website"
                          v-model="model.website"
                          :state="state('website')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="enabled"
                        v-model="model.enabled"
                        :description="$t('validation.attributes.enabled')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('companies.tabs.certificates')" id="certificates">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  Certificates
                </h3>
                <b-form-group
                  :label="$t('companies.fields.certificates')"
                  label-for="certificates"
                  :label-cols="3"
                >
                  <b-row>
                    <b-col md="8">
                      <b-form-checkbox-group
                        stacked
                        v-model="model.certificates"
                        name="certificates[]"
                      >
                        <b-form-checkbox
                          v-for="certificate in certificates"
                          :key="certificate.id"
                          :value="certificate.id"
                        >
                          {{ certificate.name }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <!-- conR <v-tab :title="$t('labels.url')" id="url">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-url"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.url') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.url')"
                  :label-for="`routes.${index}.slug`"
                  label-cols-sm="3"
                  :feedback="feedback('routes.' + index + '.slug')"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`routes.${index}.slug`"
                      :name="`routes.${index}.slug`"
                      v-model="model.routes[`${index}`]['slug']"
                      :state="state('routes.' + index + '.slug')"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-link"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('routes.' + index + '.slug') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('validation.attributes.url-target')"
                  label-for="target"
                  :description="$t('validation.attributes.url-target-info')"
                  label-cols-sm="3"
                >
                  <div class="url-link">
                    <a
                      :href="
                        $app.appUrlClient +
                          '/' +
                          meta.routes[`${index}`]['slug']
                      "
                      target="_blank"
                    >
                      {{ $app.appUrlClient }}/{{
                        meta.routes[`${index}`]['slug']
                      }}
                    </a>
                    <template
                      v-if="
                        meta.routes[`${index}`]['slug'] !==
                          model.routes[`${index}`]['slug']
                      "
                    >
                      <span class="url-link-new">
                        <i class="fas fa-long-arrow-alt-right"></i>
                        {{ $app.appUrlClient }}/{{
                          model.routes[`${index}`]['slug']
                        }}
                      </span>
                    </template>
                  </div>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab> -->

        <!-- conM <v-tab :title="$t('labels.seo')" id="seo">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                class="group-lang group-lang-seo"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.seo') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('labels.meta.title')"
                  :label-for="`metadata.title.${index}`"
                  label-cols-sm="3"
                  :description="$t('labels.meta.title-note')"
                  :feedback="feedback('metadata.title.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`metadata.title.${index}`"
                      :name="`metadata.title.${index}`"
                      v-model="model.metadata.title[`${index}`]"
                      :state="state('metadata.title.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-window-maximize"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('metadata.title.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.description')"
                  :label-for="`metadata.description.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.description.${index}`"
                    :name="`metadata.description.${index}`"
                    :rows="3"
                    v-model="model.metadata.description[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  :label="$t('labels.meta.keywords')"
                  :label-for="`metadata.keywords.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`metadata.keywords.${index}`"
                    :name="`metadata.keywords.${index}`"
                    :rows="3"
                    v-model="model.metadata.keywords[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import Multiselect from 'vue-multiselect'

export default {
  name: `CompanyEditForm`,
  components: {
    VueTabs,
    VTab,
    Multiselect
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'company',
      resourceRoute: 'companies',
      listPath: '/companies',
      model: {
        // Data
        name: '',
        email: '',
        phone: '',
        country_code: '',
        adr_city: '',
        adr_street: '',
        adr_no: '',
        adr_zip: '',
        company_code: '',
        vat_no: '',
        website: '',
        description: '',
        position: 0,
        country: '',
        enabled: false,
        certificates: [],
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      certificates: [],
      countries: [],
      icons: {
        opened: '<i class="fas fa-chevron-up"></i>',
        closed: '<i class="fas fa-chevron-down"></i>'
      }
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('companies.titles.edit')
    this.initCertificates()
    this.initCountries()
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('companies.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    async initCertificates() {
      let { data } = await axios.get(
        this.$app.route('companies_certificates.list')
      )
      this.certificates = data
    },
    async initCountries() {
      let { data } = await axios.get(this.$app.route('countries.list'))
      this.countries = data
    },
    setCountry(selectedOption) {
      if (selectedOption) {
        this.model.country_code = selectedOption.code
      }
    },
    removeCountry() {
      this.model.country_code = ''
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.country-flag {
  max-width: 30px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
}
</style>
<style scoped>
.country-flag {
  max-width: 30px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
}
</style>
