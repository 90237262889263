<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">{{ $t('users-labels.users.titles.index') }}</h3>
        <div class="card-options" v-if="this.$app.user.can('create users')">
          <b-button to="/users/create" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('users-buttons.users.create') }}
          </b-button>
        </div>
      </template>
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="users.search"
        delete-route="users.destroy"
        action-route="users.batch_action"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="md"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
        >
          <template v-slot:cell(full_name)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/users/${row.item.id}/edit`"
              v-text="row.value"
            ></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template v-slot:cell(roles)="row">
            {{ formatRoles(row.value) }}
          </template>
          <template v-slot:cell(status)="row">
            <span v-html="statusRender(row.value)"></span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              size="sm"
              variant="primary"
              v-b-tooltip.hover
              :title="trans('core.button.info')"
              class="mr-1"
              @click="Info(row.item.id)"
            >
              <i class="fas fa-info"></i>
            </b-button>
            <b-button
              v-if="row.item.can_edit"
              size="sm"
              variant="primary"
              :to="`/users/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fas fa-edit"></i>
            </b-button>
            <b-dropdown
              size="sm"
              text=""
              class="adm-btn-dropdown action-btn-dropdown"
            >
              <b-dropdown-item
                @click="
                  SendMessage(
                    row.item.id, // id
                    'message-to-user', // type
                    'Users', // module
                    'User', // module model
                    'App\\User', // path to model
                    row.item.full_name // recipient
                  )
                "
              >
                <span class="icon-holder">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="label-holder">{{
                  trans('core.button.message')
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item @click.stop="onDelete(row.item.id)">
                <span class="icon-holder icon-danger">
                  <i class="fas fa-trash-alt"></i>
                </span>
                <span class="label-holder label-delete">{{
                  trans('core.button.delete')
                }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import Message from '@email-services/js/components/Modals/Message'
import { VTab, VueTabs } from 'vue-nav-tabs'
import Info from '@users/js/components/modals/Info'

export default {
  name: 'UserList',
  data() {
    return {
      fields: [
        {
          key: 'full_name',
          label: this.$t('validation.attributes.name'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'email',
          label: this.$t('validation.attributes.email'),
          sortable: true
        },
        { key: 'roles', label: this.$t('validation.attributes.roles') },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
          class: 'data-status'
        },
        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('users-labels.users.actions.destroy'),
        enable: this.$t('users-labels.users.actions.enable'),
        disable: this.$t('users-labels.users.actions.disable')
      }
    }
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ user: id })
    },
    onActiveToggle(id) {
      axios.post(this.$app.route('users.active', { user: id })).catch(error => {
        this.$app.error(error)
      })
    },
    formatRoles(roles) {
      return roles
        .map(key => {
          return key.display_name
        })
        .join(', ')
    },
    statusRender(status) {
      return (
        '<span class="badge badge-' +
        status +
        '">' +
        this.trans('user.status.' + status) +
        '<span>'
      )
    },
    SendMessage(id, type, module, moduleModel, modelPath, recipient) {
      this.$modal.show(
        Message,
        {
          modal: true,
          id: id,
          type: type,
          module: module,
          moduleModel: moduleModel,
          modelPath: modelPath,
          recipient: recipient
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    Info(id) {
      this.$modal.show(
        Info,
        {
          modal: true,
          id: id
        },
        {
          name: 'translation-form-edit',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    }
  }
}
</script>
