<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onAttributeSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card class="attribute-tabs dialog-tabs">
            <h3 class="card-title" slot="header">
              <template v-if="mode === 'create'">
                {{ $t('attributes.titles.create') }}
              </template>
              <template v-else>
                {{ $t('attributes.titles.edit') }}
              </template>
            </h3>
            <vue-tabs
              active-tab-color="#363B64"
              active-text-color="white"
              type="pills"
              class="attribute-tabs"
              v-model="activeTab"
            >
              <v-tab
                title="Field Type"
                icon="fas fa-grip-horizontal"
                v-if="mode == 'create'"
              >
                <div class="attribute-tab-content tab-content-wrap">
                  <attribute-types
                    :select-kind="type"
                    :file-field="fileField"
                    :image-field="imageField"
                    :upload-field-form="uploadFieldForm"
                    :select-field="selectField"
                    :select-field-form="selectFieldForm"
                    :text-field="textField"
                    :text-field-form="textFieldForm"
                    :text-field-form-data="textFieldFormData"
                    :textarea-field="textareaField"
                    :textarea-field-form="textareaFieldForm"
                    :textarea-field-form-data="textareaFieldFormData"
                    :wysiwyg-field="wysiwygField"
                    :wysiwyg-field-form="wysiwygFieldForm"
                    :wysiwyg-field-form-data="wysiwygFieldFormData"
                    :number-field="numberField"
                    :number-field-form="numberFieldForm"
                    :toggle-field-form="toggleFieldForm"
                    :url-field="urlField"
                    :url-field-form="urlFieldForm"
                    :radio-field-form="radioFieldForm"
                    :checkbox-field-form="checkboxFieldForm"
                    :email-field="emailField"
                    :email-field-form="emailFieldForm"
                  ></attribute-types>
                </div>
              </v-tab>

              <v-tab title="Field Data" icon="fas fa-pen" v-if="record.type">
                <div class="attribute-tab-content">
                  <attribute-data></attribute-data>
                </div>
              </v-tab>

              <v-tab
                title="Field Options"
                icon="fas fa-grip-vertical"
                v-if="record.type && checkTypeForOptionsTab(record.type)"
              >
                <div class="attribute-tab-content">
                  <attribute-options></attribute-options>
                </div>
              </v-tab>

              <v-tab
                title="Verification"
                icon="fas fa-spell-check"
                v-if="
                  record.type && type == 'form' && record.kind != 'form_data'
                "
              >
                <div class="attribute-tab-content">
                  <attribute-validation></attribute-validation>
                </div>
              </v-tab>
              <v-tab
                title="Layouts"
                icon="fas fa-columns"
                v-if="record.type && checkTypeForLayoutTab(record.type)"
              >
                <div class="attribute-tab-content">
                  <attribute-layout></attribute-layout>
                </div>
              </v-tab>
              <v-tab
                title="Settings"
                icon="fas fa-sliders-h"
                v-if="
                  record.type &&
                    (record.type == 'file' || record.type == 'image')
                "
              >
                <div class="attribute-tab-content">
                  <attribute-settings></attribute-settings>
                </div>
              </v-tab>
            </vue-tabs>
            <template v-if="modal">
              <action-footer-attribute-modal
                :page-title="$t('attributes.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="false"
                :save-permission="resourceRoute"
              >
              </action-footer-attribute-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import attribute from '@attributes/js/mixins/attribute'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import AttributeTypes from '@attributes/js/Elements/AttributeTypes'
import AttributeData from '@attributes/js/Elements/AttributeData'
import AttributeOptions from '@attributes/js/Elements/AttributeOptions'
import AttributeValidation from '@attributes/js/Elements/AttributeValidation'
import AttributeSettings from '@attributes/js/Elements/AttributeSettings'
import AttributeLayout from '@attributes/js/Elements/AttributeLayout'
export default {
  title: '',
  name: 'Attribute',
  components: {
    VueTabs,
    VTab,
    'attribute-types': AttributeTypes,
    'attribute-data': AttributeData,
    'attribute-options': AttributeOptions,
    'attribute-validation': AttributeValidation,
    'attribute-settings': AttributeSettings,
    'attribute-layout': AttributeLayout
  },
  mixins: [attribute],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    },
    attributeData: {
      type: Object,
      default: () => ({})
    },
    fileField: {
      type: Boolean,
      default: false
    },
    imageField: {
      type: Boolean,
      default: false
    },
    uploadFieldForm: {
      type: Boolean,
      default: false
    },
    selectField: {
      type: Boolean,
      default: false
    },
    selectFieldForm: {
      type: Boolean,
      default: false
    },
    textField: {
      type: Boolean,
      default: false
    },
    textFieldForm: {
      type: Boolean,
      default: false
    },
    textFieldFormData: {
      type: Boolean,
      default: false
    },
    textareaField: {
      type: Boolean,
      default: false
    },
    textareaFieldForm: {
      type: Boolean,
      default: false
    },
    textareaFieldFormData: {
      type: Boolean,
      default: false
    },
    wysiwygField: {
      type: Boolean,
      default: false
    },
    wysiwygFieldForm: {
      type: Boolean,
      default: false
    },
    wysiwygFieldFormData: {
      type: Boolean,
      default: false
    },
    numberField: {
      type: Boolean,
      default: false
    },
    numberFieldForm: {
      type: Boolean,
      default: false
    },
    toggleFieldForm: {
      type: Boolean,
      default: false
    },
    urlField: {
      type: Boolean,
      default: false
    },
    urlFieldForm: {
      type: Boolean,
      default: false
    },
    radioFieldForm: {
      type: Boolean,
      default: false
    },
    checkboxFieldForm: {
      type: Boolean,
      default: false
    },
    emailField: {
      type: Boolean,
      default: false
    },
    emailFieldForm: {
      type: Boolean,
      default: false
    },
    priceField: {
      type: Boolean,
      default: false
    },
    separatorField: {
      type: Boolean,
      default: false
    },
    numberRangeField: {
      type: Boolean,
      default: false
    },
    datetimeField: {
      type: Boolean,
      default: false
    },
    dateField: {
      type: Boolean,
      default: false
    },
    timeField: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: 1,
      modelName: 'attribute',
      resourceRoute: 'attributes',
      isModal: this.modal
    }
  },
  computed: {
    record() {
      return this.$store.getters['attribute/data']
    },
    model() {
      return this.$store.getters['attribute/data']
    },
    attribute() {
      return this.$store.getters['attribute/attribute']
    }
  },
  watch: {
    record() {
      if (this.record.hasOwnProperty('type')) {
        this.$nextTick(() => {
          this.activeTab = 'Field Data'
        })
        //this.$store.dispatch('attribute/setCurrentTab', 'Laukelio duomenys')
      }
    }
  },
  beforeMount() {
    this.$store.commit('attribute/setModal', this.modal)
    this.$store.commit('attribute/setMode', this.mode)
    this.$store.commit('attribute/setDataType', this.type)
    if (this.mode === 'create') {
      this.$store.commit('attribute/emptyData')
    } else {
      this.$store.commit('attribute/setData', this.attributeData)

      this.$setElement(this.attributeData.identifier)

      let currentFolder = {
        name: 'Folder',
        location: 'public',
        unique_id: this.attributeData.unique_folder_id,
        folder_id: this.attributeData.folder_id
      }

      this.$store.commit('STORE_CURRENT_FOLDER', currentFolder)

      if (
        this.attributeData.type == 'file' ||
        this.attributeData.type == 'image'
      ) {
        this.$store.dispatch('getFolder', [
          { folder: currentFolder, back: false, init: false }
        ])
      }

      //this.$store.dispatch('attribute/setDataToRecord', this.attributeData)
    }
  },
  mounted() {
    if (this.mode === 'create') {
      this.$title = this.$t('attributes.titles.create')
    } else {
      this.$title = this.$t('attributes.titles.edit')
      /*      this.$nextTick(() => {
        this.$store.dispatch('attribute/setDataToRecord')
      })*/
    }
    /*    this.$nextTick(() => {
      this.$store.dispatch('attribute/setCurrentTab', 'Laukelio tipas')
    })*/
    // this.$store.commit('attribute/setCurrentTab', 'Laukelio tipas')
  },
  created() {
    /*    this.$nextTick(() => {
      this.$store.dispatch('attribute/DataToRecord', this.attributeData)
    })*/
    /*    this.$nextTick(() => {
      this.$store.dispatch('attribute/setCurrentTab', 'Laukelio tipas')
    })*/
    /*    this.$nextTick(() => {
      this.$store.dispatch('attribute/setCurrentTab', 'Laukelio tipas')
    })*/
  },
  methods: {
    modalClose() {
      this.$emit('close')
    },
    changeTab() {
      this.currentTab = this.tabToChange
      // console.log(this.currentTab)
    },
    checkTypeForOptionsTab(record_type) {
      if (record_type) {
        if (record_type == 'select') {
          return true
        }

        if (record_type == 'url') {
          return true
        }

        if (record_type == 'faq') {
          return true
        }

        if (record_type == 'radio') {
          return true
        }

        if (record_type == 'checkbox') {
          return true
        }

        return false
      }

      return false
    },
    checkTypeForLayoutTab(record_type) {
      if (record_type) {
        if (record_type == 'text') {
          return true
        }

        if (record_type == 'textarea') {
          return true
        }

        if (record_type == 'wysiwyg') {
          return true
        }

        if (record_type == 'number') {
          return true
        }

        if (record_type == 'email') {
          return true
        }

        if (record_type == 'radio') {
          return true
        }

        if (record_type == 'checkbox') {
          return true
        }

        if (record_type == 'toggle') {
          return true
        }

        if (record_type == 'select') {
          return true
        }

        return false
      }

      return false
    }
  }
}
</script>
