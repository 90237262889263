import Vue from 'vue'

export default {
  props: ['id'],
  data() {
    return {
      validation: {},
      pending: false,
      dataBeforeEdit: {},
      originalData: {},
      autoReset: false,
      pairsObjects: ''
    }
  },
  computed: {
    isNew() {
      return this.id === undefined
    },
    model() {
      return this.$store.getters['attribute/data']
    }
  },
  methods: {
    async fetchData() {
      if (!this.isNew) {
        this.onModelChanged()
      }

      /*      Object.keys(data).forEach(key => {
        if (key === 'data') {
          this.data = data[key]
        }

        if (key === 'meta') {
          this.meta = data[key]
        }

      })*/
    },
    onModelChanged() {},
    async onAttributeSubmit() {
      this.pending = true

      this.$store.commit('attribute/setError', {
        error_type_no: false,
        error_name_no: false
      })

      // Check if attribute type exist
      if (!this.model.hasOwnProperty('type')) {
        this.$store.commit('attribute/setError', { error_type_no: true })
        return
      }

      this.recordType = this.model.type

      // Check if attribute name not empty
      if (
        this.model.hasOwnProperty('type') &&
        this.model.hasOwnProperty('name') &&
        _.isEmpty(this.model.name.en)
      ) {
        this.$store.commit('attribute/setError', { error_name_no: true })
        return
      }

      if (this.mode === 'create' && this.type && this.type === 'list') {
        this.$store.dispatch('attributes/addListRecord', this.model)
        if (this.model.type == 'image' || this.model.type == 'file') {
          //let red = this.$checkForFolder()
          //return
        }
        this.$app.noty['success'](this.$t('attributes.alert.added'))
        this.clearHistory()
      }

      if (this.mode === 'edit' && this.type && this.type === 'list') {
        this.$app.noty['success'](this.$t('attributes.alert.updated'))
        this.clearHistory()
      }

      if (this.mode === 'create' && this.type && this.type === 'form') {
        this.$store.dispatch('attributes/addFormRecord', this.model)
        this.$app.noty['success'](this.$t('attributes.alert.added'))
        this.clearHistory()
      }

      if (this.mode === 'edit' && this.type && this.type === 'form') {
        this.$app.noty['success'](this.$t('attributes.alert.updated'))
        this.clearHistory()
      }

      this.pending = false

      //this.$app.noty['success']('attributes.alert.save')
      this.$emit('close')
    },
    modalSave() {
      this.$app.noty['success']('attributes.alert.added')
      this.$emit('close')
    },
    modalClose() {
      this.$emit('close')
    },
    generateIdentifier: function() {
      return Math.round(+new Date() / 1000) + this.rndNumber(4) // unix timestamp
    },
    rndNumber(len) {
      let number = ''
      let chars = '1234567890'

      for (let i = 0; i < len; i++) {
        number += chars.charAt(Math.floor(Math.random() * chars.length))
      }

      return number
    },
    cancelEdit() {
      this.$store.commit('attribute/setDataRevert', this.dataBeforeEdit)
    },
    clearHistory() {
      this.$store.commit('CLEAR_FILEINFO_DETAIL')
      this.$store.commit('FLUSH_FOLDER_HISTORY')
      this.$store.commit('REMOVE_BROWSER_HISTORY')
    },
    recordData(data, autoReset) {
      this.originalData = data
      this.autoReset = autoReset !== undefined ? autoReset : true
      for (let field in data) {
        this.dataBeforeEdit[field] = data[field]
      }
    }
  },
  mounted() {
    this.dataBeforeEdit = {}
    this.dataBeforeEdit = _.cloneDeep(this.model)
  },
  created() {
    this.$eventHub.$on('attribute-revert', this.cancelEdit)
    this.$eventHub.$on('attribute-modal-close', this.modalClose)
    this.$eventHub.$on('attribute-modal-save', this.modalSave)
    this.fetchData()
  }
}
