export default {
  path: 'message',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    /* Message */
    {
      path: 'send/create',
      name: 'message_create',
      component: require('@email-services/js/components/Modals/Message')
        .default,
      meta: {
        label: ''
      }
    }
    /* Message END */
  ]
}
