<template>
  <div>
    <b-row v-if="selectKind == 'form'">
      <b-col md="12">
        <div class="block-select-title">
          Form fields
        </div>
      </b-col>
    </b-row>
    <!-- Form elements -->
    <b-row>
      <b-col md="2" v-if="textFieldForm">
        <attribute-select
          :name="$t('attributes.types.text-form')"
          icon="text"
          @click.native="setType('text', selectKind)"
          :css-class="
            model.type === 'text' && kind == 'form_element' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="textareaFieldForm">
        <attribute-select
          :name="$t('attributes.types.textarea-form')"
          icon="textarea"
          @click.native="setType('textarea', selectKind)"
          :css-class="
            model.type === 'textarea' && kind == 'form_element' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="wysiwygFieldForm">
        <attribute-select
          :name="$t('attributes.types.wysiwyg-form')"
          icon="wysiwyg"
          @click.native="setType('wysiwyg', selectKind)"
          :css-class="
            model.type === 'wysiwyg' && kind == 'form_element' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="numberFieldForm">
        <attribute-select
          :name="$t('attributes.types.number-form')"
          icon="number"
          @click.native="setType('number', selectKind)"
          :css-class="
            model.type === 'number' && kind == 'form_element' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="selectFieldForm">
        <attribute-select
          :name="$t('attributes.types.select')"
          icon="select"
          @click.native="setType('select', selectKind)"
          :css-class="
            model.type === 'select' && kind == 'form_element' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="uploadFieldForm">
        <attribute-select
          :name="$t('attributes.types.upload')"
          icon="upload"
          @click.native="setType('upload', selectKind)"
          :css-class="
            model.type === 'upload' && kind == 'form_element' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="toggleFieldForm">
        <attribute-select
          :name="$t('attributes.types.toggle')"
          icon="toggle"
          @click.native="setType('toggle', selectKind)"
          :css-class="model.type === 'toggle' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="checkboxFieldForm">
        <attribute-select
          :name="$t('attributes.types.checkbox')"
          icon="checkbox"
          @click.native="setType('checkbox', selectKind)"
          :css-class="model.type === 'checkbox' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="radioFieldForm">
        <attribute-select
          :name="$t('attributes.types.radio')"
          icon="radio"
          @click.native="setType('radio', selectKind)"
          :css-class="model.type === 'radio' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="emailFieldForm">
        <attribute-select
          :name="$t('attributes.types.email')"
          icon="email"
          @click.native="setType('email', selectKind)"
          :css-class="model.type === 'email' ? 'active' : ''"
        ></attribute-select>
      </b-col>
    </b-row>

    <!-- Form data elements title -->

    <b-row v-if="selectKind == 'form'">
      <b-col md="12">
        <div class="block-select-title">Information blocks</div>
      </b-col>
    </b-row>

    <!-- Form data elements -->

    <b-row v-if="selectKind == 'form'">
      <b-col md="2" v-if="textFieldFormData">
        <attribute-select
          :name="$t('attributes.types.text')"
          icon="text"
          @click.native="setType('text', 'form_data')"
          :css-class="
            model.type === 'text' && kind == 'form_data' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="textareaFieldFormData">
        <attribute-select
          :name="$t('attributes.types.textarea')"
          icon="textarea"
          @click.native="setType('textarea', 'form_data')"
          :css-class="
            model.type === 'textarea' && kind == 'form_data' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="wysiwygFieldFormData">
        <attribute-select
          :name="$t('attributes.types.wysiwyg')"
          icon="wysiwyg"
          @click.native="setType('wysiwyg', 'form_data')"
          :css-class="
            model.type === 'wysiwyg' && kind == 'form_data' ? 'active' : ''
          "
        ></attribute-select>
      </b-col>
    </b-row>

    <!-- List elements -->
    <b-row v-if="selectKind == 'list'">
      <b-col md="2" v-if="textField">
        <attribute-select
          :name="$t('attributes.types.text')"
          icon="text"
          @click.native="setType('text', selectKind)"
          :css-class="model.type === 'text' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="textareaField">
        <attribute-select
          :name="$t('attributes.types.textarea')"
          icon="textarea"
          @click.native="setType('textarea', selectKind)"
          :css-class="model.type === 'textarea' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="wysiwygField">
        <attribute-select
          :name="$t('attributes.types.wysiwyg')"
          icon="wysiwyg"
          @click.native="setType('wysiwyg', selectKind)"
          :css-class="model.type === 'wysiwyg' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="numberField">
        <attribute-select
          :name="$t('attributes.types.number')"
          icon="number"
          @click.native="setType('number', selectKind)"
          :css-class="model.type === 'number' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="fileField">
        <attribute-select
          :name="$t('attributes.types.file')"
          icon="file"
          @click.native="setType('file', selectKind)"
          :css-class="model.type === 'file' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="imageField">
        <attribute-select
          :name="$t('attributes.types.image')"
          icon="image"
          @click.native="setType('image', selectKind)"
          :css-class="model.type === 'image' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="urlField">
        <attribute-select
          :name="$t('attributes.types.url')"
          icon="url"
          @click.native="setType('url', selectKind)"
          :css-class="model.type === 'url' ? 'active' : ''"
        ></attribute-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2" v-if="priceField">
        <attribute-select
          :name="$t('attributes.types.price')"
          icon="price"
          @click.native="setType('price', selectKind)"
          :css-class="model.type === 'price' ? 'active' : ''"
        ></attribute-select>
      </b-col>

      <b-col md="2" v-if="separatorField">
        <attribute-select
          :name="$t('attributes.types.separator')"
          icon="separator"
          @click.native="setType('separator', selectKind)"
          :css-class="model.type === 'separator' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="numberRangeField">
        <attribute-select
          :name="$t('attributes.types.number-range')"
          icon="number-range"
          @click.native="setType('number-range', selectKind)"
          :css-class="model.type === 'number-range' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="datetimeField">
        <attribute-select
          :name="$t('attributes.types.datetime')"
          icon="datetime"
          @click.native="setType('datetime', selectKind)"
          :css-class="model.type === 'datetime' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="dateField">
        <attribute-select
          :name="$t('attributes.types.date')"
          icon="date"
          @click.native="setType('date', selectKind)"
          :css-class="model.type === 'date' ? 'active' : ''"
        ></attribute-select>
      </b-col>
      <b-col md="2" v-if="timeField">
        <attribute-select
          :name="$t('attributes.types.time')"
          icon="time"
          @click.native="setType('time', selectKinds)"
          :css-class="model.type === 'time' ? 'active' : ''"
        ></attribute-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AttributeSelect from '@attributes/js/Elements/AttributeSelect'
export default {
  name: 'AttributeTypes',
  components: {
    'attribute-select': AttributeSelect
  },
  props: {
    selectKind: {
      type: String,
      default: ''
    },
    fileField: {
      type: Boolean,
      default: false
    },
    imageField: {
      type: Boolean,
      default: false
    },
    uploadFieldForm: {
      type: Boolean,
      default: false
    },
    selectFieldForm: {
      type: Boolean,
      default: false
    },
    selectField: {
      type: Boolean,
      default: false
    },
    textFieldForm: {
      type: Boolean,
      default: false
    },
    textFieldFormData: {
      type: Boolean,
      default: false
    },
    textField: {
      type: Boolean,
      default: false
    },
    textareaField: {
      type: Boolean,
      default: false
    },
    textareaFieldForm: {
      type: Boolean,
      default: false
    },
    textareaFieldFormData: {
      type: Boolean,
      default: false
    },
    wysiwygField: {
      type: Boolean,
      default: false
    },
    wysiwygFieldForm: {
      type: Boolean,
      default: false
    },
    wysiwygFieldFormData: {
      type: Boolean,
      default: false
    },
    numberField: {
      type: Boolean,
      default: false
    },
    numberFieldForm: {
      type: Boolean,
      default: false
    },
    toggleFieldForm: {
      type: Boolean,
      default: false
    },
    urlField: {
      type: Boolean,
      default: false
    },
    urlFieldForm: {
      type: Boolean,
      default: false
    },
    radioFieldForm: {
      type: Boolean,
      default: false
    },
    checkboxFieldForm: {
      type: Boolean,
      default: false
    },
    emailField: {
      type: Boolean,
      default: false
    },
    emailFieldForm: {
      type: Boolean,
      default: false
    },
    priceField: {
      type: Boolean,
      default: false
    },
    separatorField: {
      type: Boolean,
      default: false
    },
    numberRangeField: {
      type: Boolean,
      default: false
    },
    datetimeField: {
      type: Boolean,
      default: false
    },
    dateField: {
      type: Boolean,
      default: false
    },
    timeField: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelName: 'attribute',
      resourceRoute: 'attributes',
      isModal: this.modal,
      kind: ''
    }
  },
  computed: {
    model() {
      return this.$store.getters['attribute/data']
    }
  },
  methods: {
    setType(type, kind) {
      let identifier = this.generateIdentifier()

      if (kind == 'list') {
        this.kind = 'element'
      } else if (kind == 'form_data') {
        this.kind = kind
      } else {
        this.kind = 'form_element'
      }

      // this.$store.commit('LOADING_STATE', { loading: false, data: [] })

      this.$store.commit('attribute/setError', {
        error_type_no: false,
        error_name_no: false
      })
      this.$store.commit('attribute/emptyData')
      this.$store.commit('attribute/setType', {
        identifier: identifier,
        type: type,
        kind: this.kind
      })
      this.$setElement(identifier)
      //this.$store.commit('attribute/DataToRecord')

      if (type == 'image' || type == 'file') {
        this.$store.commit('CLEAR_DATA')

        let module = this.$store.getters.currentModule,
          record = this.$store.getters.currentRecord,
          recordFolder = this.$store.getters.currentRecordFolder
        this.$store
          .dispatch('proceedFolder', {
            module: module,
            record: record,
            recordFolder: recordFolder,
            element: identifier
          })
          .then(response => {
            this.$store.commit('SET_CURRENT_FOLDER', response.data.parent_id)
          })
          .catch(error => {
            this.$store.commit('PROCESSING_FOLDER', false)
          })
      }

      this.$nextTick(() => {
        //this.$store.dispatch('attribute/setDataToRecord')
        if (this.model.hasOwnProperty('type')) {
          this.$store.dispatch('attribute/setCurrentTab', 'Field Data')
        }
      })
    },

    generateIdentifier: function() {
      return Math.round(+new Date() / 1000) + this.rndNumber(4) // unix timestamp
    },
    rndNumber(len) {
      let number = ''
      let chars = '1234567890'

      for (let i = 0; i < len; i++) {
        number += chars.charAt(Math.floor(Math.random() * chars.length))
      }

      return number
    }
  }
}
</script>
