<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('attributes.titles.create') }}
            </h3>

            <div class="multi-lang multi-lang-open">
              <div
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
              >
                <b-form-group
                  :label="$t('validation.attributes.name') + ' *'"
                  :label-for="`name.${index}`"
                  :label-cols="3"
                  :feedback="feedback('name.' + index)"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      class="input-group-prepend-title prepend-lang"
                      :class="[locale.required ? 'prepend-lang-required' : '']"
                    >
                      <b-input-group-text
                        data-toggle
                        v-b-tooltip.hover
                        :title="$t(locale.name)"
                      >
                        <span>{{ index }}</span>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      :id="`name.${index}`"
                      :name="`name.${index}`"
                      v-model="model.name[`${index}`]"
                      :state="state('name.' + index)"
                    ></b-form-input>
                    <b-input-group-append class="input-group-append-title">
                      <b-input-group-text data-toggle>
                        <i class="fab fa-autoprefixer"></i>
                      </b-input-group-text>
                    </b-input-group-append>
                    <b-form-feedback>
                      {{ feedback('name.' + index) }}
                    </b-form-feedback>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>

            <b-form-group
              :label="$t('attributes.labels.identifier')"
              label-for="identifier"
              :label-cols="3"
              :feedback="feedback('identifier')"
              :description="$t('attributes.notes.identifier')"
            >
              <b-row>
                <b-col md="8">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-code"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="identifier"
                      name="identifier"
                      v-model="model.identifier"
                      :state="state('identifier')"
                    ></b-form-input>
                    <b-form-feedback>
                      {{ feedback('identifier') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              :label="$t('attributes.labels.comment')"
              label-for="comment"
              :label-cols="3"
              :description="$t('attributes.notes.comment')"
              :feedback="feedback('comment')"
            >
              <b-row>
                <b-col md="12">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-title">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-comment-alt"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="comment"
                      name="comment"
                      v-model="model.comment"
                      :state="state('comment')"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Attribute type -->

            <b-form-group
              :label="$t('attributes.labels.type')"
              label-for="type"
              :label-cols="3"
              :feedback="feedback('type')"
            >
              <b-row>
                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.text')"
                    @click.native="setType('text')"
                    :css-class="model.type === 'text' ? 'active' : ''"
                  ></card-select>
                </b-col>
                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.textarea')"
                    @click.native="setType('textarea')"
                    :css-class="model.type === 'textarea' ? 'active' : ''"
                  ></card-select>
                </b-col>
                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.wysiwyg')"
                    @click.native="setType('wysiwyg')"
                    :css-class="model.type === 'wysiwyg' ? 'active' : ''"
                  ></card-select>
                </b-col>
                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.number')"
                    @click.native="setType('number')"
                    :css-class="model.type === 'number' ? 'active' : ''"
                  ></card-select>
                </b-col>
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.number_range')"
                    @click.native="setType('number_range')"
                    :css-class="model.type === 'number_range' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.slide_range')"
                    @click.native="setType('slide_range')"
                    :css-class="model.type === 'slide_range' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.checkbox')"
                    @click.native="setType('checkbox')"
                    :css-class="model.type === 'checkbox' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.toggle')"
                    @click.native="setType('switch')"
                    :css-class="model.type === 'switch' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.date')"
                    @click.native="setType('date')"
                    :css-class="model.type === 'date' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.time')"
                    @click.native="setType('time')"
                    :css-class="model.type === 'time' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.price')"
                    @click.native="setType('price')"
                    :css-class="model.type === 'price' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.select')"
                    @click.native="setType('select')"
                    :css-class="model.type === 'select' ? 'active' : ''"
                  ></card-select>
                </b-col>-->
                <!--                <b-col md="6">
                  <card-select
                    :name="$t('attributes.types.select_multiple')"
                    @click.native="setType('select_multiple')"
                    :css-class="
                      model.type === 'select_multiple' ? 'active' : ''
                    "
                  ></card-select>
                </b-col>-->
              </b-row>
            </b-form-group>

            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="required"
                    v-model="model.required"
                    :description="$t('attributes.labels.required')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button to="/attributes" exact variant="danger" size="sm">
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('attributes.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'

export default {
  title: '',
  name: 'AttributeCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'attribute',
      resourceRoute: 'attributes',
      listPath: '/attributes',
      model: {
        // Data
        name: {},
        identifier: '',
        comment: '',
        required: false,
        type: ''
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('attributes.titles.create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    setType(type) {
      this.model.type = type
    }
  }
}
</script>
