<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          <b-button
            to="/lots"
            exact
            variant="secondary"
            size="sm"
            class="float-none btn-back-small btn-back-small-categories"
          >
            <i class="fas fa-reply"></i>
            {{ $t('buttons.back') }}
          </b-button>
          Participants {{ $route.params.id }}
        </h3>
        <!--        <div class="card-options" v-if="this.$app.user.can('create cities')">
          <b-button @click="modalCreate" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('geo.button.create-city') }}
          </b-button>
        </div>-->
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="lots_participants.search"
        :identifier="$route.params.id"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(name)="row">
            -
          </template>
          <template v-slot:cell(actions)="row">
            -
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  title: '',
  name: 'AuctionParticipants',
  data() {
    return {
      routeData: false,
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      parentElement: [],
      parentElementName: '',
      fields: [
        {
          key: 'name',
          label: 'City name',
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('geo.actions.destroy'),
        enable: this.$t('geo.actions.enable'),
        disable: this.$t('geo.actions.disable')
      }
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    //this.$title = this.$t('geo.titles.city_list')
    // this.$store.commit('categories/setIdentifier', this.data.identifier)
  },
  async beforeMount() {
    //await this.getParentElementName()
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ lot: id })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    async getParentElementName() {
      let { data } = await axios.get(
        this.$app.route('countries.parent.name', {
          country: this.$route.params.id
        })
      )
      this.parentElementName = data
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  margin-right: 10px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: 8px;
}
</style>
