export default (app, i18n, newPostsCount, pendingPostsCount) => {
  return [
    {
      name: i18n.t('labels.titles.dashboard'),
      url: '/dashboard',
      icon: 'fe fe-trending-up',
      access: true,
      classes: 'nav-dashboard',
      children: [
        {
          name: i18n.t('labels.titles.dashboard'),
          url: '/dashboard',
          icon: 'fe fe-trending-up',
          access: true
        }
      ]
    },
    {
      name: i18n.t('auctions.sidebar.title'),
      classes: 'nav-auctions',
      url: '/auctions',
      icon: 'fas fa-gavel',
      access: app.user.can('view auctions'),
      children: [
        {
          name: i18n.t('auctions.sidebar.title'),
          url: '/auctions',
          icon: 'fas fa-gavel',
          access: app.user.can('view auctions')
        },
        {
          name: i18n.t('auctions.sidebar.lots'),
          url: '/lots',
          icon: 'fas fa-gavel',
          access: app.user.can('view auctions')
        }
        /*        {
          title: true,
          name: i18n.t('labels.sidebar.options')
        },
        {
          name: i18n.t('categories.sidebar.title'),
          url: '/categories',
          icon: 'fas fa-project-diagram',
          access: app.user.can('view categories')
        }*/
      ]
    },
    {
      name: i18n.t('companies.sidebar.title'),
      classes: 'nav-companies',
      url: '/companies',
      icon: 'fas fa-building',
      access: app.user.can('view companies'),
      children: [
        {
          name: i18n.t('companies.sidebar.title'),
          url: '/companies',
          icon: 'fas fa-building',
          access: app.user.can('view companies')
        },
        {
          title: true,
          name: i18n.t('labels.sidebar.options')
        },
        {
          name: i18n.t('companies-certificates.sidebar.title'),
          url: '/companies/certificates',
          icon: 'fas fa-certificate',
          access: app.user.can('view companies_certificates')
        }
      ]
    },
    {
      name: i18n.t('pages.sidebar.root'),
      url: '/pages',
      icon: 'fas fa-newspaper',
      classes: 'nav-content',
      access: app.user.can('view pages') || app.user.can('view blocks'),
      children: [
        {
          title: true,
          name: i18n.t('pages.sidebar.section')
        },
        {
          name: i18n.t('pages.sidebar.title'),
          url: '/pages',
          access: app.user.can('view pages'),
          icon: 'fas fa-file-alt'
        },
        {
          name: i18n.t('blocks.sidebar.title'),
          url: '/blocks',
          access: app.user.can('view blocks'),
          icon: 'fas fa-file-alt'
        }
      ]
    },
    {
      name: i18n.t('users-labels.sidebar.root'),
      url: '/users',
      icon: 'fas fa-user-lock',
      classes: 'nav-auth',
      access: app.user.can('view users') || app.user.can('view roles'),
      children: [
        {
          name: i18n.t('users-labels.sidebar.title'),
          url: '/users',
          icon: 'fe fe-users',
          access: app.user.can('view users')
        },
        {
          name: i18n.t('roles-labels.sidebar.title'),
          url: '/roles',
          icon: 'fe fe-shield',
          access: app.user.can('view roles')
        }
      ]
    },

    {
      name: i18n.t('email-services.sidebar.title'),
      url: '/send/list',
      icon: 'fas fa-mail-bulk',
      classes: 'nav-send-services',
      access:
        app.user.can('view send_list') ||
        app.user.can('view email_messages') ||
        app.user.can('view email_services') ||
        app.user.can('view email_templates'),
      children: [
        {
          name: i18n.t('email-services.sidebar.list-title'),
          url: '/send/lists',
          icon: 'fas fa-paper-plane',
          access: app.user.can('view send_lists')
        },
        {
          name: i18n.t('email-services.sidebar.messages-title'),
          url: '/email/messages',
          icon: 'fas fa-envelope',
          access: app.user.can('view email_messages')
        },
        {
          name: i18n.t('email-services.sidebar.notifications-title'),
          url: '/send/notifications',
          icon: 'fas fa-bell',
          access: app.user.can('view email_messages')
        },
        {
          name: i18n.t('email-services.sidebar.templates-title'),
          url: '/email/templates',
          icon: 'fas fa-file-alt',
          access: app.user.can('view email_templates')
        },
        {
          name: i18n.t('email-services.sidebar.service-title'),
          url: '/email/services',
          icon: 'fas fa-project-diagram',
          access: app.user.can('view email_services')
        }
      ]
    },
    {
      name: i18n.t('labels.sidebar.settings.title'),
      url: '/translations',
      icon: 'fas fa-cogs',
      classes: 'nav-settings',
      access: app.user.can('view countries') || app.user.can('view categories'),
      children: [
        {
          name: i18n.t('settings.sidebar.title'),
          url: '/settings',
          access: app.user.can('view settings'),
          icon: 'fas fa-cogs'
        },
        {
          title: true,
          name: i18n.t('labels.sidebar.settings.attributes')
        },
        /*        {
          name: i18n.t('labels.sidebar.settings.attributes'),
          url: '/attributes',
          access: app.user.can('view attributes'),
          icon: 'fe fe-users'
        },*/
        {
          name: i18n.t('labels.sidebar.settings.attribute-sets'),
          url: '/attributes/sets',
          access: app.user.can('view attributes_sets'),
          icon: 'fe fe-users'
        },
        /*        {
          name: i18n.t('labels.sidebar.settings.attribute-groups'),
          url: '/attributes/groups',
          access: app.user.can('view attributes_groups'),
          icon: 'fe fe-users'
        },*/
        {
          title: true,
          name: i18n.t('languages.sidebar.section')
        },
        {
          name: i18n.t('languages.sidebar.section'),
          url: '/languages',
          access: app.user.can('view languages'),
          icon: 'fas fa-globe'
        },
        {
          name: i18n.t('translations.sidebar.section'),
          url: '/translations',
          access: app.user.can('view translations'),
          icon: 'fas fa-language'
        },
        {
          title: true,
          name: i18n.t('geo.sidebar.section')
        },
        {
          name: i18n.t('geo.sidebar.countries'),
          url: '/countries',
          access: app.user.can('view countries'),
          icon: 'fa fa-flag'
        },
        {
          title: true,
          name: i18n.t('units.sidebar.title')
        },
        {
          name: i18n.t('units.sidebar.title'),
          url: '/units',
          access: app.user.can('view units'),
          icon: 'fa fa-weight-hanging'
        }
      ]
    },
    {
      divider: true,
      access: true
    }
  ]
}
