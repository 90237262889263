<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="locale.backend"
                  >
                    <b-form-group
                      :label="$t('validation.attributes.name')"
                      :label-for="`name.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('name.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`name.${index}`"
                          :name="`name.${index}`"
                          v-model="model.name[`${index}`]"
                          :state="state('name.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  :label="$t('units.labels.symbol') + ' *'"
                  label-for="symbol"
                  :label-cols="3"
                  :feedback="feedback('symbol')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-weight-hanging"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="symbol"
                          name="symbol"
                          v-model="model.symbol"
                          :state="state('symbol')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `UnitEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'unit',
      resourceRoute: 'units',
      listPath: '/units',
      model: {
        // Data
        name: {},
        symbol: '',
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('units.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('units.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
