export default {
    "en": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "settings": {
            "actions": {
                "destroy": "Delete selected settings",
                "disable": "Disable selected settings",
                "enable": "Enable selected settings"
            },
            "alerts": {
                "bulk_destroyed": "Selected settings deleted",
                "bulk_disabled": "Selected settings disabled",
                "bulk_enabled": "Selected settings enabled",
                "created": "Settings created",
                "deleted": "Settings deleted",
                "disabled": "Settings disabled",
                "enabled": "Settings enabled",
                "updated": "Settings updated"
            },
            "buttons": {
                "create": "Create Settings",
                "save_preview": "Save and send test email"
            },
            "label": {
                "body": "Email body",
                "confidential_text": "Use confidential text on email template",
                "footer_links": "Use footer links on email template",
                "signature": "Use signature on email template",
                "subject": "Email subject",
                "template": "Template",
                "title": "Email title",
                "use-confidential-text": "Include confidential text in email",
                "use-logo": "Include logo in email"
            },
            "permissions": {
                "category": "Settings",
                "create": {
                    "display_name": "Create settings"
                },
                "delete": {
                    "display_name": "Delete settings"
                },
                "edit": {
                    "display_name": "Edit settings"
                },
                "view": {
                    "display_name": "View settings"
                }
            },
            "section": {
                "buttons": "Buttons",
                "elements-to-use": "Elements to use",
                "email-template": "Email template",
                "icons": "Email icon",
                "settings": "Settings"
            },
            "sections": {
                "buttons": "Buttons",
                "elements-to-use": "Elements to use",
                "email-template": "Email template",
                "icons": "Email icon"
            },
            "sidebar": {
                "title": "Settings"
            },
            "titles": {
                "create": "Settings creation",
                "edit": "Settings edit",
                "list": "Settings",
                "unit": "Settings",
                "units": "Settings"
            }
        },
        "rates": {
            "actions": {
                "destroy": "Delete selected rates",
                "disable": "Disable selected rates",
                "enable": "Enable selected rates"
            },
            "alerts": {
                "bulk_destroyed": "Selected rates deleted",
                "bulk_disabled": "Selected rates disabled",
                "bulk_enabled": "Selected rates enabled",
                "created": "Rate created",
                "deleted": "Rate deleted",
                "disabled": "Rate disabled",
                "enabled": "Rate enabled",
                "updated": "Rate updated"
            },
            "buttons": {
                "create": "Create Rate"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected rates",
                "bulk_disable": "Error on disabling selected rates",
                "bulk_enable": "Error on enabling selected rates"
            },
            "permissions": {
                "category": "Rates",
                "create": {
                    "display_name": "Create rates"
                },
                "delete": {
                    "display_name": "Delete rates"
                },
                "edit": {
                    "display_name": "Edit rates"
                },
                "view": {
                    "display_name": "View rates"
                }
            },
            "sidebar": {
                "title": "Rates"
            },
            "titles": {
                "create": "Rate creation",
                "edit": "Rate edit",
                "list": "Rates",
                "unit": "Rate",
                "units": "Rates"
            }
        },
        "users-buttons": {
            "users": {
                "create": "Create user"
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "lang-required": "The {Field} field is required in {Lang}-name ({LANG}-CODE) language.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "identifier": "Code",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "enabled": "Enabled",
                "disabled": "Disabled",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "url-target": "URL link",
                "url-target-info": "Remember. You can only access the URL link if the item is not disabled.",
                "url-description": "If you do not fill in the URL field, it will be automatically generated from the item name.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "price": "Price",
                "page-title": "Page title"
            }
        },
        "users-labels": {
            "sidebar": {
                "root": "Access",
                "title": "Users"
            },
            "users": {
                "titles": {
                    "main": "User",
                    "index": "User list",
                    "create": "User creation",
                    "edit": "User modification"
                },
                "labels": {
                    "checkbox": "Check",
                    "restrictions": "Restrictions",
                    "by-country": "By country"
                },
                "actions": {
                    "destroy": "Delete selected users",
                    "enable": "Enable selected users",
                    "disable": "Disable selected users"
                }
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "roles-labels": {
            "sidebar": {
                "title": "Roles"
            },
            "roles": {
                "titles": {
                    "main": "Role",
                    "index": "Role list",
                    "create": "Role creation",
                    "edit": "Role modification"
                }
            }
        },
        "companies-certificates": {
            "sidebar": {
                "title": "Company certificates"
            },
            "titles": {
                "unit": "Company certificate",
                "units": "Company certificates",
                "list": "Company certificates",
                "create": "Company certificate creation",
                "edit": "Company certificate edit"
            },
            "tabs": {
                "description": "Description"
            },
            "sections": [],
            "labels": {
                "certificate_description": "Certificate description"
            },
            "buttons": {
                "create": "Create company certificate"
            },
            "alerts": {
                "created": "Company certificate created",
                "updated": "Company certificate updated",
                "deleted": "Company certificate deleted",
                "enabled": "Company certificate enabled",
                "disabled": "Company certificate disabled",
                "bulk_destroyed": "Selected companies certificates deleted",
                "bulk_enabled": "Selected companies certificates enabled",
                "bulk_disabled": "Selected companies certificates disabled"
            },
            "actions": {
                "destroy": "Delete selected companies certificates",
                "enable": "Enable selected companies certificates",
                "disable": "Disable selected companies certificates"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected companies certificates",
                "bulk_enable": "Error on enabling selected companies certificates",
                "bulk_disable": "Error on disabling selected companies certificates"
            },
            "permissions": {
                "category": "Company certificates",
                "view": {
                    "display_name": "View company certificates",
                    "description": ""
                },
                "create": {
                    "display_name": "Create company certificates",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit company certificates",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete company certificates",
                    "description": ""
                }
            }
        },
        "attributes": {
            "actions": {
                "destroy": "Delete selected attributes",
                "disable": "Disable selected attributes",
                "enable": "Enable selected attributes"
            },
            "alert": {
                "added": "Attribute added",
                "empty": "No attributes",
                "name-required": "Field name is required.",
                "no-attribute-type": "Field type is required.",
                "option-removed": "Option removed"
            },
            "alerts": {
                "added": "Attribute added",
                "bulk_destroyed": "Selected attributes deleted",
                "bulk_disabled": "Selected attributes disabled",
                "bulk_enabled": "Selected attributes enabled",
                "created": "Attribute created",
                "created-set": "Attribute set created",
                "deleted": "Attribute deleted",
                "deleted-set": "Attribute set deleted",
                "disabled": "Attribute disabled",
                "enabled": "Attribute enabled",
                "list-form-removed": "Element removed",
                "list-list-removed": "Element removed",
                "save": "Element added",
                "updated": "Attribute updated",
                "updated-set": "Attribute set updated"
            },
            "buttons": {
                "create": "Create Attribute",
                "create-attribute": "Create Attribute",
                "create-group": "Create Group",
                "create-option": "Create Option",
                "create-set": "Create Attribute Set"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected attributes",
                "bulk_disable": "Error on disabling selected attributes",
                "bulk_enable": "Error on enabling selected attributes"
            },
            "field": {
                "value": "Value / Content"
            },
            "fields": {
                "name": "Attribute Name"
            },
            "label": {
                "attribute-type": "Field type",
                "layout-col": "Columns",
                "layout_col": "Columns",
                "layout_col_show_name": "Show name",
                "max-value": "Maximum number of characters",
                "max-value-msg": "Error message when the maximum number of characters is exceeded",
                "min-load-bulk": "Minimum load bulk",
                "min-load-packed": "Minimum load package",
                "min-value": "Minimum number of characters",
                "min-value-msg": "Error message when the minimum number of characters is not reached",
                "settings-allow_zoom": "Enable preview",
                "settings-show_file_extension": "Show file extension",
                "settings-show_file_name": "Show file name",
                "settings-show_file_size": "Show file size",
                "show-less": "Show less",
                "show-more": "Show more",
                "show-record": "Show more",
                "validation-required": "This field is required",
                "validation-required-msg": "Blank field error message"
            },
            "labels": {
                "additional": "Additional settings",
                "classes": "Additional CSS classes",
                "column-size": "Column size",
                "comment": "Comments",
                "data-not-filled": "Not filled",
                "default": "Default Value",
                "groups": "Groups",
                "identifier": "Attribute Code",
                "name": "Attribute Name",
                "note": "Notes",
                "notes": "Notes",
                "option": "Value",
                "placeholder": "Placeholder",
                "placeholders": "Placeholder",
                "required": "Values Required",
                "required-text": "Value Required Text",
                "tooltip": "Tooltip",
                "type": "Field type",
                "unassigned-attributes": "Attributes",
                "validation": "Validation",
                "value": "Value"
            },
            "note": {
                "max-value-msg": "(Optional field), a system generated message will be used if left blank",
                "min-value-msg": "(Optional field), a system generated message will be used if left blank",
                "validation-required-msg": "(Optional field), a system generated message will be used if left blank"
            },
            "notes": {
                "comment": "For internal use only",
                "default": "Default field value",
                "identifier": "If you leave the code field blank it will be uniquely created from the attribute name",
                "notes": "Notes below field",
                "placeholders": "Field placeholder text",
                "required": "The message is displayed when the field value is missing and field marked as required"
            },
            "permissions": {
                "category": "Attributes",
                "create": {
                    "display_name": "Create attributes"
                },
                "delete": {
                    "display_name": "Delete attributes"
                },
                "edit": {
                    "display_name": "Edit attributes"
                },
                "view": {
                    "display_name": "View attributes"
                }
            },
            "section": {
                "min-max": "Minimum maximum values",
                "required": "Necessary values",
                "settings-display": "Display",
                "settings-layout-desktop": "Desktop",
                "settings-layout-mobile": "Mobile",
                "settings-layout-tablet": "Tablet",
                "settings-options": "Settings"
            },
            "sidebar": {
                "title": "Attributes"
            },
            "tabs": {
                "additional": "Additional settings",
                "options": "Options",
                "validation": "Validation"
            },
            "titles": {
                "create": "Attribute creation",
                "create-group": "Group creation",
                "create-option": "New option",
                "create-set": "Attribute set creation",
                "edit": "Attribute edit",
                "edit-set": "Attribute set edit",
                "list": "Attributes",
                "list-sets": "Attribute sets",
                "options": "Options (Values of Your Attribute)",
                "unit": "Attribute",
                "unit-set": "Set of attributes",
                "units": "Attributes"
            },
            "types": {
                "checkbox": "CheckBox",
                "date": "Date",
                "email": "Email address",
                "file": "File",
                "image": "Image",
                "number": "Number",
                "number-form": "Form number field",
                "number_range": "Number Range",
                "price": "Price",
                "radio": "Radio",
                "select": "Selection field",
                "select_multiple": "Multiple select",
                "slide_range": "Slide range",
                "text": "Text field",
                "text-form": "Form text field",
                "textarea": "Textarea",
                "textarea-form": "Form textarea",
                "time": "Time",
                "toggle": "Switch",
                "upload": "File upload",
                "url": "Links",
                "wysiwyg": "Text editor",
                "wysiwyg-form": "Text editor"
            },
            "validation": {
                "max": "Maximum number of characters",
                "min": "Minimum number of characters"
            }
        },
        "templates": {
            "actions": {
                "destroy": "Delete selected templates",
                "disable": "Disable selected templates",
                "enable": "Enable selected templates"
            },
            "alerts": {
                "bulk_destroyed": "Selected templates deleted",
                "bulk_disabled": "Selected templates disabled",
                "bulk_enabled": "Selected templates enabled",
                "created": "Template created",
                "deleted": "Template deleted",
                "disabled": "Template disabled",
                "enabled": "Template enabled",
                "updated": "Template updated"
            },
            "buttons": {
                "create": "Create Template"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected templates",
                "bulk_disable": "Error on disabling selected templates",
                "bulk_enable": "Error on enabling selected templates"
            },
            "permissions": {
                "category": "Templates",
                "create": {
                    "display_name": "Create templates"
                },
                "delete": {
                    "display_name": "Delete templates"
                },
                "edit": {
                    "display_name": "Edit templates"
                },
                "view": {
                    "display_name": "View templates"
                }
            },
            "sidebar": {
                "title": "Templates"
            },
            "titles": {
                "create": "Template creation",
                "edit": "Template edit",
                "list": "Templates",
                "unit": "Template",
                "units": "Templates"
            }
        },
        "roles-buttons": {
            "roles": {
                "create": "Create role"
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "core": {
            "alert": {
                "invalid_action": "Invalid action"
            },
            "button": {
                "back": "Back",
                "cancel": "Cancel",
                "create": "Create",
                "delete": "Delete",
                "edit": "Edit",
                "info": "Information",
                "message": "Message",
                "save": "Save",
                "save-and-close": "Save and close"
            },
            "datatable": {
                "entries_per_page": "entries per page",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries",
                "no_matched_results": "No matched results available",
                "no_results": "No results available",
                "search": "Search",
                "show_per_page": "Show"
            },
            "label": {
                "actions": "Actions",
                "enabled": "Enabled",
                "name": "Name",
                "no": "No",
                "yes": "Yes"
            },
            "section": {
                "main": "Main information"
            },
            "tab": {
                "main": "Main information"
            }
        },
        "geo": {
            "sidebar": {
                "section": "Geo",
                "countries": "Countries",
                "title": "Geo"
            },
            "titles": {
                "unit": "Country",
                "units": "Countries",
                "list": "Countries",
                "create": "Country creation",
                "edit": "Country edit",
                "city-edit": "City edit",
                "country-adjust": "Cities of country adjustment"
            },
            "tabs": [],
            "sections": {
                "adjust": "Disable / Enable cities of country by population"
            },
            "buttons": {
                "create": "Create Country",
                "states": "Subdivisions",
                "cities": "Cities",
                "show-on-map": "Show on map",
                "adjust": "Cities of country adjustment"
            },
            "tooltips": {
                "states_view": "Subdivision list of country",
                "cities_view": "City list of country",
                "states_count": "Total states",
                "cities_count": "Total cities",
                "states_active_count": "Active states",
                "cities_active_count": "Active cities",
                "states_inactive_count": "Inactive states",
                "cities_inactive_count": "Inactive cities"
            },
            "labels": {
                "country_name": "Country",
                "country_code": "Country code",
                "country_flag": "Flag",
                "name": "Name",
                "city-name": "City name",
                "status-manual": "Can't override enabled / disabled by cities of country adjustment",
                "latitude": "Latitude",
                "longitude": "Longitude",
                "continent": "Continent",
                "country_states": "Subdivisions",
                "country_cities": "Cities",
                "name_original": "Native name",
                "code": "Code",
                "capital": "Capital",
                "area": "Area",
                "tld": "Top level domain",
                "adjust-action": "Action",
                "adjust-mode": "Mode",
                "adjust-by-population": "Population",
                "adjust-override": "Override manually enabled / disabled cities of country ( Note: manual enables will be switched off, so use only when necessary !!! )"
            },
            "alerts": {
                "country-created": "Country created",
                "country-updated": "Country updated",
                "country-deleted": "Country deleted",
                "country-enabled": "Country enabled",
                "country-disabled": "Country disabled",
                "city-created": "City created",
                "city-updated": "City updated",
                "city-deleted": "City deleted",
                "city-enabled": "City enabled",
                "city-disabled": "City disabled"
            },
            "actions": {
                "destroy": "Delete selected countries",
                "enable": "Enable selected countries",
                "disable": "Disable selected countries"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected countries",
                "bulk_enable": "Error on enabling selected countries",
                "bulk_disable": "Error on disabling selected countries"
            },
            "permissions": {
                "category": "Countries",
                "view": {
                    "display_name": "View countries",
                    "description": ""
                },
                "create": {
                    "display_name": "Create country",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit country",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete country",
                    "description": ""
                }
            }
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "save-continue": "Save and Continue Edit",
            "save-and-close": "Save and close",
            "save-and-continue": "Save and continue filling data",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply"
        },
        "translations": {
            "action": {
                "destroy": "Delete selected translations",
                "disable": "Disable selected translations",
                "enable": "Enable selected translations"
            },
            "alert": {
                "bulk_destroyed": "Selected translations deleted",
                "bulk_disabled": "Selected translations disabled",
                "bulk_enabled": "Selected translations enabled",
                "created": "Translation created",
                "deleted": "Translation deleted",
                "disabled": "Translation disabled",
                "enabled": "Translation enabled",
                "import": "Translations imported",
                "publish": "Translations published",
                "sync": "Translations synced",
                "updated": "Translation updated"
            },
            "button": {
                "create": "Create Translation",
                "import": "Import Translations",
                "publish": "Publish Translations",
                "sync-center": "Sync Backend Translations",
                "sync-site": "Sync Frontend Translations"
            },
            "exception": {
                "bulk_destroy": "Error on deleting selected translations",
                "bulk_disable": "Error on disabling selected translations",
                "bulk_enable": "Error on enabling selected translations"
            },
            "filter": {
                "area": "Area",
                "groups": "Groups",
                "languages": "Languages",
                "modules": "Modules",
                "status": "Status"
            },
            "label": {
                "area": "Area",
                "backend": "Backend",
                "frontend": "Frontend",
                "group": "Group",
                "key": "Key",
                "locale": "Locale",
                "module": "Module",
                "original": "Origin",
                "original_translation": "Original translation",
                "status": "Status",
                "value": "Translation"
            },
            "message": {
                "import_progress": "Please wait, translations import in progress ...",
                "publish_progress": "Please wait, translations publish in progress ...",
                "sync_progress": "Please wait, translations sync in progress ..."
            },
            "permission": {
                "category": "Translations",
                "create": {
                    "display_name": "Create translation"
                },
                "delete": {
                    "display_name": "Delete translation"
                },
                "edit": {
                    "display_name": "Edit translation"
                },
                "view": {
                    "display_name": "View translations"
                }
            },
            "sidebar": {
                "section": "Translations",
                "title": "Translations"
            },
            "title": {
                "create": "Translation creation",
                "edit": "Translation edit",
                "edit-form": "Origin translation edit",
                "filters": "Filters",
                "import": "Translations import",
                "list": "Translations",
                "publish": "Translations publish",
                "status": "Status",
                "sync": "Translations sync",
                "unit": "Translation",
                "units": "Translations"
            },
            "tooltip": {
                "status_missing": "Status - Missing translation",
                "status_ok": "Status - Translated",
                "status_waiting": "Status - Waiting to be published"
            }
        },
        "categories": {
            "sidebar": {
                "title": "Categories"
            },
            "titles": {
                "unit": "Category",
                "units": "Categories",
                "list": "Categories",
                "create": "Category creation",
                "create-sub": "Sub Category creation",
                "edit": "Category edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Category",
                "create-subcategory": "create SubCategory",
                "subcategories": "SubCategories"
            },
            "alerts": {
                "created": "Category created",
                "updated": "Category updated",
                "deleted": "Category deleted",
                "enabled": "Category enabled",
                "disabled": "Category disabled",
                "bulk_destroyed": "Selected categories deleted",
                "bulk_enabled": "Selected categories enabled",
                "bulk_disabled": "Selected categories disabled"
            },
            "actions": {
                "destroy": "Delete selected categories",
                "enable": "Enable selected categories",
                "disable": "Disable selected categories"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected categories",
                "bulk_enable": "Error on enabling selected categories",
                "bulk_disable": "Error on disabling selected categories"
            },
            "permissions": {
                "category": "Categories",
                "view": {
                    "display_name": "View categories",
                    "description": ""
                },
                "create": {
                    "display_name": "Create categories",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit categories",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete categories",
                    "description": ""
                }
            }
        },
        "auctions": {
            "actions": {
                "destroy": "Delete selected auctions",
                "disable": "Disable selected auctions",
                "enable": "Enable selected auctions"
            },
            "alerts": {
                "bulk_destroyed": "Selected auctions deleted",
                "bulk_disabled": "Selected auctions disabled",
                "bulk_enabled": "Selected auctions enabled",
                "created": "Auction created",
                "deleted": "Auction deleted",
                "disabled": "Auction disabled",
                "enabled": "Auction enabled",
                "updated": "Auction updated"
            },
            "buttons": {
                "create": "Create Auction"
            },
            "dates": {
                "end_bids": "End bids",
                "end_calculation": "End calculation",
                "end_close": "Close deal till",
                "end_contract": "End contract",
                "end_delivery": "End delivery",
                "end_registration": "End registration",
                "end_winner": "End winner",
                "start_bids": "Start bids",
                "start_calculation": "Start calculation",
                "start_contract": "Star contract",
                "start_delivery": "Star delivery",
                "start_registration": "Start registration",
                "start_winner": "Star winner"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected auctions",
                "bulk_disable": "Error on disabling selected auctions",
                "bulk_enable": "Error on enabling selected auctions"
            },
            "label": {
                "all-auctions": "Total number of auctions",
                "auction_set": "Product category",
                "code": "Auction code",
                "dashboard-active": "Verified",
                "dashboard-canceled": "Canceled",
                "dashboard-draft": "Auctions in draft",
                "dashboard-ended": "Ended",
                "dashboard-ongoing": "Ongoing now",
                "dashboard-pending": "Auctions awaiting verification",
                "dashboard-revise": "Waiting for corrections",
                "dashboard-suspended": "Suspended",
                "dashboard-until": "Not started yet",
                "pending-for-verification": "Auctions awaiting verification",
                "status": "Status",
                "total-bids": "Total number of bids"
            },
            "permissions": {
                "category": "Auctions",
                "create": {
                    "display_name": "Create auctions"
                },
                "delete": {
                    "display_name": "Delete auctions"
                },
                "edit": {
                    "display_name": "Edit auctions"
                },
                "view": {
                    "display_name": "View auctions"
                }
            },
            "sidebar": {
                "lots": "Auction Lots",
                "title": "Auctions"
            },
            "stage": {
                "calculation": "Delivery calculation",
                "closing": "Closing deal",
                "completed": "Deal closed",
                "contract": "Contract",
                "delivery": "Delivery",
                "registration": "Registration",
                "verification": "Verification",
                "winner": "Winner"
            },
            "status": {
                "active": "Active",
                "all_records": "All records",
                "canceled": "Canceled",
                "draft": "Draft",
                "reviewed": "Reviewed",
                "revise": "Correction needed",
                "verification": "Verifying"
            },
            "titles": {
                "create": "Auction creation",
                "dashboard-auctions": "Auctions",
                "edit": "Auction edit",
                "list": "Auctions",
                "lots": "Auction Lots",
                "unit": "Auction",
                "units": "Auctions"
            }
        },
        "blacklist-labels": {
            "blacklist": {
                "titles": {
                    "main": "Blacklist",
                    "index": "Blacklist list",
                    "create": "Blacklist creation",
                    "edit": "Blacklist modification"
                },
                "actions": {
                    "destroy": "Delete selected Blacklist",
                    "enable": "Enable selected Blacklist",
                    "disable": "Disable selected Blacklist"
                }
            }
        },
        "blacklist-buttons": {
            "blacklist": {
                "create": "Create Blacklist"
            }
        },
        "roles-permissions": {
            "category": "Roles",
            "view": {
                "display_name": "View roles",
                "description": ""
            },
            "create": {
                "display_name": "Create role",
                "description": ""
            },
            "edit": {
                "display_name": "Edit role",
                "description": ""
            },
            "delete": {
                "display_name": "Delete role",
                "description": ""
            }
        },
        "pages": {
            "sidebar": {
                "root": "Content",
                "section": "Content management",
                "title": "Pages"
            },
            "titles": {
                "unit": "Page",
                "units": "Pages",
                "list": "Pages",
                "create": "Page creation",
                "edit": "Page edit"
            },
            "labels": {
                "header": "Show on header",
                "footer": "Show on footer",
                "content_html": "Content html"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Page"
            },
            "alerts": {
                "created": "Page created",
                "updated": "Page updated",
                "deleted": "Page deleted",
                "enabled": "Page enabled",
                "disabled": "Page disabled",
                "header-disabled": "Page disabled on header",
                "header-enabled": "Page enabled on header",
                "footer-disabled": "Page disabled on footer",
                "footer-enabled": "Page enabled on footer",
                "bulk_destroyed": "Selected pages deleted",
                "bulk_enabled": "Selected pages enabled",
                "bulk_disabled": "Selected pages disabled"
            },
            "actions": {
                "destroy": "Delete selected pages",
                "enable": "Enable selected pages",
                "disable": "Disable selected pages"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected pages",
                "bulk_enable": "Error on enabling selected pages",
                "bulk_disable": "Error on disabling selected pages"
            },
            "permissions": {
                "category": "Pages",
                "view": {
                    "display_name": "View pages",
                    "description": ""
                },
                "create": {
                    "display_name": "Create pages",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit pages",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete pages",
                    "description": ""
                }
            }
        },
        "users-permissions": {
            "category": "Users",
            "category_backend": "Backend",
            "backend": {
                "display_name": "Allow access backend",
                "description": ""
            },
            "view": {
                "display_name": "View users",
                "description": ""
            },
            "create": {
                "display_name": "Create user",
                "description": ""
            },
            "edit": {
                "display_name": "Edit user",
                "description": ""
            },
            "delete": {
                "display_name": "Delete user",
                "description": ""
            }
        },
        "notifications": {
            "actions": {
                "destroy": "Delete selected notifications",
                "disable": "Disable selected notifications",
                "enable": "Enable selected notifications"
            },
            "alerts": {
                "bulk_destroyed": "Selected notifications deleted",
                "bulk_disabled": "Selected notifications disabled",
                "bulk_enabled": "Selected notifications enabled",
                "created": "Notification created",
                "deleted": "Notification deleted",
                "disabled": "Notification disabled",
                "enabled": "Notification enabled",
                "updated": "Notification updated"
            },
            "buttons": {
                "create": "Create Notification"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected notifications",
                "bulk_disable": "Error on disabling selected notifications",
                "bulk_enable": "Error on enabling selected notifications"
            },
            "permissions": {
                "category": "Notifications",
                "create": {
                    "display_name": "Create notifications"
                },
                "delete": {
                    "display_name": "Delete notifications"
                },
                "edit": {
                    "display_name": "Edit notifications"
                },
                "view": {
                    "display_name": "View notifications"
                }
            },
            "sidebar": {
                "title": "Notifications"
            },
            "titles": {
                "create": "Notification creation",
                "edit": "Notification edit",
                "list": "Notifications",
                "unit": "Notification",
                "units": "Notifications"
            }
        },
        "blocks": {
            "sidebar": {
                "title": "Blocks"
            },
            "titles": {
                "unit": "Block",
                "units": "Blocks",
                "list": "Blocks",
                "create": "Block creation",
                "edit": "Block edit"
            },
            "labels": {
                "comment": "Comment",
                "content_html": "Content Html",
                "btn_label": "Button label",
                "btn_url": "Button link",
                "btn_class": "Button extra css class",
                "btn_target_blank": "Open button link in a new window"
            },
            "notes": {
                "comment": "For internal use only"
            },
            "tabs": {
                "buttons": "Buttons"
            },
            "sections": {
                "buttons": "Button",
                "buttons_settings": "Button settings"
            },
            "buttons": {
                "create": "Create Block"
            },
            "alerts": {
                "created": "Block created",
                "updated": "Block updated",
                "deleted": "Block deleted",
                "enabled": "Block enabled",
                "disabled": "Block disabled",
                "bulk_destroyed": "Selected blocks deleted",
                "bulk_enabled": "Selected blocks enabled",
                "bulk_disabled": "Selected blocks disabled"
            },
            "actions": {
                "destroy": "Delete selected blocks",
                "enable": "Enable selected blocks",
                "disable": "Disable selected blocks"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected blocks",
                "bulk_enable": "Error on enabling selected blocks",
                "bulk_disable": "Error on disabling selected blocks"
            },
            "permissions": {
                "category": "Blocks",
                "view": {
                    "display_name": "View blocks",
                    "description": ""
                },
                "create": {
                    "display_name": "Create blocks",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit blocks",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete blocks",
                    "description": ""
                }
            }
        },
        "blacklist-alerts": {
            "blacklist": {
                "created": "Blacklist created",
                "updated": "Blacklist updated",
                "deleted": "Blacklist deleted",
                "bulk_destroyed": "Selected Blacklist deleted",
                "bulk_enabled": "Selected Blacklist enabled",
                "bulk_disabled": "Selected Blacklist disabled"
            }
        },
        "units": {
            "actions": {
                "destroy": "Delete selected units",
                "disable": "Disable selected units",
                "enable": "Enable selected units"
            },
            "alerts": {
                "bulk_destroyed": "Selected units deleted",
                "bulk_disabled": "Selected units disabled",
                "bulk_enabled": "Selected units enabled",
                "created": "Unit created",
                "deleted": "Unit deleted",
                "disabled": "Unit disabled",
                "enabled": "Unit enabled",
                "updated": "Unit updated"
            },
            "buttons": {
                "create": "Create Unit"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected units",
                "bulk_disable": "Error on disabling selected units",
                "bulk_enable": "Error on enabling selected units"
            },
            "labels": {
                "symbol": "Symbol"
            },
            "permissions": {
                "category": "Units",
                "create": {
                    "display_name": "Create units"
                },
                "delete": {
                    "display_name": "Delete units"
                },
                "edit": {
                    "display_name": "Edit units"
                },
                "view": {
                    "display_name": "View units"
                }
            },
            "sidebar": {
                "title": "Units"
            },
            "titles": {
                "create": "Unit creation",
                "edit": "Unit edit",
                "list": "Units",
                "unit": "Unit",
                "units": "Units"
            }
        },
        "dashboard": {
            "label": {
                "active-users": "Active users",
                "all-users": "Total number of users",
                "users-pending-for-verification": "Not verified users"
            },
            "permissions": {
                "category": "Dashboard",
                "create": {
                    "display_name": "Create dashboard"
                },
                "delete": {
                    "display_name": "Delete dashboard"
                },
                "edit": {
                    "display_name": "Edit dashboard"
                },
                "view": {
                    "display_name": "View dashboard"
                }
            },
            "sidebar": {
                "title": "Dashboard"
            },
            "title": {
                "dashboard-users": "Users",
                "unit": "Dashboard"
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "main_content": "Main information",
            "content": "Content",
            "url": "Url",
            "seo": "SEO",
            "meta": {
                "title": "Page title",
                "title-note": "If leave empty, item name will be used as title by default.",
                "description": "Meta description",
                "keywords": "Meta keywords"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "lt": "Lithuanian"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Dashboard",
                "administration": "Administration"
            },
            "sidebar": {
                "options": "Options",
                "content": "Content management",
                "forms": "Form management",
                "access": "Access management",
                "seo": "SEO settings",
                "settings": {
                    "title": "Settings",
                    "attributes": "Attributes",
                    "attribute-groups": "Attribute groups",
                    "attribute-sets": "Attribute sets"
                },
                "jobs": {
                    "title": "Jobs"
                },
                "catalog": {
                    "title": "Catalog",
                    "products": "Products",
                    "categories": "Categories"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                }
            }
        },
        "companies": {
            "sidebar": {
                "title": "Companies"
            },
            "titles": {
                "unit": "Company",
                "units": "Companies",
                "list": "Companies",
                "create": "Company creation",
                "edit": "Company edit"
            },
            "tabs": {
                "certificates": "Certificates"
            },
            "sections": [],
            "fields": {
                "name": "Company name",
                "email": "Email",
                "phone": "Phone number",
                "city": "City",
                "street": "Street",
                "street_no": "House number  / Flat number",
                "zip": "Postal code",
                "company_code": "Company code",
                "vat_no": "Vat number",
                "website": "Website",
                "certificates": "Company certificates"
            },
            "buttons": {
                "create": "Create Company"
            },
            "alerts": {
                "created": "Company created",
                "updated": "Company updated",
                "deleted": "Company deleted",
                "enabled": "Company enabled",
                "disabled": "Company disabled",
                "bulk_destroyed": "Selected companies deleted",
                "bulk_enabled": "Selected companies enabled",
                "bulk_disabled": "Selected companies disabled"
            },
            "actions": {
                "destroy": "Delete selected companies",
                "enable": "Enable selected companies",
                "disable": "Disable selected companies"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected companies",
                "bulk_enable": "Error on enabling selected companies",
                "bulk_disable": "Error on disabling selected companies"
            },
            "permissions": {
                "category": "Companies",
                "view": {
                    "display_name": "View companies",
                    "description": ""
                },
                "create": {
                    "display_name": "Create companies",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit companies",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete companies",
                    "description": ""
                }
            }
        },
        "users-alerts": {
            "users": {
                "created": "User created",
                "updated": "User updated",
                "deleted": "User deleted",
                "bulk_destroyed": "Selected users deleted",
                "bulk_enabled": "Selected users enabled",
                "bulk_disabled": "Selected users disabled"
            }
        },
        "languages": {
            "alert": {
                "bulk_destroyed": "Selected languages deleted",
                "bulk_disabled": "Selected languages disabled",
                "bulk_enabled": "Selected languages enabled",
                "created": "Language created",
                "deleted": "Language deleted",
                "disabled": "Language disabled",
                "enabled": "Language enabled",
                "updated": "Language updated"
            },
            "button": {
                "create": "Create Language"
            },
            "label": {
                "enabled_center": "Enabled on Backend",
                "enabled_site": "Enabled on Frontend",
                "interface_center": "Backend Interface",
                "locale": "Locale",
                "locale_select": "Select locale",
                "name": "Name",
                "native": "Native",
                "position_center": "Position on backend",
                "position_site": "Position on frontend",
                "regional": "Regional",
                "required_center": "Fields in this language are required",
                "show_center": "Show in backend list",
                "show_site": "Show in frontend list"
            },
            "note": {
                "center_sort_order": "The position of the language in the list of languages of the backend",
                "interface_center": "Use as backend interface language. There will also be a possibility to translate the backend in this language in Translations",
                "name": "Language name (mainly used)",
                "native": "Native language name",
                "required_center": "When filling in the fields of the module forms, the fields of this language are mandatory if it is provided in the backend part. For example, the category name must be filled in the default language, if you select this option, the category name must be filled in that language as well.",
                "show_center": "Show language in backend selection list",
                "show_site": "Show language in frontend selection list",
                "site_sort_order": "The position of the language in the list of languages of the frontend"
            },
            "permission": {
                "category": "Languages",
                "create": {
                    "display_name": "Create language"
                },
                "delete": {
                    "display_name": "Delete language"
                },
                "edit": {
                    "display_name": "Edit language"
                },
                "view": {
                    "display_name": "View languages"
                }
            },
            "section": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "sidebar": {
                "section": "Languages",
                "title": "Languages"
            },
            "tab": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "title": {
                "create": "Language creation",
                "edit": "Language edit",
                "list": "Languages",
                "unit": "Language",
                "units": "Languages"
            }
        },
        "mails": [],
        "roles-alerts": {
            "roles": {
                "created": "Role created",
                "updated": "Role updated",
                "deleted": "Role deleted",
                "bulk_destroyed": "Selected roles deleted",
                "bulk_enabled": "Selected roles enabled",
                "bulk_disabled": "Selected roles disabled"
            }
        },
        "email-services": {
            "actions": {
                "destroy": "Delete selected emailServices",
                "disable": "Disable selected emailServices",
                "enable": "Enable selected emailServices"
            },
            "alert": {
                "template-created": "Email template created",
                "template-deleted": "Email template deleted",
                "template-updated": "Email template updated"
            },
            "alerts": {
                "bulk_destroyed": "Selected emailServices deleted",
                "bulk_disabled": "Selected emailServices disabled",
                "bulk_enabled": "Selected emailServices enabled",
                "created": "EmailService created",
                "deleted": "EmailService deleted",
                "disabled": "EmailService disabled",
                "enabled": "EmailService enabled",
                "message-created": "Message send",
                "updated": "EmailService updated"
            },
            "button": {
                "campaign-create": "Create Campaign",
                "campaign-prepare": "Prepare for sending",
                "campaign-send": "Send Campaign",
                "download_invalid": "Download invalid records",
                "list-create": "Create Send List",
                "segment-create": "Create Segment / Group",
                "send-test-email": "Send preview test email",
                "subscriber-create": "Create Subscriber",
                "subscriber-import": "Import Subscribers",
                "subscribers-count": "Subscribers",
                "template-create": "Create Template"
            },
            "buttons": {
                "create": "Create Email Service"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected email services",
                "bulk_disable": "Error on disabling selected email services",
                "bulk_enable": "Error on enabling selected email services"
            },
            "field": {
                "as-email": "Delivery via email",
                "as-emails": "Delivery via email",
                "as-notification": "Delivery via notification",
                "as-notifications": "Delivery via notifications",
                "created_count": "Created",
                "email-service": "Email Service",
                "file": "CSV file",
                "file_name": "File name on system",
                "important": "Important",
                "invalid_count": "Invalid",
                "list-bounce": "Bounce",
                "list-clicked": "Clicked",
                "list-created": "Created",
                "list-opened": "Opened",
                "list-sent": "Sent",
                "list-status": "Status",
                "message-click-count": "Click count",
                "message-content": "Content",
                "message-email": "Email",
                "message-open-count": "Open count",
                "message-send-at": "Send At",
                "message-status": "Status",
                "message-subject": "Subject",
                "name": "Service name",
                "processed_count": "Processed",
                "recipient": "Recipient",
                "recipients": "Recipients",
                "send-list-content": "Content",
                "send-list-from-email": "From email",
                "send-list-from-name": "From name",
                "send-list-is-click-tracking": "Email click tracking",
                "send-list-is-open-tracking": "Email open tracking",
                "send-list-name": "Send list name",
                "send-list-subject": "Subject",
                "service-domain": "Domain",
                "service-encryption": "Encryption",
                "service-host": "Host",
                "service-password": "Password",
                "service-port": "Post",
                "service-secret": "Secret",
                "service-type": "Service type",
                "service-type-api": "API Key",
                "service-username": "Username",
                "service-zone": "Zone",
                "template-code": "Template content",
                "template-id": "Template",
                "template-name": "Template name",
                "updated_count": "Updated"
            },
            "note": {
                "send-list-internal-use": "For internal use only"
            },
            "permissions": {
                "category": "Send Services",
                "create": {
                    "display_name": "Create email_services"
                },
                "delete": {
                    "display_name": "Delete email_services"
                },
                "edit": {
                    "display_name": "Edit email_services"
                },
                "view": {
                    "display_name": "View email_services"
                }
            },
            "sidebar": {
                "list-title": "Send lists",
                "messages-title": "Messages",
                "notifications-title": "Notifications",
                "service-title": "Email Services",
                "templates-title": "Templates",
                "title": "Sending services"
            },
            "tab": {
                "list-buttons": "Buttons",
                "list-content": "Content"
            },
            "title": {
                "list-title": "Send Lists",
                "message-list": "Messages",
                "message-send": "Send message",
                "message-unit": "Message",
                "message-units": "Messages",
                "send-list": "Send List",
                "send-list-create": "Create send list",
                "send-list-edit": "Send list edit",
                "subscriber-units": "Subscribers",
                "template-create": "Template creation",
                "template-edit": "Template  edit",
                "template-list": "Templates",
                "template-unit": "Template",
                "template-units": "Templates"
            },
            "titles": {
                "create": "Email Service creation",
                "edit": "Email Service edit",
                "list": "Email Services",
                "unit": "Email Service",
                "units": "Email Services"
            }
        }
    },
    "lt": {
        "pagination": {
            "previous": "&laquo; Ankstesnis",
            "next": "Sekantis &raquo;"
        },
        "settings": {
            "sidebar": {
                "title": "Settings"
            },
            "titles": {
                "unit": "Settings",
                "units": "Settings",
                "list": "Settings",
                "create": "Settings creation",
                "edit": "Settings edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Settings"
            },
            "alerts": {
                "created": "Settings created",
                "updated": "Settings updated",
                "deleted": "Settings deleted",
                "enabled": "Settings enabled",
                "disabled": "Settings disabled",
                "bulk_destroyed": "Selected settings deleted",
                "bulk_enabled": "Selected settings enabled",
                "bulk_disabled": "Selected settings disabled"
            },
            "actions": {
                "destroy": "Delete selected settings",
                "enable": "Enable selected settings",
                "disable": "Disable selected settings"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected settings",
                "bulk_enable": "Error on enabling selected settings",
                "bulk_disable": "Error on disabling selected settings"
            },
            "permissions": {
                "category": "Settings",
                "view": {
                    "display_name": "View settings",
                    "description": ""
                },
                "create": {
                    "display_name": "Create settings",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit settings",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete settings",
                    "description": ""
                }
            }
        },
        "users-buttons": {
            "users": {
                "create": "Sukurti vartotoją"
            }
        },
        "validation": {
            "accepted": "Laukas {attribute} turi būti priimtas.",
            "active_url": "Laukas {attribute} nėra galiojantis internetinis adresas.",
            "after": "Lauko {attribute} reikšmė turi būti po {date} datos.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "Laukas {attribute} gali turėti tik raides.",
            "alpha_dash": "Laukas {attribute} gali turėti tik raides, skaičius ir brūkšnelius.",
            "alpha_num": "Laukas {attribute} gali turėti tik raides ir skaičius.",
            "array": "Laukas {attribute} turi būti masyvas.",
            "before": "Laukas {attribute} turi būti data prieš {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "Lauko {attribute} reikšmė turi būti tarp {min} ir {max}.",
                "file": "Failo dydis lauke {attribute} turi būti tarp {min} ir {max} kilobaitų.",
                "string": "Simbolių skaičius lauke {attribute} turi būti tarp {min} ir {max}.",
                "array": "Elementų skaičius lauke {attribute} turi turėti nuo {min} iki {max}."
            },
            "boolean": "Lauko reikšmė {attribute} turi būti 'taip' arba 'ne'.",
            "confirmed": "Lauko {attribute} patvirtinimas nesutampa.",
            "date": "Lauko {attribute} reikšmė nėra galiojanti data.",
            "date_format": "Lauko {attribute} reikšmė neatitinka formato {format}.",
            "different": "Laukų {attribute} ir {other} reikšmės turi skirtis.",
            "digits": "Laukas {attribute} turi būti sudarytas iš {digits} skaitmenų.",
            "digits_between": "Laukas {attribute} tuti turėti nuo {min} iki {max} skaitmenų.",
            "dimensions": "Lauke {attribute} įkeltas paveiksliukas neatitinka išmatavimų reikalavimo.",
            "distinct": "Laukas {attribute} pasikartoja.",
            "email": "Lauko {attribute} reikšmė turi būti galiojantis el. pašto adresas.",
            "file": "The {attribute} must be a file.",
            "filled": "Laukas {attribute} turi būti užpildytas.",
            "exists": "Pasirinkta negaliojanti {attribute} reikšmė.",
            "gt": {
                "numeric": "The {attribute} must be greater than {value}.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "string": "The {attribute} must be greater than {value} characters.",
                "array": "The {attribute} must have more than {value} items."
            },
            "gte": {
                "numeric": "The {attribute} must be greater than or equal {value}.",
                "file": "The {attribute} must be greater than or equal {value} kilobytes.",
                "string": "The {attribute} must be greater than or equal {value} characters.",
                "array": "The {attribute} must have {value} items or more."
            },
            "image": "Lauko {attribute} reikšmė turi būti paveikslėlis.",
            "in": "Pasirinkta negaliojanti {attribute} reikšmė.",
            "in_array": "Laukas {attribute} neegzistuoja {other} lauke.",
            "integer": "Lauko {attribute} reikšmė turi būti sveikasis skaičius.",
            "ip": "Lauko {attribute} reikšmė turi būti galiojantis IP adresas.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "Lauko {attribute} reikšmė turi būti JSON tekstas.",
            "lt": {
                "numeric": "The {attribute} must be less than {value}.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "string": "The {attribute} must be less than {value} characters.",
                "array": "The {attribute} must have less than {value} items."
            },
            "lte": {
                "numeric": "The {attribute} must be less than or equal {value}.",
                "file": "The {attribute} must be less than or equal {value} kilobytes.",
                "string": "The {attribute} must be less than or equal {value} characters.",
                "array": "The {attribute} must not have more than {value} items."
            },
            "max": {
                "numeric": "Lauko {attribute} reikšmė negali būti didesnė nei {max}.",
                "file": "Failo dydis lauke {attribute} reikšmė negali būti didesnė nei {max} kilobaitų.",
                "string": "Simbolių kiekis lauke {attribute} reikšmė negali būti didesnė nei {max} simbolių.",
                "array": "Elementų kiekis lauke {attribute} negali turėti daugiau nei {max} elementų."
            },
            "mimes": "Lauko reikšmė {attribute} turi būti failas vieno iš sekančių tipų: {values}.",
            "mimetypes": "Lauko reikšmė {attribute} turi būti failas vieno iš sekančių tipų: {values}.",
            "min": {
                "numeric": "Lauko {attribute} reikšmė turi būti ne mažesnė nei {min}.",
                "file": "Failo dydis lauke {attribute} turi būti ne mažesnis nei {min} kilobaitų.",
                "string": "Simbolių kiekis lauke {attribute} turi būti ne mažiau nei {min}.",
                "array": "Elementų kiekis lauke {attribute} turi būti ne mažiau nei {min}."
            },
            "not_in": "Pasirinkta negaliojanti reikšmė {attribute}.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "Lauko {attribute} reikšmė turi būti skaičius.",
            "present": "Laukas {attribute} turi egzistuoti.",
            "regex": "Negaliojantis lauko {attribute} formatas.",
            "required": "Privaloma užpildyti lauką {attribute}.",
            "required_if": "Privaloma užpildyti lauką {attribute} kai {other} yra {value}.",
            "required_unless": "Laukas {attribute} yra privalomas, nebent {other} yra tarp {values} reikšmių.",
            "required_with": "Privaloma užpildyti lauką {attribute} kai pateikta {values}.",
            "required_with_all": "Privaloma užpildyti lauką {attribute} kai pateikta {values}.",
            "required_without": "Privaloma užpildyti lauką {attribute} kai nepateikta {values}.",
            "required_without_all": "Privaloma užpildyti lauką {attribute} kai nepateikta nei viena iš reikšmių {values}.",
            "same": "Laukai {attribute} ir {other} turi sutapti.",
            "size": {
                "numeric": "Lauko {attribute} reikšmė turi būti {size}.",
                "file": "Failo dydis lauke {attribute} turi būti {size} kilobaitai.",
                "string": "Simbolių skaičius lauke {attribute} turi būti {size}.",
                "array": "Elementų kiekis lauke {attribute} turi būti {size}."
            },
            "string": "Laukas {attribute} turi būti tekstinis.",
            "timezone": "Lauko {attribute} reikšmė turi būti galiojanti laiko zona.",
            "unique": "Tokia {attribute} reikšmė jau pasirinkta.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "Negaliojantis lauko {attribute} formatas.",
            "lang-required": "Lauką {Field} privaloma užpildyti {Lang}-name ({LANG}-CODE).",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Pavadinimas",
                "identifier": "Kodas",
                "display_name": "Rodomas pavadinimas",
                "username": "Pseudo",
                "email": "El. paštas",
                "first_name": "Vardas",
                "last_name": "Pavardė",
                "password": "Slaptažodis",
                "password_confirmation": "Pakartoti slaptažodį",
                "old_password": "Senas slaptažodis",
                "new_password": "Naujas slaptažodis",
                "new_password_confirmation": "Patvirtinti naują slaptažodį",
                "postal_code": "Pašto kodas",
                "city": "Miestas",
                "country": "Šalis",
                "address": "Adresas",
                "phone": "Telefonas",
                "mobile": "Mobilus",
                "age": "Amžius",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Diena",
                "month": "Mėnuo",
                "year": "Metai",
                "hour": "Valanda",
                "minute": "Minutė",
                "second": "Sekundė",
                "title": "Title",
                "content": "Turinys",
                "description": "Apibudinimas",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Data",
                "time": "Laikas",
                "available": "Available",
                "size": "Dydis",
                "roles": "Rolės",
                "permissions": "Leidimai",
                "active": "Aktyvus",
                "enabled": "Aktyvus",
                "disabled": "Neaktyvus",
                "message": "Žinutė",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL adresas",
                "url-target": "URL nuoroda",
                "url-target-info": "URL nuoroda galite pasiekti tik tuo atveju jei elementas nėra išjungtas.",
                "url-description": "Jei URL adreso lauko neužpildysite jis bus automatiškai sugeneruotas iš pavadinimo.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Pozicija",
                "position": "Pozicija",
                "image": "Image",
                "status": "Statusas",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Paskelbta",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "working_hours": "Darbo laikas",
                "zip": "Pašto kodas",
                "coordinates": "Koordinatės",
                "price": "Kaina",
                "page-title": "Puslapio pavadinimas"
            }
        },
        "users-labels": {
            "sidebar": {
                "root": "Prieiga",
                "title": "Vartotojai"
            },
            "users": {
                "sidebar": {
                    "label": "Prieiga"
                },
                "titles": {
                    "label": "Vartotojai",
                    "main": "Vartotojai",
                    "index": "Vartotojų sąrašas",
                    "create": "Naujo vartotojo kūrimas",
                    "edit": "Vartotojo koregavimas"
                },
                "labels": {
                    "checkbox": "Žymėti",
                    "restrictions": "Apribojimai"
                },
                "actions": {
                    "destroy": "Ištrinti pasirinktus vartotojus",
                    "enable": "Aktyvuoti pasirinktus vartotojus",
                    "disable": "Deaktyvuoti pasirinktus vartotojus"
                }
            }
        },
        "auth": {
            "failed": "Neteisingti prisijungimo duomenys",
            "throttle": "Perdaug bandymų prisijungti. Bandykite po {seconds} sec.",
            "socialite": {
                "unacceptable": "{provider} neleidžia prisijungti."
            }
        },
        "roles-labels": {
            "sidebar": {
                "title": "Rolės"
            },
            "roles": {
                "titles": {
                    "main": "Rolės",
                    "index": "Rolių sąrašas",
                    "create": "Naujos rolės kūrimas",
                    "edit": "Rolės koregavimas"
                }
            }
        },
        "roles-buttons": {
            "roles": {
                "create": "Sukurti rolę"
            }
        },
        "passwords": {
            "password": "Slaptažodis turi būti bent šešių simbolių ir sutapti su patvirtinimu.",
            "reset": "Nustatytas naujas slaptažodis!",
            "sent": "Naujo slaptažodžio nustatymo nuoroda išsiųsta",
            "token": "Šis slaptažodžio raktas yra neteisingas.",
            "user": "Vartotojas su tokiu el. paštu nerastas."
        },
        "core": {
            "alert": {
                "invalid_action": "Invalid action"
            },
            "button": {
                "back": "Back",
                "cancel": "Cancel",
                "create": "Create",
                "edit": "Edit",
                "save-and-close": "Save and close"
            },
            "datatable": {
                "entries_per_page": "entries per page",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries",
                "no_matched_results": "No matched results available",
                "no_results": "No results available",
                "search": "Search",
                "show_per_page": "Show"
            },
            "label": {
                "actions": "Actions",
                "name": "Name",
                "no": "No",
                "yes": "Yes"
            },
            "section": {
                "main": "Main information"
            },
            "tab": {
                "main": "Main information"
            }
        },
        "buttons": {
            "cancel": "Atšaukti",
            "save": "Saugoti",
            "save-continue": "Saugoti ir tęsti redagavimą",
            "save-and-close": "Saugoti ir uždaryti",
            "save-and-continue": "Saugoti ir tęsti duomenų pildymą",
            "close": "Uždaryti",
            "create": "Sukurti",
            "delete": "Trinti",
            "confirm": "Patvirtinti",
            "show": "Rodyti",
            "edit": "Koreguoti",
            "update": "Atnaujinti",
            "view": "Peržiūra",
            "preview": "Peržiūrėti",
            "back": "Atgal",
            "send": "Siųsti",
            "login-as": "Prisijungta {name}",
            "apply": "Taikyti"
        },
        "translations": {
            "action": {
                "destroy": "Delete selected translations",
                "disable": "Disable selected translations",
                "enable": "Enable selected translations"
            },
            "alert": {
                "bulk_destroyed": "Selected translations deleted",
                "bulk_disabled": "Selected translations disabled",
                "bulk_enabled": "Selected translations enabled",
                "created": "Translation created",
                "deleted": "Translation deleted",
                "disabled": "Translation disabled",
                "enabled": "Translation enabled",
                "import": "Translations imported",
                "publish": "Translations published",
                "sync": "Translations synced",
                "updated": "Translation updated"
            },
            "button": {
                "create": "Create Translation",
                "import": "Import Translations",
                "publish": "Publish Translations",
                "sync-center": "Sync Backend Translations",
                "sync-site": "Sync Frontend Translations"
            },
            "exception": {
                "bulk_destroy": "Error on deleting selected translations",
                "bulk_disable": "Error on disabling selected translations",
                "bulk_enable": "Error on enabling selected translations"
            },
            "filter": {
                "area": "Area",
                "groups": "Groups",
                "languages": "Languages",
                "modules": "Modules",
                "status": "Status"
            },
            "label": {
                "area": "Area",
                "backend": "Backend",
                "frontend": "Frontend",
                "group": "Group",
                "key": "Key",
                "locale": "Locale",
                "module": "Module",
                "original": "Origin",
                "original_translation": "Original translation",
                "status": "asafaf",
                "value": "Translation"
            },
            "message": {
                "import_progress": "Please wait, translations import in progress ...",
                "publish_progress": "Please wait, translations publish in progress ...",
                "sync_progress": "Please wait, translations sync in progress ..."
            },
            "permission": {
                "category": "Translations",
                "create": {
                    "display_name": "Create translation"
                },
                "delete": {
                    "display_name": "Delete translation"
                },
                "edit": {
                    "display_name": "Edit translation"
                },
                "view": {
                    "display_name": "View translations"
                }
            },
            "sidebar": {
                "section": "Translations",
                "title": "Translations"
            },
            "title": {
                "create": "Translation creation",
                "edit": "Translation edit",
                "edit-form": "Origin translation edit",
                "filters": "Filters3",
                "import": "Translations importing",
                "list": "Translations",
                "publish": "Translations publishing",
                "status": "sdfsfasdfasdf asfasdff s fsf",
                "sync": "Translations sync",
                "unit": "Translation",
                "units": "Translations"
            },
            "tooltip": {
                "status_missing": "Status - Missing translation",
                "status_ok": "Status - Translated",
                "status_waiting": "Status - Waiting to be published"
            }
        },
        "categories": {
            "sidebar": {
                "title": "Kategorijos"
            },
            "titles": {
                "unit": "Kategorija",
                "units": "Kategorijos",
                "list": "Kategorijos",
                "create": "Kategorijos kūrimas",
                "create-sub": "Sub Kategorijos kūrimas",
                "edit": "Kategorijos koregavimas"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Sukurti kategoriją",
                "create-subcategory": "Sukurti sub kategoriją",
                "subcategories": "Sub Kategorijos"
            },
            "alerts": {
                "created": "Kategorija sukurta",
                "updated": "Kategorija atnaujinta",
                "deleted": "Kategorija ištrinta",
                "enabled": "Kategorija įjungta",
                "disabled": "Kategorija išjungta",
                "bulk_destroyed": "Selected categories deleted",
                "bulk_enabled": "Selected categories enabled",
                "bulk_disabled": "Selected categories disabled"
            },
            "actions": {
                "destroy": "Delete selected categories",
                "enable": "Enable selected categories",
                "disable": "Disable selected categories"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected categories",
                "bulk_enable": "Error on enabling selected categories",
                "bulk_disable": "Error on disabling selected categories"
            },
            "permissions": {
                "category": "Kategorijos",
                "view": {
                    "display_name": "Kategorijų peržiūra",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti naują kategoriją",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti kategoriją",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti kategoriją",
                    "description": ""
                }
            }
        },
        "blacklist-labels": {
            "blacklist": {
                "titles": {
                    "main": "Blacklist",
                    "index": "Blacklist list",
                    "create": "Blacklist creation",
                    "edit": "Blacklist modification"
                },
                "actions": {
                    "destroy": "Delete selected Blacklist",
                    "enable": "Enable selected Blacklist",
                    "disable": "Disable selected Blacklist"
                }
            }
        },
        "blacklist-buttons": {
            "blacklist": {
                "create": "Create Blacklist"
            }
        },
        "roles-permissions": {
            "category": "Rolės",
            "view": {
                "display_name": "Rolių peržiūra",
                "description": ""
            },
            "create": {
                "display_name": "Sukurti naują rolę",
                "description": ""
            },
            "edit": {
                "display_name": "Koreguoti rolę",
                "description": ""
            },
            "delete": {
                "display_name": "Ištrinti rolę",
                "description": ""
            }
        },
        "pages": {
            "sidebar": {
                "root": "Turinys",
                "section": "Turinio valdymas",
                "title": "Puslapiai"
            },
            "titles": {
                "unit": "Puslapis",
                "units": "Puslapiai",
                "list": "Puslapiai",
                "create": "Puslapio kūrimas",
                "edit": "Puslapio koregavimas"
            },
            "labels": {
                "header": "Rodyti viršuje (header)",
                "footer": "Rodyti apačioje (footer)",
                "content_html": "Turinys (Html)"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Sukurti puslapį"
            },
            "alerts": {
                "created": "Puslapis sukurtas",
                "updated": "Puslapis atnaujintas",
                "deleted": "Puslapis ištrintas",
                "enabled": "Puslapis įjungtas",
                "disabled": "Puslapis išjungtas",
                "header-disabled": "Puslapis išjungtas viršuje (header)",
                "header-enabled": "Puslapis įjungtas viršuje (header)",
                "footer-disabled": "Puslapis išjungtas apačioje (footer)",
                "footer-enabled": "Puslapis įjungtas apačioje (footer)",
                "bulk_destroyed": "Selected pages deleted",
                "bulk_enabled": "Selected pages enabled",
                "bulk_disabled": "Selected pages disabled"
            },
            "actions": {
                "destroy": "Delete selected pages",
                "enable": "Enable selected pages",
                "disable": "Disable selected pages"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected pages",
                "bulk_enable": "Error on enabling selected pages",
                "bulk_disable": "Error on disabling selected pages"
            },
            "permissions": {
                "category": "Turinio puslapiai",
                "view": {
                    "display_name": "Peržiūrėti puslapius",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti naują puslapį",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti puslapį",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti puslapį",
                    "description": ""
                }
            }
        },
        "geo-buttons": {
            "geo": {
                "create": "Create Geo"
            }
        },
        "geo-labels": {
            "geo": {
                "titles": {
                    "main": "Geo",
                    "index": "Geo list",
                    "create": "Geo creation",
                    "edit": "Geo modification"
                },
                "actions": {
                    "destroy": "Delete selected Geo",
                    "enable": "Enable selected Geo",
                    "disable": "Disable selected Geo"
                }
            }
        },
        "users-permissions": {
            "category": "Vartotojai",
            "category_backend": "Backend",
            "backend": {
                "display_name": "Leisti prisijungti prie backend",
                "description": ""
            },
            "view": {
                "display_name": "Vartotojų peržiūra",
                "description": ""
            },
            "create": {
                "display_name": "Sukurti naują vartotoją",
                "description": ""
            },
            "edit": {
                "display_name": "Koreguoti vartotoją",
                "description": ""
            },
            "delete": {
                "display_name": "Ištrinti vartotoją",
                "description": ""
            }
        },
        "blocks": {
            "sidebar": {
                "title": "Blokai"
            },
            "titles": {
                "unit": "Blokas",
                "units": "Blokai",
                "list": "Blokai",
                "create": "Bloko kūrimas",
                "edit": "Bloko koregavimas"
            },
            "labels": {
                "comment": "Komentaras",
                "content_html": "Turinys (Html formatu)",
                "btn_label": "Mygtuko pavadinimas",
                "btn_url": "Mykgtuko nuoroda",
                "btn_class": "Mygtuko papildoma css klasė",
                "btn_target_blank": "Atidaryti mygtuko nuorodą naujame lange"
            },
            "notes": {
                "comment": "Vidiniam naudojimui"
            },
            "tabs": {
                "buttons": "Mygtukai"
            },
            "sections": {
                "buttons": "Mygtukas",
                "buttons_settings": "Mygtuko nustatymai"
            },
            "buttons": {
                "create": "Sukurti bloką"
            },
            "alerts": {
                "created": "Blokas sukurtas",
                "updated": "Blokas atnaujintas",
                "deleted": "Blokas ištrintas",
                "enabled": "Blokas įjungtas",
                "disabled": "Blokas išjungtas",
                "bulk_destroyed": "Selected blocks deleted",
                "bulk_enabled": "Selected blocks enabled",
                "bulk_disabled": "Selected blocks disabled"
            },
            "actions": {
                "destroy": "Delete selected blocks",
                "enable": "Enable selected blocks",
                "disable": "Disable selected blocks"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected blocks",
                "bulk_enable": "Error on enabling selected blocks",
                "bulk_disable": "Error on disabling selected blocks"
            },
            "permissions": {
                "category": "Turinio blokai",
                "view": {
                    "display_name": "Peržiūrėti blokus",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti naują bloką",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti bloką",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti bloką",
                    "description": ""
                }
            }
        },
        "blacklist-alerts": {
            "blacklist": {
                "created": "Blacklist created",
                "updated": "Blacklist updated",
                "deleted": "Blacklist deleted",
                "bulk_destroyed": "Selected Blacklist deleted",
                "bulk_enabled": "Selected Blacklist enabled",
                "bulk_disabled": "Selected Blacklist disabled"
            }
        },
        "labels": {
            "language": "Kalba",
            "actions": "Veiksmai",
            "general": "Pagrindinis",
            "no_results": "Rezultatų nėra",
            "search": "Ieškoti",
            "validate": "Vykdyti",
            "choose_file": "Pasirinkti failą",
            "no_file_chosen": "Failas neparinktas",
            "are_you_sure": "Ar tikrai ?",
            "delete_image": "Trinti paveiksliuką",
            "yes": "Taip",
            "no": "Ne",
            "add_new": "Pridėti",
            "export": "Eksportuoti",
            "more_info": "Daugiau",
            "author": "Autorius",
            "author_id": "Autoriaus ID",
            "last_access_at": "Paskutinį kartą prisijungta",
            "published_at": "Paskelbta",
            "created_at": "Sukurta",
            "updated_at": "Atnaujinta",
            "deleted_at": "Ištrinta",
            "no_value": "Nėra reikšmės",
            "upload_image": "Įkelti paveiksliuką",
            "anonymous": "Anonimas",
            "all_rights_reserved": "Visos teisės saugomos.",
            "with": "su",
            "by": "by",
            "main_content": "Pagrindinė informacija",
            "content": "Turinys",
            "url": "Nuorodos (URL)",
            "seo": "SEO",
            "meta": {
                "title": "Puslapio pavadinimas",
                "title-note": "Jei paliksite tuščią, puslapio pavadinimui bus naudojamas elemento pavadinimas",
                "description": "Meta apibūdinimas",
                "keywords": "Meta raktažodžiai"
            },
            "datatables": {
                "no_results": "Nieko nerasta",
                "no_matched_results": "Pagal nurodytus kriterijus rezultatų nerasta",
                "show_per_page": "Rodyti",
                "entries_per_page": "įrašai per puslapį",
                "search": "Ieškoti",
                "infos": "Rodoma {offset_start} iki {offset_end} iš {total} įrašų"
            },
            "morphs": {
                "user": "Vartotojas, identifikatorius {id}"
            },
            "auth": {
                "disabled": "Jūsų paskyra buvo užblokuota."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "Anglų k.",
                "lt": "Lietuvių k."
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Prisiminti",
                "login": "Prisijungti",
                "logout": "Atsijungti",
                "password_forgot": "Pamiršote slaptažodį ?",
                "send_password_link": "Siųsti slaptažodžio keitimo nuorodą",
                "password_reset": "Slaptažodžio keitimas",
                "register": "Registruoti",
                "space": "Mano erdvė",
                "settings": "Nustatymai",
                "account": "Mano paskyra",
                "profile": "Mano profilis",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Koreguoti mano profilį",
                "change_password": "Keisti mano slaptažodį",
                "delete": "Trinti mano paskyrą",
                "administration": "Adminisravimas",
                "member_since": "Narys nuo {date}",
                "profile_updated": "Profilis sėkmingai atnaujintas.",
                "password_updated": "Slaptaždosis sėkmingai atnaujintas.",
                "super_admin": "Super administratorius",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "Mano erdvė",
                    "account": "Mano paskyra"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Pagrindinis",
                "administration": "Administravimas"
            },
            "sidebar": {
                "options": "Variantai / Pasirinkimai",
                "forms": "Form management",
                "access": "Prieigos valdymas",
                "seo": "SEO settings",
                "settings": {
                    "title": "Nustatymai",
                    "attributes": "Atributai",
                    "attribute-groups": "Atributų grupės",
                    "attribute-sets": "Atributų rinkiniai"
                },
                "jobs": {
                    "title": "Darbai"
                },
                "catalog": {
                    "title": "Katalogas",
                    "products": "Produktai",
                    "categories": "Kategorijos",
                    "colors": "Spalvos",
                    "occasions": "Progos",
                    "dedications": "Skirta"
                },
                "content": {
                    "title": "Turinys",
                    "content": "Turinys",
                    "pages": "Puslapiai"
                },
                "sales": {
                    "title": "Pardavimai",
                    "orders": "Užsakymai"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Aktyvūs vartotojai",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "user": "Naujas vartotojas",
                    "role": "Nauja rolė"
                }
            }
        },
        "companies": {
            "sidebar": {
                "title": "Įmonės"
            },
            "titles": {
                "unit": "Įmonė",
                "units": "Įmonės",
                "list": "Įmonės",
                "create": "Company creation",
                "edit": "Company edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Company"
            },
            "alerts": {
                "created": "Company created",
                "updated": "Company updated",
                "deleted": "Company deleted",
                "enabled": "Company enabled",
                "disabled": "Company disabled",
                "bulk_destroyed": "Selected companies deleted",
                "bulk_enabled": "Selected companies enabled",
                "bulk_disabled": "Selected companies disabled"
            },
            "actions": {
                "destroy": "Delete selected companies",
                "enable": "Enable selected companies",
                "disable": "Disable selected companies"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected companies",
                "bulk_enable": "Error on enabling selected companies",
                "bulk_disable": "Error on disabling selected companies"
            },
            "permissions": {
                "category": "Companies",
                "view": {
                    "display_name": "View companies",
                    "description": ""
                },
                "create": {
                    "display_name": "Create companies",
                    "description": ""
                },
                "edit": {
                    "display_name": "Edit companies",
                    "description": ""
                },
                "delete": {
                    "display_name": "Delete companies",
                    "description": ""
                }
            }
        },
        "users-alerts": {
            "users": {
                "created": "Vartotojas sukurtas",
                "updated": "Vartotojas atnaujintas",
                "deleted": "Vartotojas ištrintas",
                "bulk_destroyed": "Pasirinkti vartotojai ištrinti",
                "bulk_enabled": "Pasirinkti vartotojai aktyvuoti",
                "bulk_disabled": "Pasirinkti vartotojai deaktyvuoti"
            }
        },
        "languages": {
            "alert": {
                "bulk_destroyed": "Selected languages deleted",
                "bulk_disabled": "Selected languages disabled",
                "bulk_enabled": "Selected languages enabled",
                "created": "Language created",
                "deleted": "Language deleted",
                "disabled": "Language disabled",
                "enabled": "Language enabled",
                "updated": "Language updated"
            },
            "button": {
                "create": "Create Language"
            },
            "label": {
                "enabled_center": "Enabled on Backend",
                "enabled_site": "Enabled on Frontend",
                "interface_center": "Backend Interface",
                "locale": "Locale",
                "locale_select": "Select locale",
                "name": "Name",
                "native": "Native",
                "position_center": "Position on backend",
                "position_site": "Position on frontend",
                "regional": "Regional",
                "required_center": "Fields in this language are required",
                "show_center": "Show in backend list",
                "show_site": "Show in frontend list"
            },
            "note": {
                "center_sort_order": "The position of the language in the list of languages of the backend",
                "interface_center": "Use as backend interface language. There will also be a possibility to translate the backend in this language in Translations",
                "name": "Language name (mainly used)",
                "native": "Native language name",
                "required_center": "When filling in the fields of the module forms, the fields of this language are mandatory if it is provided in the backend part. For example, the category name must be filled in the default language, if you select this option, the category name must be filled in that language as well.",
                "show_center": "Show language in backend selection list",
                "show_site": "Show language in frontend selection list",
                "site_sort_order": "The position of the language in the list of languages of the frontend"
            },
            "permission": {
                "category": "Languages",
                "create": {
                    "display_name": "Create language"
                },
                "delete": {
                    "display_name": "Delete language"
                },
                "edit": {
                    "display_name": "Edit language"
                },
                "view": {
                    "display_name": "View languages"
                }
            },
            "section": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "sidebar": {
                "section": "64564",
                "title": "Kalbos"
            },
            "tab": {
                "center": "Backend Content",
                "center_interface": "Backend Interface",
                "site": "Frontend"
            },
            "title": {
                "create": "Language creation",
                "edit": "Language edit",
                "list": "Languages",
                "unit": "Language",
                "units": "Languages"
            }
        },
        "countries": {
            "sidebar": {
                "section": "Geo",
                "title": "Countries"
            },
            "titles": {
                "unit": "Šalis",
                "units": "Šalys",
                "list": "Šalys",
                "create": "Country creation",
                "edit": "Country edit"
            },
            "tabs": [],
            "sections": [],
            "buttons": {
                "create": "Create Country"
            },
            "labels": {
                "name": "Pavadinimas",
                "name_original": "Originalus pavadinimas",
                "code": "Kodas",
                "capital": "Sostinė",
                "area": "Plotas",
                "tld": "Top level domain"
            },
            "alerts": {
                "created": "Country created",
                "updated": "Country updated",
                "deleted": "Country deleted",
                "enabled": "Country enabled",
                "disabled": "Country disabled",
                "bulk_destroyed": "Selected countries deleted",
                "bulk_enabled": "Selected countries enabled",
                "bulk_disabled": "Selected countries disabled"
            },
            "actions": {
                "destroy": "Delete selected countries",
                "enable": "Enable selected countries",
                "disable": "Disable selected countries"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected countries",
                "bulk_enable": "Error on enabling selected countries",
                "bulk_disable": "Error on disabling selected countries"
            },
            "permissions": {
                "category": "Šalys",
                "view": {
                    "display_name": "Peržiūrėti šalis",
                    "description": ""
                },
                "create": {
                    "display_name": "Sukurti šalį",
                    "description": ""
                },
                "edit": {
                    "display_name": "Koreguoti šalį",
                    "description": ""
                },
                "delete": {
                    "display_name": "Ištrinti šalį",
                    "description": ""
                }
            }
        },
        "geo-alerts": {
            "geo": {
                "created": "Geo created",
                "updated": "Geo updated",
                "deleted": "Geo deleted",
                "bulk_destroyed": "Selected Geo deleted",
                "bulk_enabled": "Selected Geo enabled",
                "bulk_disabled": "Selected Geo disabled"
            }
        },
        "roles-alerts": {
            "roles": {
                "created": "Rolė sukurta",
                "updated": "Rolė atnaujinta",
                "deleted": "Rolė ištrinta",
                "bulk_destroyed": "Pasirinktos rolės ištrintos",
                "bulk_enabled": "Pasirinktos rolės aktyvuotos",
                "bulk_disabled": "Pasirinktos rolės deaktyvuotos"
            }
        }
    },
    "lv": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "lang-required": "The {Field} field is required in {Lang}-name ({LANG}-CODE) language.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "identifier": "Code",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "enabled": "Enabled",
                "disabled": "Disabled",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "url-target": "URL link",
                "url-target-info": "Remember. You can only access the URL link if the item is not disabled.",
                "url-description": "If you do not fill in the URL field, it will be automatically generated from the item name.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "price": "Price",
                "page-title": "Page title"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "save-continue": "Save and Continue Edit",
            "save-and-close": "Save and close",
            "save-and-continue": "Save and continue filling data",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply"
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "main_content": "Main information",
            "content": "Content",
            "url": "Url",
            "seo": "SEO",
            "meta": {
                "title": "Page title",
                "title-note": "If leave empty, item name will be used as title by default.",
                "description": "Meta description",
                "keywords": "Meta keywords"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "lt": "Lithuanian"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Dashboard",
                "administration": "Administration"
            },
            "sidebar": {
                "options": "Options",
                "content": "Content management",
                "forms": "Form management",
                "access": "Access management",
                "seo": "SEO settings",
                "settings": {
                    "title": "Settings",
                    "attributes": "Attributes",
                    "attribute-groups": "Attribute groups",
                    "attribute-sets": "Attribute sets"
                },
                "jobs": {
                    "title": "Jobs"
                },
                "catalog": {
                    "title": "Catalog",
                    "products": "Products",
                    "categories": "Categories"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                }
            }
        }
    },
    "ru": {
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "lang-required": "The {Field} field is required in {Lang}-name ({LANG}-CODE) language.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "name": "Name",
                "identifier": "Code",
                "display_name": "Display name",
                "username": "Pseudo",
                "email": "Email",
                "first_name": "Firstname",
                "last_name": "Lastname",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "old_password": "Old password",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "postal_code": "Postal code",
                "city": "City",
                "country": "Country",
                "address": "Address",
                "phone": "Phone",
                "mobile": "Mobile",
                "age": "Age",
                "sex": "Sex",
                "gender": "Gender",
                "day": "Day",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "enabled": "Enabled",
                "disabled": "Disabled",
                "message": "Message",
                "g-recaptcha-response": "Captcha",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "url-target": "URL link",
                "url-target-info": "Remember. You can only access the URL link if the item is not disabled.",
                "url-description": "If you do not fill in the URL field, it will be automatically generated from the item name.",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "target_path": "Target path",
                "redirect_type": "Redirect type",
                "timezone": "Timezone",
                "order": "Display order",
                "image": "Image",
                "status": "Status",
                "pinned": "Pinned",
                "promoted": "Promoted",
                "body": "Body",
                "tags": "Tags",
                "published_at": "Publish at",
                "unpublished_at": "Unpublish at",
                "metable_type": "Entity",
                "price": "Price",
                "page-title": "Page title"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "buttons": {
            "cancel": "Cancel",
            "save": "Save",
            "save-continue": "Save and Continue Edit",
            "save-and-close": "Save and close",
            "save-and-continue": "Save and continue filling data",
            "close": "Close",
            "create": "Create",
            "delete": "Delete",
            "confirm": "Confirm",
            "show": "Show",
            "edit": "Edit",
            "update": "Update",
            "view": "View",
            "preview": "Preview",
            "back": "Back",
            "send": "Send",
            "login-as": "Login as {name}",
            "apply": "Apply"
        },
        "translations": {
            "sidebar": {
                "title": "Translations",
                "section": "Translations"
            },
            "titles": {
                "unit": "Translation",
                "units": "Translations",
                "list": "Translations",
                "create": "Translation creation",
                "edit": "Translation edit",
                "import": "Translations importing",
                "publish": "Translations publishing"
            },
            "buttons": {
                "create": "Create Translation",
                "import": "Import/Sync Translations",
                "publish": "Publish translations"
            },
            "alerts": {
                "created": "Translation created",
                "updated": "Translation updated",
                "deleted": "Translation deleted",
                "enabled": "Translation enabled",
                "disabled": "Translation disabled",
                "bulk_destroyed": "Selected translations deleted",
                "bulk_enabled": "Selected translations enabled",
                "bulk_disabled": "Selected translations disabled"
            },
            "actions": {
                "destroy": "Delete selected translations",
                "enable": "Enable selected translations",
                "disable": "Disable selected translations"
            },
            "exceptions": {
                "bulk_destroy": "Error on deleting selected translations",
                "bulk_enable": "Error on enabling selected translations",
                "bulk_disable": "Error on disabling selected translations"
            },
            "permissions": {
                "category": "Translations",
                "view": {
                    "display_name": "View translations"
                },
                "create": {
                    "display_name": "Create translations"
                },
                "edit": {
                    "display_name": "Edit translations"
                },
                "delete": {
                    "display_name": "Delete translations"
                }
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "main_content": "Main information",
            "content": "Content",
            "url": "Url",
            "seo": "SEO",
            "meta": {
                "title": "Page title",
                "title-note": "If leave empty, item name will be used as title by default.",
                "description": "Meta description",
                "keywords": "Meta keywords"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "morphs": {
                "user": "User, identity {id}"
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "lt": "Lithuanian"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "user": {
                "dashboard": "Dashboard",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forget password ?",
                "send_password_link": "Send reset password link",
                "password_reset": "Password Reset",
                "register": "Register",
                "space": "My space",
                "settings": "Settings",
                "account": "My account",
                "profile": "My profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit my profile",
                "change_password": "Change my password",
                "delete": "Delete my account",
                "administration": "Administration",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrateur",
                "account_delete": "<p>This action will delete entirely your account from this site as well as all associated data.</p>",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "space": "My space",
                    "account": "My account"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\">{admin}</a>."
            },
            "titles": {
                "dashboard": "Dashboard",
                "administration": "Administration"
            },
            "sidebar": {
                "options": "Options",
                "content": "Content management",
                "forms": "Form management",
                "access": "Access management",
                "seo": "SEO settings",
                "settings": {
                    "title": "Settings",
                    "attributes": "Attributes",
                    "attribute-groups": "Attribute groups",
                    "attribute-sets": "Attribute sets"
                },
                "jobs": {
                    "title": "Jobs"
                },
                "catalog": {
                    "title": "Catalog",
                    "products": "Products",
                    "categories": "Categories"
                }
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "new_menu": {
                    "post": "New post",
                    "form_setting": "New form setting",
                    "user": "New user",
                    "role": "New role",
                    "meta": "New meta",
                    "redirection": "New redirection"
                }
            }
        }
    }
}
