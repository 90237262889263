export default {
  path: 'attributes',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'attributes',
      component: require('@attributes/js/components/AttributeList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'sets/',
      name: 'attributes_sets',
      component: require('@attributes/js/components/AttributeSetList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'attributes_create',
      component: require('@attributes/js/components/AttributeCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'sets/create',
      name: 'attributes_sets_create',
      component: require('@attributes/js/components/AttributeSetCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'groups/create',
      name: 'attributes_groups_create',
      component: require('@attributes/js/components/AttributeGroupCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'attributes_edit',
      component: require('@attributes/js/components/AttributeEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'sets/:id/edit',
      name: 'attributes_sets_edit',
      component: require('@attributes/js/components/AttributeSetEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
