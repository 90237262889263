import axios from 'axios'
import Vue from 'vue'
const state = {
  modal: false,
  data_type: '',
  data: {
    options: [],
    layout: {
      type: 'default',
      d_col: 12,
      t_col: 12,
      m_col: 12,
      d_n: true,
      t_n: true,
      m_n: true
    }
  },
  errors: {
    error_type_no: false,
    error_name_no: false,
    error_value_no: false
  },
  current_tab: 1
}

const getters = {
  modal(state) {
    return state.modal
  },
  mode(state) {
    return state.mode
  },
  data(state) {
    return state.data
  },
  record(state) {
    return state.data
  },
  errors(state) {
    return state.errors
  },
  attribute(state) {
    return state
  },
  getCurrentTab(state) {
    return state.current_tab
  }
}

const mutations = {
  setModal(state, data) {
    state.modal = data ?? false
  },
  setMode(state, data) {
    state.mode = data ?? ''
  },
  setDataType(state, data) {
    state.data_type = data ?? ''
  },
  setError(state, data) {
    for (let key of Object.keys(data)) {
      state.errors[key] = data[key] !== null ? data[key] : ''
    }
  },
  setCurrentTab(state, data) {
    state.current_tab = data ?? 1
  },
  setData(state, data) {
    state.data = data ?? ''

    if (!state.data.layout) {
      state.data.layout = {
        type: 'default',
        d_col: 12,
        t_col: 12,
        m_col: 12,
        d_n: true,
        t_n: true,
        m_n: true
      }
    }
  },
  setDataRevert(state, data) {
    //console.log(data)
    /*    for (let key of Object.keys(state.data)) {
      if (typeof data[key] !== 'object') {
        data[key] !== null ? data[key] : ''
      } else {
        console.log(data[key])
      }
    }*/
    //state.data.push(_.cloneDeep(data))
    //state.data = [{ ...data }]

    for (let key of Object.keys(state.data)) {
      state.data[key] = data[key] !== null ? data[key] : ''
    }
  },
  emptyData(state, data) {
    state.data = {
      options: [],
      layout: {
        type: 'default',
        d_col: 12,
        t_col: 12,
        m_col: 12,
        d_n: true,
        t_n: true,
        m_n: true
      }
    }
  },
  setElementFolder(state, data) {
    state.data.folder_id = data.folder_id ?? ''
    state.data.unique_folder_id = data.unique_folder_id ?? ''
  },
  setFiles(state, data) {
    state.data.files = data.files ?? {}
  },

  setType(state, data) {
    // let iden = this.generateIdentifier()

    state.data.options = data.options ? data.options : undefined
    state.data.layout = data.layout
      ? data.layout
      : {
          type: 'default',
          d_col: 12,
          t_col: 12,
          m_col: 12,
          d_n: true,
          t_n: true,
          m_n: true
        }

    state.data.group = data.group ?? undefined
    state.data.type = data.type ?? ''
    state.data.kind = data.kind ?? ''
    state.data.identifier = data.identifier ?? ''

    state.data.order = 0
    state.data.orderLang = {
      en: 0
    }
    state.data.status = 1
    state.data.statusChangeable = 1
    state.data.deletable = 1
    state.data.searchable = 0
    state.data.filterable = 0
    state.data.filter_identifier = ''
    state.data.classes = ''
    state.data.validation = {}
    state.data.tooltip = { en: '' }
    state.data.name = { en: '' }

    if (
      data.type === 'text' ||
      data.type === 'textarea' ||
      data.type === 'wysiwyg'
    ) {
      state.data.valueLang = { en: '' }
      state.data.note = { en: '' }
      state.data.placeholder = { en: '' }
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
      state.data.validation.min = ''
      state.data.validation.max = ''
      state.data.validation.minMsg = { en: '' }
      state.data.validation.maxMsg = { en: '' }
    }

    if (data.type === 'title') {
      state.data.valueLang = { en: '' }
      state.data.variant = 'h1'
    }

    if (data.type === 'divider') {
      state.data.valueLang = { en: '' }
      state.data.variant = 'solid'
    }

    if (data.type === 'email') {
      state.data.value = ''
      state.data.note = { en: '' }
      state.data.placeholder = { en: '' }
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
    }

    if (data.type === 'number') {
      state.data.valueNumeric = ''
      state.data.note = { en: '' }
      state.data.placeholder = { en: '' }
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
      state.data.validation.min = ''
      state.data.validation.max = ''
      state.data.validation.minMsg = { en: '' }
      state.data.validation.maxMsg = { en: '' }
    }

    if (data.type === 'upload') {
      state.data.note = { en: '' }
      state.data.preview_type = 'grid'
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
      state.data.public = false
    }

    if (data.type === 'image') {
      state.data.note = { en: '' }
      state.data.folder_id = ''
      state.data.unique_folder_id = ''
      state.data.files = {}
      state.data.preview_type = 'grid'
      state.data.public = true
      state.data.show_file_name = false
      state.data.show_file_size = false
      state.data.show_file_date = false
      state.data.show_file_time = false
      state.data.show_file_extension = false
      state.data.allow_zoom = true
      state.data.allow_download = true
      state.data.allow_print = true
    }

    if (data.type === 'file') {
      state.data.note = { en: '' }
      state.data.folder_id = ''
      state.data.unique_folder_id = ''
      state.data.files = {}
      state.data.preview_type = 'grid'
      state.data.public = true
      state.data.show_file_name = true
      state.data.show_file_size = true
      state.data.show_file_date = false
      state.data.show_file_time = false
      state.data.show_file_extension = true
      state.data.allow_zoom = true
      state.data.allow_download = true
      state.data.allow_print = true
    }

    if (data.type === 'url' || data.type === 'faq') {
      state.data.note = { en: '' }
    }

    if (data.type === 'select' || data.type === 'radio') {
      state.data.multiselect = false
      state.data.note = { en: '' }
      state.data.placeholder = { en: '' }
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
    }

    if (data.type === 'checkbox') {
      state.data.valueChecked = {}
      state.data.multiselect = true
      state.data.note = { en: '' }
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
    }

    if (data.type === 'toggle') {
      state.data.multiselect = false
      state.data.note = { en: '' }
      state.data.valueBoolean = {}
      state.data.validation.required = false
      state.data.validation.requiredMsg = { en: '' }
    }

    if (data.type === 'select') {
      state.data.placeholder = { en: '' }
    }
  },
  /*  addToScannedList(state, item) {
    if(item !== undefined) {
      state.scannedList.push(item);
    } else {
      state.scannedList = [];
    }
  },*/
  addItem(state, data) {
    Vue.set(state.data, 'options', data)

    /*state.data.options = []
    state.data.options.push(data)*/
  },
  removeOptionRecord(state, data) {
    const index = state.data.options.findIndex(record => record.id === data.id)
    state.data.options.splice(index, 1)
    Vue.prototype.$app.noty['success'](
      Vue.prototype.trans('attributes.alerts.option-removed')
    )
  }
}

const actions = {
  generateIdentifier() {
    return Math.round(+new Date() / 1000) + this.rndNumber(4) // unix timestamp
  },
  rndNumber(len) {
    let number = ''
    let chars = '1234567890'

    for (let i = 0; i < len; i++) {
      number += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return number
  },
  setCurrentTab({ commit, dispatch, state }, data) {
    commit('setCurrentTab', data)
  },
  removeOptionRecord({ commit, dispatch }, record) {
    commit('removeOptionRecord', record)
  },
  addEmptyItem({ commit, dispatch, state }, data) {
    if (state.data.options != undefined && state.data.options != 'undefined') {
      state.data.options.push({
        id: data.identifier,
        name: '',
        text: '',
        value: data.identifier,
        content: '',
        selected: false,
        enabled: true
      })
    } else {
      let item = [
        {
          id: data.identifier,
          name: '',
          text: '',
          value: data.identifier,
          content: '',
          selected: false,
          enabled: true
        }
      ]
      commit('addItem', item)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
