<template>
  <li class="menu-option" :class="[icon === 'trash' ? 'danger' : '']">
    <div class="icon">
      <i class="fas fa-trash-alt" v-if="icon === 'trash'"></i>
      <i class="fas fa-eye" v-if="icon === 'preview'"></i>
      <i class="fas fa-pencil-alt" v-if="icon === 'rename'"></i>
      <i class="fas fa-cloud-download-alt" v-if="icon === 'download'"></i>
    </div>
    <div class="text-label">
      {{ title }}
    </div>
  </li>
</template>

<script>
export default {
  name: 'Option',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.danger {
  .text-label {
    color: $danger !important;
  }
  .icon {
    path,
    line,
    polyline,
    rect,
    circle,
    polygon {
      stroke: $danger !important;
    }
  }
}

.menu-option {
  white-space: nowrap;
  font-weight: 700;
  @include font-size(14);
  padding: 8px 20px;
  cursor: pointer;
  width: 100%;
  color: $text;
  display: flex;
  align-items: center;

  .icon {
    margin-right: 20px;
    line-height: 0;
  }

  .text-label {
    @include font-size(16);
  }

  &:hover {
    background: $light_background;

    .text-label {
      color: $theme;
    }

    path,
    line,
    polyline,
    rect,
    circle,
    polygon {
      stroke: $theme;
    }
  }
}
</style>
