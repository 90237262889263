<template>
  <AuthContentWrapper ref="auth">
    <AuthContent name="log-in" :visible="true">
      <img
        src="@main/images/logo.png"
        width="32"
        height="32"
        alt=""
        class="logo"
      />
      <h1>{{ trans('labels.user.login') }}</h1>

      <ValidationObserver
        @submit.prevent="logIn"
        ref="log_in"
        v-slot="{ invalid }"
        tag="form"
        class="form block-form"
      >
        <div class="block-wrapper">
          <label>{{ trans('validation.attributes.email') }}</label>
          <ValidationProvider
            tag="div"
            mode="passive"
            class="input-wrapper"
            name="E-Mail"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              v-model="loginForm.email"
              :placeholder="trans('validation.attributes.email')"
              type="email"
              :class="{ 'is-error': errors[0] }"
            />
            <span class="error-message" v-if="errors[0]">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="block-wrapper">
          <label>{{ trans('validation.attributes.password') }}</label>
          <ValidationProvider
            tag="div"
            mode="passive"
            class="input-wrapper"
            name="Your Password"
            rules="required"
            v-slot="{ errors }"
          >
            <input
              v-model="loginForm.password"
              :placeholder="trans('validation.attributes.password')"
              type="password"
              :class="{ 'is-error': errors[0] }"
            />
            <span class="error-message" v-if="errors[0]">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div>
          <AuthButton
            icon="chevron-right"
            :text="trans('labels.user.login')"
            :loading="isLoading"
            :disabled="isLoading"
          ></AuthButton>
        </div>
      </ValidationObserver>

      <span class="additional-link">
        <router-link to="/password/reset">
          {{ trans('labels.user.password_forgot') }}
        </router-link>
      </span>
    </AuthContent>
  </AuthContentWrapper>
</template>

<script>
// import axios from 'axios'
import AuthContentWrapper from '@main/js/components/Auth/AuthContentWrapper'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'
import AuthContent from '@main/js/components/Auth/AuthContent'
import AuthButton from '@main/js/components/Auth/AuthButton'
import store from '@main/js/store'
import Vue from 'vue'
export default {
  components: {
    AuthContentWrapper,
    ValidationProvider,
    ValidationObserver,
    AuthContent,
    AuthButton
  },
  data() {
    return {
      loginForm: new Form({
        email: '',
        password: ''
      }),
      isLoading: false,
      social_login_providers: [],
      state: {
        email: '',
        password: ''
      }
    }
  },
  computed: {
    mode() {
      return this.$store.getters['attribute/mode']
    }
  },
  mounted() {
    // axios.get('/sanctum/csrf-cookie').then(response => {})
  },
  methods: {
    async logIn() {
      // Validate fields
      const isValid = await this.$refs.log_in.validate()

      if (!isValid) return

      // Start loading
      this.isLoading = true

      this.state.email = this.loginForm.email
      this.state.password = this.loginForm.password

      this.loginForm
        .post('/api/auth/login')
        .then(response => {
          // End loading
          this.isLoading = false

          // Login to external api
          //this.loginToExternalApi()

          this.$nextTick(() => {
            let redirect_path = '/dashboard'
            this.$router.go(redirect_path)
          })

          // let redirect_path = '/dashboard'
          // this.$router.go(redirect_path)
        })
        .catch(error => {
          this.$showErrorMsg(error)
          this.isLoading = false
        })
    },
    submit() {
      this.loginForm
        .post('/api/auth/login')
        .then(response => {
          this.$store.dispatch('setAuthStatus')
          this.$store.dispatch('setLastActivity')
          toastr.success(response.message)

          if (
            helper.getConfig('two_factor_security') &&
            response.two_factor_code
          ) {
            this.$store.dispatch('setTwoFactorCode', response.two_factor_code)
            this.$router.push('/auth/security')
          } else {
            var redirect_path = response.reload ? '/home?reload=1' : '/home'
            this.$store.dispatch('resetTwoFactorCode')
            this.$router.push(redirect_path)
          }
        })
        .catch(error => {
          this.$showErrorMsg(error)
        })
    },
    getConfig(config) {
      return helper.getConfig(config)
    },
    loginToExternalApi() {
      this.$store.dispatch('logIn', {
        email: this.state.email,
        password: this.state.password
      })
    }
  }
}
</script>
