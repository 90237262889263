import Vue from 'vue'
import Vuex from 'vuex'

import { createActions } from './actions'
import mutations from './mutations'
import fileFunctions from '@main/js/store/modules/fileFunctions'
import fileBrowser from '@main/js/store/modules/fileBrowser'
import app from '@main/js/store/modules/app'
import apiUserAuth from '@main/js/store/modules/apiUserAuth'
import group from '@attributes/js/modules/group'
import attribute from '@attributes/js/modules/attribute'
import attributes from '@attributes/js/modules/attributes'

Vue.use(Vuex)

export function createStore(route) {
  const actions = createActions(route)

  return new Vuex.Store({
    modules: {
      fileFunctions,
      fileBrowser,
      app,
      apiUserAuth,
      group,
      attribute,
      attributes
    },
    state: {
      counters: {
        pendingAuctionsCount: 0,
        reviseAuctionsCount: 0,
        draftAuctionsCount: 0,
        activeAuctionsCount: 0,
        suspendedAuctionsCount: 0,
        canceledAuctionsCount: 0,
        ongoingAuctionsCount: 0,
        untilAuctionsCount: 0,
        endedAuctionsCount: 0,
        allAuctionsCount: 0,
        allBidsCount: 0,
        allUsersCount: 0,
        activeUsersCount: 0,
        pendingUsersCount: 0
      }
    },
    actions,
    mutations
  })
}
