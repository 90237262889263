export default {
  path: 'settings',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'settings',
      component: require('../components/SettingsList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'settings_create',
      component: require('../components/SettingsCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':module/:nav',
      name: 'settings_adjustment',
      component: require('../components/SettingsList').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: ':module/:nav/:element',
      name: 'settings_adjustment',
      component: require('../components/SettingsList').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'settings_edit',
      component: require('../components/SettingsList').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
