import axios from 'axios'
import Vue from 'vue'
const state = {
  mode: '',
  modal: false,
  data: {
    name: {
      en: ''
    }
  },
  errors: {
    error_name_no: false,
    error_value_no: false
  },
  current_tab: 1
}

const getters = {
  mode(state) {
    return state.mode
  },
  modal(state) {
    return state.modal
  },
  data(state) {
    return state.data
  },
  record(state) {
    return state.data
  },
  errors(state) {
    return state.errors
  },
  attribute(state) {
    return state
  },
  getCurrentTab(state) {
    return state.current_tab
  }
}

const mutations = {
  setModal(state, data) {
    state.modal = data ?? false
  },
  setMode(state, data) {
    state.mode = data ?? ''
  },
  setError(state, data) {
    for (let key of Object.keys(data)) {
      state.errors[key] = data[key] !== null ? data[key] : ''
    }
  },
  setCurrentTab(state, data) {
    state.current_tab = data ?? 1
  },
  setData(state, data) {
    state.data = data ?? ''
  },
  setDataRevert(state, data) {
    for (let key of Object.keys(state.data)) {
      state.data[key] = data[key] !== null ? data[key] : ''
    }
  },
  emptyData(state, data) {
    state.data = {}
  }
}

const actions = {
  generateIdentifier() {
    return Math.round(+new Date() / 1000) + this.rndNumber(4) // unix timestamp
  },
  rndNumber(len) {
    let number = ''
    let chars = '1234567890'

    for (let i = 0; i < len; i++) {
      number += chars.charAt(Math.floor(Math.random() * chars.length))
    }

    return number
  },
  setCurrentTab({ commit, dispatch, state }, data) {
    commit('setCurrentTab', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
