<template>
  <PopupWrapper name="rename-item">
    <!--Title-->
    <PopupHeader :title="$t('assets.title.rename')"></PopupHeader>

    <!--Content-->
    <PopupContent>
      <ValidationObserver
        @submit.prevent="changeName"
        ref="renameForm"
        v-slot="{ invalid }"
        tag="form"
        class="form-wrapper"
      >
        <!--Set password-->
        <ValidationProvider
          tag="div"
          mode="passive"
          class="input-wrapper password"
          name="Name"
          rules="required"
          v-slot="{ errors }"
        >
          <label class="input-label">{{ $t('assets.label.name') }}:</label>
          <div class="input">
            <input
              v-model="pickedItem.name"
              :class="{ 'is-error': errors[0] }"
              ref="input"
              type="text"
              :placeholder="$t('assets.placeholder.name')"
            />
            <div @click="pickedItem.name = ''" class="close-icon-wrapper">
              <x-icon class="close-icon" size="14"></x-icon>
            </div>
          </div>
          <span class="error-message" v-if="errors[0]">{{ errors[0] }}</span>
        </ValidationProvider>
      </ValidationObserver>
    </PopupContent>

    <!--Actions-->
    <PopupActions>
      <ButtonBase
        class="popup-button"
        @click.native="$closePopup()"
        button-style="secondary"
      >
        {{ $t('assets.button.cancel') }}
      </ButtonBase>
      <ButtonBase
        class="popup-button"
        @click.native="changeName"
        button-style="theme"
      >
        {{ $t('assets.button.save') }}
      </ButtonBase>
    </PopupActions>
  </PopupWrapper>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full'
import PopupWrapper from '@assets/js/components/Popup/PopupWrapper'
import PopupActions from '@assets/js/components/Popup/PopupActions'
import PopupContent from '@assets/js/components/Popup/PopupContent'
import PopupHeader from '@assets/js/components/Popup/PopupHeader'
import ButtonBase from '@assets/js/components/ButtonBase'
import { XIcon } from 'vue-feather-icons'
import { required } from 'vee-validate/dist/rules'
import { events } from '@main/js/bus'
import axios from 'axios'

export default {
  name: 'RenameItem',
  components: {
    ValidationProvider,
    ValidationObserver,
    PopupWrapper,
    PopupActions,
    PopupContent,
    PopupHeader,
    ButtonBase,
    XIcon
  },
  data() {
    return {
      pickedItem: undefined,
      isMoreOptions: false,
      setFolderIcon: undefined
    }
  },
  computed: {},
  mounted() {
    // Show popup
    events.$on('popup:open', args => {
      if (args.name !== 'rename-item') return

      this.isMoreOptions = false

      this.setFolderIcon = undefined

      // Store picked item
      this.pickedItem = args.item
    })
  },
  methods: {
    changeName() {
      if (this.pickedItem.name && this.pickedItem.name !== '') {
        let item = {
          unique_id: this.pickedItem.unique_id,
          type: this.pickedItem.type,
          name: this.pickedItem.name,
          folder_icon: this.setFolderIcon ? this.setFolderIcon : null
        }

        // Rename item request
        this.$store.dispatch('renameItem', item)

        // Rename item in view
        events.$emit('change:name', item)

        this.$closePopup()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.input {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .close-icon-wrapper {
    width: 22px;
    height: 22px;
    position: absolute;
    cursor: pointer;
    right: 15px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .close-icon {
        line {
          stroke: $theme;
        }
      }
    }

    .close-icon {
      line {
        stroke: rgba($text-muted, 0.3);
      }
    }
  }
}

.item-thumbnail {
  margin-bottom: 20px;
}
</style>
