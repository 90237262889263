<template>
  <div
    class="file-content"
    id="file-content-id"
    :class="{ 'is-offset': filesInQueueTotal > 0 }"
    @keydown.delete="deleteItems"
    tabindex="-1"
  >
    <div
      class="files-container"
      ref="fileContainer"
      :class="{
        'is-fileinfo-visible': fileInfoVisible && !$isMinimalScale(),
        'mobile-multi-select': mobileMultiSelect
      }"
      @click.self="filesContainerClick"
    >
      <!--Item previews list-->
      <div v-if="isList" class="file-list-wrapper">
        <transition-group
          name="file"
          tag="section"
          class="file-list"
          :class="FilePreviewType"
        >
          <FileItemList
            @dragstart="dragStart(item)"
            @drop.stop.native.prevent="dragFinish(item, $event)"
            @contextmenu.native.prevent="contextMenu($event, item)"
            :item="item"
            v-for="item in data"
            :key="item.unique_id"
            class="file-item"
            :class="draggedItems.includes(item) ? 'dragged' : ''"
          ></FileItemList>
        </transition-group>
      </div>

      <!--Item previews grid-->
      <div v-if="isGrid" class="file-grid-wrapper">
        <transition-group
          name="file"
          tag="section"
          class="file-list"
          :class="FilePreviewType"
        >
          <FileItemGrid
            @contextmenu.native.prevent="contextMenu($event, item)"
            :item="item"
            v-for="item in data"
            :key="item.unique_id"
            class="file-item"
          ></FileItemGrid>
        </transition-group>
      </div>

      <!--Show empty page if folder is empty-->
      <EmptyPage v-if="!isSearching"></EmptyPage>

      <!--Show empty page if no search results-->
      <EmptyMessage
        v-if="isSearching && isEmpty"
        :message="$t('messages.nothing_was_found')"
        icon="eye-slash"
      ></EmptyMessage>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import EmptyPage from '@assets/js/components/EmptyPage'
import EmptyMessage from '@assets/js/components/EmptyMessage'
import FileItemList from '@assets/js/components/FileItemList'
import FileItemGrid from '@assets/js/components/FileItemGrid'
import { events } from '@main/js/bus'
export default {
  name: 'FilesContainer',
  components: {
    EmptyPage,
    FileItemList,
    FileItemGrid,
    EmptyMessage
  },
  data() {
    return {
      draggingId: undefined,
      isDragging: false,
      mobileMultiSelect: false
    }
  },
  computed: {
    ...mapGetters([
      'filesInQueueTotal',
      'fileInfoVisible',
      'fileInfoDetail',
      'currentFolder',
      'currentModule',
      'currentRecord',
      'currentElement',
      'FilePreviewType',
      'isSearching',
      'isLoading',
      'data'
    ]),
    isGrid() {
      return this.FilePreviewType === 'grid'
    },
    isList() {
      return this.FilePreviewType === 'list'
    },
    isEmpty() {
      return this.data.length == 0
    },
    draggedItems() {
      //Set opacity for dragged items

      if (!this.fileInfoDetail.includes(this.draggingId)) {
        return [this.draggingId]
      }

      if (this.fileInfoDetail.includes(this.draggingId)) {
        return this.fileInfoDetail
      }

      return false
    }
  },
  created() {
    events.$on('drop', () => {
      this.isDragging = false
      setTimeout(() => {
        this.draggingId = undefined
      }, 10)
    })

    events.$on('fileItem:deselect', () =>
      this.$store.commit('CLEAR_FILEINFO_DETAIL')
    )
  },
  methods: {
    contextMenu(event, item) {
      events.$emit('contextMenu:show', event, item)
    },
    dropUpload(event) {
      // Upload external file
      this.$uploadExternalFiles(event, this.currentFolder.unique_id)

      this.isDragging = false
    },
    deleteItems() {
      if (
        (this.fileInfoDetail.length > 0 && this.$checkPermission('master')) ||
        this.$checkPermission('editor')
      ) {
        this.$store.dispatch('deleteItem')
      }
    },
    filesContainerClick() {
      // Deselect itms clicked by outside
      this.$store.commit('CLEAR_FILEINFO_DETAIL')
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.file-list {
  .dragged {
    /deep/.is-dragenter {
      border: 2px solid transparent;
    }
  }
}

.dragged {
  opacity: 0.5;
}

#multi-selected {
  position: fixed;
  pointer-events: none;
  z-index: 100;
}

.mobile-multi-select {
  bottom: 50px !important;
  top: 0px;
}

.button-upload {
  display: block;
  text-align: center;
  margin: 20px 0;
}

.mobile-search {
  display: none;
  margin-bottom: 10px;
  margin-top: 10px;
}

.file-content {
  display: flex;

  &.is-dragging {
    @include transform(scale(0.99));
  }
}

.files-container {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 0 0 100%;
  @include transition(150ms);
  position: relative;
  // scroll-behavior: smooth;

  &.is-fileinfo-visible {
    flex: 0 1 100%;
  }

  .file-list {
    &.grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, 180px);
      justify-content: space-evenly;
    }
  }
}

.file-info-container {
  flex: 0 0 300px;
  padding-left: 20px;
  overflow: auto;
}

// Transition
.file-move {
  transition: transform 0.6s;
}

.file-enter-active {
  transition: all 300ms ease;
}

.file-leave-active {
  transition: all 0ms;
}

.file-enter, .file-leave-to /* .list-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateX(-20px);
}

@media only screen and (min-width: 960px) {
  .file-content {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    @include transition;
    overflow-y: auto;
    overflow-x: hidden;
    border: 2px dashed #eee;

    .file-grid-wrapper {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &.is-offset {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .file-info-container {
    display: none;
  }

  .mobile-search {
    display: block;
  }
  .file-content {
    position: absolute;
    top: 0px;
    left: 15px;
    right: 15px;
    bottom: 0;
    @include transition;
    overflow-y: auto;
    overflow-x: hidden;

    &.is-offset {
      margin-top: 50px;
    }
  }
}

@media only screen and (max-width: 690px) {
  .files-container {
    padding-left: 15px;
    padding-right: 15px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    overflow-y: auto;

    .file-list {
      &.grid {
        grid-template-columns: repeat(auto-fill, 120px);
      }
    }
  }

  .file-content {
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    bottom: 0;
    @include transition;

    &.is-offset {
      margin-top: 50px;
    }
  }

  .mobile-search {
    margin-bottom: 0;
  }

  .file-info-container {
    display: none;
  }
}
</style>
