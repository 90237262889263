import { createStore } from './store/index'
import { debounce, includes, isArray } from 'lodash'
import { events } from './bus'
import axios from 'axios'
import Vue from 'vue'

const store = createStore(window.route)

const Helpers = {
  install(Vue) {
    Vue.prototype.$setModuleRecord = async function(module, id) {
      this.$store.commit('SET_MODULE_RECORD', {
        module: module,
        id: id
      })
      if (
        store.getters.currentFolder &&
        store.getters.currentFolder != undefined
      ) {
        this.$store.dispatch('getFolder', [
          { folder: store.getters.currentFolder, back: false, init: false }
        ])
      } else {
        if (
          store.getters.currentFolder &&
          store.getters.currentFolder.unique_id === null
        ) {
          // Proceed folders
          this.$store.commit('PROCESSING_FOLDER', true)
        }
        /*        this.$checkForFolder().then(
          response => {
            //console.log(response.data)
            // Check if has module and record folder if needed
            if (!store.getters.isProcessingFolder) {
              // Start uploading if uploading process isn't running

              // Increase total files in upload bar
              this.$store.dispatch('getFolder', [
                {
                  folder: store.getters.currentFolder,
                  back: false,
                  init: false
                }
              ])
            }
          },
          error => {}
        )*/
      }
    }

    Vue.prototype.$setElement = async function(identifier) {
      this.$store.commit('SET_ELEMENT', {
        identifier: identifier
      })
    }

    Vue.prototype.$getDataByLocation = function() {
      let folder = store.getters.currentFolder

      let actions = {
        base: [
          'getFolder',
          [{ folder: folder, back: true, init: false, sorting: true }]
        ],
        public: [
          'browseShared',
          [{ folder: folder, back: true, init: false, sorting: true }]
        ],
        trash: [
          'getFolder',
          [{ folder: folder, back: true, init: false, sorting: true }]
        ],
        participant_uploads: ['getParticipantUploads'],
        'trash-root': ['getTrash'],
        latest: ['getLatest'],
        shared: ['getShared']
      }

      this.$store.dispatch(...actions[folder.location])

      // Get dara of user with favourites tree
      this.$store.dispatch('getAppData')

      // Get data of Navigator tree
      this.$store.dispatch('getFolderTree')
    }

    Vue.prototype.$isThisLocation = function(location) {
      // Get current location
      let currentLocation =
        store.getters.currentFolder && store.getters.currentFolder.location
          ? store.getters.currentFolder.location
          : undefined
      /*
      // Check if type is object
      if (typeof location === 'Object' || location instanceof Object) {
        return includes(location, currentLocation)
      } else {
        return currentLocation === location
      }*/

      return true
    }

    Vue.prototype.$checkPermission = function(type) {
      let currentPermission = store.getters.permission

      // Check if type is object
      /*      if (typeof type === 'Object' || type instanceof Object) {
        return includes(type, currentPermission)
      } else {
        return currentPermission === type
      }*/

      return true
    }

    Vue.prototype.$uploadFiles = async function(files) {
      if (files.length == 0) return

      if (!this.$checkFileMimetype(files) || !this.$checkUploadLimit(files))
        return // Push items to file queue

      // Proceed folders
      this.$store.commit('PROCESSING_FOLDER', true)
      if (
        store.getters.currentFolder &&
        store.getters.currentFolder.unique_id === null
      ) {
        this.$checkForFolder().then(
          response => {
            // Check if has module and record folder if needed
            if (!store.getters.isProcessingFolder) {
              ;[...files].map(item => {
                this.$store.commit('ADD_FILES_TO_QUEUE', {
                  parent_id: store.getters.currentFolder.unique_id,
                  file: item
                })
              })

              // Start uploading if uploading process isn't running
              if (this.$store.getters.filesInQueueTotal == 0)
                this.$handleUploading(store.getters.fileQueue[0])

              // Increase total files in upload bar
              this.$store.commit('INCREASE_FILES_IN_QUEUES_TOTAL', files.length)
            }
          },
          error => {}
        )
      } else {
        this.$store.commit('PROCESSING_FOLDER', false)
        //if (!store.getters.isProcessingFolder) {
        ;[...files].map(item => {
          this.$store.commit('ADD_FILES_TO_QUEUE', {
            parent_id: store.getters.currentFolder.unique_id,
            file: item
          })
        })

        // Start uploading if uploading process isn't running
        if (this.$store.getters.filesInQueueTotal == 0)
          this.$handleUploading(store.getters.fileQueue[0])

        // Increase total files in upload bar
        this.$store.commit('INCREASE_FILES_IN_QUEUES_TOTAL', files.length)
      }
      //}
    }

    Vue.prototype.$downloadFile = function(url, filename) {
      var anchor = document.createElement('a')

      anchor.href = url

      anchor.download = filename

      document.body.appendChild(anchor)

      anchor.click()
    }

    Vue.prototype.$closePopup = function() {
      events.$emit('popup:close')
    }

    Vue.prototype.$checkForModuleRecordMainFolder = function() {
      let module = store.getters.currentModule,
        record = store.getters.currentRecord
    }

    Vue.prototype.$checkForFolder = function() {
      if (
        store.getters.currentFolder &&
        store.getters.currentFolder.unique_id
      ) {
        let currentFolder = {
          name: 'Folder',
          location: 'public',
          unique_id: store.getters.currentFolder.unique_id
          //folder_id: store.getters.currentFolder.unique_id
        }

        this.$store.commit('STORE_CURRENT_FOLDER', currentFolder)
        return currentFolder
      } else {
        let module = store.getters.currentModule,
          record = store.getters.currentRecord,
          recordFolder = store.getters.currentRecordFolder,
          element = store.getters.currentElement

        return this.$store.dispatch('proceedFolder', {
          module: module,
          record: record,
          recordFolder: recordFolder,
          element: element
        })
      }
    }

    Vue.prototype.$checkUploadLimit = function(files) {
      // let uploadLimit = store.getters.config.uploadLimit
      let uploadLimit = 20000000
      let validate = true

      for (let i = 0; i < files.length; i++) {
        if (uploadLimit != 0 && files[i].size > uploadLimit) {
          validate = false
          events.$emit('alert:open', {
            emoji: '😟😟😟',
            title: this.trans('popup_upload_limit.title'),
            message: this.trans('popup_upload_limit.message', {
              //uploadLimit: store.getters.config.uploadLimitFormatted
              uploadLimit: '20MB'
            })
          })
          break
        }
      }
      return validate
    }

    Vue.prototype.$uploadExternalFiles = async function(event, parent_id) {
      // Prevent submit empty files
      if (event.dataTransfer.items.length === 0)
        return // Push items to file queue
      ;[...event.dataTransfer.items].map(item => {
        this.$store.commit('ADD_FILES_TO_QUEUE', {
          parent_id: parent_id,
          file: item.getAsFile()
        })
      })

      // Start uploading if uploading process isn't running
      if (this.$store.getters.filesInQueueTotal == 0)
        this.$handleUploading(this.$store.getters.fileQueue[0])

      // Increase total files in upload bar
      this.$store.commit(
        'INCREASE_FILES_IN_QUEUES_TOTAL',
        [...event.dataTransfer.items].length
      )
    }

    Vue.prototype.$checkFileMimetype = function(files) {
      let validated = true
      //let mimetypesBlacklist = store.getters.config.mimetypesBlacklist
      let mimetypesBlacklist = null

      for (let i = 0; i < files.length; i++) {
        let fileType = files[i].type.split('/')

        if (!fileType[0]) {
          fileType[1] = _.last(files[i].name.split('.'))
        }

        /*        if (mimetypesBlacklist.includes(fileType[1])) {
          validated = false

          events.$emit('alert:open', {
            emoji: '😬😬😬',
            title: Vue.prototype.trans('popup_mimetypes_blacklist.title'),
            message: Vue.prototype.trans('popup_mimetypes_blacklist.message', {
              mimetype: fileType[1]
            })
          })
        }*/
      }
      return validated
    }

    Vue.prototype.$handleUploading = async function(item) {
      // Create ceil
      let size = 12800000,
        chunksCeil = Math.ceil(item.file.size / size),
        chunks = []

      // Create chunks
      for (let i = 0; i < chunksCeil; i++) {
        chunks.push(
          item.file.slice(
            i * size,
            Math.min(i * size + size, item.file.size),
            item.file.type
          )
        )
      }

      // Set Data
      let formData = new FormData(),
        uploadedSize = 0,
        isNotGeneralError = true,
        striped_name = item.file.name.replace(
          /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
          ''
        ),
        filename =
          Array(16)
            .fill(0)
            .map(x =>
              Math.random()
                .toString(36)
                .charAt(2)
            )
            .join('') +
          '-' +
          striped_name +
          '.part'

      do {
        let isLast = chunks.length === 1,
          chunk = chunks.shift(),
          attempts = 0

        // Set form data
        formData.set('file', chunk, filename)
        formData.set('parent_id', item.parent_id)
        formData.set('is_last', isLast)

        // Upload chunks
        do {
          await store
            .dispatch('uploadFiles', {
              form: formData,
              fileSize: item.file.size,
              totalUploadedSize: uploadedSize
            })
            .then(() => {
              uploadedSize = uploadedSize + chunk.size
            })
            .catch(error => {
              // Count attempts
              attempts++

              // Show Error
              if (attempts === 3) this.$isSomethingWrong()

              // Break uploading process
              if ([500, 415].includes(error.response.status))
                isNotGeneralError = false
            })
        } while (isNotGeneralError && attempts !== 0 && attempts !== 3)
      } while (isNotGeneralError && chunks.length !== 0)
    }

    Vue.prototype.$isMinimalScale = function() {
      let sizeType = store.getters.filesViewWidth

      return sizeType === 'minimal-scale'
    }

    Vue.prototype.$isCompactScale = function() {
      let sizeType = store.getters.filesViewWidth

      return sizeType === 'compact-scale'
    }

    Vue.prototype.$isFullScale = function() {
      let sizeType = store.getters.filesViewWidth

      return sizeType === 'full-scale'
    }

    Vue.prototype.$isMobile = function() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ]

      return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem)
      })
    }

    Vue.prototype.$checkOS = function() {
      // Handle styled scrollbar for Windows
      if (navigator.userAgent.indexOf('Windows') != -1) {
        let body = document.body
        body.classList.add('windows')
      }
    }
    Vue.prototype.$isApple = function() {
      const toMatch = [
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /iOS/i,
        /macOS/i,
        /Macintosh/i
      ]

      // Check if device is iOS
      return toMatch.some(toMatchItem => {
        return navigator.userAgent.match(toMatchItem)
      })
    }

    Vue.prototype.$isSomethingWrong = function() {
      events.$emit('alert:open', {
        title: Vue.prototype.trans('popup_error.title'),
        message: Vue.prototype.trans('popup_error.message')
      })
    }

    Vue.prototype.$setLastActivity = function() {
      if (!this.isScreenLocked()) this.$store.dispatch('setLastActivity')
    }

    Vue.prototype.$getLastActivity = function() {
      return store.getters['apiUserAuth/getLastActivity']
    }
    ;(Vue.prototype.isScreenLocked = function() {
      let last_activity = this.$getLastActivity()
      let lock_screen_timeout = this.getConfig('lock_screen_timeout')
      let last_activity_after_timeout = moment(last_activity)
        .add(lock_screen_timeout, 'minutes')
        .format('LLL')
      return moment().format('LLL') > last_activity_after_timeout
    }),
      // shows toastr notification for axios form request
      (Vue.prototype.$showErrorMsg = function(error) {
        this.$setLastActivity()

        if (error.hasOwnProperty('errors')) {
          Vue.prototype.$app.noty['error'](error.message)
        }

        /*        if (error.hasOwnProperty('errors')) {
          if (error.error.indexOf(' ') >= 0) {
            Vue.prototype.$app.noty['error'](error.error)
          } else toastr.error(i18n.general[error.error])

          if (error.error === 'token_expired') router.push('/login')
        } else if (
          error.hasOwnProperty('response') &&
          error.response.status == 403
        ) {
          toastr.error(i18n.general.permission_denied)
        } else if (
          error.hasOwnProperty('response') &&
          error.response.status == 422 &&
          error.response.data.hasOwnProperty('error')
        ) {
          toastr.error(error.response.data.error)
        } else if (
          error.hasOwnProperty('response') &&
          error.response.status == 404
        ) {
          toastr.error(i18n.general.invalid_link)
        } else if (error.errors.hasOwnProperty('message'))
          toastr.error(error.errors.message[0])*/
      })
  }
}

export default Helpers
