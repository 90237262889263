<template>
  <div v-if="filteredFiles.length > 1">
    <div @click.prevent="prev" class="prev">
      <chevron-left-icon size="40"></chevron-left-icon>
    </div>

    <div @click.prevent="next" class="next">
      <chevron-right-icon size="40"></chevron-right-icon>
    </div>
  </div>
</template>

<script>
import { events } from '@main/js/bus'
import { mapGetters } from 'vuex'
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons'
export default {
  name: 'FilePreviewActions',
  components: {
    ChevronLeftIcon,
    ChevronRightIcon
  },
  computed: {
    ...mapGetters(['fileInfoDetail', 'data']),

    filteredFiles() {
      let filteredData = []
      this.data.filter(element => {
        if (element.type == this.fileInfoDetail[0].type) {
          filteredData.push(element)
        }
      })
      return filteredData
    }
  },

  methods: {
    next: function() {
      events.$emit('filePreviewAction:next')
    },
    prev: function() {
      events.$emit('filePreviewAction:prev')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@main/sass/_variables';

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 52%;
  display: flex;
  justify-content: center;
  color: $color-light;
  border-radius: 50%;
  text-decoration: none;
  user-select: none;
  filter: drop-shadow(0px 1px 0 $shadow_element_color);
  padding: 10px;
}
.next {
  right: 0;
}
.prev {
  left: 0;
}

@media (prefers-color-scheme: dark) {
  .prev,
  .next {
    color: $light-text;
    filter: drop-shadow(0px 1px 0 rgba(17, 19, 20, 1));
  }
}
</style>
