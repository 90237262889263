<template>
  <div>
    <b-row class="attribute-data-block">
      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.attribute-type')"
            label-for="attribute-type"
            :label-cols="3"
          >
            <b-input-group>
              <div class="attribute-type-block">- {{ getAttributeType() }}</div>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>

      <b-col md="12">
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.settings-options') }}
              </h4>
            </b-col>
            <b-col lg="9"></b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col md="12" v-if="model.type == 'file' || model.type == 'image'">
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="show_file_name"
                  v-model="model.show_file_name"
                  :description="$t('attributes.label.settings-show_file_name')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="show_file_size"
                  v-model="model.show_file_size"
                  :description="$t('attributes.label.settings-show_file_size')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="show_file_extension"
                  v-model="model.show_file_extension"
                  :description="
                    $t('attributes.label.settings-show_file_extension')
                  "
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="allow_zoom"
                  v-model="model.allow_zoom"
                  :description="$t('attributes.label.settings-allow_zoom')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col md="12">
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.settings-display') }}
              </h4>
            </b-col>
            <b-col lg="9"></b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AttributeSettings',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    mode() {
      return this.$store.getters['attribute/mode']
    },
    model() {
      return this.$store.getters['attribute/data']
    },
    attribute() {
      return this.$store.getters['attribute/attribute']
    },
    errors() {
      return this.$store.getters['attribute/errors']
    }
  },
  methods: {
    getAttributeType() {
      let recordType = this.model.type

      if (recordType == 'text') {
        return this.$t('attributes.types.text')
      }

      if (recordType == 'textarea') {
        return this.$t('attributes.types.textarea')
      }

      if (recordType == 'wysiwyg') {
        return this.$t('attributes.types.wysiwyg')
      }

      if (recordType == 'number') {
        return this.$t('attributes.types.number')
      }

      if (recordType == 'email') {
        return this.$t('attributes.types.email')
      }

      if (recordType == 'upload') {
        return this.$t('attributes.types.upload')
      }

      if (recordType == 'image') {
        return this.$t('attributes.types.image')
      }

      if (recordType == 'file') {
        return this.$t('attributes.types.file')
      }

      if (recordType == 'select') {
        return this.$t('attributes.types.select')
      }

      if (recordType == 'toggle') {
        return this.$t('attributes.types.toggle')
      }

      if (recordType == 'checkbox') {
        return this.$t('attributes.types.checkbox')
      }

      if (recordType == 'radio') {
        return this.$t('attributes.types.radio')
      }

      if (recordType == 'service_updated_at') {
        return this.$t('services.types.service_updated_at')
      }

      if (recordType == 'service_online') {
        return this.$t('services.types.service_online')
      }

      return recordType
    }
  }
}
</script>
