<template>
  <div class="attribute-select" :class="cssClass">
    <div class="attribute-icon" :class="icon" v-if="icon"></div>
    <div class="attribute-img" v-if="image">{{ image }}</div>
    <div class="attribute-label">{{ name }}</div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    identifier: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    cssClass: {
      type: String,
      default: null
    }
  },
  computed: {},
  methods: {}
}
</script>
