export default {
  path: 'companies',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'companies',
      component: require('@companies/js/components/CompanyList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'companies_create',
      component: require('@companies/js/components/CompanyCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'companies_edit',
      component: require('@companies/js/components/CompanyEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
