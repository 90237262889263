<template>
  <div>
    <div class="vue-dialog-buttons" slot="footer">
      <button
        type="button"
        class="vue-dialog-button vue-dialog-button-cancel"
        style="flex: 1 1 30%;"
        @click="modalClose"
        v-if="cancelEnabled"
      >
        <i class="fas fa-times"></i> {{ $t('buttons.cancel') }}
      </button>
      <button
        type="submit"
        class="vue-dialog-button vue-dialog-button-save"
        style="flex: 1 1 70%;"
        :disabled="pending"
        v-if="saveEnabled"
      >
        <i class="fas fa-save"></i> {{ $t('buttons.save-and-close') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionFooterGroupModal',
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    pending: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    cancelEnabled: {
      type: Boolean,
      default: true
    },
    saveEnabled: {
      type: Boolean,
      default: true
    },
    savePermission: {
      type: String,
      default: ''
    }
  },
  computed: {
    modal() {
      return this.$store.getters['group/modal']
    },
    mode() {
      return this.$store.getters['group/mode']
    },
    model() {
      return this.$store.getters['group/data']
    },
    errors() {
      return this.$store.getters['group/errors']
    },
    attribute() {
      return this.$store.getters['group/attribute']
    }
  },
  methods: {
    modalClose() {
      this.$eventHub.$emit('group-revert')
      this.$eventHub.$emit('group-modal-close')
      this.$title = this.pageTitle
    },
    continueSave() {
      this.$eventHub.$emit('group-modal-save')
      this.$title = this.pageTitle
    }
  }
}
</script>
