export default {
  path: 'countries',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'countries',
      component: require('@geo/js/components/CountryList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'countries_create',
      component: require('@geo/js/components/CountryCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'countries_edit',
      component: require('@geo/js/components/CountryEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/cities',
      name: 'countries_cities',
      component: require('@geo/js/components/CityList').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/states',
      name: 'countries_states',
      component: require('@geo/js/components/StateList').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
