<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('email-services.title.message-send') }}
            </h3>
            <div>
              <div role="group" class="form-row form-group form-row-info">
                <label class="col-3 col-form-label">
                  {{ trans('email-services.field.recipient') }}:
                </label>
                <div class="col-form-label col-form-label-value">
                  {{ recipient }}
                </div>
              </div>

              <div class="form-group">
                <b-row>
                  <b-col lg="9" offset-lg="3">
                    <c-switch
                      name="is_click_tracking"
                      v-model="model.via_notification"
                      :description="$t('email-services.field.as-notification')"
                    ></c-switch>
                  </b-col>
                </b-row>
              </div>

              <div class="form-group">
                <b-row>
                  <b-col lg="9" offset-lg="3">
                    <c-switch
                      name="is_click_tracking"
                      v-model="model.via_email"
                      :description="$t('email-services.field.as-email')"
                    ></c-switch>
                  </b-col>
                </b-row>
              </div>

              <b-form-group
                :label="trans('email-services.field.message-subject') + ' *'"
                label-for="subject"
                label-cols-sm="3"
                :feedback="feedback('subject')"
              >
                <b-form-input
                  id="subject"
                  name="subject"
                  v-model="model.subject"
                  :state="state('subject')"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                :label="trans('email-services.field.message-content') + ' *'"
                label-for="content"
                label-cols-sm="3"
                :feedback="feedback('content')"
              >
                <p-richtexteditor
                  name="content"
                  v-model="model.content"
                ></p-richtexteditor>
              </b-form-group>

              <div class="form-group">
                <b-row>
                  <b-col lg="9" offset-lg="3">
                    <c-switch
                      name="is_click_tracking"
                      v-model="model.important"
                      :description="$t('email-services.field.important')"
                    ></c-switch>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 24%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 38%"
                  :disabled="pending"
                  @click="sendNotification"
                >
                  {{ trans('core.button.save-and-close') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'Message',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: ''
    },
    type: {
      type: [Number, String],
      default: ''
    },
    module: {
      type: [String],
      default: ''
    },
    moduleModel: {
      type: [String],
      default: ''
    },
    modelPath: {
      type: [String],
      default: ''
    },
    recipient: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: '',
      resourceRoute: 'message_send',
      listPath: '/message/send',
      model: {
        via_notification: true,
        via_email: true,
        important: false,
        subject: '',
        content: ''
      },
      errors: []
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('translations.title.edit-form')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    async sendNotification() {
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('type', this.type)
      formData.append('module', this.module)
      formData.append('module_model', this.moduleModel)
      formData.append('model_path', this.modelPath)
      formData.append('via_notification', this.model.via_notification)
      formData.append('via_email', this.model.via_email)
      formData.append('subject', this.model.subject)
      formData.append('content', this.model.content)
      formData.append('important', this.model.important)
      try {
        let { data } = await axios.post(
          this.$app.route('message_send.store'),
          formData
        )
        //this.$refs.datatable.refresh()
        //this.$modal.hide('subscribers-import-modal')
        this.modalClose()
        this.$app.noty[data.status](data.message)
        //this.$eventHub.$emit('refresh-data')
      } catch (error) {
        this.errors = error.response.data.errors
      }
    }
  }
}
</script>
