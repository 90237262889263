<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('email-services.title.message-list') }}
        </h3>
      </template>
      <!--:actions="actions"-->
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="email_messages.search"
        delete-route="email_messages.destroy"
        action-route="email_messages.batch_action"
        :length-change="true"
        :paging="true"
        :infos="true"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          borderless
          hover
          show-empty
          stacked="sm"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          :busy.sync="isBusy"
          sort-by="created_at"
          :sort-desc="true"
        >
          <template v-slot:cell(recipient_email)="row">
            <span v-text="row.value"></span>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  title: '',
  name: 'MessageList',
  data() {
    return {
      selected: [],
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      fields: [
        {
          key: 'created_at',
          label: this.$t('email-services.field.message-send-at'),
          class: 'text-left',
          sortable: true
        },
        {
          key: 'subject',
          label: this.$t('email-services.field.message-subject'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'open_count',
          label: this.$t('email-services.field.message-open-count'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'click_count',
          label: this.$t('email-services.field.message-click-count'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'recipient_email',
          label: this.$t('email-services.field.message-email'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'status',
          label: this.$t('email-services.field.message-status'),
          class: 'text-center',
          sortable: true
        }
      ]
    }
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('email-services.title.message-list')
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ email_message: id })
    },
    onActiveToggle(id) {
      axios
        .post(this.$app.route('email_messages.enabled', { email_message: id }))
        .then(response => {
          this.$app.noty[response.data.status](response.data.message)
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    }
  }
}
</script>
