<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <div
                    :key="index"
                    v-for="(locale, index) in this.$app.locales"
                    v-if="locale.backend"
                  >
                    <b-form-group
                      :label="$t('validation.attributes.name')"
                      :label-for="`name.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('name.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                          :class="[
                            locale.required ? 'prepend-lang-required' : ''
                          ]"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`name.${index}`"
                          :name="`name.${index}`"
                          v-model="model.name[`${index}`]"
                          :state="state('name.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('name.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                    <b-form-group
                      :label="$t('validation.attributes.title')"
                      :label-for="`title.${index}`"
                      label-cols-sm="3"
                      :feedback="feedback('title.' + index)"
                    >
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title prepend-lang"
                        >
                          <b-input-group-text
                            data-toggle
                            v-b-tooltip.hover
                            :title="$t(locale.name)"
                          >
                            <span>{{ index }}</span>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`title.${index}`"
                          :name="`title.${index}`"
                          v-model="model.title[`${index}`]"
                          :state="state('title.' + index)"
                        ></b-form-input>
                        <b-input-group-append class="input-group-append-title">
                          <b-input-group-text data-toggle>
                            <i class="fab fa-autoprefixer"></i>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-feedback>
                          {{ feedback('title.' + index) }}
                        </b-form-feedback>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
                <b-form-group
                  :label="$t('blocks.labels.comment')"
                  label-for="comment"
                  :label-cols="3"
                  :description="$t('blocks.notes.comment')"
                  :feedback="feedback('comment')"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-comment-alt"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="comment"
                          name="comment"
                          v-model="model.comment"
                          :state="state('comment')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="enabled"
                        v-model="model.enabled"
                        :description="$t('validation.attributes.enabled')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('labels.content')" id="content">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
                class="group-lang group-lang-seo"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.content') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('validation.attributes.content')"
                  :label-for="`content.${index}`"
                  label-cols-sm="3"
                  :feedback="feedback('content.' + index)"
                >
                  <p-richtexteditor
                    name="content"
                    v-model="model.content[`${index}`]"
                  ></p-richtexteditor>
                </b-form-group>
                <b-form-group
                  :label="$t('blocks.labels.content_html')"
                  :label-for="`content_html.${index}`"
                  label-cols-sm="3"
                >
                  <b-form-textarea
                    :id="`content_html.${index}`"
                    :name="`content_html.${index}`"
                    :rows="3"
                    v-model="model.content_html[`${index}`]"
                  ></b-form-textarea>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('blocks.tabs.buttons')" id="buttons">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card
                :key="index"
                v-for="(locale, index) in this.$app.locales"
                v-if="locale.backend"
                class="group-lang group-lang-buttons"
              >
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('blocks.sections.buttons') }} - {{ index }}
                </h3>
                <b-form-group
                  :label="$t('blocks.labels.btn_label')"
                  :label-for="`btn_label.${index}`"
                  :label-cols="3"
                  :feedback="feedback('btn_label.' + index)"
                >
                  <b-row>
                    <b-col md="8">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-tag"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`btn_label.${index}`"
                          :name="`btn_label.${index}`"
                          v-model="model.btn_label[`${index}`]"
                          :state="state('btn_label.' + index)"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  :label="$t('blocks.labels.btn_url')"
                  :label-for="`btn_url.${index}`"
                  :label-cols="3"
                  :feedback="feedback('btn_url.' + index)"
                >
                  <b-row>
                    <b-col md="12">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fas fa-link"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`btn_url.${index}`"
                          :name="`btn_url.${index}`"
                          v-model="model.btn_url[`${index}`]"
                          :state="state('btn_url.' + index)"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
              <b-card class="group-lang group-lang-buttons">
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('blocks.sections.buttons_settings') }}
                </h3>
                <b-form-group
                  :label="$t('blocks.labels.btn_class')"
                  label-for="btn_class"
                  :label-cols="3"
                  :feedback="feedback('btn_class')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-input-group>
                        <b-input-group-prepend
                          class="input-group-prepend-title"
                        >
                          <b-input-group-text data-toggle>
                            <i class="fab fa-css3"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-input
                          id="btn_class"
                          name="btn_class"
                          v-model="model.btn_class"
                          :state="state('btn_class')"
                        ></b-form-input>
                      </b-input-group>
                    </b-col>
                  </b-row>
                </b-form-group>
                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="btn_target_blank"
                        v-model="model.btn_target_blank"
                        :description="$t('blocks.labels.btn_target_blank')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `BlockEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'block',
      resourceRoute: 'blocks',
      listPath: '/blocks',
      model: {
        // Data
        name: {},
        title: {},
        comment: '',
        content: {},
        content_html: {},
        order: 0,
        enabled: false,
        position: 0,
        // Buttons
        btn_class: '',
        btn_target_blank: false,
        btn_label: {},
        btn_url: {},
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('blocks.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('blocks.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
