<template>
  <div class="list-page dashboard-page">
    <!--    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('dashboard.titles.unit') }}
        </h3>
      </template>
    </b-card>-->
    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="12" xl="12">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('auctions.titles.dashboard-auctions') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <b-col md="12" xl="12">
              <!--              <vnodesScreen
                ref="screen"
                :options="vueoptions"
                class="vnode-wrap"
              >
                <vnodesEdge
                  v-for="edge in graph.edges"
                  :data="edge"
                  :nodes="graph.nodes"
                  :key="edge.id"
                  :use-drag="false"
                >
                </vnodesEdge>

                <vnodesNode
                  v-for="node in graph.nodes"
                  :data="node"
                  :key="node.id"
                  :use-drag="false"
                >
                  <div style="text-align: center">
                    <strong>{{ node.title }}</strong>
                  </div>
                </vnodesNode>
              </vnodesScreen>-->

              <div class="mermaid-container">
                <vue-mermaid
                  :nodes="auctionDataVue"
                  type="graph LR"
                  @nodeClick="editNode"
                  :config="config"
                ></vue-mermaid>
              </div>
              <!--<div class="tree-container">
                <vue-tree
                  style="width: 100%; height: 800px;"
                  :dataset="auctionsData"
                  :config="treeConfig"
                  direction="horizontal"
                  link-style="straight"
                  :collapse-enabled="false"
                >
                  <template v-slot:node="{ node, collapsed }">
                    <div class="element-node">
                      <img
                        :src="node.avatar"
                        style="width: 16px; height: 16px; border-raduis: 4px;"
                      />
                      <span style="padding: 4px 0; font-weight: bold;">
                        {{ node.name }} {{ node.value }}
                      </span>
                    </div>
                  </template>
                </vue-tree>
              </div>-->
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col mc="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('auctions.titles.dashboard-auctions') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Auctions pending for verification -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-pending') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingAuctionsCount > 0
                          ? 'd-text-orange'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingAuctionsCount > 0
                          ? 'icon-orange'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-hourglass-half"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions rejected and waiting for corrections  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-revise') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.reviseAuctionsCount > 0
                          ? 'd-text-light-blue'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.reviseAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.reviseAuctionsCount > 0
                          ? 'icon-light-blue'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-edit"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions draft -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-draft') }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block d-text-grey"
                    >
                      {{ this.$store.state.counters.draftAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-grey">
                      <i class="fab fa-firstdraft"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions active  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-active') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.activeAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions suspended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-suspended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.suspendedAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions canceled  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-canceled') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.canceledAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions ongoing  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-ongoing') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.ongoingAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions until  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-until') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.untilAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions ended  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.dashboard-ended') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.endedAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-red">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Auctions all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.all-auctions') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allAuctionsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-gavel"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Total bids  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('auctions.label.total-bids') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allBidsCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-bullseye"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5"> </b-col>
      </b-row>
    </b-card>
    <b-card class="mb-0 card-adj">
      <b-row>
        <b-col md="7" xl="7">
          <b-row class="d-header-row">
            <b-col md="12" xl="12">
              <h3 class="card-title">
                {{ $t('dashboard.title.dashboard-users') }}
              </h3>
            </b-col>
          </b-row>
          <b-row class="d-body-row">
            <!-- Users not verified -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{
                        trans('dashboard.label.users-pending-for-verification')
                      }}
                    </p>
                    <span
                      class="fs-28 text-black font-w600 d-block"
                      :class="
                        this.$store.state.counters.pendingUsersCount > 0
                          ? 'd-text-red'
                          : 'd-text-grey'
                      "
                    >
                      {{ this.$store.state.counters.pendingUsersCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span
                      class="icon-title"
                      :class="
                        this.$store.state.counters.pendingUsersCount > 0
                          ? 'icon-red'
                          : 'icon-grey'
                      "
                    >
                      <i class="fas fa-user-clock"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Users active -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('dashboard.label.active-users') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.activeUsersCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-light-green">
                      <i class="fas fa-user-check"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
            <!-- Users all  -->
            <b-col md="4" xl="4">
              <b-card class="d-card d-card-main">
                <div class="media">
                  <div class="media-body">
                    <p class="text-black fw-medium mb-0">
                      {{ trans('dashboard.label.all-users') }}
                    </p>
                    <span class="fs-28 text-black font-w600 d-block">
                      {{ this.$store.state.counters.allUsersCount }}
                    </span>
                  </div>
                  <div class="align-self-center mini-stat-icon">
                    <span class="icon-title icon-violet">
                      <i class="fas fa-users"></i>
                    </span>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="5" xl="5"> </b-col>
      </b-row>
    </b-card>
    <!--<b-card class="mb-0">
      <b-row>
        <b-col xl="6" v-if="this.$app.user.can('view own posts')">
          <b-card>
            <h3 class="card-title" slot="header">
              Last auctions
            </h3>
            <b-table
              striped
              bordered
              hover
              show-empty
              :fields="post_fields"
              :items="posts"
              :empty-text="$t('labels.no_results')"
            >
              <template slot="title" slot-scope="row">
                <router-link :to="`/posts/${row.item.id}/edit`">
                  {{ row.value }}
                </router-link>
              </template>
              <template slot="status" slot-scope="row">
                <b-badge :variant="row.item.state">{{
                  $t(row.item.status_label)
                }}</b-badge>
              </template>
              <template slot="pinned" slot-scope="row">
                <b-badge :variant="row.value ? 'success' : 'danger'">
                  {{ row.value ? $t('labels.yes') : $t('labels.no') }}
                </b-badge>
              </template>
            </b-table>
            <b-button to="/posts" variant="primary" class="float-right">
              All auctions
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <b-col xl="12" v-if="this.$app.user.can('view own auctions')">
        <b-card>
          <h3 class="card-title" slot="header">
            Auction waiting for verification
          </h3>
          <b-table
            striped
            bordered
            hover
            show-empty
            :fields="post_fields"
            :items="posts"
            :empty-text="$t('labels.no_results')"
          >
            <template slot="title" slot-scope="row">
              <router-link :to="`/posts/${row.item.id}/edit`">
                {{ row.value }}
              </router-link>
            </template>
            <template slot="status" slot-scope="row">
              <b-badge :variant="row.item.state">{{
                $t(row.item.status_label)
              }}</b-badge>
            </template>
            <template slot="pinned" slot-scope="row">
              <b-badge :variant="row.value ? 'success' : 'danger'">
                {{ row.value ? $t('labels.yes') : $t('labels.no') }}
              </b-badge>
            </template>
          </b-table>
          <b-button to="/posts" variant="primary" class="float-right">
            All auctions
          </b-button>
        </b-card>
      </b-col>
      <b-row> </b-row>
    </b-card>-->
  </div>
</template>

<script>
import axios from 'axios'
//import VueTree from '@ssthouse/vue-tree-chart'
import VueMermaid from '@main/js/components/vue-mermaid/vue-mermaid'
//import vnodes from 'vnodes'

/*const vnodesScreen = vnodes.Screen
const vnodesNode = vnodes.Node
const vnodesEdge = vnodes.Edge
const vnodesgraph = vnodes.graph*/

export default {
  title: '',
  name: 'Dashboard',
  components: {
    //VueTree,
    VueMermaid
  },
  data() {
    return {
      vueoptions: {
        fit: false,
        dblClickZoomEnabled: false,
        mouseWheelZoomEnabled: false
      },
      /* auctionsData: {
        name: 'Auctions',
        code: 'auctions',
        value: 20,
        avatar: 'https://cdn-icons-png.flaticon.com/512/2639/2639683.png',
        children: [
          {
            name: 'Creation',
            code: 'init',
            value: 10,
            avatar: 'https://cdn-icons-png.flaticon.com/512/2639/2639683.png',
            children: [
              {
                customID: 3,
                name: 'Draft',
                code: 'draft',
                value: 100,
                avatar:
                  'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
              },
              {
                customID: 2,
                name: 'Pending',
                code: 'pending',
                value: 20,

                avatar:
                  'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
              },
              {
                name: 'Check',
                code: 'check',
                value: 1,
                customID: 4,
                avatar:
                  'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
              }
            ]
          },
          {
            name: 'Kita',
            value: 200,
            avatar: 'https://cdn-icons-png.flaticon.com/512/2639/2639683.png',
            children: [
              {
                name: 'S1',
                value: 230,
                avatar:
                  'https://cdn-icons-png.flaticon.com/512/2639/2639683.png',
                children: [
                  {
                    name: '16',
                    value: 230,
                    avatar:
                      'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
                  },
                  {
                    name: '16',
                    value: 230,
                    avatar:
                      'https://cdn-icons-png.flaticon.com/512/2639/2639683.png',
                    children: [
                      {
                        name: '16',
                        value: 230,
                        avatar:
                          'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
                      },
                      {
                        name: '16',
                        value: 230,
                        avatar:
                          'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
                      },
                      {
                        name: '16',
                        value: 230,
                        avatar:
                          'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
                      }
                    ]
                  },
                  {
                    name: '16',
                    value: 230,
                    avatar:
                      'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
                  }
                ]
              }
            ]
          },
          {
            name: 'Elemrns',
            value: 300,
            avatar: 'https://cdn-icons-png.flaticon.com/512/2639/2639683.png'
          }
        ],
        links: [
          { parent: 1, child: 2 },
          { parent: 3, child: 2 },
          { parent: 4, child: 2 }
        ],
        identifier: 'customID'
      },*/
      treeConfig: {
        nodeWidth: 120,
        nodeHeight: 80,
        levelHeight: 200
      },
      graph: new vnodesgraph(),
      auctionDataVue: [
        {
          id: '1',
          text: 'A',
          link: '---',
          next: ['2'],
          editable: true,
          style: 'fill:#f9f,stroke:#333,stroke-width:4px'
        },
        {
          id: '7',
          text: 'RRRR',
          edgeType: 'circle',
          next: ['1', '3'],
          direction: 'TB'
        },
        { id: '2', text: 'B', edgeType: 'circle', next: ['3'] },
        { id: '3', text: 'C', next: ['4', '6'] },
        { id: '4', text: 'D', link: '-- This is the text ---', next: ['5'] },
        { id: '5', text: 'E' },
        { id: '6', text: 'F' }
      ],
      config: {
        theme: 'neutral'
      }
    }
  },
  beforeMount() {
    this.fetchData()
  },
  created() {
    this.$eventHub.$on('refresh-data', this.refreshData)
    this.graph.createNode({
      id: '1',
      useDrag: false,
      title: 'nodeA',
      width: 250,
      height: 150,
      x: 290,
      y: -90
    })
    this.graph.createNode({
      id: '2',
      useDrag: false,
      x: 300,
      y: 95,
      title: 'Bbita'
    })
    this.graph.createNode({
      id: '3',
      useDrag: false,
      x: -50,
      y: -100,
      title: 'C'
    })
    /*    this.graph.createEdge('1', '2', {
      fromAnchor: 'rect',
      toAnchor: 'rect',
      align: 'bottom-right',
      active: false
    })
    this.graph.createEdge('1', '3', {
      fromAnchor: 'rect',
      toAnchor: 'rect',
      align: 'bottom-left',
      active: false
    })*/
    this.graph.graphNodes()
  },
  mounted() {
    this.$title = this.$t('dashboard.titles.list')
  },
  methods: {
    editNode(nodeId) {
      alert(nodeId)
    },
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ dashboard: id })
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    async fetchData() {
      await this.$store.dispatch('LOAD_COUNTERS')
    }
  }
}
</script>
<style scoped lang="scss">
.tree-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.element-node {
  width: 100px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: white;
  background-color: #535780;
  border-radius: 4px;
}
.vnode-wrap {
  height: 400px;
  border: none;
  padding: 50px 20px 20px 20px;
  .node {
    .outer {
      .content {
        background-color: red;
      }
    }
  }
}

svg .content {
  position: relative;
  border-radius: 7px;
  background-color: blue;
  display: inline-block;
  width: auto;
  height: auto;
  white-space: nowrap;
}

svg .edge {
  stroke-width: 3px;
  stroke: blue;
}
</style>
