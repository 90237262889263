<template>
  <div class="list-page">
    <b-card>
      <template slot="header">
        <h3 class="card-title">
          {{ $t('roles-labels.roles.titles.index') }}
        </h3>
        <div class="card-options" v-if="this.$app.user.can('create roles')">
          <b-button to="/roles/create" variant="success" size="sm">
            <i class="fe fe-plus-circle"></i>
            {{ $t('roles-buttons.roles.create') }}
          </b-button>
        </div>
      </template>
      <b-datatable
        ref="datasource"
        @context-changed="onContextChanged"
        search-route="roles.search"
        delete-route="roles.destroy"
        :length-change="false"
        :paging="false"
        :infos="false"
        :search="true"
        :export-data="false"
      >
        <b-table
          ref="datatable"
          hover
          borderless
          show-empty
          stacked="md"
          no-local-sorting
          :empty-text="$t('labels.datatables.no_results')"
          :empty-filtered-text="$t('labels.datatables.no_matched_results')"
          :fields="fields"
          :items="dataLoadProvider"
          sort-by="order"
          :sort-desc="false"
        >
          <template v-slot:cell(name)="row">
            <router-link
              v-if="row.item.can_edit"
              :to="`/roles/${row.item.id}/edit`"
              v-text="row.value"
            ></router-link>
            <span v-else v-text="row.value"></span>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button
              size="sm"
              variant="primary"
              :to="`/roles/${row.item.id}/edit`"
              v-b-tooltip.hover
              :title="$t('buttons.edit')"
              class="mr-1"
            >
              <i class="fe fe-edit"></i>
            </b-button>
            <b-button
              v-if="
                row.item.can_delete &&
                  row.item.id != 1 &&
                  row.item.id != 2 &&
                  row.item.id != 3 &&
                  row.item.id != 4
              "
              size="sm"
              variant="danger"
              v-b-tooltip.hover
              :title="$t('buttons.delete')"
              @click.stop="onDelete(row.item.id)"
            >
              <i class="fe fe-trash"></i>
            </b-button>
          </template>
        </b-table>
      </b-datatable>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'RoleList',
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: this.$t('validation.attributes.name'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'order',
          label: this.$t('validation.attributes.order'),
          class: 'text-right',
          sortable: true
        },
        {
          key: 'display_name',
          label: this.$t('validation.attributes.display_name'),
          sortable: true
        },
        {
          key: 'description',
          label: this.$t('validation.attributes.description'),
          sortable: true
        },
        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ]
    }
  },
  methods: {
    dataLoadProvider(ctx) {
      return this.$refs.datasource.loadData(ctx.sortBy, ctx.sortDesc)
    },
    onContextChanged() {
      return this.$refs.datatable.refresh()
    },
    onDelete(id) {
      this.$refs.datasource.deleteRow({ role: id })
    }
  }
}
</script>
