<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('email-services.title.template-create') }}
            </h3>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                :label="$t('email-services.field.template-name') + ' *'"
                label-for="name"
                :label-cols="3"
                :feedback="feedback('name')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fab fa-autoprefixer"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="name"
                        name="name"
                        v-model="model.name"
                        :state="state('name')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('name') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button
                    to="/email/templates"
                    exact
                    variant="danger"
                    size="sm"
                  >
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('email-services.title.template-list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'

export default {
  title: '',
  name: 'TemplateCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'email_template',
      resourceRoute: 'email_templates',
      listPath: '/email/templates',
      model: {
        // Data
        name: '',
        // Routes
        routes: {}
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('email-services.title.template-create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    }
  }
}
</script>
