<template>
  <div v-if="isVisible" class="sorting-preview">
    <SortingAndPreviewMenu></SortingAndPreviewMenu>
  </div>
</template>

<script>
import SortingAndPreviewMenu from '@assets/js/components/SortingAndPreviewMenu'
import { events } from '@main/js/bus'

export default {
  name: 'DesktopSortingAndPreview',
  components: { SortingAndPreviewMenu },
  data() {
    return {
      isVisible: false
    }
  },
  mounted() {
    events.$on('sortingAndPreview', state => {
      this.isVisible = state
    })

    events.$on('unClick', () => {
      this.isVisible = false
    })
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.sorting-preview {
  min-width: 250px;
  position: absolute;
  z-index: 99;
  box-shadow: $shadow;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  right: 66px;
  top: 63px;

  &.showed {
    display: block;
  }

  /deep/.menu-option {
    &:hover {
      background: $light_background;

      .text-label {
        color: $theme;
      }

      path,
      /deep/ line,
      /deep/ polyline,
      rect,
      circle,
      polygon {
        stroke: $theme !important;
      }
    }
  }
}
</style>
