export default {
  path: 'send',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    /* list */
    {
      path: 'lists/',
      name: 'send_lists',
      component: require('@email-services/js/components/SendLists/SendLists')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'lists/send',
      name: 'send_lists_send',
      component: require('@email-services/js/components/SendLists/SendLists')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'lists/create',
      name: 'send_lists_create',
      component: require('@email-services/js/components/SendLists/SendListCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'lists/:id/edit',
      name: 'send_lists_edit',
      component: require('@email-services/js/components/SendLists/SendListEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: 'lists/:id/preview',
      name: 'send_lists_preview',
      component: require('@email-services/js/components/SendLists/SendListPreviewForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
    /* list END */
  ]
}
