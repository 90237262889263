<template>
  <div
    class="help has-error error form-text text-danger"
    v-if="formName.errors.has(propName)"
  >
    <div class="error__message">
      <i class="icon-cancel-circle2 mr-2"></i>
      {{ formName.errors.get(propName) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    formName: {
      type: Object,
      default: () => ({})
    },
    propName: {
      type: String,
      default: ''
    }
  }
}
</script>
