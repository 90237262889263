export default {
  path: 'pages',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'pages',
      component: require('@pages/js/components/PageList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'pages_create',
      component: require('@pages/js/components/PageCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'pages_edit',
      component: require('@pages/js/components/PageEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
