const defaultState = {
  fileInfoPanelVisible:
    localStorage.getItem('file_info_visibility') == 'true' || true,
  FilePreviewType: localStorage.getItem('preview_type') || 'list',
  config: undefined,
  sorting: {
    sort: localStorage.getItem('sorting')
      ? JSON.parse(localStorage.getItem('sorting')).sort
      : 'DESC',
    field: localStorage.getItem('sorting')
      ? JSON.parse(localStorage.getItem('sorting')).field
      : 'created_at'
  },
  filesViewWidth: 'minimal-scale'
}

const actions = {
  changePreviewType: ({ commit, state }, preview) => {
    // Get preview type
    let previewType = preview

    // Store preview type to localStorage
    localStorage.setItem('preview_type', previewType)

    // Change preview
    commit('CHANGE_PREVIEW', previewType)
  },
  fileInfoToggle: (context, visibility = undefined) => {
    if (!visibility) {
      if (context.state.fileInfoPanelVisible) {
        context.commit('FILE_INFO_TOGGLE', false)
      } else {
        context.commit('FILE_INFO_TOGGLE', true)
      }
    } else {
      context.commit('FILE_INFO_TOGGLE', visibility)
    }
  }
}

const mutations = {
  INIT(state, data) {
    state.config = data.config
  },
  CHANGE_PREVIEW(state, type) {
    state.FilePreviewType = type
  },
  UPDATE_SORTING(state) {
    state.sorting.field = JSON.parse(localStorage.getItem('sorting')).field
    state.sorting.sort = JSON.parse(localStorage.getItem('sorting')).sort
  },
  FILE_INFO_TOGGLE(state, isVisible) {
    state.fileInfoPanelVisible = isVisible

    localStorage.setItem('file_info_visibility', isVisible)
  }
}

const getters = {
  filesViewWidth: state => state.filesViewWidth,
  fileInfoVisible: state => state.fileInfoPanelVisible,
  FilePreviewType: state => state.FilePreviewType,
  api: state => state.config.api,
  config: state => state.config,
  sorting: state => {
    return {
      sorting: state.sorting,
      URI: '?sort=' + state.sorting.field + '&direction=' + state.sorting.sort
    }
  }
}

export default {
  state: defaultState,
  getters,
  actions,
  mutations
}
