<template>
  <div @click="unClick">
    <!--System alerts-->
    <Alert></Alert>

    <!--Rename folder or file item-->
    <RenameItem></RenameItem>

    <!--Confirm Popup-->
    <Confirm></Confirm>

    <!--  Drag & Drop UI -->
    <!--<DragUI></DragUI>-->

    <DesktopSortingAndPreview></DesktopSortingAndPreview>

    <DesktopToolbar></DesktopToolbar>

    <div
      @contextmenu.prevent.capture="contextMenu($event, undefined)"
      id="files-view"
    >
      <ContextMenu></ContextMenu>

      <FileBrowser></FileBrowser>
    </div>
  </div>
</template>

<script>
//import DragUI from '@assets/js/components/DragUI'
import Alert from '@assets/js/components/Alert'
import DesktopSortingAndPreview from '@assets/js/components/DesktopSortingAndPreview'
import DesktopToolbar from '@assets/js/components/DesktopToolbar'
import FileBrowser from '@assets/js/components/FileBrowser'
import ContextMenu from '@assets/js/components/ContextMenu'
import FileFullPreview from '@assets/js/components/FileFullPreview'
import RenameItem from '@assets/js/components/Actions/RenameItem'
import Confirm from '@assets/js/components/Popup/Confirm'
import { mapGetters } from 'vuex'
import { events } from '@main/js/bus'

export default {
  name: 'FilesView',
  components: {
    //DragUI,
    Alert,
    DesktopSortingAndPreview,
    DesktopToolbar,
    FileBrowser,
    RenameItem,
    Confirm,
    ContextMenu
  },
  props: {
    allowFiles: {
      type: Boolean,
      default: false
    },
    allowImages: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['config'])
  },
  mounted() {
    this.$checkOS()
  },
  created() {
    this.$store.commit('SET_UPLOAD_RESTRICTIONS', {
      allowFiles: this.allowFiles,
      allowImages: this.allowImages,
      single: this.single
    })
  },
  methods: {
    contextMenu(event, item) {
      events.$emit('contextMenu:show', event, item)
    },
    unClick() {
      events.$emit('unClick')
    }
  }
}
</script>

<style lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

[v-cloak],
[v-cloak] > * {
  display: none;
}

#files-view {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 320px;
  min-height: 267px;
  overflow-x: hidden;
  padding-left: 15px;
  padding-right: 15px;
  overflow-y: hidden;
}

@media only screen and (max-width: 690px) {
  #files-view {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
