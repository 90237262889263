import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faHome,
  faDollarSign,
  faFileInvoiceDollar,
  faCogs,
  faProjectDiagram,
  faTh,
  faDiceD6,
  faPalette,
  faBirthdayCake,
  faUserTag,
  faUserLock,
  faUsers,
  faUserFriends,
  faUserTie,
  faNewspaper,
  faFileAlt,
  faLongArrowAltRight,
  faIndustry,
  faEuroSign,
  faBullhorn,
  faPercent,
  faSpa,
  faObjectGroup,
  faBoxes,
  faDollyFlatbed,
  faFlag,
  faCity,
  faStoreAlt,
  faCloudUploadAlt,
  faArrowsAlt,
  faAt,
  faPhone,
  faMobileAlt,
  faLink,
  faMapMarkedAlt,
  faList,
  faThList,
  faHourglassEnd,
  faBuilding,
  faReply,
  faWindowMaximize,
  faCode,
  faCommentAlt,
  faTag,
  faLanguage,
  faHotel,
  faLandmark,
  faTty,
  faCarAlt,
  faPeopleCarry,
  faTasks,
  faAward,
  faFileInvoice,
  faChevronDown,
  faChevronUp,
  faSearch,
  faCertificate,
  faGavel,
  faTimes,
  faArrowsAltV,
  faStream,
  faEdit,
  faTrashAlt,
  faCloudDownloadAlt,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faBan,
  faStop,
  faSync,
  faGlobe,
  faEnvelopeOpenText,
  faUserCheck,
  faUserClock,
  faChevronRight,
  faBullseye,
  faHourglassHalf,
  faCircleNotch,
  faPlus,
  faBars,
  faBriefcase,
  faGripHorizontal,
  faPen,
  faAngleDoubleRight,
  faSave,
  faExclamationTriangle,
  faKey,
  faAddressCard,
  faCalendar,
  faTicketAlt,
  faSpellCheck,
  faSyncAlt,
  faFolder,
  faAngleRight,
  faFilter,
  faFile,
  faGripVertical,
  faAd,
  faPrint,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileCsv,
  faFileArchive,
  faSlidersH,
  faPencilAlt,
  faEye,
  faEnvelope,
  faMailBulk,
  faPaperPlane,
  faBell,
  faColumns,
  faInfo,
  faMapMarkerAlt,
  faWeightHanging,
  faChevronCircleDown,
  faChevronCircleUp
} from '@fortawesome/free-solid-svg-icons'

import { faCircle } from '@fortawesome/free-regular-svg-icons'

import {
  faFacebook,
  faTwitter,
  faGoogle,
  faLinkedin,
  faGithub,
  faBitbucket,
  faPinterest,
  faBimobject,
  faAutoprefixer,
  faProductHunt,
  faBuffer,
  faCss3,
  faFirstdraft
} from '@fortawesome/free-brands-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faHome,
  faDollarSign,
  faFileInvoiceDollar,
  faCogs,
  faProjectDiagram,
  faFacebook,
  faTwitter,
  faGoogle,
  faLinkedin,
  faGithub,
  faBitbucket,
  faPinterest,
  faDiceD6,
  faTh,
  faPalette,
  faBirthdayCake,
  faUserTag,
  faUserLock,
  faUsers,
  faUserFriends,
  faUserTie,
  faNewspaper,
  faFileAlt,
  faLongArrowAltRight,
  faIndustry,
  faEuroSign,
  faBullhorn,
  faPercent,
  faBimobject,
  faSpa,
  faObjectGroup,
  faBoxes,
  faDollyFlatbed,
  faFlag,
  faCity,
  faStoreAlt,
  faCloudUploadAlt,
  faArrowsAlt,
  faAt,
  faPhone,
  faMobileAlt,
  faAutoprefixer,
  faProductHunt,
  faLink,
  faMapMarkedAlt,
  faList,
  faBuffer,
  faThList,
  faHourglassEnd,
  faBuilding,
  faReply,
  faWindowMaximize,
  faCode,
  faCommentAlt,
  faCss3,
  faTag,
  faLanguage,
  faHotel,
  faLandmark,
  faTty,
  faCarAlt,
  faPeopleCarry,
  faTasks,
  faAward,
  faFileInvoice,
  faChevronDown,
  faChevronUp,
  faCircle,
  faSearch,
  faCertificate,
  faGavel,
  faTimes,
  faArrowsAltV,
  faStream,
  faEdit,
  faTrashAlt,
  faCloudDownloadAlt,
  faCheck,
  faCheckCircle,
  faInfoCircle,
  faBan,
  faStop,
  faSync,
  faGlobe,
  faEnvelopeOpenText,
  faUserCheck,
  faUserClock,
  faChevronRight,
  faFirstdraft,
  faBullseye,
  faHourglassHalf,
  faCircleNotch,
  faPlus,
  faBars,
  faBriefcase,
  faGripHorizontal,
  faPen,
  faAngleDoubleRight,
  faSave,
  faExclamationTriangle,
  faKey,
  faAddressCard,
  faCalendar,
  faTicketAlt,
  faSpellCheck,
  faSyncAlt,
  faFolder,
  faAngleRight,
  faFilter,
  faFile,
  faGripVertical,
  faAd,
  faPrint,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileCsv,
  faFileArchive,
  faSlidersH,
  faPencilAlt,
  faEye,
  faEnvelope,
  faMailBulk,
  faPaperPlane,
  faBell,
  faColumns,
  faInfo,
  faMapMarkerAlt,
  faWeightHanging,
  faChevronCircleDown,
  faChevronCircleUp
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
