<template>
  <div
    v-if="showFullPreview"
    class="file-full-preview-wrapper"
    id="fileFullPreview"
    ref="filePreview"
    tabindex="-1"
    @click="closeContextMenu"
    @keydown.esc=";(showFullPreview = false), hideContextMenu()"
    @keydown.right="next"
    @keydown.left="prev"
  >
    <FilePreviewNavigationPanel></FilePreviewNavigationPanel>
    <MediaFullPreview></MediaFullPreview>
    <FilePreviewActions></FilePreviewActions>
  </div>
</template>

<script>
import { events } from '@main/js/bus'
import { mapGetters } from 'vuex'

import MediaFullPreview from '@assets/js/components/MediaFullPreview'
import FilePreviewActions from '@assets/js/components/FilePreviewActions'
import FilePreviewNavigationPanel from '@assets/js/components/FilePreviewNavigationPanel'

export default {
  name: 'FileFullPreview',
  components: {
    MediaFullPreview,
    FilePreviewNavigationPanel,
    FilePreviewActions
  },
  computed: {
    ...mapGetters(['fileInfoDetail', 'data'])
  },
  data() {
    return {
      showFullPreview: false
    }
  },

  updated() {
    //Focus file preview for key binding
    if (this.showFullPreview) {
      this.$refs.filePreview.focus()
    }
  },
  mounted() {
    events.$on('fileFullPreview:show', () => {
      this.showFullPreview = true
    })
    events.$on('fileFullPreview:hide', () => {
      this.showFullPreview = false
      events.$emit('hide:mobile-navigation')
    })
  },
  methods: {
    closeContextMenu(event) {
      if (
        (event.target.parentElement.id || event.target.id) ===
        'fast-preview-menu'
      ) {
        return
      } else {
        events.$emit('showContextMenuPreview:hide')
      }
    },
    next: function() {
      events.$emit('filePreviewAction:next')
    },
    prev: function() {
      events.$emit('filePreviewAction:prev')
    },
    hideContextMenu() {
      events.$emit('showContextMenuPreview:hide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@main/sass/_variables';

.file-full-preview-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.14);
}
</style>
