<template>
  <div>
    <div class="vue-dialog-buttons" slot="footer">
      <button
        type="button"
        class="vue-dialog-button vue-dialog-button-cancel"
        style="flex: 1 1 24%;"
        @click="modalClose"
        v-if="cancelEnabled"
      >
        {{ $t('buttons.cancel') }}
      </button>
      <button
        type="submit"
        class="vue-dialog-button vue-dialog-button-save"
        style="flex: 1 1 38%;"
        :disabled="pending"
        v-if="saveEnabled"
      >
        {{ $t('buttons.save-and-close') }}
      </button>
      <button
        type="submit"
        class="vue-dialog-button vue-dialog-button-continue"
        style="flex: 1 1 38%;"
        :disabled="pending"
        @click="continueEditing()"
        v-if="saveContinueEnabled"
      >
        {{ $t('buttons.save-and-continue') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionFooter',
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    pending: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    cancelEnabled: {
      type: Boolean,
      default: true
    },
    saveEnabled: {
      type: Boolean,
      default: true
    },
    saveContinueEnabled: {
      type: Boolean,
      default: true
    },
    savePermission: {
      type: String,
      default: ''
    }
  },
  methods: {
    modalClose() {
      this.$eventHub.$emit('modal-close')
      this.$title = this.pageTitle
    },
    continueSave() {
      this.$eventHub.$emit('continue-save-modal')
      this.$title = this.pageTitle
    },
    continueEditing() {
      this.$eventHub.$emit('continue-editing-modal')
    }
  }
}
</script>
