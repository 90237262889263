<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form>
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('translations.title.edit') }}
            </h3>

            <b-form-group class="card-divider">
              <b-row>
                <b-col lg="3">
                  <h4 class="card-divider-title">EN</h4>
                </b-col>
                <b-col lg="9">
                  <h4 class="card-divider-title a-left">
                    {{ trans('translations.label.module') }}: {{ module }} |
                    <template v-if="area == 1">{{
                      trans('translations.label.backend')
                    }}</template>
                    <template v-else>{{
                      trans('translations.label.frontend')
                    }}</template>
                  </h4>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              :label="trans('translations.label.original_translation')"
              label-for="value"
              label-cols-sm="3"
            >
              <div class="data-identifier-block-trans">
                {{ origin }}
              </div>
            </b-form-group>

            <b-form-group class="card-divider">
              <b-row>
                <b-col lg="3">
                  <h4 class="card-divider-title">
                    {{ locale }}
                  </h4>
                </b-col>
                <b-col lg="9"></b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              :label="trans('translations.label.value')"
              label-for="saved"
              label-cols-sm="3"
            >
              <b-form-textarea
                id="value"
                name="value"
                :rows="5"
                v-model="saved"
              ></b-form-textarea>
            </b-form-group>

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 24%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 38%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save-and-close') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'TranslationForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    },
    area: {
      type: Number,
      default: 0
    },
    locale: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      default: ''
    },
    tkey: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    saved: {
      type: [String, Number],
      default: ''
    },
    origin: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'translation',
      resourceRoute: 'translations',
      listPath: '/translations',
      model: {
        value: ''
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('translations.title.edit')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    save() {
      // Setup data.
      let data = {}
      data.id = this.id
      data.value = this.value
      data.saved_value = this.saved

      axios
        .patch(
          this.$app.route('translations.update', { translation: this.id }),
          data
        )
        .then(response => {
          this.refreshData()
          this.$modal.hide('translation-form')
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.$app.error(error)
        })
    }
  }
}
</script>
