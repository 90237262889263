<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.template-name') + ' *'"
                    label-for="name"
                    :label-cols="3"
                    :feedback="feedback('name')"
                  >
                    <b-row>
                      <b-col md="8">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="name"
                            name="name"
                            v-model="model.name"
                            :state="state('name')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('name') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.template-code') + ' *'"
                    label-for="content"
                    label-cols-sm="3"
                  >
                    <b-form-textarea
                      id="content"
                      name="content"
                      :rows="20"
                      v-model="model.content"
                      :state="state('content')"
                    ></b-form-textarea>
                    <b-form-feedback>
                      {{ feedback('content') }}
                    </b-form-feedback>
                  </b-form-group>
                  <!--<vuep :v-model="model.content"></vuep>-->
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `TemplateEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'email_template',
      resourceRoute: 'email_templates',
      listPath: '/email/templates',
      model: {
        id: '',
        // Data
        name: '',
        content: '',
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('email-services.title.template-edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('email-services.title.template-unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
