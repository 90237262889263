<template>
  <div>
    <b-row class="attribute-data-block">
      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.attribute-type')"
            label-for="attribute-type"
            :label-cols="3"
          >
            <b-input-group>
              <div class="attribute-type-block">- {{ getAttributeType() }}</div>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>

      <b-col
        md="12"
        v-if="checkModelTypeForRequired() && model.kind == 'form_element'"
      >
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.required') }}
              </h4>
            </b-col>
            <b-col lg="9"></b-col>
          </b-row>
        </b-form-group>
      </b-col>

      <b-col
        md="12"
        v-if="checkModelTypeForRequired() && model.kind == 'form_element'"
      >
        <div class="multi-lang multi-lang-open">
          <div class="form-group">
            <b-row>
              <b-col lg="9" offset-lg="3">
                <c-switch
                  name="validation_required"
                  v-model="model.validation.required"
                  :description="$t('attributes.label.validation-required')"
                ></c-switch>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.label.validation-required-msg')"
              :label-for="`note.${index}`"
              :label-cols="3"
              :description="$t('attributes.note.validation-required-msg')"
            >
              <b-input-group>
                <b-form-input
                  :id="`note.${index}`"
                  :name="`note.${index}`"
                  v-model="model.validation.requiredMsg[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_note_no">
                  {{ $t('attributes.alert.note-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col
        md="12"
        v-if="checkModelTypeForMinMax() && model.kind == 'form_element'"
      >
        <b-form-group class="card-divider">
          <b-row>
            <b-col lg="3">
              <h4 class="card-divider-title">
                {{ trans('attributes.section.min-max') }}
              </h4>
            </b-col>
            <b-col lg="9"></b-col>
          </b-row>
        </b-form-group>
      </b-col>
      <b-col
        md="12"
        v-if="checkModelTypeForMinMax() && model.kind == 'form_element'"
      >
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.min-value')"
            label-for="default_min_max"
            label-cols-sm="3"
          >
            <div class="data-min-max">
              <div class="row">
                <div class="col-2">
                  <b-form-input
                    id="default_min"
                    name="default_min"
                    v-model="model.validation.min"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>

        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.label.min-value-msg')"
              :label-for="`note.${index}`"
              :label-cols="3"
              :description="$t('attributes.note.min-value-msg')"
            >
              <b-input-group>
                <b-form-input
                  :id="`minMsg.${index}`"
                  :name="`minMsg.${index}`"
                  v-model="model.validation.minMsg[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_note_no">
                  {{ $t('attributes.alert.note-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.max-value')"
            label-for="default_min_max"
            label-cols-sm="3"
          >
            <div class="data-min-max">
              <div class="row">
                <div class="col-2">
                  <b-form-input
                    id="default_max"
                    name="default_max"
                    v-model="model.validation.max"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </b-form-group>
        </div>
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.label.max-value-msg')"
              :label-for="`note.${index}`"
              :label-cols="3"
              :description="$t('attributes.note.max-value-msg')"
            >
              <b-input-group>
                <b-form-input
                  :id="`maxMsg.${index}`"
                  :name="`maxMsg.${index}`"
                  v-model="model.validation.maxMsg[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <!--                <div class="invalid-feedback" v-if="errors.error_note_no">
                  {{ $t('attributes.alert.note-required') }}
                </div>-->
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'AttributeValidation',
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {
    mode() {
      return this.$store.getters['attribute/mode']
    },
    model() {
      return this.$store.getters['attribute/data']
    },
    attribute() {
      return this.$store.getters['attribute/attribute']
    },
    errors() {
      return this.$store.getters['attribute/errors']
    }
  },
  methods: {
    checkModelTypeForRequired() {
      if (this.model.type) {
        if (this.model.type == 'text') {
          return true
        }
        if (this.model.type == 'textarea') {
          return true
        }
        if (this.model.type == 'wysiwyg') {
          return true
        }
        if (this.model.type == 'number') {
          return true
        }
        if (this.model.type == 'select') {
          return true
        }
        if (this.model.type == 'toggle') {
          return true
        }
        if (this.model.type == 'radio') {
          return true
        }
        if (this.model.type == 'checbox') {
          return true
        }
        if (this.model.type == 'email') {
          return true
        }
      }
      return false
    },
    checkModelTypeForMinMax() {
      if (this.model.type) {
        if (this.model.type == 'text') {
          return true
        }
        if (this.model.type == 'textarea') {
          return true
        }
        if (this.model.type == 'wysiwyg') {
          return false
        }
        if (this.model.type == 'number') {
          return true
        }
      }
      return false
    },
    getAttributeType() {
      let recordType = this.model.type

      if (recordType == 'text') {
        return this.$t('attributes.types.text')
      }

      if (recordType == 'textarea') {
        return this.$t('attributes.types.textarea')
      }

      if (recordType == 'wysiwyg') {
        return this.$t('attributes.types.wysiwyg')
      }

      if (recordType == 'number') {
        return this.$t('attributes.types.number')
      }

      if (recordType == 'email') {
        return this.$t('attributes.types.email')
      }

      if (recordType == 'upload') {
        return this.$t('attributes.types.upload')
      }

      if (recordType == 'select') {
        return this.$t('attributes.types.select')
      }

      if (recordType == 'toggle') {
        return this.$t('attributes.types.toggle')
      }

      if (recordType == 'checkbox') {
        return this.$t('attributes.types.checkbox')
      }

      if (recordType == 'radio') {
        return this.$t('attributes.types.radio')
      }

      if (recordType == 'service_updated_at') {
        return this.$t('services.types.service_updated_at')
      }

      if (recordType == 'service_online') {
        return this.$t('services.types.service_online')
      }

      return recordType
    }
  }
}
</script>
