<template>
  <label label="file" class="button file-input">
    <i class="fas fa-cloud-upload-alt"></i>
    <input
      @change="emmitFiles"
      v-show="false"
      id="file"
      type="file"
      name="files[]"
      :accept="acceptTypes()"
      multiple
    />
  </label>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ToolbarButtonUpload',
  props: {
    action: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters(['allowFiles', 'allowImages', 'single'])
  },
  methods: {
    emmitFiles(e) {
      this.$uploadFiles(e.target.files)
    },
    acceptTypes() {
      if (this.allowFiles) {
        return '*'
      }
      return 'image/*'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.button {
  height: 42px;
  width: 42px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  border: none;

  .svg-inline--fa {
    font-size: 24px;
    color: #6f73ad;
  }

  &:hover {
    background: $light_background;

    path,
    line,
    polyline,
    rect,
    circle {
      @include transition(150ms);
      stroke: $theme;
    }
  }
}
</style>
