<template>
  <div>
    <b-row class="attribute-data-block">
      <!--      <b-col md="12">
        Duomenys modal:{{ modal }} mode: {{ mode }} model:
        {{ model }} attribute: {{ attribute }}
      </b-col>-->

      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.attribute-type')"
            label-for="attribute-type"
            :label-cols="3"
          >
            <b-input-group>
              <div class="attribute-type-block">- {{ getAttributeType() }}</div>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <!-- Attribute NAME -->
      <b-col md="12" v-if="checkModelTypeGeneral()">
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('validation.attributes.name') + ' *'"
              :label-for="`name.${index}`"
              :label-cols="3"
            >
              <b-input-group>
                <b-input-group-prepend
                  class="input-group-prepend-title prepend-lang"
                  :class="[locale.required ? 'prepend-lang-required' : '']"
                >
                  <b-input-group-text
                    data-toggle
                    v-b-tooltip.hover
                    :title="$t(locale.name)"
                  >
                    <span>{{ index }}</span>
                  </b-input-group-text>
                </b-input-group-prepend>
                <b-form-input
                  :id="`name.${index}`"
                  :name="`name.${index}`"
                  v-model="model.name[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_name_no">
                  {{ $t('attributes.alert.name-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <!-- Attribute TEXT value  -->
      <b-col
        md="12"
        v-if="
          model.type && model.type === 'text' && model.kind !== 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.field.value')"
              :label-for="`value.${index}`"
              :label-cols="3"
            >
              <b-input-group>
                <b-form-input
                  :id="`value.${index}`"
                  :name="`value.${index}`"
                  v-model="model.valueLang[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_value_no">
                  {{ $t('attributes.alert.name-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <!-- Attribute TEXTAREA value  -->
      <b-col
        md="12"
        v-if="
          model.type &&
            model.type === 'textarea' &&
            model.kind !== 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.field.value')"
              :label-for="`value.${index}`"
              :label-cols="3"
            >
              <b-input-group>
                <b-form-textarea
                  :id="`value.${index}`"
                  :name="`value.${index}`"
                  v-model="model.valueLang[`${index}`]"
                  :rows="10"
                ></b-form-textarea>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_value_no">
                  {{ $t('attributes.alert.name-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <!-- Attribute WYISWYG value  -->
      <b-col
        md="12"
        v-if="
          model.type &&
            model.type === 'wysiwyg' &&
            model.kind !== 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.field.value')"
              :label-for="`value.${index}`"
              :label-cols="3"
            >
              <b-input-group>
                <p-richtexteditor
                  :id="`value.${index}`"
                  :name="`value.${index}`"
                  v-model="model.valueLang[`${index}`]"
                ></p-richtexteditor>
                <div class="invalid-feedback" v-if="errors.error_value_no">
                  {{ $t('attributes.alert.name-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <!-- Attribute NUMERIC value  -->
      <b-col
        md="12"
        v-if="
          model.type && model.type === 'number' && model.kind !== 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div>
            <b-form-group
              :label="$t('attributes.field.value')"
              label-for="valueNumeric"
              :label-cols="3"
            >
              <b-input-group>
                <b-form-input
                  id="valueNumeric"
                  name="valueNumeric"
                  type="number"
                  v-model="model.valueNumeric"
                ></b-form-input>
                <div class="invalid-feedback" v-if="errors.error_value_no">
                  {{ $t('attributes.alert.name-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>

      <!-- Attribute file manager  -->
      <b-col
        md="12"
        v-if="
          model.type && model.type === 'file' && model.kind !== 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <FileView
            :allow-files="true"
            :allow-images="false"
            :single="false"
          ></FileView>
        </div>
      </b-col>

      <!-- Attribute image manager  -->
      <b-col
        md="12"
        v-if="
          model.type && model.type === 'image' && model.kind !== 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <FileView
            :allow-files="false"
            :allow-images="true"
            :single="false"
          ></FileView>
        </div>
      </b-col>

      <!-- Note (form element) -->

      <b-col
        md="12"
        v-if="
          model.type && checkModelTypeForNote() && model.kind == 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.labels.note')"
              :label-for="`note.${index}`"
              :label-cols="3"
              :description="$t('attributes.notes.notes')"
            >
              <b-input-group>
                <b-form-input
                  :id="`note.${index}`"
                  :name="`note.${index}`"
                  v-model="model.note[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_note_no">
                  {{ $t('attributes.alert.note-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>

      <!-- Placeholder (form element) -->

      <b-col
        md="12"
        v-if="
          model.type &&
            checkModelTypeForPlaceholder() &&
            model.kind == 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.labels.placeholder')"
              :label-for="`placeholder.${index}`"
              :label-cols="3"
            >
              <b-input-group>
                <b-form-input
                  :id="`placeholder.${index}`"
                  :name="`placeholder.${index}`"
                  v-model="model.placeholder[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div
                  class="invalid-feedback"
                  v-if="errors.error_placeholder_no"
                >
                  {{ $t('attributes.alert.placeholder-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>

      <!-- Tooltip (form element) -->

      <b-col
        md="12"
        v-if="
          model.type &&
            checkModelTypeForTooltip() &&
            model.kind == 'form_element'
        "
      >
        <div class="multi-lang multi-lang-open">
          <div
            :key="index"
            v-for="(locale, index) in this.$app.locales"
            v-if="locale.backend"
          >
            <b-form-group
              :label="$t('attributes.labels.tooltip')"
              :label-for="`tooltip.${index}`"
              :label-cols="3"
            >
              <b-input-group>
                <b-form-input
                  :id="`tooltip.${index}`"
                  :name="`tooltip.${index}`"
                  v-model="model.tooltip[`${index}`]"
                ></b-form-input>
                <b-input-group-append class="input-group-append-title">
                  <b-input-group-text data-toggle>
                    <i class="fab fa-autoprefixer"></i>
                  </b-input-group-text>
                </b-input-group-append>
                <div class="invalid-feedback" v-if="errors.error_tooltip_no">
                  {{ $t('attributes.alert.tooltip-required') }}
                </div>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import FileView from '@assets/js/views/FileView'
export default {
  name: 'AttributeData',
  components: {
    FileView
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    modal() {
      return this.$store.getters['attribute/modal']
    },
    mode() {
      return this.$store.getters['attribute/mode']
    },
    model() {
      return this.$store.getters['attribute/data']
    },
    attribute() {
      return this.$store.getters['attribute/attribute']
    },
    errors() {
      return this.$store.getters['attribute/errors']
    }
  },
  methods: {
    checkModelTypeForNote() {
      if (this.model.type) {
        if (this.model.type == 'text') {
          return true
        }
        if (this.model.type == 'textarea') {
          return true
        }
        if (this.model.type == 'wysiwyg') {
          return true
        }
        if (this.model.type == 'number') {
          return true
        }
        if (this.model.type == 'select') {
          return true
        }
        if (this.model.type == 'email') {
          return true
        }
      }
      return false
    },
    checkModelTypeForPlaceholder() {
      if (this.model.type) {
        if (this.model.type == 'text') {
          return true
        }
        if (this.model.type == 'textarea') {
          return true
        }
        if (this.model.type == 'wysiwyg') {
          return false
        }
        if (this.model.type == 'number') {
          return true
        }
        if (this.model.type == 'select') {
          return true
        }
        if (this.model.type == 'email') {
          return true
        }
      }
      return false
    },
    checkModelTypeForTooltip() {
      if (this.model.type) {
        if (this.model.type == 'text') {
          return true
        }
        if (this.model.type == 'textarea') {
          return true
        }
        if (this.model.type == 'wysiwyg') {
          return true
        }
        if (this.model.type == 'number') {
          return true
        }
        if (this.model.type == 'select') {
          return true
        }

        if (this.model.type == 'email') {
          return true
        }

        if (this.model.type == 'radio') {
          return true
        }

        if (this.model.type == 'checkbox') {
          return true
        }
      }
      return false
    },
    checkModelTypeGeneral() {
      if (this.model.type) {
        if (this.model.type == 'text') {
          return true
        }

        if (this.model.type == 'textarea') {
          return true
        }

        if (this.model.type == 'wysiwyg') {
          return true
        }

        if (this.model.type == 'number') {
          return true
        }

        if (this.model.type == 'select') {
          return true
        }

        if (this.model.type == 'email') {
          return true
        }

        if (this.model.type == 'switch') {
          return true
        }

        if (this.model.type == 'toggle') {
          return true
        }

        if (this.model.type == 'url') {
          return true
        }

        if (this.model.type == 'upload') {
          return true
        }

        if (this.model.type == 'radio') {
          return true
        }

        if (this.model.type == 'checkbox') {
          return true
        }

        if (this.model.type == 'image') {
          return true
        }

        if (this.model.type == 'file') {
          return true
        }

        return false
      }

      return false
    },
    getAttributeType() {
      let recordType = this.model.type

      if (recordType == 'text') {
        return this.$t('attributes.types.text')
      }

      if (recordType == 'textarea') {
        return this.$t('attributes.types.textarea')
      }

      if (recordType == 'wysiwyg') {
        return this.$t('attributes.types.wysiwyg')
      }

      if (recordType == 'number') {
        return this.$t('attributes.types.number')
      }

      if (recordType == 'url') {
        return this.$t('attributes.types.url')
      }

      if (recordType == 'radio') {
        return this.$t('attributes.types.radio')
      }

      if (recordType == 'checkbox') {
        return this.$t('attributes.types.checkbox')
      }

      if (recordType == 'faq') {
        return this.$t('attributes.types.faq')
      }

      if (recordType == 'email') {
        return this.$t('attributes.types.email')
      }

      if (recordType == 'upload') {
        return this.$t('attributes.types.upload')
      }

      if (recordType == 'file') {
        return this.$t('attributes.types.file')
      }

      if (recordType == 'image') {
        return this.$t('attributes.types.image')
      }

      if (recordType == 'select') {
        return this.$t('attributes.types.select')
      }

      if (recordType == 'toggle') {
        return this.$t('attributes.types.toggle')
      }

      if (recordType == 'service_updated_at') {
        return this.$t('services.types.service_updated_at')
      }

      if (recordType == 'service_online') {
        return this.$t('services.types.service_online')
      }

      return recordType
    }
  }
}
</script>
