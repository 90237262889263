export default {
  path: 'languages',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'languages',
      component: require('@languages/js/components/LanguageList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'languages_create',
      component: require('@languages/js/components/LanguageCreateForm').default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'languages_edit',
      component: require('@languages/js/components/LanguageEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
