<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.send-list-name') + ' *'"
                    label-for="name"
                    :label-cols="3"
                    :feedback="feedback('name')"
                    :description="
                      $t('email-services.note.send-list-internal-use')
                    "
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="name"
                            name="name"
                            v-model="model.name"
                            :state="state('name')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('name') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="is_click_tracking"
                        v-model="model.as_emails"
                        :description="$t('email-services.field.as-emails')"
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>

                <div class="form-group">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="is_click_tracking"
                        v-model="model.as_notifications"
                        :description="
                          $t('email-services.field.as-notifications')
                        "
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>

                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.send-list-subject') + ' *'"
                    label-for="subject"
                    :label-cols="3"
                    :feedback="feedback('subject')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="subject"
                            name="subject"
                            v-model="model.subject"
                            :state="state('subject')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('subject') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open" v-if="model.as_emails">
                  <b-form-group
                    :label="
                      $t('email-services.field.send-list-from-name') + ' *'
                    "
                    label-for="from_name"
                    :label-cols="3"
                    :feedback="feedback('from_name')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="from_name"
                            name="from_name"
                            v-model="model.from_name"
                            :state="state('from_name')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('from_name') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open" v-if="model.as_emails">
                  <b-form-group
                    :label="
                      $t('email-services.field.send-list-from-email') + ' *'
                    "
                    label-for="from_email"
                    :label-cols="3"
                    :feedback="feedback('from_email')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-at"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="from_email"
                            name="from_email"
                            v-model="model.from_email"
                            :state="state('from_email')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('from_email') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open" v-if="model.as_emails">
                  <b-form-group
                    :label="$t('email-services.field.template-id')"
                    label-for="template_id"
                    horizontal
                    :label-cols="3"
                    :feedback="feedback('template_id')"
                    :state="state('template_id')"
                  >
                    <b-form-select
                      id="template_id"
                      name="template_id"
                      v-model="model.template_id"
                      :options="templates"
                    >
                      <template slot="first">
                        <option :value="null" selected>
                          - None -
                        </option>
                      </template>
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('template_id') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>

                <div class="multi-lang multi-lang-open" v-if="model.as_emails">
                  <b-form-group
                    :label="$t('email-services.field.email-service') + ' *'"
                    label-for="email_service_id"
                    horizontal
                    :label-cols="3"
                    :feedback="feedback('email_service_id')"
                    :state="state('email_service_id')"
                  >
                    <b-form-select
                      id="email_service_id"
                      name="email_service_id"
                      v-model="model.email_service_id"
                      :options="email_services"
                    >
                      <template slot="first">
                        <option :value="null">
                          -- Select Email Service --
                        </option>
                      </template>
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('email_service_id') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>

                <div class="form-group" v-if="model.as_emails">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="is_open_tracking"
                        v-model="model.is_open_tracking"
                        :description="
                          $t('email-services.field.send-list-is-open-tracking')
                        "
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>

                <div class="form-group" v-if="model.as_emails">
                  <b-row>
                    <b-col lg="9" offset-lg="3">
                      <c-switch
                        name="is_click_tracking"
                        v-model="model.is_click_tracking"
                        :description="
                          $t('email-services.field.send-list-is-click-tracking')
                        "
                      ></c-switch>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <v-tab :title="$t('email-services.tab.list-content')" id="content">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  Content
                </h3>
                <b-form-group
                  class="adjust-height"
                  :label="$t('email-services.field.send-list-content')"
                  :label-for="content"
                  label-cols-sm="3"
                  :feedback="feedback('content')"
                >
                  <p-richtexteditor
                    name="content"
                    v-model="model.content"
                  ></p-richtexteditor>
                  <b-form-feedback>
                    {{ feedback('content') }}
                  </b-form-feedback>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <!--          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <div
                role="group"
                class="form-row form-group form-row-info form-row-no-bm"
              >
                <label class="col-sm-3 col-form-label">
                  {{ trans('email-services.field.campaign-fields') }}
                </label>
                <div class="col-sm-9 col-form-label col-form-label-value">
                  <span class="field-attribute">[[ button ]]</span>
                  <span class="field-attribute-explanation">
                    - Content button with link
                  </span>
                </div>
              </div>
              <div
                role="group"
                class="form-row form-group form-row-info form-row-no-bm"
              >
                <label class="col-sm-3 col-form-label"> </label>
                <div class="col-sm-9 col-form-label col-form-label-value">
                  <span class="field-attribute">[[ button-2 ]]</span>
                  <span class="field-attribute-explanation">
                    - Second content button with link
                  </span>
                </div>
              </div>
              <div
                role="group"
                class="form-row form-group form-row-info form-row-no-bm"
              >
                <label class="col-sm-3 col-form-label"> </label>
                <div class="col-sm-9 col-form-label col-form-label-value">
                  <span class="field-attribute">[[ email ]]</span>
                  <span class="field-attribute-explanation">
                    - Subscriber's email
                  </span>
                </div>
              </div>
              <div
                role="group"
                class="form-row form-group form-row-info form-row-no-bm"
              >
                <label class="col-sm-3 col-form-label"> </label>
                <div class="col-sm-9 col-form-label col-form-label-value">
                  <span class="field-attribute">[[ first_name ]]</span>
                  <span class="field-attribute-explanation">
                    - Subscriber's first name
                  </span>
                </div>
              </div>
              <div
                role="group"
                class="form-row form-group form-row-info form-row-no-bm"
              >
                <label class="col-sm-3 col-form-label"> </label>
                <div class="col-sm-9 col-form-label col-form-label-value">
                  <span class="field-attribute">[[ last_name ]]</span>
                  <span class="field-attribute-explanation">
                    - Subscriber's last name
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>-->
        </v-tab>

        <!--        <v-tab :title="$t('email-services.tab.list-buttons')" id="buttons">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('email-services.section.button_1') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.btn-1-name')"
                    label-for="btn_1_name"
                    :label-cols="3"
                    :feedback="feedback('btn_1_name')"
                  >
                    <b-row>
                      <b-col md="8">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="btn_1_name"
                            name="btn_1_name"
                            v-model="model.btn_1_name"
                            :state="state('btn_1_name')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('btn_1_name') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.btn-1-url')"
                    label-for="btn_1_url"
                    :label-cols="3"
                    :feedback="feedback('btn_1_url')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-link"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="btn_1_url"
                            name="btn_1_url"
                            v-model="model.btn_1_url"
                            :state="state('btn_1_url')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('btn_1_url') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card class="group-lang group-lang-seo">
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('email-services.section.button_2') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.btn-2-name')"
                    label-for="btn_2_name"
                    :label-cols="3"
                    :feedback="feedback('btn_2_name')"
                  >
                    <b-row>
                      <b-col md="8">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="btn_2_name"
                            name="btn_2_name"
                            v-model="model.btn_2_name"
                            :state="state('btn_2_name')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('btn_2_name') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.btn-2-url')"
                    label-for="btn_2_url"
                    :label-cols="3"
                    :feedback="feedback('btn_2_url')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-link"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="btn_2_url"
                            name="btn_2_url"
                            v-model="model.btn_2_url"
                            :state="state('btn_2_url')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('btn_2_url') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>-->

        <campaign-footer
          :type="modelName"
          :send-enabled="true"
          :back-enabled="true"
          :back-url="listPath"
          :preview-url="previewPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </campaign-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import CampaignFooter from '@email-services/js/components/SendLists/Elements/ActionFooter'
import axios from 'axios'

export default {
  name: `SendListEditForm`,
  components: {
    VueTabs,
    VTab,
    CampaignFooter
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'send_list',
      resourceRoute: 'send_lists',
      listPath: '/send/lists',
      previewPath: '/send/lists/' + this.id + '/preview',
      model: {
        id: '',
        // Data
        as_notifications: false,
        as_emails: false,
        name: '',
        subject: '',
        from_name: '',
        from_email: '',
        template_id: null,
        email_service_id: '',
        is_open_tracking: true,
        is_click_tracking: true,
        content: null,
        //btn_1_name: '',
        //btn_1_url: '',
        //btn_2_name: '',
        //btn_2_url: '',
        //img_1_name: '',
        //img_1_url: '',
        //img_2_name: '',
        //img_2_url: '',
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {},
      templates: [],
      email_services: []
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('email-services.title.send-list-edit')
    this.fetchTemplates()
    this.fetchEmailServices()
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('email-services.title.send-list')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {},
    async fetchTemplates() {
      let { data } = await axios.get(
        this.$app.route('email_templates.get_templates')
      )
      this.templates = data.items
    },
    async fetchEmailServices() {
      let { data } = await axios.get(
        this.$app.route('email_services.get_email_services')
      )
      this.email_services = data.items
    }
  }
}
</script>
