import Vue from 'vue'
import Router from 'vue-router'

// Containers
import Full from '../containers/Full'

// Views
import Dashboard from '@dashboard/js/components/Dashboard'

Vue.use(Router)

export function createRouter(base, i18n) {
  return new Router({
    mode: 'history',
    base: base,
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: '/',
        redirect: '/dashboard',
        name: 'home',
        component: Full,
        meta: {
          label: i18n.t('labels.frontend.titles.administration')
        },
        children: [
          /*          {
            path: 'search',
            name: 'search',
            component: Search,
            meta: {
              label: i18n.t('labels.search')
            }
          },*/
          {
            path: 'dashboard',
            name: 'dashboard',
            component: Dashboard,
            meta: {
              label: Vue.prototype.trans('labels.titles.dashboard')
            }
          },
          require('@users/js/routes').default,
          require('@roles/js/routes').default,
          require('@settings/js/routes').default,
          require('@languages/js/routes').default,
          require('@translations/js/routes').default,
          require('@blocks/js/routes').default,
          require('@pages/js/routes').default,
          require('@units/js/routes').default,
          require('@geo/js/routes').default,
          require('@categories/js/routes').default,
          require('@attributes/js/routes').default,
          require('@auctions/js/routes/auctions').default,
          require('@auctions/js/routes/lots').default,
          require('@companies/js/routes').default,
          require('@companies-certificates/js/routes').default,
          require('@email-services/js/routes/email').default,
          require('@email-services/js/routes/send').default,
          require('@email-services/js/routes/message').default
        ]
      },
      {
        path: '/', // all the routes which can be access without authentication
        component: require('@main/js/layouts/guest-page').default,
        meta: { validate: ['guest'], label: 'Dss' },
        children: [
          {
            path: '/',
            component: require('@main/js/views/auth/login').default,
            meta: {
              label: Vue.prototype.trans('labels.user.login')
            }
          },
          {
            path: '/login',
            component: require('@main/js/views/auth/login').default,
            meta: {
              label: Vue.prototype.trans('labels.user.login')
            }
          }
        ]
      }
    ]
  })
}
