<template>
  <div :class="modal ? 'vue-dialog vue-dialog-min' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('languages.title.create') }}
            </h3>

            <div class="multi-lang multi-lang-open"></div>
            <b-form-group
              :label="trans('languages.label.locale_select') + ' *'"
              label-for="locale"
              :label-cols="3"
              :feedback="feedback('locale')"
            >
              <multiselect
                v-model="model.locale_selected"
                track-by="locale"
                label="name"
                placeholder=""
                :options="locales"
                :searchable="true"
                :allow-empty="true"
                :multiple="false"
                autocomplete="on"
                @input="setLocale"
                @select="setLocale"
                @remove="removeLocale"
              >
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong> {{ option.name }} </strong> - {{ option.locale }}
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      {{ props.option.name }} - {{ props.option.locale }}
                    </span>
                  </div>
                </template>
                <template slot="caret" slot-scope="{ toggle }">
                  <div @mousedown.prevent.stop="toggle" class="toggle-hold">
                    <font-awesome-icon
                      :icon="['fa', 'chevron-down']"
                    ></font-awesome-icon>
                  </div>
                </template>
              </multiselect>
              <b-form-feedback>
                {{ feedback('locale') }}
              </b-form-feedback>
            </b-form-group>

            <b-form-group class="card-divider">
              <b-row>
                <b-col lg="3">
                  <h4 class="card-divider-title">
                    {{ trans('languages.section.site') }}
                  </h4>
                </b-col>
                <b-col lg="9"></b-col>
              </b-row>
            </b-form-group>

            <b-form-group label-cols-sm="3">
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="enabled_site"
                    v-model="model.enabled_site"
                    :description="trans('languages.label.enabled_site')"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              :description="trans('languages.note.show_site')"
              v-if="model.enabled_site"
            >
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="enabled_site"
                    v-model="model.show_site"
                    :description="trans('languages.label.show_site')"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              :label="trans('languages.label.position_site')"
              label-for="position_site"
              label-cols-sm="3"
              :description="trans('languages.note.site_sort_order')"
              v-if="model.enabled_site"
            >
              <b-row>
                <b-col md="4">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-position">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-arrows-alt"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="position_site"
                      name="position_site"
                      type="number"
                      v-model="model.position_site"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group class="card-divider">
              <b-row>
                <b-col lg="3">
                  <h4 class="card-divider-title">
                    {{ trans('languages.section.center') }}
                  </h4>
                </b-col>
                <b-col lg="9"></b-col>
              </b-row>
            </b-form-group>

            <b-form-group label-cols-sm="3">
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="enabled_center"
                    v-model="model.enabled_center"
                    :description="trans('languages.label.enabled_center')"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              :description="trans('languages.note.required_center')"
              v-if="model.enabled_center"
            >
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="required_center"
                    v-model="model.required_center"
                    :description="trans('languages.label.required_center')"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group class="card-divider">
              <b-row>
                <b-col lg="3">
                  <h4 class="card-divider-title">
                    {{ trans('languages.section.center_interface') }}
                  </h4>
                </b-col>
                <b-col lg="9"></b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              :description="trans('languages.note.interface_center')"
            >
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="interface_center"
                    v-model="model.interface_center"
                    :description="trans('languages.label.interface_center')"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              label-cols-sm="3"
              :description="trans('languages.note.show_center')"
              v-if="model.interface_center"
            >
              <b-row>
                <b-col lg="9">
                  <c-switch
                    name="show_center"
                    v-model="model.show_center"
                    :description="trans('languages.label.show_center')"
                  ></c-switch>
                </b-col>
              </b-row>
            </b-form-group>

            <b-form-group
              :label="trans('languages.label.position_center')"
              label-for="position_center"
              label-cols-sm="3"
              :description="trans('languages.note.center_sort_order')"
              v-if="model.interface_center"
            >
              <b-row>
                <b-col md="4">
                  <b-input-group>
                    <b-input-group-prepend class="input-group-prepend-position">
                      <b-input-group-text data-toggle>
                        <i class="fas fa-arrows-alt"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      id="position_center"
                      name="position_center"
                      type="number"
                      v-model="model.position_center"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button to="/languages" exact variant="danger" size="sm">
                    {{ trans('core.button.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ trans('core.button.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="trans('languages.title.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import form from '@core/js/mixins/form'
import Multiselect from 'vue-multiselect'

export default {
  title: '',
  name: 'LanguageCreateForm',
  components: {
    Multiselect
  },
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'language',
      resourceRoute: 'languages',
      listPath: '/languages',
      model: {
        // Data
        name: '',
        locale: '',
        locale_selected: '',
        enabled_center: false,
        enabled_site: false,
        show_site: false,
        show_center: false,
        interface_center: false,
        required_center: false,
        position_site: '',
        position_center: '',
        // Routes
        routes: {}
      },
      locales: []
    }
  },
  computed: {},
  async created() {
    let { data } = await axios.get(this.$app.route(`locales.list`))
    this.locales = data
  },
  mounted() {
    this.$title = this.trans('languages.title.create')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    setLocale(selectedOption) {
      if (selectedOption) {
        this.model.locale = selectedOption.locale
      }
    },
    removeLocale() {
      this.model.locale = ''
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
