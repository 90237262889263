<template>
  <b-row>
    <b-col xl="4" lg="4">
      <span class="item-name">Name</span>
    </b-col>
    <b-col xl="5" lg="5">
      <span class="item-name item-data">Data</span>
    </b-col>
    <b-col xl="2" lg="2">
      <span class="item-name item-type">Field type</span>
    </b-col>
    <b-col xl="1" lg="1"></b-col>
  </b-row>
</template>

<script>
export default {
  name: 'ListItemHeader',
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {}
}
</script>
