<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              Sign owner contract
            </h3>

            <div class="form-group form-group-element">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="sign_owner"
                    v-model="model.sign_owner"
                    description="Confirmation of owner sign contract"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 24%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 38%"
                  :disabled="pending"
                  @click="signContract"
                >
                  Sign contract
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'SignOwner',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: '',
      resourceRoute: 'auctions',
      listPath: '/auctions/sign-owner-contract',
      model: {
        sign_owner: false
      },
      errors: []
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = 'Sign owner contract'
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    async signContract() {
      let formData = new FormData()
      formData.append('id', this.id)
      formData.append('sign_owner', this.model.sign_owner)
      try {
        let { data } = await axios.post(this.listPath, formData)
        //this.$refs.datatable.refresh()
        this.modalClose()
        this.$app.noty[data.status](data.message)
        this.$eventHub.$emit('refresh-data')
      } catch (error) {
        this.errors = error.response.data.errors
      }
    }
  }
}
</script>
