<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('geo.titles.country-adjust') }}
            </h3>

            <!-- Country name -->
            <div
              role="group"
              class="form-row form-group form-row-info mb-0 mt-0"
            >
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_name') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <template v-if="data && data.name">
                  {{ data.name }}
                </template>
              </div>
            </div>

            <!-- Country native -->
            <!--            <div role="group" class="form-row form-group form-row-info mb-0">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.name_original') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <template v-if="data && data.native">
                  {{ data.native }}
                </template>
              </div>
            </div>-->

            <!-- Continent -->
            <div role="group" class="form-row form-group form-row-info mb-0">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.continent') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <template v-if="data && data.continent && data.continent.name">
                  {{ data.continent.name }}
                </template>
              </div>
            </div>

            <!-- Country Flag -->
            <div role="group" class="form-row form-group form-row-info mb-0">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_flag') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                <img
                  class="country-flag"
                  :src="getFlag(data.code)"
                  :alt="data.name"
                />
              </div>
            </div>

            <!-- Code -->
            <div role="group" class="form-row form-group form-row-info">
              <label class="col-3 col-form-label">
                {{ trans('geo.labels.country_code') }}:
              </label>
              <div class="col-form-label col-form-label-value">
                {{ data.code }}
              </div>
            </div>

            <!-- Divider -->

            <b-form-group class="card-divider">
              <b-row>
                <b-col lg="12">
                  <h4 class="card-divider-title text-left">
                    {{ trans('geo.sections.adjust') }}
                  </h4>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Action -->
            <b-form-group
              :label="trans('geo.labels.adjust-action') + ' *'"
              label-for="action"
              :label-cols="3"
              :feedback="feedback('action')"
            >
              <b-row>
                <b-col md="12">
                  <b-input-group>
                    <b-form-select
                      id="action"
                      name="action"
                      v-model="model.action"
                      :options="adjustActions"
                      class="form-control form-control-status select-box-with-arrow"
                    >
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('action') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Mode -->
            <b-form-group
              :label="trans('geo.labels.adjust-mode') + ' *'"
              label-for="mode"
              :label-cols="3"
              :feedback="feedback('mode')"
            >
              <b-row>
                <b-col md="12">
                  <b-input-group>
                    <b-form-select
                      id="mode"
                      name="mode"
                      v-model="model.mode"
                      :options="adjustModes"
                      class="form-control form-control-status select-box-with-arrow"
                    >
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('mode') }}
                    </b-form-feedback>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- Population -->
            <b-form-group
              :label="trans('geo.labels.adjust-by-population') + ' *'"
              label-for="name"
              label-cols-sm="3"
              :feedback="feedback('population')"
            >
              <b-form-input
                id="population"
                name="population"
                type="number"
                v-model="model.population"
                :state="state('population')"
                class="form-control-status"
                min="0"
              ></b-form-input>

              <b-form-feedback>
                {{ feedback('population') }}
              </b-form-feedback>
            </b-form-group>

            <!-- Override -->
            <!--            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="override"
                    v-model="model.override"
                    :description="$t('geo.labels.adjust-override')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>-->

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 50%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 50%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'CountryRecord',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'country',
      resourceRoute: 'countries',
      listPath: '/countries',
      model: {
        action: 'disable',
        population: 20000,
        mode: 'less',
        override: false
      },
      data: {},
      meta: {},
      adjustActions: {
        disable: 'Disable',
        enable: 'Enable'
      },
      adjustModes: {
        less: 'Less or equal',
        more: 'More or equal'
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('geo.title.edit')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    // gets a flag picture
    getFlag(flagName) {
      try {
        return require(`@main/images/flags/${flagName.toLowerCase()}.svg`)
      } catch (e) {
        return require('@main/images/flags/undefined.svg')
      }
    },
    save() {
      // Setup data.
      let data = {}
      data.adjust = true
      data.name = this.data.name
      data.native = this.data.native
      data.population = this.model.population
      data.mode = this.model.mode
      data.override = this.model.override
      data.action = this.model.action

      axios
        .patch(
          this.$app.route('countries.update', {
            country: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.modalClose()
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.pending = false
          if (error.response.status === 422) {
            this.validation = error.response.data
            return
          }
          this.$app.error(error)
        })
    }
  }
}
</script>
<style scoped>
.country-flag {
  max-width: 40px;
  width: 40px;
  border: 1px solid #eaecf0;
  padding: 2px;
  border-radius: 3px;
  margin-top: -3px;
  display: block;
}
</style>
