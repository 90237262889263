<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form>
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ trans('translations.title.edit-form') }}
            </h3>

            <b-form-group
              :label="trans('translations.label.area')"
              label-for="identifier"
              label-cols-sm="3"
            >
              <div class="data-info-block">
                <span>
                  <template v-if="area == 1">
                    {{ trans('translations.label.backend') }}
                  </template>
                  <template v-else>
                    {{ trans('translations.label.frontend') }}
                  </template>
                </span>
              </div>
            </b-form-group>

            <b-form-group
              :label="trans('translations.label.module')"
              label-for="identifier"
              label-cols-sm="3"
            >
              <div class="data-info-block">
                <span>{{ module }}</span>
              </div>
            </b-form-group>

            <b-form-group
              :label="trans('translations.label.group')"
              label-for="identifier"
              label-cols-sm="3"
            >
              <div class="data-info-block">
                <span>{{ group }}</span>
              </div>
            </b-form-group>

            <b-form-group
              :label="trans('translations.label.original')"
              label-for="origin"
              label-cols-sm="3"
            >
              <b-form-input
                id="origin"
                name="origin"
                v-model="origin"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              :label="trans('translations.label.key')"
              label-for="tkey"
              label-cols-sm="3"
            >
              <b-form-input id="tkey" name="tkey" v-model="tkey"></b-form-input>
            </b-form-group>

            <div>
              <div class="vue-dialog-buttons" slot="footer">
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-cancel"
                  style="flex: 1 1 24%"
                  @click="modalClose"
                >
                  {{ trans('core.button.cancel') }}
                </button>
                <button
                  type="button"
                  class="vue-dialog-button vue-dialog-button-save"
                  style="flex: 1 1 38%"
                  :disabled="pending"
                  @click="save"
                >
                  {{ trans('core.button.save-and-close') }}
                </button>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'TranslationFormEdit',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    id: {
      type: [String, Number],
      default: ''
    },
    area: {
      type: Number,
      default: 0
    },
    locale: {
      type: String,
      default: ''
    },
    module: {
      type: String,
      default: ''
    },
    tkey: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    saved: {
      type: [String, Number],
      default: ''
    },
    origin: {
      type: [String, Number],
      default: ''
    },
    group: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'translation',
      resourceRoute: 'translations',
      listPath: '/translations',
      model: {
        value: '',
        origin_value: ''
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('translations.title.edit-form')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    save() {
      // Setup data.
      let data = {}
      data.id = this.id
      data.tkey = this.tkey
      data.origin = this.origin

      axios
        .patch(
          this.$app.route('translations.update.origin', {
            translation: this.id
          }),
          data
        )
        .then(response => {
          this.refreshData()
          this.$modal.hide('translation-form-edit')
          this.$app.noty[response.data.status](response.data.message)
          this.$eventHub.$emit('refresh-data')
        })
        .catch(error => {
          this.$app.error(error)
        })
    }
  }
}
</script>
