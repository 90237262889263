<template>
  <div class="multi-lang multi-lang-open">
    <div :key="index" v-for="(locale, index) in this.$app.locales">
      <b-form-group
        :label="label"
        :label-for="`${name}.${index}`"
        :label-cols-sm="labelCol"
        class="form-group-lang"
        :state="stateValid(name + '.' + index)"
      >
        <b-input-group>
          <b-input-group-prepend
            class="input-group-prepend-title prepend-lang"
            :class="[locale.required ? 'prepend-lang-required' : '']"
          >
            <b-input-group-text
              data-toggle
              v-b-tooltip.hover
              :title="$t(locale.name)"
            >
              <span>{{ index }}</span>
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            :id="`${name}.${index}`"
            :name="`${name}.${index}`"
            v-model="mutableValue[`${index}`]"
            :state="stateValid(name + '.' + index)"
          ></b-form-input>
          <b-input-group-append class="input-group-append-title" v-if="icon">
            <b-input-group-text data-toggle>
              <i :class="icon"></i>
            </b-input-group-text>
          </b-input-group-append>
          <b-form-feedback>
            {{ feedback('name.' + index) }}
          </b-form-feedback>
        </b-input-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextLang',
  props: {
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labelCol: {
      type: Number,
      default: 3
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    valueCol: {
      type: Number,
      default: 9
    },
    icon: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    inputClass: {
      type: [String, Object],
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    errors: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      mutableValue: this.value
    }
  },
  computed: {},
  watch: {
    mutableValue(newValue) {
      this.$emit('input', newValue)
    },
    value(newValue) {
      this.mutableValue = newValue
    }
  },
  methods: {
    feedback(name) {
      if (this.state(name)) {
        return this.errors[name][0]
      }
    },
    state(name) {
      return this.errors !== undefined && this.errors.hasOwnProperty(name)
        ? 'invalid'
        : null
    },
    stateValid(name) {
      return this.errors !== undefined && this.errors.hasOwnProperty(name)
        ? false
        : true
    }
  }
}
</script>
