<template>
  <div class="row list-page list-settings">
    <data-sidebar class="col-3 col-md-3 col-lg-2 col-xl-2">
      <!--      <template v-slot:stats>
        <div class="side-block">
          <div class="data-sidebar-title">
            {{ trans('translations.title.status') }}
          </div>
          <div class="data-sidebar-data"></div>
        </div>
      </template>-->
      <template v-slot:filters>
        <div class="side-block">
          <!--          <div class="data-sidebar-title">
            {{ trans('translations.title.filters') }}
          </div>-->
          <div class="data-sidebar-data">
            <div class="sidebar-title">
              {{ trans('settings.nav') }}
            </div>
            <div class="sidebar-choices">
              <div :key="moduleIndex" v-for="(modules, moduleIndex) in nav">
                <div :key="index" v-for="(navFilter, index) in modules">
                  <router-link
                    :to="navFilter.url"
                    class="value"
                    @click.native="getSettings()"
                  >
                    <div
                      class="opt-icon"
                      :style="`background-color:${navFilter.icon_bg_color}`"
                    >
                      <i
                        :class="navFilter.icon"
                        :style="`color:${navFilter.icon_color}`"
                      ></i>
                    </div>
                    <div class="opt-content">
                      <div class="opt-title">{{ navFilter.label }}</div>
                      <div class="opt-desc">{{ navFilter.description }}</div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </data-sidebar>
    <b-card class="col-9 col-md-9 col-lg-10 col-xl-10 settings-body-wrap">
      <div
        class="settings-container"
        :class="templatePage && innerPage ? 'email-template-container' : ''"
      >
        <div class="settings-container-header">
          <h3 class="card-title">
            <template v-if="this.$route.name != 'settings'">
              <template
                v-if="
                  innerPage &&
                    selectedSetting &&
                    selectedSetting.type === 'email_template'
                "
              >
                {{ selectedSetting.name[`${$app.locale}`] }}
              </template>
              <template v-else> {{ model.settings.label }}</template>
            </template>

            <template v-else> {{ $t('settings.titles.list') }}</template>
          </h3>
        </div>
        <div
          class="settings-container-body"
          :class="!innerPage && model.settings.list ? 'template-list' : ''"
        >
          <div class="settings-container-inner">
            <form @submit.prevent="onSubmit">
              <template v-if="this.$route.name != 'settings'">
                <div
                  :key="index"
                  v-for="(setting, index) in model.settings.elements"
                  class="value-wrap"
                  :class="setting.class"
                >
                  <b-form-group
                    v-if="setting.divider && setting.divider == 'top'"
                  >
                    <b-row>
                      <b-col col="12">
                        <hr class="setting-divider" />
                      </b-col>
                    </b-row>
                  </b-form-group>

                  <!-- Send Service  -->
                  <template v-if="setting.type == 'send_service'">
                    <b-form-group v-if="setting.description[`${$app.locale}`]">
                      <b-row>
                        <b-col sm="3"> </b-col>
                        <b-col sm="9">
                          <div class="setting-description">
                            {{ setting.description[`${$app.locale}`] }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-form-group
                      :label="setting.name[`${$app.locale}`]"
                      :label-for="setting.key"
                      :label-cols="3"
                    >
                      <b-row>
                        <b-col md="12">
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fas fa-stream"></i>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-select
                              :name="setting.key"
                              :id="setting.key"
                              class="form-control select-box-with-arrow"
                              v-model="
                                model.settings.elements[`${index}`].data.service
                              "
                            >
                              <b-form-select-option
                                v-for="(option, optionIdx) in model.settings
                                  .elements[`${index}`].data_protected"
                                :key="optionIdx"
                                :value="optionIdx"
                                :selected="
                                  model.settings.elements[`${index}`].data
                                    .service == option.value
                                "
                              >
                                {{ option.name[`${$app.locale}`] }}
                              </b-form-select-option>
                            </b-form-select>
                          </b-input-group>
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <template
                      v-if="model.settings.elements[`${index}`].data.service"
                    >
                      <div
                        v-for="(optData, optDataIdx) in model.settings.elements[
                          `${index}`
                        ].data_protected"
                        :key="optDataIdx"
                      >
                        <template
                          v-if="
                            model.settings.elements[`${index}`].data.service ==
                              optDataIdx
                          "
                        >
                          <div
                            v-for="(element, elId) in optData.data"
                            :key="elId"
                          >
                            <template
                              v-if="
                                element.type == 'text' ||
                                  element.type == 'password'
                              "
                            >
                              <b-form-group
                                v-if="
                                  element.description &&
                                    element.description[`${$app.locale}`]
                                "
                              >
                                <b-row>
                                  <b-col sm="3"> </b-col>
                                  <b-col sm="9">
                                    <div class="setting-description">
                                      {{
                                        element.description[`${$app.locale}`]
                                      }}
                                    </div>
                                  </b-col>
                                </b-row>
                              </b-form-group>

                              <b-form-group
                                :label="element.name[`${$app.locale}`]"
                                :label-for="elId"
                                label-cols-sm="3"
                              >
                                <b-input-group>
                                  <b-form-input
                                    :name="elId"
                                    :id="elId"
                                    :type="element.type"
                                    v-model="
                                      model.settings.elements[`${index}`]
                                        .data_protected[`${optDataIdx}`].data[
                                        `${elId}`
                                      ].value
                                    "
                                  ></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </template>
                          </div>
                        </template>
                      </div>
                    </template>
                  </template>

                  <!-- Switch -->
                  <template v-if="setting.type == 'switch'">
                    <c-switch
                      :name="index"
                      :id="index"
                      v-model="model.settings.elements[`${index}`].data.value"
                      :description="setting.name[`${$app.locale}`]"
                    ></c-switch>
                    <small
                      tabindex="-1"
                      class="form-text text-muted field-notes"
                      v-if="setting.description[`${$app.locale}`]"
                    >
                      {{ setting.description[`${$app.locale}`] }}
                    </small>
                  </template>

                  <!-- Wysiwyg -->

                  <template v-if="setting.type == 'wysiwyg'">
                    <b-form-group v-if="setting.description[`${$app.locale}`]">
                      <b-row>
                        <b-col sm="3"> </b-col>
                        <b-col sm="9">
                          <div class="setting-description">
                            {{ setting.description[`${$app.locale}`] }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group
                      :label="setting.name[`${$app.locale}`]"
                      :label-for="setting.key"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <rich-text-editor
                          :name="setting.key"
                          :id="setting.key"
                          v-model="
                            model.settings.elements[`${index}`].data.value[
                              `${$app.locale}`
                            ]
                          "
                        ></rich-text-editor>
                      </b-input-group>
                    </b-form-group>
                  </template>

                  <!-- Textarea -->

                  <template v-if="setting.type == 'textarea'">
                    <b-form-group v-if="setting.description[`${$app.locale}`]">
                      <b-row>
                        <b-col sm="3"> </b-col>
                        <b-col sm="9">
                          <div class="setting-description">
                            {{ setting.description[`${$app.locale}`] }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group
                      :label="setting.name[`${$app.locale}`]"
                      :label-for="setting.key"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <b-form-textarea
                          :name="setting.key"
                          :id="setting.key"
                          :rows="4"
                          v-model="
                            model.settings.elements[`${index}`].data.value[
                              `${$app.locale}`
                            ]
                          "
                        ></b-form-textarea>
                      </b-input-group>
                    </b-form-group>
                  </template>

                  <!-- Text -->

                  <template v-if="setting.type == 'text'">
                    <b-form-group v-if="setting.description[`${$app.locale}`]">
                      <b-row>
                        <b-col sm="3"> </b-col>
                        <b-col sm="9">
                          <div class="setting-description">
                            {{ setting.description[`${$app.locale}`] }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group
                      :label="setting.name[`${$app.locale}`]"
                      :label-for="setting.key"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <b-form-input
                          :name="setting.key"
                          :id="setting.key"
                          type="text"
                          v-model="
                            model.settings.elements[`${index}`].data.value[
                              `${$app.locale}`
                            ]
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </template>

                  <!-- Email -->

                  <template v-if="setting.type == 'email'">
                    <b-form-group v-if="setting.description[`${$app.locale}`]">
                      <b-row>
                        <b-col sm="3"> </b-col>
                        <b-col sm="9">
                          <div class="setting-description">
                            {{ setting.description[`${$app.locale}`] }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group
                      :label="setting.name[`${$app.locale}`]"
                      :label-for="setting.key"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <b-form-input
                          :name="setting.key"
                          :id="setting.key"
                          type="email"
                          v-model="
                            model.settings.elements[`${index}`].data.value[
                              `${$app.locale}`
                            ]
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </template>

                  <!-- Number -->

                  <template v-if="setting.type == 'number'">
                    <b-form-group v-if="setting.description[`${$app.locale}`]">
                      <b-row>
                        <b-col sm="3"> </b-col>
                        <b-col sm="9">
                          <div class="setting-description">
                            {{ setting.description[`${$app.locale}`] }}
                          </div>
                        </b-col>
                      </b-row>
                    </b-form-group>

                    <b-form-group
                      :label="setting.name[`${$app.locale}`]"
                      :label-for="setting.key"
                      label-cols-sm="3"
                    >
                      <b-input-group>
                        <b-form-input
                          :name="setting.key"
                          :id="setting.key"
                          type="number"
                          v-model="
                            model.settings.elements[`${index}`].data.value
                          "
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </template>

                  <!-- Email template -->
                  <template
                    v-if="setting.type == 'email_template' && !innerPage"
                  >
                    <router-link
                      :to="setting.url"
                      class="value"
                      @click.native="setInnerTemplatePage(setting, true)"
                    >
                      <div class="opt-content">
                        <div class="opt-title">
                          {{ setting.name[`${$app.locale}`] }}
                        </div>
                        <div class="opt-desc">
                          {{ setting.description[`${$app.locale}`] }}
                        </div>
                        <div class="opt-nav-icon">
                          <i class="fas fa-chevron-right"></i>
                        </div>
                      </div>
                    </router-link>
                  </template>

                  <!-- Email template -->
                  <template
                    v-if="
                      selectedSetting.element === setting.element &&
                        selectedSetting.type === 'email_template' &&
                        innerPage
                    "
                  >
                    <b-row class="justify-content-center">
                      <b-col sm="12" md="6" lg="8" xl="8">
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ trans('settings.section.email-template') }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <!--                    {{
                          model.settings.elements[`${selectedSetting.element}`]
                            .name[`${$app.locale}`]
                        }}-->
                        <!--                    {{
                          model.settings.elements[`${selectedSetting.element}`]
                            .subject[`${$app.locale}`]
                        }}-->
                        <b-form-group
                          :label="$t('settings.label.subject')"
                          :label-for="`subject.${$app.locale}`"
                          label-cols-sm="3"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title prepend-lang"
                            >
                              <b-input-group-text data-toggle>
                                <span>{{ $app.locale }}</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`subject.${$app.locale}`"
                              :name="`subject.${$app.locale}`"
                              v-model="
                                model.settings.elements[`${index}`].data
                                  .subject[`${$app.locale}`]
                              "
                            ></b-form-input>
                            <b-input-group-append
                              class="input-group-append-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fab fa-autoprefixer"></i>
                              </b-input-group-text>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                        <b-form-group
                          :label="$t('settings.label.title')"
                          :label-for="`title.${$app.locale}`"
                          label-cols-sm="3"
                        >
                          <b-input-group>
                            <b-input-group-prepend
                              class="input-group-prepend-title prepend-lang"
                            >
                              <b-input-group-text data-toggle>
                                <span>{{ $app.locale }}</span>
                              </b-input-group-text>
                            </b-input-group-prepend>
                            <b-form-input
                              :id="`title.${$app.locale}`"
                              :name="`title.${$app.locale}`"
                              v-model="
                                model.settings.elements[`${index}`].data.title[
                                  `${$app.locale}`
                                ]
                              "
                            ></b-form-input>
                            <b-input-group-append
                              class="input-group-append-title"
                            >
                              <b-input-group-text data-toggle>
                                <i class="fab fa-autoprefixer"></i>
                              </b-input-group-text>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                        <b-form-group
                          :label="$t('settings.label.body')"
                          :label-for="`body.${$app.locale}`"
                          label-cols-sm="3"
                        >
                          <b-input-group>
                            <rich-text-editor
                              :id="`body.${$app.locale}`"
                              :name="`body.${$app.locale}`"
                              v-model="
                                model.settings.elements[`${index}`].data.body[
                                  `${$app.locale}`
                                ]
                              "
                            ></rich-text-editor>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col sm="12" md="6" lg="4" xl="4">
                        <b-form-group class="card-divider setting-e-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ trans('settings.section.settings') }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-group class="card-e-body">
                          <b-row>
                            <b-col lg="12" class="setting-e-template">
                              <c-switch
                                :name="index"
                                :id="index"
                                v-model="
                                  model.settings.elements[`${index}`].data.logo
                                "
                                :description="trans('settings.label.use-logo')"
                              ></c-switch>
                            </b-col>
                            <b-col lg="12" class="setting-e-template">
                              <c-switch
                                :name="index"
                                :id="index"
                                v-model="
                                  model.settings.elements[`${index}`].data
                                    .confidential_text
                                "
                                :description="
                                  trans('settings.label.use-confidential-text')
                                "
                              ></c-switch>
                            </b-col>
                          </b-row>
                        </b-form-group>

                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ trans('settings.section.icons') }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ trans('settings.section.elements-to-use') }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-group class="card-e-body">
                          <div
                            v-for="(elementToUse, eIndex) in setting.elements"
                            :key="eIndex"
                          >
                            <b-row>
                              <b-col
                                lg="5"
                                class="setting-e-template setting-e-template-code"
                              >
                                {{ elementToUse.code }}
                              </b-col>
                              <b-col
                                lg="7"
                                class="setting-e-template setting-e-template-desc"
                              >
                                {{ elementToUse.desc[`${$app.locale}`] }}
                              </b-col>
                            </b-row>
                          </div>
                        </b-form-group>
                        <b-form-group class="card-divider">
                          <b-row>
                            <b-col lg="12">
                              <h4 class="card-divider-title text-left">
                                {{ trans('settings.section.buttons') }}
                              </h4>
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <b-form-group
                    v-if="setting.divider && setting.divider == 'bottom'"
                  >
                    <b-row>
                      <b-col col="12">
                        <hr class="setting-divider" />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <div
                  class="action-footer"
                  v-if="model.settings.action_save || innerPage"
                >
                  <b-row class="justify-content-center">
                    <b-col md="12" lg="12" xl="12">
                      <b-button
                        type="submit"
                        variant="success"
                        size="sm"
                        class="float-right bnt-save"
                        :disabled="pending"
                        @click="continueEditing()"
                      >
                        {{ $t('buttons.save') }}
                      </b-button>
                      <template
                        v-if="
                          selectedSetting.type === 'email_template' && innerPage
                        "
                      >
                        <b-button
                          type="submit"
                          variant="success"
                          size="sm"
                          class="float-right bnt-save bnt-send-test"
                          :disabled="pending"
                          @click="continueEditingPreview(selectedSetting)"
                        >
                          {{ $t('settings.buttons.save_preview') }}
                        </b-button>
                      </template>
                    </b-col>
                  </b-row>
                </div>
              </template>

              <template v-else>Choose settings</template>
            </form>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import form from '@core/js/mixins/form-settings'
import SettingsCreateForm from './SettingsCreateForm.vue'
import RichTextEditor from '@core/js/components/Plugins/RichTextEditor'
import EmailTesting from './modal/EmailTesting.vue'
import TranslationFormEdit from '@translations/js/components/modal/TranslationFormEdit'
// import { EmailEditor } from 'vue-email-editor'
export default {
  title: '',
  name: 'SettingsList',
  components: { RichTextEditor },
  mixins: [form],
  data() {
    return {
      modelName: 'settings',
      resourceRoute: 'settings',
      listPath: '/settings',
      innerPage: false,
      templatePage: false,
      selected: [],
      element: '',
      selectedSetting: '',
      nav: [],
      model: {
        settings: []
      },
      isModalCreate: true,
      isModalEdit: false,
      isBusy: false,
      term_formats: ['d.d', 'd'],
      fields: [
        {
          key: 'name',
          label: this.$t('validation.attributes.name'),
          sortable: true,
          class: 'data-name'
        },
        {
          key: 'enabled',
          label: this.$t('validation.attributes.enabled'),
          class: 'text-center'
        },
        {
          key: 'created_at',
          label: this.$t('labels.created_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'updated_at',
          label: this.$t('labels.updated_at'),
          class: 'text-center',
          sortable: true
        },
        {
          key: 'actions',
          label: this.$t('labels.actions'),
          class: 'nowrap text-right actions'
        }
      ],
      actions: {
        destroy: this.$t('settings.actions.destroy'),
        enable: this.$t('settings.actions.enable'),
        disable: this.$t('settings.actions.disable')
      }
    }
  },
  created() {
    //this.$eventHub.$on('refresh-data', this.refreshData)
  },
  mounted() {
    this.$title = this.$t('settings.titles.list')
    this.getNav()
    this.getSettings()
    //this.getSettingsData()
  },
  methods: {
    editorLoaded() {},
    saveDesign() {
      this.$refs.editor.saveDesign(design => {
        // console.log('saveDesign', design)
      })
    },
    exportHtml(event) {
      event.preventDefault()
      this.$refs.editor.exportHtml(data => {
        // console.log('exportHtml', data)
      })
    },
    continueEditing() {
      this.$eventHub.$emit('continue-editing')
    },
    continueEditingPreview(selectedSetting) {
      this.$eventHub.$emit('continue-editing')
      this.$modal.show(
        EmailTesting,
        {
          modal: true,
          sKey: selectedSetting.key
        },
        {
          name: 'email-testing',
          transition: '',
          clickToClose: false,
          resizable: false,
          adaptive: false,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    modalCreate() {
      this.$modal.show(
        SettingsCreateForm,
        {
          modal: this.isModalCreate
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          height: 'auto',
          width: 700
        }
      )
    },
    onModelChanged() {
      return this.$refs.datatable.refresh()
    },
    refreshData() {
      return this.$refs.datatable.refresh()
    },
    setInnerPage(template = null) {
      this.innerPage = true
      if (template) {
        this.templatePage = true
      }
    },
    setInnerTemplatePage(record, template = null) {
      this.innerPage = true
      if (template) {
        this.element = record.element
        this.templatePage = true
        this.selectedSetting = record
      }
    },
    navAction() {
      this.innerPage = false
    },
    async getNav() {
      axios
        .post(this.$app.route('settings.nav', {}))
        .then(response => {
          this.nav = response.data
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    async getSettings() {
      axios
        .post(
          this.$app.route('settings.settings', {
            module: this.$route.params.module,
            nav: this.$route.params.nav
          })
        )
        .then(response => {
          this.model.settings = response.data
        })
        .catch(error => {
          this.$app.error(error)
        })
      this.innerPage = false
    },
    async getSettingsData() {
      axios
        .post(this.$app.route('settings.data', { nav: this.$route.params.id }))
        .then(response => {
          this.model.settings_data = response.data
        })
        .catch(error => {
          this.$app.error(error)
        })
    },
    async getTemplateData() {
      axios
        .post(this.$app.route('settings.data', { nav: this.$route.params.id }))
        .then(response => {
          this.model.settings_data = response.data
        })
        .catch(error => {
          this.$app.error(error)
        })
    }
  }
}
</script>
<style scoped>
.unlayer-editor {
  flex: 1;
  display: flex;
}
</style>
