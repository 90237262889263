<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>
                <div class="multi-lang multi-lang-open">
                  <b-form-group
                    :label="$t('email-services.field.name') + ' *'"
                    label-for="name"
                    :label-cols="3"
                    :feedback="feedback('name')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fab fa-autoprefixer"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="name"
                            name="name"
                            v-model="model.name"
                            :state="state('name')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('name') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>

                <!-- Sendgrid -->
                <div
                  class="multi-lang multi-lang-open"
                  v-if="model.type_id == 2"
                >
                  <b-form-group
                    :label="$t('email-services.field.service-type-api') + ' *'"
                    label-for="settings[key]"
                    :label-cols="3"
                    :feedback="feedback('settings.key')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-key"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="settings.key"
                            name="settings[key]"
                            v-model="model.settings['key']"
                            :state="state('settings.key')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.key') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <!-- SMTP -->
                <div
                  class="multi-lang multi-lang-open"
                  v-if="model.type_id == 6"
                >
                  <b-form-group
                    :label="$t('email-services.field.service-host') + ' *'"
                    label-for="settings[host]"
                    :label-cols="3"
                    :feedback="feedback('settings.host')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.host"
                            name="settings[host]"
                            v-model="model.settings['host']"
                            :state="state('settings.host')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.host') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-port') + ' *'"
                    label-for="settings[port]"
                    :label-cols="3"
                    :feedback="feedback('settings.port')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.port"
                            name="settings[port]"
                            type="number"
                            v-model="model.settings['port']"
                            :state="state('settings.port')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.port') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="
                      $t('email-services.field.service-encryption') + ' *'
                    "
                    label-for="settings[encryption]"
                    :label-cols="3"
                    :feedback="feedback('settings.encryption')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.encryption"
                            name="settings[encryption]"
                            v-model="model.settings['encryption']"
                            :state="state('settings.encryption')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.encryption') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-username') + ' *'"
                    label-for="settings[username]"
                    :label-cols="3"
                    :feedback="feedback('settings.username')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.username"
                            name="settings[username]"
                            v-model="model.settings['username']"
                            :state="state('settings.username')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.username') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-password') + ' *'"
                    label-for="settings[password]"
                    :label-cols="3"
                    :feedback="feedback('settings.password')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.password"
                            name="settings[password]"
                            type="password"
                            v-model="model.settings['password']"
                            :state="state('settings.password')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.password') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                </div>
                <!-- Mailgun -->
                <div
                  class="multi-lang multi-lang-open"
                  v-if="model.type_id == 3"
                >
                  <b-form-group
                    :label="$t('email-services.field.service-type-api') + ' *'"
                    label-for="settings[key]"
                    :label-cols="3"
                    :feedback="feedback('settings.key')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-key"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="settings.key"
                            name="settings[key]"
                            v-model="model.settings['key']"
                            :state="state('settings.key')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.key') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-domain') + ' *'"
                    label-for="settings[domain]"
                    :label-cols="3"
                    :feedback="feedback('settings.domain')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.domain"
                            name="settings[domain]"
                            v-model="model.settings['domain']"
                            :state="state('settings.domain')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.domain') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-zone') + ' *'"
                    label-for="settings[zone]"
                    horizontal
                    :label-cols="3"
                    :feedback="feedback('settings.zone')"
                  >
                    <b-form-select
                      id="settings.zone"
                      name="settings[zone]"
                      v-model="model.settings['zone']"
                      :options="mailgun_zones"
                    >
                      <template slot="first">
                        <option :value="null">
                          -- Select Zone --
                        </option>
                      </template>
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('settings.zone') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>
                <!-- MailJet -->
                <div
                  class="multi-lang multi-lang-open"
                  v-if="model.type_id == 5"
                >
                  <b-form-group
                    :label="$t('email-services.field.service-type-api') + ' *'"
                    label-for="settings[key]"
                    :label-cols="3"
                    :feedback="feedback('settings.key')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-input-group-prepend
                            class="input-group-prepend-title"
                          >
                            <b-input-group-text data-toggle>
                              <i class="fas fa-key"></i>
                            </b-input-group-text>
                          </b-input-group-prepend>
                          <b-form-input
                            id="settings.key"
                            name="settings[key]"
                            v-model="model.settings['key']"
                            :state="state('settings.key')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.key') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-secret') + ' *'"
                    label-for="settings[secret]"
                    :label-cols="3"
                    :feedback="feedback('settings.secret')"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-input-group>
                          <b-form-input
                            id="settings.secret"
                            name="settings[secret]"
                            type="password"
                            v-model="model.settings['secret']"
                            :state="state('settings.secret')"
                          ></b-form-input>
                          <b-form-feedback>
                            {{ feedback('settings.password') }}
                          </b-form-feedback>
                        </b-input-group>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-group
                    :label="$t('email-services.field.service-zone') + ' *'"
                    label-for="settings[zone]"
                    horizontal
                    :label-cols="3"
                    :feedback="feedback('settings.zone')"
                  >
                    <b-form-select
                      id="settings.zone"
                      name="settings[zone]"
                      v-model="model.settings['zone']"
                      :options="mailjet_zones"
                    >
                      <template slot="first">
                        <option :value="null">
                          -- Select Zone --
                        </option>
                      </template>
                    </b-form-select>
                    <b-form-feedback>
                      {{ feedback('settings.zone') }}
                    </b-form-feedback>
                  </b-form-group>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
// import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'

export default {
  name: `EmailServiceEditForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      title: '',
      subTitle: '',
      modelName: 'email_service',
      resourceRoute: 'email_services',
      listPath: '/email/services',
      model: {
        // Data
        name: '',
        type_id: '',
        settings: [],
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      mailgun_zones: {
        EU: 'EU',
        US: 'US'
      },
      mailjet_zones: {
        Default: 'Default',
        US: 'US'
      },
      data: {},
      meta: {}
    }
  },
  created() {},
  mounted() {
    this.$title = this.$t('email-services.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('email-services.titles.unit')
  },
  methods: {
    onModelChanged() {},
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
