export default {
  path: 'lots',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'lots',
      component: require('@auctions/js/components/AuctionLots').default,
      meta: {
        label: ''
      }
    },
    /* Logistic */
    /*    {
      path: ':id/logistic',
      name: 'auctions_lots_logistic',
      component: require('@auctions/js/components/AuctionLogistic').default,
      props: true,
      meta: {
        label: ''
      }
    },*/
    /* Participants END */
    /* Participants */
    {
      path: ':id/participants',
      name: 'lots_participants',
      component: require('@auctions/js/components/AuctionParticipants').default,
      props: true,
      meta: {
        label: ''
      }
    }
    /* Participants END */
  ]
}
