<template>
  <b-row>
    <b-col xl="4" lg="4">
      <span class="item-name">
        <i class="fas fa-arrows-alt"></i>
        <template
          v-if="
            data.type === 'text' ||
              data.type === 'textarea' ||
              data.type === 'wysiwyg' ||
              data.type === 'number' ||
              data.type === 'email' ||
              data.type === 'url' ||
              data.type === 'select' ||
              data.type === 'file' ||
              data.type === 'image' ||
              data.type === 'radio' ||
              data.type === 'checkbox' ||
              data.type === 'toggle'
          "
        >
          {{ getAttributeValue('name', lang) }}
        </template>
        <template v-if="data.type === 'service_categories'">
          {{ trans('services.field.service_categories') }}
        </template>
        <template v-if="data.type === 'service_online'">
          {{ trans('services.field.service_online') }}
        </template>
        <template v-if="data.type === 'service_updated_at'">
          {{ trans('services.field.service_updated_at') }}
        </template>
        <template v-if="data.type === 'service_department'">
          {{ trans('services.field.service_department') }}
        </template>
        <template v-if="data.type === 'service_recipient'">
          {{ trans('services.field.service_recipient') }}
        </template>
        <template v-if="data.type === 'service_duration'">
          {{ trans('services.field.service_duration') }}
        </template>
        <template v-if="data.type === 'service_contacts'">
          {{ trans('services.field.service_contacts') }}
        </template>
        <template v-if="data.type === 'service_life_cases'">
          {{ trans('services.field.service_life_cases') }}
        </template>
        <template v-if="data.type === 'service_form_fullname'">
          {{ trans('services.field.service_form_fullname') }}
        </template>
        <template v-if="data.type === 'service_form_email'">
          {{ trans('services.field.service_form_email') }}
        </template>
      </span>
    </b-col>
    <b-col xl="5" lg="5">
      <span class="item-name item-data">
        <template
          v-if="
            (data.type === 'text' ||
              data.type === 'textarea' ||
              data.type === 'wysiwyg') &&
              getAttributeValueTrunc('valueLang', lang)
          "
        >
          <truncate
            action-class="item-show-more-btn"
            :clamp="trans('attributes.label.show-more')"
            :length="160"
            :less="trans('attributes.label.show-less')"
            type="html"
            :text="getAttributeValueTrunc('valueLang', lang)"
          ></truncate>
        </template>
        <template v-if="data.type === 'service_online'">
          <template v-if="extraData.online_service">
            <span class="badge badge-success">
              {{ trans('core.label.yes') }}
            </span>
          </template>
          <template v-else>
            <span class="badge badge-danger">
              {{ trans('core.label.no') }}
            </span>
          </template>
        </template>
        <template v-if="data.type === 'service_updated_at'">
          <template v-if="extraData.updated_at">
            {{ extraData.updated_at }}
          </template>
        </template>
        <template v-if="data.type === 'service_department'">
          <template v-if="extraData.department_name">
            {{ extraData.department_name }}
          </template>
        </template>
        <template v-if="data.type === 'service_categories'">
          <template v-if="extraData.categories">
            <span
              class="badge badge-category"
              v-for="category in extraData.categories"
              :key="category"
            >
              {{ getNameById(extraData.records, category) }}
            </span>
          </template>
          <template v-if="Object.keys(extraData.categories).length == 0">
            <span class="data-not-filled">
              -- {{ trans('attributes.labels.data-not-filled') }} --
            </span>
          </template>
        </template>
        <template v-if="data.type === 'service_life_cases'">
          <template v-if="extraData.life_cases">
            <span
              class="badge badge-case"
              v-for="life_case in extraData.life_cases"
              :key="life_case"
            >
              {{ getNameById(extraData.records, life_case) }}
            </span>
          </template>
          <template v-if="Object.keys(extraData.life_cases).length == 0">
            <span class="data-not-filled">
              -- {{ trans('attributes.labels.data-not-filled') }} --
            </span>
          </template>
        </template>
        <template v-if="data.type === 'service_contacts'">
          <template v-if="extraData.contacts">
            <span
              class="badge badge-case"
              v-for="contact in extraData.contacts"
              :key="contact"
            >
              {{ getContactById(extraData.records, contact) }}
            </span>
          </template>
          <template v-if="Object.keys(extraData.contacts).length == 0">
            <span class="data-not-filled">
              -- {{ trans('attributes.labels.data-not-filled') }} --
            </span>
          </template>
        </template>
        <template v-if="data.type === 'service_duration'">
          <template v-if="extraData.term_max && extraData.term_format">
            {{ extraData.term_max }} {{ extraData.term_format }}
          </template>
          <template v-else>
            <span class="data-not-filled">
              -- {{ trans('attributes.labels.data-not-filled') }} --
            </span>
          </template>
        </template>
        <template v-if="data.type === 'service_recipient'">
          <template
            v-if="
              extraData.dedicated_individual == 1 &&
                extraData.dedicated_legal == 1
            "
          >
            Fizinis arba juridinis asmuo
          </template>

          <template
            v-if="
              extraData.dedicated_individual == 1 &&
                extraData.dedicated_legal != 1
            "
          >
            Fizinis asmuo
          </template>

          <template
            v-if="
              extraData.dedicated_individual != 1 &&
                extraData.dedicated_legal == 1
            "
          >
            Juridinis asmuo
          </template>
          <template
            v-if="
              extraData.dedicated_individual != 1 &&
                extraData.dedicated_legal != 1
            "
          >
            <span class="data-not-filled">
              -- {{ trans('attributes.labels.data-not-filled') }} --
            </span>
          </template>
        </template>
        <template v-if="data.type === 'number'">
          {{ getAttributeValue('valueNumeric') }}
        </template>
        <template v-if="data.type === 'url'"> </template>
        <template
          v-if="
            type != 'form' &&
              !getAttributeValue('valueLang', lang) &&
              !getAttributeValue('valueNumeric') &&
              data.type !== 'url' &&
              checkModelTypeGeneral()
          "
        >
          <span class="data-not-filled">
            -- {{ trans('attributes.labels.data-not-filled') }} --
          </span>
        </template>
      </span>
    </b-col>
    <b-col xl="2" lg="2">
      <span class="item-name item-type">
        <template> {{ getAttributeType() }} </template>
      </span>
    </b-col>
    <b-col xl="1" lg="1">
      <span
        class="group-action-wrap group-action-edit"
        @click="modalEdit(type, data)"
        v-if="checkModelTypeGeneral()"
      >
        <i class="fas fa-edit"></i>
      </span>
      <span
        class="group-action-wrap group-action-delete"
        v-confirm="{
          html: true,
          okText: 'Yes, delete',
          cancelText: 'Cancel',
          message: `Are you sure you want to delete <strong>${getAttributeValue(
            'name',
            lang
          )}</strong> ?`,
          animation: 'zoom',
          ok: dialog => deleteConfirmed(dialog, data)
        }"
        v-if="data.deletable == 1"
      >
        <i class="fas fa-trash-alt"></i>
      </span>
    </b-col>
  </b-row>
</template>

<script>
import Attribute from '@attributes/js/modals/Attribute'
import truncate from 'vue-truncate-collapsed'

export default {
  name: 'ListItem',
  components: {
    truncate
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    extraData: {
      type: Object,
      default: () => ({})
    },
    lang: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  methods: {
    getAttributeValue(param, lang = null) {
      if (!lang && this.data.hasOwnProperty(param)) {
        return this.data[param]
      }
      if (lang && this.data.hasOwnProperty(param)) {
        let value = this.data[param]
        if (value && value.hasOwnProperty(lang)) {
          return value[lang]
        }
        return value
      }
      return ''
    },
    getAttributeValueTrunc(param, lang = null) {
      if (!lang && this.data.hasOwnProperty(param)) {
        return this.data[param]
      }
      if (lang && this.data.hasOwnProperty(param)) {
        let value = this.data[param]
        if (value && value.hasOwnProperty(lang)) {
          return value[lang]
        }
        return value
      }
      return ''
    },
    modalEdit(type, data) {
      this.$modal.show(
        Attribute,
        {
          modal: true,
          type: type,
          mode: 'edit',
          attributeData: data
        },
        {
          clickToClose: false,
          resizable: false,
          adaptive: true,
          draggable: false,
          scrollable: true,
          height: 'auto',
          width: 1100,
          transition: 'nice-modal-fade'
        }
      )
      //this.$store.dispatch('attribute/setCurrentTab', 'Laukelio tipas')
    },
    deleteConfirmed(dialog, data) {
      if (this.type == 'form') {
        return this.$store.dispatch('attributes/removeFormRecord', data)
      }
      return this.$store.dispatch('attributes/removeListRecord', data)
    },
    getAttributeType() {
      let recordType = this.getAttributeValue('type')

      if (recordType == 'text') {
        return this.$t('attributes.types.text')
      }

      if (recordType == 'textarea') {
        return this.$t('attributes.types.textarea')
      }

      if (recordType == 'wysiwyg') {
        return this.$t('attributes.types.wysiwyg')
      }

      if (recordType == 'number') {
        return this.$t('attributes.types.number')
      }

      if (recordType == 'email') {
        return this.$t('attributes.types.email')
      }

      if (recordType == 'toggle') {
        return this.$t('attributes.types.toggle')
      }

      if (recordType == 'select') {
        return this.$t('attributes.types.select')
      }

      if (recordType == 'faq') {
        return this.$t('attributes.types.faq')
      }

      if (recordType == 'file') {
        return this.$t('attributes.types.file')
      }

      if (recordType == 'image') {
        return this.$t('attributes.types.image')
      }

      if (recordType == 'url') {
        return this.$t('attributes.types.url')
      }

      if (recordType == 'radio') {
        return this.$t('attributes.types.radio')
      }

      if (recordType == 'checkbox') {
        return this.$t('attributes.types.checkbox')
      }

      if (recordType == 'service_updated_at') {
        return this.$t('services.types.service_updated_at')
      }

      if (recordType == 'service_online') {
        return this.$t('services.types.service_online')
      }

      if (recordType == 'service_categories') {
        return this.$t('services.types.service_categories')
      }

      if (recordType == 'service_life_cases') {
        return this.$t('services.types.service_life_cases')
      }

      if (recordType == 'service_duration') {
        return this.$t('services.types.service_duration')
      }

      if (recordType == 'service_recipient') {
        return this.$t('services.types.service_recipient')
      }

      if (recordType == 'service_department') {
        return this.$t('services.types.service_department')
      }

      if (recordType == 'service_contacts') {
        return this.$t('services.types.service_contacts')
      }

      if (recordType == 'service_form_fullname') {
        return this.$t('services.types.service_form_fullname')
      }

      if (recordType == 'service_form_email') {
        return this.$t('services.types.service_form_email')
      }

      return recordType
    },
    getNameById(data, id) {
      let valObj = data.filter(function(elem) {
        if (elem.id == id) return elem
      })

      if (valObj && valObj[0]) {
        return valObj[0].name
      }

      return ''
    },
    getContactById(data, id) {
      let valObj = data.filter(function(elem) {
        if (elem.id == id) return elem
      })

      if (valObj && valObj[0]) {
        return valObj[0].name + ' ' + valObj[0].surname
      }

      return ''
    },
    checkModelTypeGeneral() {
      if (this.data.type) {
        if (this.data.type == 'text') {
          return true
        }

        if (this.data.type == 'textarea') {
          return true
        }

        if (this.data.type == 'wysiwyg') {
          return true
        }

        if (this.data.type == 'number') {
          return true
        }

        if (this.data.type == 'email') {
          return true
        }

        if (this.data.type == 'toggle') {
          return true
        }

        if (this.data.type == 'url') {
          return true
        }

        if (this.data.type == 'select') {
          return true
        }

        if (this.data.type == 'radio') {
          return true
        }

        if (this.data.type == 'checkbox') {
          return true
        }

        if (this.data.type == 'upload') {
          return true
        }

        if (this.data.type == 'file') {
          return true
        }

        if (this.data.type == 'image') {
          return true
        }

        return false
      }

      return false
    }
  }
}
</script>
