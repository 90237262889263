export default {
  path: 'categories',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: {
    label: ''
  },
  children: [
    {
      path: '/',
      name: 'categories',
      component: require('@categories/js/components/CategoryList').default,
      meta: {
        label: ''
      }
    },
    {
      path: 'create',
      name: 'categories_create',
      component: require('@categories/js/components/CategoryCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: 'subcategories/create',
      name: 'categories_sub_create',
      component: require('@categories/js/components/SubCategoryCreateForm')
        .default,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/edit',
      name: 'categories_edit',
      component: require('@categories/js/components/CategoryEditForm').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/subcategories',
      name: 'categories_subcategories',
      component: require('@categories/js/components/SubCategoryList').default,
      props: true,
      meta: {
        label: ''
      }
    },
    {
      path: ':id/subcategories/edit',
      name: 'categories_subcategories_edit',
      component: require('@categories/js/components/SubCategoryEditForm')
        .default,
      props: true,
      meta: {
        label: ''
      }
    }
  ]
}
