const defaultState = {
  fileInfoDetail: [],
  currentFolder: undefined,
  currentModule: undefined,
  currentRecord: undefined,
  currentRecordFolder: undefined,
  currentElement: undefined,
  navigation: undefined,
  isSearching: false,
  browseHistory: [],
  isLoading: true,
  data: [],
  allowFiles: false,
  allowImages: false,
  single: false
}

const actions = {
  getFolderById: ({ commit, getters }, data) => {
    let url = '/folders/' + data.folder_uid + getters.sorting.URI

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getters.token
    }

    let apiUrl = getters.api

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        baseURL: apiUrl,
        headers: configHeaders
      })
        .then(response => {
          // request succeeded
          resolve(response) // return response data to calling function
        })
        .catch(error => {
          // request failed
          reject(error) // return error to calling function
        })
    })
  },
  getFolderByIdAndSetData: ({ commit, getters }, data) => {
    let url = '/folders/' + data.folder_uid + getters.sorting.URI

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getters.token
    }

    let apiUrl = getters.api

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url,
        baseURL: apiUrl,
        headers: configHeaders
      })
        .then(response => {
          // request succeeded
          //commit('GET_FILEINFO_DETAIL', data.item)
          commit('LOADING_STATE', { loading: false, data: response.data })
          //commit('STORE_CURRENT_FOLDER', payload.folder)
          resolve(response) // return response data to calling function
        })
        .catch(error => {
          //console.log(error)
          // request failed
          reject(error) // return error to calling function
        })
    })
  },
  getFolder: ({ commit, getters }, [payload]) => {
    commit('LOADING_STATE', { loading: false, data: [] })

    if (payload.init) commit('FLUSH_FOLDER_HISTORY')

    // Clear search
    if (getters.isSearching) {
      commit('CHANGE_SEARCHING_STATE', false)
      events.$emit('clear-query')
    }

    // Set folder location
    payload.folder.location =
      payload.folder.deleted_at || payload.folder.location === 'trash'
        ? 'trash'
        : 'base'

    if (!payload.back && !payload.sorting)
      commit('STORE_PREVIOUS_FOLDER', getters.currentFolder)

    let url =
      payload.folder.location === 'trash'
        ? '/folders/' +
          payload.folder.unique_id +
          getters.sorting.URI +
          '&trash=true'
        : '/folders/' + payload.folder.unique_id + getters.sorting.URI

    const configHeaders = {
      'content-type': 'application/json',
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: 'Bearer ' + getters.token
    }

    let apiUrl = getters.api

    axios({
      method: 'get',
      url: url,
      baseURL: apiUrl,
      headers: configHeaders
    })
      .then(response => {
        commit('LOADING_STATE', { loading: false, data: response.data })
        commit('STORE_CURRENT_FOLDER', payload.folder)

        if (payload.back && !payload.sorting) commit('REMOVE_BROWSER_HISTORY')

        // events.$emit('scrollTop')
      })
      .catch(error => {
        // Redirect if unauthenticated
        if ([401, 403].includes(error.response.status)) {
          commit('SET_AUTHORIZED', false)
          router.push({ name: 'SignIn' })
        } else {
          // Show error message
          events.$emit('alert:open', {
            title: Vue.prototype.trans('popup_error.title'),
            message: Vue.prototype.trans('popup_error.message')
          })
        }
      })
  },
  getFolderTree: ({ commit, getters }) => {
    return new Promise((resolve, reject) => {
      // Get route
      let route = undefined

      if (getters.sharedDetail && getters.sharedDetail.protected)
        route = '/api/navigation/private'
      else if (getters.sharedDetail && !getters.sharedDetail.protected)
        route = '/api/navigation/public/' + router.currentRoute.params.token
      else route = '/api/navigation'

      axios
        .get(route + getters.sorting.URI)
        .then(response => {
          resolve(response)

          commit('UPDATE_FOLDER_TREE', response.data)
        })
        .catch(error => {
          reject(error)

          Vue.prototype.$isSomethingWrong()
        })
    })
  }
}

const mutations = {
  SET_MODULE_RECORD(state, data) {
    state.currentModule = data.module
    state.currentRecord = data.id
    //state.currentRecordFolder = data.folder_id
  },
  SET_ELEMENT(state, data) {
    state.currentElement = data.identifier
  },
  SET_CURRENT_FOLDER(state, folder_id) {
    state.currentRecordFolder = folder_id
  },
  LOADING_STATE(state, payload) {
    state.fileInfoDetail = []
    state.data = payload.data
    state.isLoading = payload.loading
  },
  ADD_NEW_FOLDER(state, folder) {
    state.data.unshift(folder)
  },
  ADD_NEW_ITEMS(state, items) {
    state.data = state.data.concat(items)
  },
  REMOVE_ITEM(state, unique_id) {
    state.data = state.data.filter(el => el.unique_id !== unique_id)
  },
  INCREASE_FOLDER_ITEM(state, unique_id) {
    state.data.map(el => {
      if (el.unique_id && el.unique_id == unique_id) el.items++
    })
  },
  REMOVE_BROWSER_HISTORY(state) {
    state.browseHistory.pop()
  },
  STORE_CURRENT_FOLDER(state, folder) {
    state.currentFolder = folder
  },
  REMOVE_ITEM_FILEINFO_DETAIL(state, item) {
    state.fileInfoDetail = state.fileInfoDetail.filter(
      element => element.unique_id !== item.unique_id
    )
  },
  GET_FILEINFO_DETAIL(state, item) {
    let checkData = state.data.find(el => el.unique_id == item.unique_id)

    if (state.fileInfoDetail.includes(checkData)) return

    state.fileInfoDetail.push(checkData ? checkData : state.currentFolder)
  },
  CLEAR_DATA(state) {
    state.fileInfoDetail = []
    state.currentFolder = undefined
    //state.currentRecordFolder = undefined
    state.currentElement = undefined
    state.navigation = undefined
    state.isSearching = false
    state.browseHistory = []
    state.isLoading = false
    state.data = []
  },
  CLEAR_FILEINFO_DETAIL(state) {
    state.fileInfoDetail = []
  },
  LOAD_FILEINFO_DETAIL(state, item) {
    state.fileInfoDetail = []
    state.fileInfoDetail.push(item)
  },
  SELECT_ALL_FILES(state) {
    state.fileInfoDetail = state.data
  },
  STORE_PREVIOUS_FOLDER(state, folder) {
    state.browseHistory.push(folder)
  },
  FLUSH_FOLDER_HISTORY(state) {
    state.browseHistory = []
  },
  SET_UPLOAD_RESTRICTIONS(state, restrictions) {
    state.allowFiles = restrictions.allowFiles
    state.allowImages = restrictions.allowImages
    state.single = restrictions.single
  },
  CHANGE_ITEM_NAME(state, updatedFile) {
    // Rename filename in file info detail
    if (
      state.fileInfoDetail &&
      state.fileInfoDetail.unique_id == updatedFile.unique_id
    ) {
      state.fileInfoDetail = updatedFile
    }

    // Rename item name in data view
    state.data.find(item => {
      if (item.unique_id == updatedFile.unique_id) {
        item.name = updatedFile.name
        item.icon_color = updatedFile.icon_color ? updatedFile.icon_color : null
      }
    })
  }
}

const getters = {
  fileInfoDetail: state => state.fileInfoDetail,
  currentFolder: state => state.currentFolder,
  currentModule: state => state.currentModule,
  currentRecord: state => state.currentRecord,
  currentRecordFolder: state => state.currentRecordFolder,
  currentElement: state => state.currentElement,
  browseHistory: state => state.browseHistory,
  isSearching: state => state.isSearching,
  navigation: state => state.navigation,
  isLoading: state => state.isLoading,
  data: state => state.data,
  allowFiles: state => state.allowFiles,
  allowImages: state => state.allowImages,
  single: state => state.single
}

export default {
  state: defaultState,
  getters,
  actions,
  mutations
}
