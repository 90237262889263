<template>
  <div
    :style="{ top: positionY + 'px', left: positionX + 'px' }"
    @click="closeAndResetContextMenu"
    class="contextmenu"
    v-show="isVisible || showFromPreview"
    ref="contextmenu"
    :class="{ filePreviewFixed: showFromPreview }"
  >
    <!-- Base location with MASTER permission-->
    <div
      v-if="
        $isThisLocation(['base', 'participant_uploads', 'latest']) &&
          $checkPermission('master') &&
          !showFromPreview
      "
      id="menu-list"
      class="menu-options"
    >
      <OptionGroup v-if="item && multiSelectContextMenu">
        <Option
          @click.native="renameItem"
          :title="$t('assets.menu.rename')"
          icon="rename"
        ></Option>
        <Option
          @click.native="deleteItem"
          :title="$t('assets.menu.delete')"
          icon="trash"
        ></Option>
        <Option
          @click.native="goToItem"
          :title="$t('assets.menu.preview')"
          icon="preview"
        ></Option>
        <Option
          @click.native="downloadItem"
          :title="$t('assets.menu.download')"
          icon="download"
        ></Option>
      </OptionGroup>
    </div>
  </div>
</template>

<script>
import OptionGroup from '@assets/js/components/OptionGroup'
import Option from '@assets/js/components/Option'
import { mapGetters } from 'vuex'
import { events } from '@main/js/bus'

export default {
  name: 'ContextMenu',
  components: {
    OptionGroup,
    Option
  },
  data() {
    return {
      showFromPreview: false,
      item: undefined,
      isVisible: false,
      positionX: 0,
      positionY: 0
    }
  },
  computed: {
    ...mapGetters(['user', 'fileInfoDetail']),
    hasFolder() {
      // Check if selected items includes some folder
      if (this.fileInfoDetail.find(item => item.type === 'folder')) return true
      return false
    },
    hasFile() {
      // Check if selected items includes some files
      if (this.fileInfoDetail.find(item => item.type !== 'folder')) return true
      return false
    },
    multiSelectContextMenu() {
      // If is context Menu open on multi selected items open just options for the multi selected items
      if (
        this.fileInfoDetail.length > 1 &&
        this.fileInfoDetail.includes(this.item)
      )
        return false

      // If is context Menu open for the non selected item open options for the single item
      if (
        this.fileInfoDetail.length < 2 ||
        !this.fileInfoDetail.includes(this.item)
      )
        return true

      return false
    },
    isFolder() {
      return this.item && this.item.type === 'folder'
    },
    isFile() {
      return (
        this.item &&
        this.item.type !== 'folder' &&
        this.item &&
        this.item.type !== 'image'
      )
    },
    isImage() {
      return this.item && this.item.type === 'image'
    },
    isPdf() {
      return this.item.mimetype === 'pdf'
    },
    isVideo() {
      return this.item.type === 'video'
    },
    isAudio() {
      let mimetypes = ['mpeg', 'mp3', 'mp4', 'wan', 'flac']
      return (
        mimetypes.includes(this.item.mimetype) && this.item.type === 'audio'
      )
    }
  },
  watch: {
    item(newValue, oldValue) {
      if (oldValue != undefined && this.showFromPreview) {
        this.showFromPreview = false
      }
    }
  },

  mounted() {
    events.$on('actualShowingImage:ContextMenu', item => {
      this.item = item
    })
  },
  created() {
    events.$on('showContextMenuPreview:show', item => {
      if (!this.showFromPreview) {
        this.item = item
        this.showFromPreview = true
        this.showFilePreviewMenu()
      } else if (this.showFromPreview) {
        this.showFromPreview = false
        this.item = undefined
      }
    })

    events.$on('showContextMenuPreview:hide', () => {
      this.isVisible = false
      this.showFromPreview = false
      this.item = undefined
    })

    events.$on('contextMenu:show', (event, item) => {
      // Store item
      this.item = item

      // Show context menu
      setTimeout(() => this.showContextMenu(event, item), 10)
    })

    events.$on('unClick', () => this.closeAndResetContextMenu())

    events.$on('folder:actions', folder => {
      // Store item
      this.item = folder

      if (this.isVisible) this.isVisible = false
      else this.showFolderActionsMenu()
    })
  },

  methods: {
    goToItem() {
      if (this.isImage || this.isVideo || this.isAudio || this.isPdf) {
        events.$emit('fileFullPreview:show')
      } else if (
        this.isFile ||
        (!this.isFolder &&
          !this.isVideo &&
          !this.isAudio &&
          !this.isImage &&
          !this.isPdf)
      ) {
        this.$downloadFile(
          this.item.file_url,
          this.item.name + '.' + this.item.mimetype
        )
      } else if (this.isFolder) {
        //Clear selected data after open another folder
        this.$store.commit('CLEAR_FILEINFO_DETAIL')

        if (this.$isThisLocation('public')) {
          this.$store.dispatch('browseShared', [
            { folder: this.item, back: false, init: false }
          ])
        } else {
          this.$store.dispatch('getFolder', [
            { folder: this.item, back: false, init: false }
          ])
        }
      }
    },
    downloadFolder() {
      this.$store.dispatch('downloadFolder', this.item)
    },
    emptyTrash() {
      this.$store.dispatch('emptyTrash')
    },
    restoreItem() {
      // If is item not in selected items restore just this single item
      if (!this.fileInfoDetail.includes(this.item))
        this.$store.dispatch('restoreItem', this.item)

      // If is item in selected items restore all items from fileInfoDetail
      if (this.fileInfoDetail.includes(this.item))
        this.$store.dispatch('restoreItem', null)
    },
    shareCancel() {
      this.$store.dispatch('shareCancel')
    },
    renameItem() {
      events.$emit('popup:open', { name: 'rename-item', item: this.item })
    },
    moveItem() {
      events.$emit('popup:open', { name: 'move', item: [this.item] })
    },

    downloadItem() {
      if (this.fileInfoDetail.length > 1) this.$store.dispatch('downloadFiles')
      else {
        this.$downloadFile(
          this.item.file_url,
          this.item.name + '.' + this.item.mimetype
        )
      }
    },
    deleteItem() {
      // If is context menu open on non selected item delete this single item
      if (!this.fileInfoDetail.includes(this.item)) {
        this.$store.dispatch('deleteItem', this.item)
      }
      // If is context menu open to multi selected items dele this selected items
      if (this.fileInfoDetail.includes(this.item)) {
        this.$store.dispatch('deleteItem')
      }
    },
    closeAndResetContextMenu() {
      // Close context menu
      this.isVisible = false

      // Reset item container
      this.item = undefined
    },
    showContextMenu(event) {
      let parent = document.getElementById('menu-list')
      let nodesSameClass = parent.getElementsByClassName('menu-option')

      let VerticalOffsetArea = nodesSameClass.length * 35
      let HorizontalOffsetArea = 190

      let container = document.getElementById('files-view')

      var offset = container.getClientRects()[0]

      let x = event.clientX - offset.left
      let y = event.clientY - offset.top

      // Set position Y
      if (container.offsetHeight - y < VerticalOffsetArea) {
        this.positionY = y - VerticalOffsetArea
      } else {
        this.positionY = y
      }

      // Set position X
      if (container.offsetWidth - x < HorizontalOffsetArea) {
        this.positionX = x - HorizontalOffsetArea
      } else {
        this.positionX = x
      }

      // Show context menu
      this.isVisible = true
    },
    showFilePreviewMenu() {
      let container = document.getElementById('fast-preview-menu')
      if (container) {
        this.positionX = container.offsetLeft + 16
        this.positionY = container.offsetTop + 60
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';
.no-options {
  /deep/ .text-label {
    color: $text-muted !important;
  }

  /deep/ &:hover {
    background: transparent;
  }

  /deep/ path,
  /deep/ line,
  /deep/ circle {
    stroke: $text-muted !important;
  }
}

.filePreviewFixed {
  position: fixed !important;
  display: flex;
}

.contextmenu {
  min-width: 150px;
  position: absolute;
  z-index: 99;
  box-shadow: $shadow;
  background: white;
  border-radius: 8px;
  overflow: hidden;

  &.showed {
    display: block;
  }
}

.menu-options {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
</style>
