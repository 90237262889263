<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form>
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('translations.title.sync') }}
            </h3>
            <div class="multi-lang multi-lang-open">
              {{ trans('translations.message.sync_progress') }}
            </div>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'

export default {
  title: '',
  name: 'TranslationSyncProgress',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    },
    area: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'translation',
      resourceRoute: 'translations',
      listPath: '/translations',
      model: {}
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.trans('translations.title.sync')
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    }
  }
}
</script>
