import axios from 'axios'
import Vue from 'vue'
const state = {
  data: {
    data_list: [],
    data_form: []
  }
}

const mutations = {
  addListRecord(state, record) {
    state.data.data_list.push(record)
  },
  addFormRecord(state, record) {
    state.data.data_form.push(record)
  },
  removeListRecord(state, item) {
    const index = state.data.data_list.findIndex(
      record => record.identifier === item.identifier
    )
    state.data.data_list.splice(index, 1)
    Vue.prototype.$app.noty['success'](
      Vue.prototype.trans('attributes.alerts.list-list-removed')
    )
  },
  removeFormRecord(state, item) {
    const index = state.data.data_form.findIndex(
      record => record.identifier === item.identifier
    )
    state.data.data_form.splice(index, 1)
    Vue.prototype.$app.noty['success'](
      Vue.prototype.trans('attributes.alerts.list-form-removed')
    )
  },
  emptyFields(state, data) {
    state.data.data.name = ''
  },
  setDataList(state, data) {
    state.data.data_list = data ? data : {}
  },
  setDataForm(state, data) {
    state.data.data_form = data ? data : {}
  }
}

const getters = {
  data(state) {
    return state.data
  }
}

const actions = {
  addListRecord({ commit, dispatch, state }, record) {
    commit('addListRecord', record)
  },
  addFormRecord({ commit, dispatch, state }, record) {
    commit('addFormRecord', record)
  },
  removeListRecord({ commit, dispatch }, record) {
    commit('removeListRecord', record)
  },
  removeFormRecord({ commit, dispatch }, record) {
    commit('removeFormRecord', record)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
