<template>
  <button class="button outline">
    <span class="text-label">{{ text }}</span>

    <span v-if="loading" class="icon">
      <font-awesome-icon
        icon="circle-notch"
        class="sync-alt"
      ></font-awesome-icon>
    </span>
    <span v-if="!loading && icon" class="icon">
      <font-awesome-icon :icon="icon"></font-awesome-icon>
    </span>
  </button>
</template>

<script>
export default {
  name: 'AuthContent',
  props: {
    icon: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  created() {
    this.isVisible = this.visible
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.button {
  cursor: pointer;
  border-radius: 6px;
  text-decoration: none;
  padding: 10px 35px;
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
  white-space: nowrap;
  @include transition(150ms);
  background: transparent;

  .text-label {
    @include transition(150ms);
    @include font-size(17);
    font-weight: 800;
    line-height: 0;
  }

  .icon {
    margin-left: 12px;
    @include font-size(16);
  }

  &.solid {
    background: $theme-main;
    border: 2px solid $theme-main;

    .text-label {
      color: $theme_light;
    }
  }

  &.outline {
    border: 2px solid $theme-main;
    background: $theme-main;

    .text-label {
      color: $theme_light;
    }

    .icon {
      path {
        fill: $theme_light;
      }
    }

    &:hover {
      border-color: $theme;
      background: $theme;

      .text-label {
        color: $theme_light;
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  .button {
    &.outline {
      background: $dark_mode_background;
      border-color: $dark_mode_text_primary;

      .text-label {
        color: $dark_mode_text_primary;
      }
    }
  }
}

.sync-alt {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
