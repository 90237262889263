<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('email-services.title.send-list-create') }}
            </h3>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                :label="$t('email-services.field.send-list-name') + ' *'"
                label-for="name"
                :label-cols="3"
                :feedback="feedback('name')"
                :description="$t('email-services.note.send-list-internal-use')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fab fa-autoprefixer"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="name"
                        name="name"
                        v-model="model.name"
                        :state="state('name')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('name') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="is_click_tracking"
                    v-model="model.as_emails"
                    :description="$t('email-services.field.as-emails')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <div class="form-group">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="is_click_tracking"
                    v-model="model.as_notifications"
                    :description="$t('email-services.field.as-notifications')"
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                :label="$t('email-services.field.send-list-subject') + ' *'"
                label-for="subject"
                :label-cols="3"
                :feedback="feedback('subject')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fab fa-autoprefixer"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="subject"
                        name="subject"
                        v-model="model.subject"
                        :state="state('subject')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('subject') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <div class="multi-lang multi-lang-open" v-if="model.as_emails">
              <b-form-group
                :label="$t('email-services.field.send-list-from-name') + ' *'"
                label-for="from_name"
                :label-cols="3"
                :feedback="feedback('from_name')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fab fa-autoprefixer"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="from_name"
                        name="from_name"
                        v-model="model.from_name"
                        :state="state('from_name')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('from_name') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <div class="multi-lang multi-lang-open" v-if="model.as_emails">
              <b-form-group
                :label="$t('email-services.field.send-list-from-email') + ' *'"
                label-for="from_email"
                :label-cols="3"
                :feedback="feedback('from_email')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-at"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="from_email"
                        name="from_email"
                        v-model="model.from_email"
                        :state="state('from_email')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('from_email') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <div class="multi-lang multi-lang-open" v-if="model.as_emails">
              <b-form-group
                :label="$t('email-services.field.template-id')"
                label-for="template_id"
                horizontal
                :label-cols="3"
                :feedback="feedback('template_id')"
                :state="state('template_id')"
              >
                <b-form-select
                  id="template_id"
                  name="template_id"
                  v-model="model.template_id"
                  :options="templates"
                >
                  <template slot="first">
                    <option :value="null" selected>
                      - None -
                    </option>
                  </template>
                </b-form-select>
                <b-form-feedback>
                  {{ feedback('template_id') }}
                </b-form-feedback>
              </b-form-group>
            </div>

            <div class="multi-lang multi-lang-open" v-if="model.as_emails">
              <b-form-group
                :label="$t('email-services.field.email-service') + ' *'"
                label-for="email_service_id"
                horizontal
                :label-cols="3"
                :feedback="feedback('email_service_id')"
                :state="state('email_service_id')"
              >
                <b-form-select
                  id="email_service_id"
                  name="email_service_id"
                  v-model="model.email_service_id"
                  :options="email_services"
                >
                  <template slot="first">
                    <option :value="null">
                      -- Select Email Service --
                    </option>
                  </template>
                </b-form-select>
                <b-form-feedback>
                  {{ feedback('email_service_id') }}
                </b-form-feedback>
              </b-form-group>
            </div>

            <div class="form-group" v-if="model.as_emails">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="is_open_tracking"
                    v-model="model.is_open_tracking"
                    :description="
                      $t('email-services.field.send-list-is-open-tracking')
                    "
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <div class="form-group" v-if="model.as_emails">
              <b-row>
                <b-col lg="9" offset-lg="3">
                  <c-switch
                    name="is_click_tracking"
                    v-model="model.is_click_tracking"
                    :description="
                      $t('email-services.field.send-list-is-click-tracking')
                    "
                  ></c-switch>
                </b-col>
              </b-row>
            </div>

            <b-form-group
              :label="$t('email-services.field.send-list-content')"
              :label-for="content"
              label-cols-sm="3"
              :feedback="feedback('content')"
            >
              <p-richtexteditor
                id="content"
                name="content"
                v-model="model.content"
              ></p-richtexteditor>
              <b-form-feedback>
                {{ feedback('content') }}
              </b-form-feedback>
            </b-form-group>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button to="/send/list" exact variant="danger" size="sm">
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('email-services.title.send-list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'SendListCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'send_list',
      resourceRoute: 'send_lists',
      listPath: '/send/lists',
      templates: [],
      email_services: [],
      model: {
        // Data
        as_notifications: false,
        as_emails: false,
        name: '',
        subject: '',
        from_name: '',
        from_email: '',
        template_id: null,
        email_service_id: '',
        is_open_tracking: true,
        is_click_tracking: true,
        content: '',
        // Routes
        routes: {}
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('email-services.title.send-list-create')
    this.fetchTemplates()
    this.fetchEmailServices()
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    async fetchTemplates() {
      let { data } = await axios.get(
        this.$app.route('email_templates.get_templates')
      )
      this.templates = data.items
    },
    async fetchEmailServices() {
      let { data } = await axios.get(
        this.$app.route('email_services.get_email_services')
      )
      this.email_services = data.items
    }
  }
}
</script>
