<template>
  <div :class="modal ? 'vue-dialog' : ''">
    <form @submit.prevent="onSubmit">
      <b-row>
        <b-col v-if="modal ? (xl = '12') : (xl = '6')">
          <b-card>
            <h3 class="card-title" slot="header">
              {{ $t('email-services.titles.create') }}
            </h3>

            <div class="multi-lang multi-lang-open">
              <b-form-group
                :label="$t('email-services.field.name') + ' *'"
                label-for="name"
                :label-cols="3"
                :feedback="feedback('name')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fab fa-autoprefixer"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="name"
                        name="name"
                        v-model="model.name"
                        :state="state('name')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('name') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
            <div class="multi-lang multi-lang-open">
              <b-form-group
                :label="$t('email-services.field.service-type') + ' *'"
                label-for="type_id"
                horizontal
                :label-cols="3"
                :feedback="feedback('type_id')"
                :state="state('type_id')"
              >
                <b-form-select
                  id="type_id"
                  name="type_id"
                  v-model="model.type_id"
                  :options="service_types"
                >
                  <template slot="first">
                    <option :value="null">
                      -- Select Service Type --
                    </option>
                  </template>
                </b-form-select>
                <b-form-feedback>
                  {{ feedback('type_id') }}
                </b-form-feedback>
              </b-form-group>
            </div>

            <!-- Sendgrid -->
            <div class="multi-lang multi-lang-open" v-if="model.type_id == 2">
              <b-form-group
                :label="$t('email-services.field.service-type-api') + ' *'"
                label-for="settings[key]"
                :label-cols="3"
                :feedback="feedback('settings.key')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-key"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="settings.key"
                        name="settings[key]"
                        v-model="model.settings['key']"
                        :state="state('settings.key')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.key') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <!-- SMTP -->
            <div class="multi-lang multi-lang-open" v-if="model.type_id == 6">
              <b-form-group
                :label="$t('email-services.field.service-host') + ' *'"
                label-for="settings[host]"
                :label-cols="3"
                :feedback="feedback('settings.host')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.host"
                        name="settings[host]"
                        v-model="model.settings['host']"
                        :state="state('settings.host')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.host') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-port') + ' *'"
                label-for="settings[port]"
                :label-cols="3"
                :feedback="feedback('settings.port')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.port"
                        name="settings[port]"
                        type="number"
                        v-model="model.settings['port']"
                        :state="state('settings.port')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.port') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-encryption') + ' *'"
                label-for="settings[encryption]"
                :label-cols="3"
                :feedback="feedback('settings.encryption')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.encryption"
                        name="settings[encryption]"
                        v-model="model.settings['encryption']"
                        :state="state('settings.encryption')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.encryption') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-username') + ' *'"
                label-for="settings[username]"
                :label-cols="3"
                :feedback="feedback('settings.username')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.username"
                        name="settings[username]"
                        v-model="model.settings['username']"
                        :state="state('settings.username')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.username') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-password') + ' *'"
                label-for="settings[password]"
                :label-cols="3"
                :feedback="feedback('settings.password')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.password"
                        name="settings[password]"
                        type="password"
                        v-model="model.settings['password']"
                        :state="state('settings.password')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.password') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>

            <!-- Mailgun -->
            <div class="multi-lang multi-lang-open" v-if="model.type_id == 3">
              <b-form-group
                :label="$t('email-services.field.service-type-api') + ' *'"
                label-for="settings[key]"
                :label-cols="3"
                :feedback="feedback('settings.key')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-key"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="settings.key"
                        name="settings[key]"
                        v-model="model.settings['key']"
                        :state="state('settings.key')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.key') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-domain') + ' *'"
                label-for="settings[domain]"
                :label-cols="3"
                :feedback="feedback('settings.domain')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.domain"
                        name="settings[domain]"
                        v-model="model.settings['domain']"
                        :state="state('settings.domain')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.domain') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-zone') + ' *'"
                label-for="settings[zone]"
                horizontal
                :label-cols="3"
                :feedback="feedback('settings.zone')"
              >
                <b-form-select
                  id="settings.zone"
                  name="settings[zone]"
                  v-model="model.settings['zone']"
                  :options="mailgun_zones"
                >
                  <template slot="first">
                    <option :value="null">
                      -- Select Zone --
                    </option>
                  </template>
                </b-form-select>
                <b-form-feedback>
                  {{ feedback('settings.zone') }}
                </b-form-feedback>
              </b-form-group>
            </div>

            <!-- MailJet -->
            <div class="multi-lang multi-lang-open" v-if="model.type_id == 5">
              <b-form-group
                :label="$t('email-services.field.service-type-api') + ' *'"
                label-for="settings[key]"
                :label-cols="3"
                :feedback="feedback('settings.key')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-input-group-prepend class="input-group-prepend-title">
                        <b-input-group-text data-toggle>
                          <i class="fas fa-key"></i>
                        </b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input
                        id="settings.key"
                        name="settings[key]"
                        v-model="model.settings['key']"
                        :state="state('settings.key')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.key') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-secret') + ' *'"
                label-for="settings[secret]"
                :label-cols="3"
                :feedback="feedback('settings.secret')"
              >
                <b-row>
                  <b-col md="12">
                    <b-input-group>
                      <b-form-input
                        id="settings.secret"
                        name="settings[secret]"
                        type="password"
                        v-model="model.settings['secret']"
                        :state="state('settings.secret')"
                      ></b-form-input>
                      <b-form-feedback>
                        {{ feedback('settings.password') }}
                      </b-form-feedback>
                    </b-input-group>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group
                :label="$t('email-services.field.service-zone') + ' *'"
                label-for="settings[zone]"
                horizontal
                :label-cols="3"
                :feedback="feedback('settings.zone')"
              >
                <b-form-select
                  id="settings.zone"
                  name="settings[zone]"
                  v-model="model.settings['zone']"
                  :options="mailjet_zones"
                >
                  <template slot="first">
                    <option :value="null">
                      -- Select Zone --
                    </option>
                  </template>
                </b-form-select>
                <b-form-feedback>
                  {{ feedback('settings.zone') }}
                </b-form-feedback>
              </b-form-group>
            </div>

            <template v-if="!modal">
              <b-row slot="footer">
                <b-col>
                  <b-button
                    to="/email/services"
                    exact
                    variant="danger"
                    size="sm"
                  >
                    {{ $t('buttons.back') }}
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    type="submit"
                    variant="success"
                    size="sm"
                    class="float-right"
                    :disabled="pending"
                    v-if="isNew"
                  >
                    {{ $t('buttons.create') }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template v-if="modal">
              <action-footer-modal
                :page-title="$t('email-services.titles.list')"
                :type="modelName"
                :cancel-enabled="true"
                :save-enabled="true"
                :save-continue-enabled="true"
                :save-permission="resourceRoute"
              >
              </action-footer-modal>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import form from '@core/js/mixins/form'
import axios from 'axios'

export default {
  title: '',
  name: 'EmailServiceCreateForm',
  mixins: [form],
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      modelName: 'email_service',
      resourceRoute: 'email_services',
      listPath: '/email/services',
      service_types: [],
      model: {
        // Data
        name: '',
        type_id: '',
        settings: [],
        // Routes
        routes: {}
      },
      mailgun_zones: {
        EU: 'EU',
        US: 'US'
      },
      mailjet_zones: {
        Default: 'Default',
        US: 'US'
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('email-services.titles.create')
    this.fetchServiceTypes()
  },
  methods: {
    onModelChanged() {},
    refreshData() {
      this.$eventHub.$emit('refresh-data')
    },
    async fetchServiceTypes() {
      let { data } = await axios.get(
        this.$app.route('email_services.get_service_types')
      )
      this.service_types = data.items
    }
  }
}
</script>
