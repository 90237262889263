<template>
  <div class="auth-form" v-if="isVisible">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'AuthContent',
  props: {
    name: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  created() {
    this.isVisible = this.visible
  }
}
</script>
