<template>
  <div>
    <action-header
      :type="modelName"
      :title="title"
      :subtitle="subTitle"
      :back-enabled="true"
      :back-url="listPath"
    ></action-header>
    <form @submit.prevent="onSubmit">
      <vue-tabs
        active-tab-color="#7a7cbd"
        active-text-color="white"
        type="pills"
        :start-index="1"
        direction="vertical"
        @tab-change="tabAction"
      >
        <v-tab :title="$t('labels.main_content')" id="main">
          <b-row class="left-content-center">
            <b-col xl="9" lg="12">
              <b-card>
                <h3 class="header-card-title card-title" slot="header">
                  {{ $t('labels.main_content') }}
                </h3>

                <b-form-group
                  :label="$t('validation.attributes.name')"
                  label-for="name"
                  horizontal
                  :label-cols="2"
                  :feedback="feedback('name')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-input
                        id="name"
                        name="name"
                        required
                        :placeholder="$t('validation.attributes.name')"
                        v-model="model.name"
                        :state="state('name')"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.display_name')"
                  label-for="display_name"
                  horizontal
                  :label-cols="2"
                  :feedback="feedback('display_name')"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-input
                        id="display_name"
                        name="display_name"
                        required
                        :placeholder="$t('validation.attributes.display_name')"
                        v-model="model.display_name"
                        :state="state('display_name')"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.description')"
                  label-for="description"
                  horizontal
                  :label-cols="2"
                  :feedback="feedback('description')"
                >
                  <b-form-input
                    id="description"
                    name="description"
                    :placeholder="$t('validation.attributes.description')"
                    v-model="model.description"
                    :state="state('description')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('validation.attributes.order')"
                  label-for="order"
                  horizontal
                  :label-cols="2"
                >
                  <b-row>
                    <b-col md="3">
                      <b-form-input
                        id="order"
                        name="order"
                        type="number"
                        required
                        v-model="model.order"
                      ></b-form-input>
                    </b-col>
                  </b-row>
                </b-form-group>

                <div class="form-group">
                  <b-row>
                    <label class="col-sm-2 col-form-label">
                      {{ $t('validation.attributes.permissions') }}
                    </label>
                    <b-col sm="10">
                      <b-row>
                        <b-col
                          md="6"
                          xl="4"
                          class="mb-3"
                          v-for="category in permissions"
                          :key="category.title"
                        >
                          <h4>{{ $t(category.title) }}</h4>
                          <b-form-checkbox-group
                            stacked
                            v-model="model.permissions"
                            name="permissions[]"
                          >
                            <b-form-checkbox
                              v-for="permission in category.permissions"
                              :key="permission.name"
                              v-b-tooltip.left
                              :title="$t(permission.description)"
                              :value="permission.name"
                            >
                              {{ $t(permission.display_name) }}
                            </b-form-checkbox>
                          </b-form-checkbox-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </v-tab>

        <action-footer
          :type="modelName"
          :back-enabled="true"
          :back-url="listPath"
          :save-enabled="true"
          :save-continue-enabled="true"
          :save-permission="resourceRoute"
        >
        </action-footer>
      </vue-tabs>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import form from '@core/js/mixins/form'
import { VueTabs, VTab } from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import _ from 'lodash'
import { groupBy, forEach } from 'lodash/collection'

export default {
  name: `RoleForm`,
  components: {
    VueTabs,
    VTab
  },
  mixins: [form],
  data() {
    return {
      permissions: [],
      title: '',
      subTitle: '',
      modelName: 'role',
      resourceRoute: 'roles',
      listPath: '/roles',
      model: {
        // Data
        name: null,
        display_name: null,
        description: null,
        order: 0,
        permissions: [],
        content: {},
        position: 0,
        enabled: false,
        // Routes
        routes: {},
        // Meta
        metadata: {
          title: {},
          description: {},
          keywords: {}
        }
      },
      data: {},
      meta: {}
    }
  },
  async created() {
    this.fetchData()

    let { data } = await axios.get(this.$app.route(`roles.get_permissions`))

    let categories = _.groupBy(
      _.forEach(data, (value, key) => {
        value['name'] = key
      }),
      'category'
    )

    this.permissions = Object.keys(categories).map(key => {
      return {
        title: key,
        permissions: categories[key]
      }
    })
  },
  mounted() {
    this.$title = this.$t('categories.titles.edit')
  },
  beforeUpdate: function() {
    this.title = this.meta.title
    this.subTitle = this.$t('categories.titles.unit')
  },
  methods: {
    onModelChanged() {
      if (this.model.permissions) {
        this.model.permissions = this.model.permissions.map(item => {
          return item.name
        })
      }
    },
    tabAction(tabIndex, newTab, oldTab) {}
  }
}
</script>
