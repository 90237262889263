<template>
  <div class="navigation-panel" v-if="fileInfoDetail[0]">
    <div class="row row-panel">
      <div class="col-2 toolbar-btn-wrap">
        <ToolbarButton
          v-if="this.fileInfoDetail[0].type === 'image'"
          source="print"
          :action="$t('assets.button.print')"
          @click.native="printMethod()"
        ></ToolbarButton>
        <ToolbarButton
          source="download"
          class="mobile-hide"
          @click.native="downloadItem"
          :action="$t('assets.button.download')"
        ></ToolbarButton>
      </div>
      <div class="col-8 toolbar-info-wrap">
        <p class="title">
          {{ fileInfoDetail[0].name }} | {{ fileInfoDetail[0].filesize }}
        </p>
        <span class="file-count">
          {{
            showingImageIndex +
              ' ' +
              $t('assets.label.of') +
              ' ' +
              filteredFiles.length
          }}
        </span>
      </div>
      <div class="col-2 close-col">
        <ToolbarButton
          source="close"
          class="mobile-hide"
          @click.native="closeFullPreview"
          :action="$t('assets.button.close')"
        ></ToolbarButton>
      </div>
    </div>
  </div>
</template>

<script>
import { events } from '@main/js/bus'
import { mapGetters } from 'vuex'

import ToolbarButton from '@assets/js/components/ToolbarButton'

export default {
  name: 'FilePreviewNavigationPanel',
  components: { ToolbarButton },
  data() {
    return {
      showContextMenu: false
    }
  },
  computed: {
    ...mapGetters(['fileInfoDetail', 'data']),
    filteredFiles() {
      let files = []
      this.data.filter(element => {
        if (element.type == this.fileInfoDetail[0].type) {
          files.push(element)
        }
      })
      return files
    },
    showingImageIndex() {
      let activeIndex = ''
      this.filteredFiles.filter((element, index) => {
        if (element.unique_id == this.fileInfoDetail[0].unique_id) {
          activeIndex = index + 1
        }
      })
      return activeIndex
    },
    canShowShareView() {
      return this.$isThisLocation([
        'base',
        'participant_uploads',
        'latest',
        'shared'
      ])
    },
    canShareInView() {
      return !this.$isThisLocation([
        'base',
        'participant_uploads',
        'latest',
        'shared'
      ])
    }
  },

  methods: {
    printMethod() {
      var tab = document.getElementById('image')
      var win = window.open('', '', 'height=700,width=700')
      win.document.write(tab.outerHTML)
      win.document.close()
      win.print()
    },
    downloadItem() {
      this.$downloadFile(
        this.fileInfoDetail[0].file_url,
        this.fileInfoDetail[0].name + '.' + this.fileInfoDetail[0].mimetype
      )
    },
    menuOpen() {
      if (this.$isMobile()) {
        events.$emit('mobileMenu:show', 'showFromMediaPreview')
      } else {
        events.$emit('showContextMenuPreview:show', this.fileInfoDetail[0])
      }
    },
    closeFullPreview() {
      events.$emit('fileFullPreview:hide')
      events.$emit('showContextMenuPreview:hide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.row-panel {
  width: 100%;

  .toolbar-btn-wrap {
    text-align: left;
    padding-left: 30px;
  }

  .close-col {
    text-align: right;
    .icon-close {
      float: right;
      width: 30px;
      height: 30px;
      display: block;
      padding: 1px 4px;
      border-radius: 6px;
      vertical-align: middle;
      cursor: pointer;
      color: #ffffff;
      @include transition(150ms);

      &:hover {
        color: $color-red;
        line {
          stroke: $color-red;
        }
      }
    }
  }
}

.name-wrapper {
  width: 33%;
  height: 22px;
  display: flex;
  position: relative;
  align-items: center;
  flex-grow: 1;
  align-self: center;
  white-space: nowrap;

  .name-count-wrapper {
    margin-left: 6px;
    margin-right: 6px;

    .file-count {
      @include font-size(15);
      line-height: 1;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
      align-self: center;
      color: $text;
    }
    .title {
      @include font-size(15);
      max-width: 250px;
      line-height: 1;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
      color: $text;
    }
    @media (max-width: 570px) {
      .title {
        max-width: 180px;
        @include font-size(17);
      }
      .file-count {
        @include font-size(17);
      }
    }
  }
}
.context-menu {
  min-width: 250px;
  position: absolute;
  z-index: 99;
  box-shadow: $shadow;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  top: 29px;

  &.showed {
    display: block;
  }
}

.created-at-wrapper {
  width: 33%;
  display: flex;
  text-align: center;
  justify-content: center;

  p {
    display: flex;
    align-items: center;
    @include font-size(11);
  }
}

.toolbar-info-wrap {
  text-align: center;
  .title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.7px;
    color: #afafaf;
    margin-bottom: 3px;
  }
}

.navigation-icons {
  width: 33%;
  text-align: right;

  .navigation-tool-wrapper {
    margin-left: 28px;
    display: inline-block;
    vertical-align: middle;
  }

  .button {
    margin-left: 5px;
    &:hover {
      background: $light_background;
    }
  }
}

.navigation-panel {
  height: 63px;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  position: absolute;
  z-index: 8;
  align-items: center;
  background-color: #525f75;
  color: #ffffff;
  box-shadow: 0 8px 40px rgba(17, 26, 52, 0.5);
}

@media (max-width: 960px) {
  .context-menu {
    .name-wrapper {
      width: 67%;
    }
  }

  .navigation-icons {
    display: none;
  }

  .navigation-panel {
    height: 53px;
    padding: 15px;
  }

  .created-at-wrapper {
    display: none;
  }

  .name-wrapper {
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
  }
}
</style>
