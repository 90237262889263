<template>
  <label
    :class="buttonStyle"
    label="file"
    class="button file-input button-base"
  >
    <slot></slot>
    <input
      v-show="false"
      @change="emmitFiles"
      id="file"
      type="file"
      name="files[]"
      :accept="acceptTypes"
      multiple
    />
  </label>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ButtonUpload',
  props: {
    buttonStyle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      files: undefined
    }
  },
  computed: {
    ...mapGetters(['allowFiles', 'allowImages', 'single'])
  },
  methods: {
    emmitFiles(e) {
      this.$uploadFiles(e.target.files)
    },
    acceptTypes() {
      if (this.allowFiles) {
        return '*'
      }
      return 'image/*'
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.button-base {
  @include font-size(15);
  font-weight: 700;
  cursor: pointer;
  transition: 0.15s all ease;
  border-radius: 8px;
  border: 0;
  padding: 10px 28px;
  display: inline-block;

  &:active {
    transform: scale(0.95);
  }

  &.theme {
    color: $theme;
    background: rgba($theme, 0.1);
  }

  &.secondary {
    color: $text;
    background: $light_background;
  }
}
</style>
