<template>
  <div>
    <b-row class="attribute-data-block options-wrap">
      <b-col md="12">
        <div class="multi-lang multi-lang-open">
          <b-form-group
            :label="$t('attributes.label.attribute-type')"
            label-for="attribute-type"
            :label-cols="3"
          >
            <b-input-group>
              <div class="attribute-type-block">- {{ getAttributeType() }}</div>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col
        md="12"
        v-if="
          model.type == 'select' ||
            model.type == 'radio' ||
            model.type == 'checkbox' ||
            model.type == 'faq' ||
            model.type == 'url'
        "
      >
        <draggable
          class="row"
          :options="{
            group: {
              name: 'groups',
              pull: false,
              put: false
            }
          }"
          v-model="model.options"
          v-if="model.options && model.options != 'undefined'"
        >
          <div
            v-for="(item, index) in model.options"
            :key="index"
            class="item draggable group col-12 attribute-item"
            v-if="model.options"
          >
            <div class="row">
              <div
                class="col-md-9 col-lg-11 col-xl-11"
                v-if="model.type !== 'url'"
              >
                <span class="item-name item-option">
                  <i class="fas fa-arrows-alt option-arrow"></i>
                  <input
                    :id="item.id"
                    name="item_name[]"
                    type="text"
                    class="form-control form-control-option"
                    v-model="item.text"
                  />
                </span>
              </div>
              <div
                class="col-md-9 col-lg-11 col-xl-11"
                v-if="model.type == 'url'"
              >
                <b-row class="attribute-url-block">
                  <div class="col-md-2 col-lg-2 col-xl-2 col-url-pre">
                    <span class="item-name item-option">
                      <i class="fas fa-arrows-alt option-arrow"></i>
                    </span>
                    <span class="url-block-label">Pavadinimas</span>
                  </div>
                  <div class="col-md-9 col-lg-9 col-xl-9">
                    <span class="item-name item-option">
                      <input
                        :id="item.id"
                        name="item_name[]"
                        type="text"
                        class="form-control form-control-option"
                        v-model="item.text"
                      />
                    </span>
                  </div>
                </b-row>
                <b-row class="attribute-url-block">
                  <div class="col-md-2 col-lg-2 col-xl-2">
                    <span class="url-block-label">Nuoroda</span>
                  </div>
                  <div class="col-md-9 col-lg-9 col-xl-9">
                    <span class="item-name item-option">
                      <input
                        :id="`item_content_${item.id}`"
                        name="item_content[]"
                        type="text"
                        class="form-control form-control-option"
                        v-model="item.content"
                      />
                    </span>
                  </div>
                </b-row>
              </div>
              <div class="col-md-3 col-lg-1 col-xl-1">
                <span
                  class="group-action-wrap group-action-delete"
                  v-confirm="{
                    html: true,
                    okText: 'Yes, delete',
                    cancelText: 'Cancel',
                    message: `Are you sure you want to delete <strong>${getAttributeValue(
                      item.text
                    )}</strong> ?`,
                    animation: 'zoom',
                    ok: dialog => deleteConfirmed(dialog, item)
                  }"
                >
                  <i class="fas fa-trash-alt"></i>
                </span>
              </div>
            </div>
          </div>
          <div
            v-else
            class="item group col-12 attribute-item attribute-item-empty"
          >
            {{ $t('attributes.alert.empty') }}
          </div>
        </draggable>
      </b-col>
      <b-col
        md="12"
        v-if="
          model.type == 'select' ||
            model.type == 'radio' ||
            model.type == 'checkbox' ||
            model.type == 'faq' ||
            model.type == 'url'
        "
        class="option-btn-wrap"
      >
        <button
          type="button"
          class="btn btn-success btn-sm btn-option-add"
          @click="addItem"
        >
          <i class="fas fa-plus"></i>
          Add option
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'AttributeOptions',
  components: {
    draggable
  },
  props: {},
  data() {
    return {}
  },
  computed: {
    mode() {
      return this.$store.getters['attribute/mode']
    },
    model() {
      return this.$store.getters['attribute/data']
    },
    attribute() {
      return this.$store.getters['attribute/attribute']
    },
    errors() {
      return this.$store.getters['attribute/errors']
    }
  },
  methods: {
    addItem: function() {
      let identifier = this.generateIdentifier()
      this.$store.dispatch('attribute/addEmptyItem', {
        identifier: identifier
      })
    },
    removeItem(id) {
      this.$store.dispatch('attribute/removeItem', id)
    },
    deleteConfirmed(dialog, item) {
      return this.$store.dispatch('attribute/removeOptionRecord', item)
    },
    getAttributeValue(data) {
      if (data) {
        return data
      }
      return ''
    },
    generateIdentifier: function() {
      return Math.round(+new Date() / 1000) + this.rndNumber(4) // unix timestamp
    },
    rndNumber(len) {
      let number = ''
      let chars = '1234567890'

      for (let i = 0; i < len; i++) {
        number += chars.charAt(Math.floor(Math.random() * chars.length))
      }

      return number
    },
    getAttributeType() {
      let recordType = this.model.type

      if (recordType == 'text') {
        return this.$t('attributes.types.text')
      }

      if (recordType == 'textarea') {
        return this.$t('attributes.types.textarea')
      }

      if (recordType == 'wysiwyg') {
        return this.$t('attributes.types.wysiwyg')
      }

      if (recordType == 'number') {
        return this.$t('attributes.types.number')
      }

      if (recordType == 'url') {
        return this.$t('attributes.types.url')
      }

      if (recordType == 'email') {
        return this.$t('attributes.types.email')
      }

      if (recordType == 'upload') {
        return this.$t('attributes.types.upload')
      }

      if (recordType == 'radio') {
        return this.$t('attributes.types.radio')
      }

      if (recordType == 'checkbox') {
        return this.$t('attributes.types.checkbox')
      }

      if (recordType == 'select') {
        return this.$t('attributes.types.select')
      }

      if (recordType == 'service_updated_at') {
        return this.$t('services.types.service_updated_at')
      }

      if (recordType == 'service_online') {
        return this.$t('services.types.service_online')
      }

      return recordType
    }
  }
}
</script>
