<template>
  <div class="progress-bar">
    <span :style="{ width: progress + '%' }"></span>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped lang="scss">
@import '@main/sass/_variables';
@import '@main/sass/_mixins';

.progress-bar {
  width: 100%;
  height: 5px;
  background: $light_background;
  margin-top: 6px;
  border-radius: 10px;

  span {
    background: $theme;
    display: block;
    height: 100%;
    border-radius: 10px;
    max-width: 100%;
  }
}
</style>
