<template>
  <div :class="modal ? 'vue-dialog' : ''">
    Edit Atribute
  </div>
</template>

<script>
export default {
  title: '',
  name: 'AttributeEdit',
  props: {
    modal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isModal: this.modal,
      model: {
        // Data
        name: {},
        identifier: '',
        comment: '',
        required: false,
        type: ''
      }
    }
  },
  computed: {},
  async created() {},
  mounted() {
    this.$title = this.$t('attributes.titles.create')
  },
  methods: {
    setType(type) {
      this.model.type = type
    }
  }
}
</script>
